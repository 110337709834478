@media (-webkit-min-device-pixel-ratio: 1) and (-webkit-max-device-pixel-ratio: 1.24){
.navbar-style {
  background-color: #ffffffe6;
  position: fixed !important;
  top: 0;
  z-index: 9;
  width: 100%;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
  font-family: "Be Vietnam Pro", sans-serif;
  font-style: normal;
  .whatsappstyle{
    color: #25D366;
    // margin-right: 10px;
    padding: 15px;
    position: fixed;
    bottom: 200px;
    right: 0;
    z-index: 999;
    background-color: #fff;
    border-radius: 50% 0% 0% 50%;
    // height: 50px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .call{
    height: 24px;
    border: 1px solid #2b4bb6;
    border-radius: 4px;
    padding: 2px;
  }
  .btn-style {
    background-color: #2b4bb6;
    padding: 0px 24px;
    border-radius: 6px;
    border-color: transparent !important;
    height: 36px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    color: white; /* Ensure the text color is set for visibility */
    text-align: center; /* Center align text horizontally */
    display: inline-flex; /* Aligns text vertically */
    align-items: center; /* Aligns text vertically */
    justify-content: center; /* Aligns text horizontally */
    cursor: pointer;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
  }
  .btn-style::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200%;
    height: 200%;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.4s ease-in-out;
    z-index: 0;
  }
  
  .btn-style:hover::before {
    transform: translate(-50%, -50%) scale(1);
  }
  
  .btn-style:hover {
    background-color: #2b4bb6;
    color: white; /* Ensure text color remains visible on hover */
  }
  
  .franchisebtn {
    background-color: #3EBF1B;
    padding: 0px 24px;
    border-radius: 6px;
    border-color: transparent !important;
    height: 36px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    color: white; /* Ensure the text color is set for visibility */
    text-align: center; /* Center align text horizontally */
    display: inline-flex; /* Aligns text vertically */
    align-items: center; /* Aligns text vertically */
    justify-content: center; /* Aligns text horizontally */
    cursor: pointer;
    transition: all 0.4s ease-in-out;
}

.franchisebtn::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200%;
    height: 200%;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.4s ease-in-out;
    z-index: 0;
}

.franchisebtn:hover::before {
    transform: translate(-50%, -50%) scale(1);
}

.franchisebtn:hover {
    background-color: #3EBF1B; /* Ensure background color remains the same */
    color: white; /* Ensure text color remains visible on hover */
}

  .menustyle {
    color: #2b4bb6 !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    // &:hover{
    //   border-bottom: 3px solid #2b4bb6;
    // }
  }
  .navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: #2b4bb6;
}
.navbar-nav .nav-link, .navbar-nav .nav-link.show {
  color: #2b4bb6;
}
  .navbar-nav .nav-link.active,
  .navbar-nav .nav-link.show {
    &:focus,
    &:hover,
    &:active {
      border-bottom: 2px solid #2b4bb6;
      border: none;
    }
  }
  .navbar-toggler {
    background-color: transparent !important;
    border: none;
    padding: 6px;
  }
  .navbar-toggler:hover,
  .navbar-toggler:active,
  .navbar-toggler:focus {
    background-color: #84bbda4d !important;
    border-radius: 50%;
    padding: 6px;
    transition: 0.1s;
    border: 1px solid transparent;
  }
  .dropdown-menu::before{
    content: '';
    position: absolute;
    top: -20px;
    left:10px;
    border: 10px solid transparent;
    border-bottom-color: #ddd;
  }
  .dropdown-menu::after{
    content: '';
    position: absolute;
    top: -18px;
    left:10px;
    border: 10px solid transparent;
    border-bottom-color: #fff;
  }
/* Add the following CSS for hover effect */
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  visibility: visible;
  opacity: 1;
}
.nav-item.dropdown .dropdown-menu {
  display: none; /* Hide dropdown by default */
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s, opacity 0.2s ease-in-out;
}

  .dropdown-divider{
    margin: 0px;
    border-top: 1px solid #EDF1F4;
  }
  .logostyle {
    width: 100%;
    height: 64px;
    object-fit: contain;
    object-position: left;
  }
  .offcanvas-title {
    font-size: 15px;
    color: #2b4bb6;
  }
}
.contact-style {
  font-size: 16px;
  color: #2b4bb6;
  font-weight: 500;
}
.icon-style {
  width: 16px;
  height: 16px;
}
.menuhover {
  font-size: 28px !important;
  color: #2b4bb6 !important;
  font-weight: 600 !important;
  transition: width 2s !important;
  width: 0px !important;
  &:hover {
    border-bottom: 3px solid #2b4bb6 !important;
    color: #2b4bb6 !important;
    transition: width 2s !important;
    width: 150px !important;
  }
}
.navbar-nav .dropdown-menu {
  position: absolute !important;
   border:1px solid #ddd !important;
  box-shadow: -1px 1px 8px 0 rgba(0, 0, 0, 0.2);
}
.linkstyle {
  font-size: 16px ;
  font-weight: 600;
  color: #2b4bb6;
  text-decoration: none;
  transition: width 2s !important;
  width: 0px !important;
  &:hover {
    border-bottom: 3px solid #2b4bb6 !important;
    color: #2b4bb6 !important;
    transition: width 2s !important;
    width: 50px !important;
  }
}
.loginstyle{
  font-size: 15px !important;
  font-weight: 500 !important;
  color:#2B4BB6 !important;
}

@media (min-width: 1281px) and (max-width: 1368px) {
  .navbar-style {
    background-color: #ffffffe6;
    position: fixed !important;
    top: 0;
    z-index: 9;
    width: 100%;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .dropdown-item {
      font-size: 14px;
    }
    .call{
      height: 18px;
      border: 1px solid #2b4bb6;
      border-radius: 4px;
      padding: 2px;
    }
    .btn-style{
      background-color: #2b4bb6;
      padding: 2px 8px;
      border-radius: 6px;
      border-color: transparent !important;
       height: 24px;
      font-size: 14px;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
  }
  
  .btn-style::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
  }
  
  .btn-style:hover::before {
      transform: translate(-50%, -50%) scale(1);
  }
  
  .btn-style:hover {
      background-color: #2b4bb6; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
  }
 
    .franchisebtn {
      background-color: #3EBF1B;
      padding: 2px 8px;
      border-radius: 6px;
      border-color: transparent !important;
       height: 24px;
      font-size: 14px;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
  }
  .franchisebtn::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
  }
  
  .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
  }
  
  .franchisebtn:hover {
      background-color: #3EBF1B; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
  }
    .menustyle {
      color: #2b4bb6;
      font-size: 14px;
      font-weight: 600;
    }

    .navbar-nav .nav-link.active,
    .navbar-nav .nav-link.show {
      &:focus,
      &:hover,
      &:active {
        border-bottom: 2px solid #2b4bb6;
        border: none;
      }
    }
    .navbar-toggler {
      background-color: transparent !important;
      border: none;
      padding: 6px;
    }
    .navbar-toggler-icon {
      display: inline-block;
      width: 1em;
      height: 1em;
      vertical-align: middle;
      background-image: var(--bs-navbar-toggler-icon-bg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
    }
    .navbar-toggler:hover,
    .navbar-toggler:active,
    .navbar-toggler:focus {
      background-color: #84bbda4d !important;
      border-radius: 50%;
      padding: 6px;
      transition: 0.1s;
      border: 1px solid transparent;
    }
    .logostyle {
      width: 100%;
      height: 56px;
    }
    .offcanvas-title {
      font-size: 15px;
      color: #2b4bb6;
    }
  }
  .contact-style {
    font-size: 16px;
    color: #2b4bb6;
    font-weight: 500;
  }
  .icon-style {
    width: 16px;
    height: 16px;
  }
  .menuhover {
    font-size: 24px !important;
    color: #2b4bb6 !important;
    font-weight: 600 !important;
    transition: width 2s !important;
    width: 0px !important;
    &:hover {
      border-bottom: 3px solid #2b4bb6 !important;
      color: #2b4bb6 !important;
      transition: width 2s !important;
      width: 100px !important;
    }
  }
  .linkstyle {
    font-size: 16px;
    font-weight: 600;
    color: #2b4bb6;
    text-decoration: none;
    transition: width 2s;
    width: 0px;
    &:hover {
      border-bottom: 3px solid #2b4bb6;
      color: #2b4bb6;
      transition: width 2s;
      width: 50px;
    }
  }
}
@media (min-width: 992px) and (max-width: 1280px) {
  .navbar-style {
    background-color: #ffffffe6;
    position: fixed !important;
    top: 0;
    z-index: 9;
    width: 100%;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .dropdown-item {
      font-size: 12px;
    }
    .call{
      height: 18px;
      border: 1px solid #2b4bb6;
      border-radius: 4px;
      padding: 2px;
    }
    .btn-style{
      background-color: #2b4bb6;
      padding: 2px 10px;
      border-radius: 6px;
      border-color: transparent !important;
       height: 24px;
      font-size: 12px;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
  }
  
  .btn-style::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
  }
  
  .btn-style:hover::before {
      transform: translate(-50%, -50%) scale(1);
  }
  
  .btn-style:hover {
      background-color: #2b4bb6; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
  }

    .franchisebtn {
      background-color: #3EBF1B;
      padding: 2px 10px;
      border-radius: 6px;
      border-color: transparent !important;
       height: 24px;
      font-size: 12px;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
  }
  .franchisebtn::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
  }
  
  .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
  }
  
  .franchisebtn:hover {
      background-color: #3EBF1B; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
  }
    .menustyle {
      color: #2b4bb6;
      font-size: 12px !important;
      font-weight: 600;
    }

    .navbar-nav .nav-link.active,
    .navbar-nav .nav-link.show {
      &:focus,
      &:hover,
      &:active {
        border-bottom: 2px solid #2b4bb6;
        border: none;
      }
    }
    .navbar-toggler {
      background-color: transparent !important;
      border: none;
      padding: 6px;
    }
    .navbar-toggler-icon {
      display: inline-block;
      width: 1em;
      height: 1em;
      vertical-align: middle;
      background-image: var(--bs-navbar-toggler-icon-bg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
    }
    .navbar-toggler:hover,
    .navbar-toggler:active,
    .navbar-toggler:focus {
      background-color: #84bbda4d !important;
      border-radius: 50%;
      padding: 6px;
      transition: 0.1s;
      border: 1px solid transparent;
    }
    .logostyle {
      width: 100%;
      height: 45px;
    }
    .offcanvas-title {
      font-size: 15px;
      color: #2b4bb6;
    }
  }
  .contact-style {
    font-size: 14px;
    color: #2b4bb6;
    font-weight: 500;
  }
  .icon-style {
    width: 14px;
    height: 14px;
  }
  .menuhover {
    font-size: 22px !important;
    color: #2b4bb6 !important;
    font-weight: 600 !important;
    transition: width 2s !important;
    width: 0px !important;
    &:hover {
      border-bottom: 3px solid #2b4bb6 !important;
      color: #2b4bb6 !important;
      transition: width 2s !important;
      width: 100px !important;
    }
  }
  .linkstyle {
    font-size: 14px;
    font-weight: 600;
    color: #2b4bb6;
    text-decoration: none;
    transition: width 2s;
    width: 0px;
    &:hover {
      border-bottom: 3px solid #2b4bb6;
      color: #2b4bb6;
      transition: width 2s;
      width: 50px;
    }
  }
}
}
@media  (max-width: 430px) {
  .navbar-style {
    background-color: #ffffffe6;
    position: fixed !important;
    top: 0;
    z-index: 9;
    width: 100%;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .btn-style {
      background-color: #2b4bb6;
      padding: 2px;
      border-radius: 6px;
      border-color: transparent !important;
      font-weight: 500;
      font-size: 14px;
    }
    .franchisebtn {
      background-color: #3EBF1B;
      padding: 2px;
      border-radius: 6px;
      border-color: transparent !important;
      font-weight: 500;
      font-size: 14px;
    }
    .menustyle {
      color: #2b4bb6 !important;
      font-size: 14px !important;
      font-weight: 600 !important;
    }

    .navbar-nav .nav-link.active,
    .navbar-nav .nav-link.show {
      &:focus,
      &:hover,
      &:active {
        border-bottom: 2px solid #2b4bb6;
        border: none;
      }
    }
    .navbar-toggler {
      background-color: transparent !important;
      border: none;
      padding: 6px;
    }
    .navbar-toggler-icon {
      display: inline-block;
      width: 1em;
      height: 1em;
      vertical-align: middle;
      background-image: var(--bs-navbar-toggler-icon-bg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
    }
    .navbar-toggler:hover,
    .navbar-toggler:active,
    .navbar-toggler:focus {
      background-color: #84bbda4d !important;
      border-radius: 50%;
      padding: 6px;
      transition: 0.1s;
      border: 1px solid transparent;
    }
    .logostyle {
      width: 100%;
      height: 50px;
    }
    .offcanvas-title {
      font-size: 15px;
      color: #2b4bb6;
    }
  }
  .paddingmenubar{
    padding-left: 0px !important;
  }
  .contact-style {
    font-size: 16px;
    color: #2b4bb6;
    font-weight: 500;
  }
  .icon-style {
    width: 14px;
    height: 14px;
  }
  .menuhover {
    font-size: 18px !important;
    color: #2b4bb6 !important;
    font-weight: 600 !important;
    transition: width 2s !important;
    width: 0px !important;
    &:hover {
      border-bottom: 3px solid #2b4bb6 !important;
      color: #2b4bb6 !important;
      transition: width 2s !important;
      width: 100px !important;
    }
  }
  .linkstyle {
    font-size: 14px;
    font-weight: 600;
    color: #2b4bb6;
    text-decoration: none;
    transition: width 2s;
    width: 0px;
    &:hover {
      border-bottom: 3px solid #2b4bb6;
      color: #2b4bb6;
      transition: width 2s;
      width: 50px;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.49) {
  .navbar-style {
    background-color: #ffffffe6;
    position: fixed !important;
    top: 0;
    z-index: 9;
    width: 100%;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .whatsappstyle{
      color: #25D366;
      padding: 15px;
      position: fixed;
      bottom: 200px;
      right: 0;
      z-index: 999;
      background-color: #fff;
      border-radius: 50% 0% 0% 50%;
      // height: 50px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
      // margin-right: 10px;
    }
    .call{
      height: 24px;
      border: 1px solid #2b4bb6;
      border-radius: 4px;
      padding: 2px;
    }
    .btn-style {
      background-color: #2b4bb6;
      padding: 0px 24px;
      border-radius: 6px;
      border-color: transparent !important;
      height: 36px;
      font-size: 14px;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      -webkit-transition: all 0.4s ease-in-out;
      -moz-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      -ms-transition: all 0.4s ease-in-out;
    }
    .btn-style::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }
    
    .btn-style:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }
    
    .btn-style:hover {
      background-color: #2b4bb6;
      color: white; /* Ensure text color remains visible on hover */
    }
    
    .franchisebtn {
      background-color: #3EBF1B;
      padding: 0px 24px;
      border-radius: 6px;
      border-color: transparent !important;
      height: 36px;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
  }
  
  .franchisebtn::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
  }
  
  .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
  }
  
  .franchisebtn:hover {
      background-color: #3EBF1B; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
  }
  
    .menustyle {
      color: #2b4bb6 !important;
      font-size: 15px !important;
      font-weight: 600 !important;
      // &:hover{
      //   border-bottom: 3px solid #2b4bb6;
      // }
    }
    .navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
      color: #2b4bb6;
  }
  .navbar-nav .nav-link, .navbar-nav .nav-link.show {
    color: #2b4bb6;
  }
    .navbar-nav .nav-link.active,
    .navbar-nav .nav-link.show {
      &:focus,
      &:hover,
      &:active {
        border-bottom: 2px solid #2b4bb6;
        border: none;
      }
    }
    .navbar-toggler {
      background-color: transparent !important;
      border: none;
      padding: 6px;
    }
    .navbar-toggler:hover,
    .navbar-toggler:active,
    .navbar-toggler:focus {
      background-color: #84bbda4d !important;
      border-radius: 50%;
      padding: 6px;
      transition: 0.1s;
      border: 1px solid transparent;
    }
    .dropdown-menu::before{
      content: '';
      position: absolute;
      top: -20px;
      left:10px;
      border: 10px solid transparent;
      border-bottom-color: #ddd;
    }
    .dropdown-menu::after{
      content: '';
      position: absolute;
      top: -18px;
      left:10px;
      border: 10px solid transparent;
      border-bottom-color: #fff;
    }
    .dropdown-divider{
      margin: 0px;
      border-top: 1px solid #EDF1F4;
    }
    .logostyle {
      width: 100%;
      height: 60px;
      object-fit: contain;
      object-position: left;
    }
    .offcanvas-title {
      font-size: 15px;
      color: #2b4bb6;
    }
  }
  .contact-style {
    font-size: 16px;
    color: #2b4bb6;
    font-weight: 500;
  }
  .icon-style {
    width: 16px;
    height: 16px;
  }
  .menuhover {
    font-size: 28px !important;
    color: #2b4bb6 !important;
    font-weight: 600 !important;
    transition: width 2s !important;
    width: 0px !important;
    &:hover {
      border-bottom: 3px solid #2b4bb6 !important;
      color: #2b4bb6 !important;
      transition: width 2s !important;
      width: 150px !important;
    }
  }
  .navbar-nav .dropdown-menu {
    position: absolute !important;
     border:1px solid #ddd !important;
    box-shadow: -1px 1px 8px 0 rgba(0, 0, 0, 0.2);
  }
  .linkstyle {
    font-size: 16px ;
    font-weight: 600;
    color: #2b4bb6;
    text-decoration: none;
    transition: width 2s !important;
    width: 0px !important;
    &:hover {
      border-bottom: 3px solid #2b4bb6 !important;
      color: #2b4bb6 !important;
      transition: width 2s !important;
      width: 50px !important;
    }
  }
  .loginstyle{
    font-size: 15px !important;
    font-weight: 500 !important;
    color:#2B4BB6 !important;
  } 
  @media (min-width: 1281px) and (max-width: 1368px) {
    .navbar-style {
      background-color: #ffffffe6;
      position: fixed !important;
      top: 0;
      z-index: 9;
      width: 100%;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .call{
        height: 24px;
        border: 1px solid #2b4bb6;
        border-radius: 4px;
        padding: 2px;
      }
      .btn-style{
        background-color: #2b4bb6;
        padding: 2px 8px;
        border-radius: 6px;
        border-color: transparent !important;
         height: 24px;
        font-size: 14px;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
    }
    
    .btn-style::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
    }
    
    .btn-style:hover::before {
        transform: translate(-50%, -50%) scale(1);
    }
    
    .btn-style:hover {
        background-color: #2b4bb6; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
    }
   
      .franchisebtn {
        background-color: #3EBF1B;
        padding: 2px 8px;
        border-radius: 6px;
        border-color: transparent !important;
         height: 24px;
        font-size: 14px;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
    }
    .franchisebtn::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
    }
    
    .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
    }
    
    .franchisebtn:hover {
        background-color: #3EBF1B; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
    }
      .menustyle {
        color: #2b4bb6;
        font-size: 14px;
        font-weight: 600;
      }
  
      .navbar-nav .nav-link.active,
      .navbar-nav .nav-link.show {
        &:focus,
        &:hover,
        &:active {
          border-bottom: 2px solid #2b4bb6;
          border: none;
        }
      }
      .navbar-toggler {
        background-color: transparent !important;
        border: none;
        padding: 6px;
      }
      .navbar-toggler-icon {
        display: inline-block;
        width: 1em;
        height: 1em;
        vertical-align: middle;
        background-image: var(--bs-navbar-toggler-icon-bg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
      }
      .navbar-toggler:hover,
      .navbar-toggler:active,
      .navbar-toggler:focus {
        background-color: #84bbda4d !important;
        border-radius: 50%;
        padding: 6px;
        transition: 0.1s;
        border: 1px solid transparent;
      }
      .logostyle {
        width: 100%;
        height: 56px;
      }
      .offcanvas-title {
        font-size: 15px;
        color: #2b4bb6;
      }
    }
    .contact-style {
      font-size: 16px;
      color: #2b4bb6;
      font-weight: 500;
    }
    .icon-style {
      width: 16px;
      height: 16px;
    }
    .menuhover {
      font-size: 24px !important;
      color: #2b4bb6 !important;
      font-weight: 600 !important;
      transition: width 2s !important;
      width: 0px !important;
      &:hover {
        border-bottom: 3px solid #2b4bb6 !important;
        color: #2b4bb6 !important;
        transition: width 2s !important;
        width: 100px !important;
      }
    }
    .linkstyle {
      font-size: 16px;
      font-weight: 600;
      color: #2b4bb6;
      text-decoration: none;
      transition: width 2s;
      width: 0px;
      &:hover {
        border-bottom: 3px solid #2b4bb6;
        color: #2b4bb6;
        transition: width 2s;
        width: 50px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1280px) {
    .navbar-style {
      background-color: #ffffffe6;
      position: fixed !important;
      top: 0;
      z-index: 9;
      width: 100%;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .call{
        height: 18px;
        border: 1px solid #2b4bb6;
        border-radius: 4px;
        padding: 2px;
      }
      .btn-style{
        background-color: #2b4bb6;
        padding: 2px 10px;
        border-radius: 6px;
        border-color: transparent !important;
         height: 24px;
        font-size: 12px;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
    }
    
    .btn-style::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
    }
    
    .btn-style:hover::before {
        transform: translate(-50%, -50%) scale(1);
    }
    
    .btn-style:hover {
        background-color: #2b4bb6; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
    }
  
      .franchisebtn {
        background-color: #3EBF1B;
        padding: 2px 10px;
        border-radius: 6px;
        border-color: transparent !important;
         height: 24px;
        font-size: 12px;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
    }
    .franchisebtn::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
    }
    
    .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
    }
    
    .franchisebtn:hover {
        background-color: #3EBF1B; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
    }
      .menustyle {
        color: #2b4bb6;
        font-size: 12px !important;
        font-weight: 600;
      }
  
      .navbar-nav .nav-link.active,
      .navbar-nav .nav-link.show {
        &:focus,
        &:hover,
        &:active {
          border-bottom: 2px solid #2b4bb6;
          border: none;
        }
      }
      .navbar-toggler {
        background-color: transparent !important;
        border: none;
        padding: 6px;
      }
      .navbar-toggler-icon {
        display: inline-block;
        width: 1em;
        height: 1em;
        vertical-align: middle;
        background-image: var(--bs-navbar-toggler-icon-bg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
      }
      .navbar-toggler:hover,
      .navbar-toggler:active,
      .navbar-toggler:focus {
        background-color: #84bbda4d !important;
        border-radius: 50%;
        padding: 6px;
        transition: 0.1s;
        border: 1px solid transparent;
      }
      .logostyle {
        width: 100%;
        height: 45px;
      }
      .offcanvas-title {
        font-size: 15px;
        color: #2b4bb6;
      }
    }
    .contact-style {
      font-size: 14px;
      color: #2b4bb6;
      font-weight: 500;
    }
    .icon-style {
      width: 14px;
      height: 14px;
    }
    .menuhover {
      font-size: 22px !important;
      color: #2b4bb6 !important;
      font-weight: 600 !important;
      transition: width 2s !important;
      width: 0px !important;
      &:hover {
        border-bottom: 3px solid #2b4bb6 !important;
        color: #2b4bb6 !important;
        transition: width 2s !important;
        width: 100px !important;
      }
    }
    .linkstyle {
      font-size: 14px;
      font-weight: 600;
      color: #2b4bb6;
      text-decoration: none;
      transition: width 2s;
      width: 0px;
      &:hover {
        border-bottom: 3px solid #2b4bb6;
        color: #2b4bb6;
        transition: width 2s;
        width: 50px;
      }
    }
  }
}
@media (-webkit-min-device-pixel-ratio: 1.50) {
  .navbar-style {
    background-color: #ffffffe6;
    position: fixed !important;
    top: 0;
    z-index: 9;
    width: 100%;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .whatsappstyle{
      color: #25D366;
      padding: 15px;
      position: fixed;
      bottom: 200px;
      right: 0;
      z-index: 999;
      background-color: #fff;
      border-radius: 50% 0% 0% 50%;
      // height: 50px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
      // margin-right: 10px;
    }
    .call{
      height: 24px;
      border: 1px solid #2b4bb6;
      border-radius: 4px;
      padding: 2px;
    }
    .btn-style {
      background-color: #2b4bb6;
      padding: 0px 24px;
      border-radius: 6px;
      border-color: transparent !important;
      height: 36px;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      -webkit-transition: all 0.4s ease-in-out;
      -moz-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      -ms-transition: all 0.4s ease-in-out;
    }
    .btn-style::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }
    
    .btn-style:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }
    
    .btn-style:hover {
      background-color: #2b4bb6;
      color: white; /* Ensure text color remains visible on hover */
    }
    
    .franchisebtn {
      background-color: #3EBF1B;
      padding: 0px 24px;
      border-radius: 6px;
      border-color: transparent !important;
      height: 36px;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
  }
  
  .franchisebtn::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
  }
  
  .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
  }
  
  .franchisebtn:hover {
      background-color: #3EBF1B; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
  }
  
    .menustyle {
      color: #2b4bb6 !important;
      font-size: 15px !important;
      font-weight: 600 !important;
      // &:hover{
      //   border-bottom: 3px solid #2b4bb6;
      // }
    }
    .navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
      color: #2b4bb6;
  }
  .navbar-nav .nav-link, .navbar-nav .nav-link.show {
    color: #2b4bb6;
  }
    .navbar-nav .nav-link.active,
    .navbar-nav .nav-link.show {
      &:focus,
      &:hover,
      &:active {
        border-bottom: 2px solid #2b4bb6;
        border: none;
      }
    }
    .navbar-toggler {
      background-color: transparent !important;
      border: none;
      padding: 6px;
    }
    .navbar-toggler:hover,
    .navbar-toggler:active,
    .navbar-toggler:focus {
      background-color: #84bbda4d !important;
      border-radius: 50%;
      padding: 6px;
      transition: 0.1s;
      border: 1px solid transparent;
    }
    .dropdown-menu::before{
      content: '';
      position: absolute;
      top: -20px;
      left:10px;
      border: 10px solid transparent;
      border-bottom-color: #ddd;
    }
    .dropdown-menu::after{
      content: '';
      position: absolute;
      top: -18px;
      left:10px;
      border: 10px solid transparent;
      border-bottom-color: #fff;
    }
  /* Add the following CSS for hover effect */
  .nav-item.dropdown:hover .dropdown-menu {
    display: block;
    visibility: visible;
    opacity: 1;
  }
  .nav-item.dropdown .dropdown-menu {
    display: none; /* Hide dropdown by default */
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.2s, opacity 0.2s ease-in-out;
  }
  
    .dropdown-divider{
      margin: 0px;
      border-top: 1px solid #EDF1F4;
    }
    .logostyle {
      width: 100%;
      height: 64px;
      object-fit: contain;
      object-position: left;
    }
    .offcanvas-title {
      font-size: 15px;
      color: #2b4bb6;
    }
  }
  .contact-style {
    font-size: 16px;
    color: #2b4bb6;
    font-weight: 500;
  }
  .icon-style {
    width: 16px;
    height: 16px;
  }
  .menuhover {
    font-size: 28px !important;
    color: #2b4bb6 !important;
    font-weight: 600 !important;
    transition: width 2s !important;
    width: 0px !important;
    &:hover {
      border-bottom: 3px solid #2b4bb6 !important;
      color: #2b4bb6 !important;
      transition: width 2s !important;
      width: 150px !important;
    }
  }
  .navbar-nav .dropdown-menu {
    position: absolute !important;
     border:1px solid #ddd !important;
    box-shadow: -1px 1px 8px 0 rgba(0, 0, 0, 0.2);
  }
  .linkstyle {
    font-size: 16px ;
    font-weight: 600;
    color: #2b4bb6;
    text-decoration: none;
    transition: width 2s !important;
    width: 0px !important;
    &:hover {
      border-bottom: 3px solid #2b4bb6 !important;
      color: #2b4bb6 !important;
      transition: width 2s !important;
      width: 50px !important;
    }
  }
  .loginstyle{
    font-size: 15px !important;
    font-weight: 500 !important;
    color:#2B4BB6 !important;
  }
  @media (min-width: 1281px) and (max-width: 1368px) {
    .navbar-style {
      background-color: #ffffffe6;
      position: fixed !important;
      top: 0;
      z-index: 9;
      width: 100%;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .call{
        height: 18px;
        border: 1px solid #2b4bb6;
        border-radius: 4px;
        padding: 2px;
      }
      .btn-style{
        background-color: #2b4bb6;
        padding: 2px 8px;
        border-radius: 6px;
        border-color: transparent !important;
         height: 24px;
        font-size: 14px;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
    }
    
    .btn-style::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
    }
    
    .btn-style:hover::before {
        transform: translate(-50%, -50%) scale(1);
    }
    
    .btn-style:hover {
        background-color: #2b4bb6; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
    }
   
      .franchisebtn {
        background-color: #3EBF1B;
        padding: 2px 8px;
        border-radius: 6px;
        border-color: transparent !important;
         height: 24px;
        font-size: 14px;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
    }
    .franchisebtn::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
    }
    
    .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
    }
    
    .franchisebtn:hover {
        background-color: #3EBF1B; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
    }
      .menustyle {
        color: #2b4bb6;
        font-size: 14px;
        font-weight: 600;
      }
  
      .navbar-nav .nav-link.active,
      .navbar-nav .nav-link.show {
        &:focus,
        &:hover,
        &:active {
          border-bottom: 2px solid #2b4bb6;
          border: none;
        }
      }
      .navbar-toggler {
        background-color: transparent !important;
        border: none;
        padding: 6px;
      }
      .navbar-toggler-icon {
        display: inline-block;
        width: 1em;
        height: 1em;
        vertical-align: middle;
        background-image: var(--bs-navbar-toggler-icon-bg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
      }
      .navbar-toggler:hover,
      .navbar-toggler:active,
      .navbar-toggler:focus {
        background-color: #84bbda4d !important;
        border-radius: 50%;
        padding: 6px;
        transition: 0.1s;
        border: 1px solid transparent;
      }
      .logostyle {
        width: 100%;
        height: 56px;
      }
      .offcanvas-title {
        font-size: 15px;
        color: #2b4bb6;
      }
    }
    .contact-style {
      font-size: 16px;
      color: #2b4bb6;
      font-weight: 500;
    }
    .icon-style {
      width: 16px;
      height: 16px;
    }
    .menuhover {
      font-size: 24px !important;
      color: #2b4bb6 !important;
      font-weight: 600 !important;
      transition: width 2s !important;
      width: 0px !important;
      &:hover {
        border-bottom: 3px solid #2b4bb6 !important;
        color: #2b4bb6 !important;
        transition: width 2s !important;
        width: 100px !important;
      }
    }
    .linkstyle {
      font-size: 16px;
      font-weight: 600;
      color: #2b4bb6;
      text-decoration: none;
      transition: width 2s;
      width: 0px;
      &:hover {
        border-bottom: 3px solid #2b4bb6;
        color: #2b4bb6;
        transition: width 2s;
        width: 50px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1280px) {
    .navbar-style {
      background-color: #ffffffe6;
      position: fixed !important;
      top: 0;
      z-index: 9;
      width: 100%;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .call{
        height: 18px;
        border: 1px solid #2b4bb6;
        border-radius: 4px;
        padding: 2px;
      }
      .btn-style{
        background-color: #2b4bb6;
        padding: 2px 10px;
        border-radius: 6px;
        border-color: transparent !important;
         height: 24px;
        font-size: 12px;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
    }
    
    .btn-style::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
    }
    
    .btn-style:hover::before {
        transform: translate(-50%, -50%) scale(1);
    }
    
    .btn-style:hover {
        background-color: #2b4bb6; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
    }
  
      .franchisebtn {
        background-color: #3EBF1B;
        padding: 2px 10px;
        border-radius: 6px;
        border-color: transparent !important;
         height: 24px;
        font-size: 12px;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
    }
    .franchisebtn::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
    }
    
    .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
    }
    
    .franchisebtn:hover {
        background-color: #3EBF1B; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
    }
      .menustyle {
        color: #2b4bb6;
        font-size: 12px !important;
        font-weight: 600;
      }
  
      .navbar-nav .nav-link.active,
      .navbar-nav .nav-link.show {
        &:focus,
        &:hover,
        &:active {
          border-bottom: 2px solid #2b4bb6;
          border: none;
        }
      }
      .navbar-toggler {
        background-color: transparent !important;
        border: none;
        padding: 6px;
      }
      .navbar-toggler-icon {
        display: inline-block;
        width: 1em;
        height: 1em;
        vertical-align: middle;
        background-image: var(--bs-navbar-toggler-icon-bg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
      }
      .navbar-toggler:hover,
      .navbar-toggler:active,
      .navbar-toggler:focus {
        background-color: #84bbda4d !important;
        border-radius: 50%;
        padding: 6px;
        transition: 0.1s;
        border: 1px solid transparent;
      }
      .logostyle {
        width: 100%;
        height: 45px;
      }
      .offcanvas-title {
        font-size: 15px;
        color: #2b4bb6;
      }
    }
    .contact-style {
      font-size: 14px;
      color: #2b4bb6;
      font-weight: 500;
    }
    .icon-style {
      width: 14px;
      height: 14px;
    }
    .menuhover {
      font-size: 22px !important;
      color: #2b4bb6 !important;
      font-weight: 600 !important;
      transition: width 2s !important;
      width: 0px !important;
      &:hover {
        border-bottom: 3px solid #2b4bb6 !important;
        color: #2b4bb6 !important;
        transition: width 2s !important;
        width: 100px !important;
      }
    }
    .linkstyle {
      font-size: 14px;
      font-weight: 600;
      color: #2b4bb6;
      text-decoration: none;
      transition: width 2s;
      width: 0px;
      &:hover {
        border-bottom: 3px solid #2b4bb6;
        color: #2b4bb6;
        transition: width 2s;
        width: 50px;
      }
    }
  }
}