.footer{
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .footerlogo{
      height: 72px;
    }
    .footertxt{
      font-size: 16px;
      font-weight: 400;
      color:#000;
    }
    .fthead{
      font-size: 16px;
      font-weight: 700;
    }
    .contact-style{
      font-size: 17px;
      color: #2B4BB6;
      font-weight: 600;
      
    }
    .footerdiv{
      background-color: #A1E6FE;
      // display: flex;
      height: 86px;
      border-radius: 16px;
      font-size: 16px;
      font-weight: 500;
      padding: 20px;
    }
    .footerimg{
      height: 41px;
    }
  }
  @media (min-width:1441px) and (max-width:1680px){
    .footer{
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .footerlogo{
        height: 72px;
      }
      .footertxt{
        font-size: 16px;
        font-weight: 400;
      }
      .fthead{
        font-size: 16px;
        font-weight: 700;
      }
      .contact-style{
        font-size: 16px;
        color: #2B4BB6;
        font-weight: 500;
        
      }
      .footerdiv{
        background-color: #A1E6FE;
        // display: flex;
        height: 86px;
        border-radius: 16px;
        font-size: 16px;
        font-weight: 500;
        padding: 20px;
      }
      .footerimg{
        height: 41px;
      }
    }
  }
  @media (min-width:1281px) and (max-width:1440px){
    .footer{
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .footerlogo{
        height: 68px;
      }
      .footertxt{
        font-size: 14px;
        font-weight: 400;
      }
      .fthead{
        font-size: 16px;
        font-weight: 700;
      }
      .contact-style{
        font-size: 16px;
        color: #2B4BB6;
        font-weight: 500;
        
      }
      .footerdiv{
        background-color: #A1E6FE;
        // display: flex;
        height: 80px;
        border-radius: 16px;
        font-size: 14px;
        font-weight: 500;
        padding: 20px;
      }
      .footerimg{
        height: 38px;
      }
    }
  }
  @media (min-width:1025px) and (max-width:1280px){
    .footer{
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .footerlogo{
        height: 68px;
      }
      .footertxt{
        font-size: 12px;
        font-weight: 400;
      }
      .fthead{
        font-size: 14px;
        font-weight: 700;
      }
      .contact-style{
        font-size: 14px;
        color: #2B4BB6;
        font-weight: 500;
        
      }
      .footerdiv{
        background-color: #A1E6FE;
        // display: flex;
        height: 80px;
        border-radius: 16px;
        font-size: 14px;
        font-weight: 500;
        padding: 20px;
      }
      .footerimg{
        height: 38px;
      }
    }
  }
  @media (min-width:992px) and (max-width:1024px){
    .footer{
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .footerlogo{
        height: 60px;
      }
      .footertxt{
        font-size: 12px;
        font-weight: 400;
      }
      .fthead{
        font-size: 12px;
        font-weight: 700;
      }
      .contact-style{
        font-size: 12px;
        color: #2B4BB6;
        font-weight: 500;
        
      }
      .footerdiv{
        background-color: #A1E6FE;
        // display: flex;
        height: 80px;
        border-radius: 16px;
        font-size: 14px;
        font-weight: 500;
        padding: 20px;
      }
      .footerimg{
        height: 28px;
      }
    }
  }
  @media (max-width:430px){
    .footer{
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      padding: 20px;
      .footerlogo{
        height: 60px;
      }
      .footertxt{
        font-size: 14px;
        font-weight: 400;
      }
      .fthead{
        font-size: 14px;
        font-weight: 700;
      }
      .contact-style{
        font-size: 14px;
        color: #2B4BB6;
        font-weight: 500;
        
      }
      .footerdiv{
        background-color: #A1E6FE;
        // display: flex;
        height: 80px;
        border-radius: 16px;
        font-size: 14px;
        font-weight: 500;
        padding: 20px;
      }
      .footerimg{
        height: 28px;
      }
      .liststyle{
        padding-left: 0px !important;
      }
      .fttext{
        text-align: center !important;
      }
    }
  }
  @media (min-width:431px) and (max-width:767px){
    .footer{
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      padding: 20px;
      .fttext{
        text-align: center !important;
      }
      .footerlogo{
        height: 60px;
      }
      .footertxt{
        font-size: 14px;
        font-weight: 400;
      }
      .fthead{
        font-size: 14px;
        font-weight: 700;
      }
      .contact-style{
        font-size: 14px;
        color: #2B4BB6;
        font-weight: 500;
        
      }
      .footerdiv{
        background-color: #A1E6FE;
        // display: flex;
        height: 80px;
        border-radius: 16px;
        font-size: 14px;
        font-weight: 500;
        padding: 20px;
      }
      .footerimg{
        height: 28px;
      }
      .liststyle{
        padding-left: 0px !important;
      }
    }
  }
  @media (min-width:768px) and (max-width:991px){
    .footer{
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      padding: 20px;
      .footerlogo{
        height: 60px;
      }
      .footertxt{
        font-size: 14px;
        font-weight: 400;
      }
      .fthead{
        font-size: 14px;
        font-weight: 700;
      }
      .contact-style{
        font-size: 14px;
        color: #2B4BB6;
        font-weight: 500;
        
      }
      .footerdiv{
        background-color: #A1E6FE;
        // display: flex;
        height: 80px;
        border-radius: 16px;
        font-size: 14px;
        font-weight: 500;
        padding: 20px;
      }
      .footerimg{
        height: 28px;
      }
      .liststyle{
        padding-left: 0px !important;
      }
    }
  }

  // @media (-webkit-min-device-pixel-ratio: 1.25) {
  //   .footer{
  //     font-family: "Be Vietnam Pro", sans-serif;
  //     font-style: normal;
  //     .footerlogo{
  //       height: 72px;
  //     }
  //     .footertxt{
  //       font-size: 14px !important;
  //       font-weight: 400;
  //       color:#000;
  //     }
  //     .footerhead{
  //       font-size: 14px !important;
  //       font-weight: 700;
  //     }
  //     .contact-style{
  //       font-size: 14px !important;
  //       color: #2B4BB6;
  //       font-weight: 500;
        
  //     }
  //     .footerdiv{
  //       background-color: #A1E6FE;
  //       // display: flex;
  //       height: 86px;
  //       border-radius: 16px;
  //       font-size: 16px;
  //       font-weight: 500;
  //       padding: 20px;
  //     }
  //     .footerimg{
  //       height: 41px;
  //     }
  //   }
  //  }