@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .safari-style {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 78px;
    [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
    [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
      background-color: transparent;
    }
    .bgbanner2 {
      background-image: url("../images/banner2.webp");
      background-size: cover;
      background-repeat: no-repeat;
      //  width: 100vw;
      height: 80vh;
    }
    .bgbanner1 {
      background-image: url("../images/banner.webp");
      background-size: cover;
      background-repeat: no-repeat;
      //  width: 100vw;
      height: 80vh;
    }
    .bgbanner3 {
      background-image: url("../images/banner3.webp");
      background-size: cover;
      background-repeat: no-repeat;
      //  width: 100vw;
      height: 80vh;
    }
    // .headerimg {
    //   height: 250px;
    //   margin-top: -175px;
    //   border-radius: 16px;
    //   position: relative;
    // }
    .dividersection {
      position: relative;
      // height:100vh;
    }
    .bannerslik {
      //   .slick-track {
      //      width:100% !important;
      //      position: absolute;

      //   }
      //   .slick-list {
      //      position: relative;
      //     display: block;
      //     overflow: visible;
      //     margin: 0;
      //     padding: 0;
      // }
      .carousel-inner {
        overflow: visible;
      }
      [data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
      [data-bs-theme="dark"].carousel .carousel-control-next-icon,
      [data-bs-theme="dark"].carousel .carousel-control-prev-icon {
        filter: invert(0) grayscale(0);
      }
      .carousel-control-next-icon,
      .carousel-control-prev-icon {
        border-radius: 50%;
        background-color: #3ebf1b;
        padding: 5px;
      }
    }
    .headerimg {
      height: 25vh !important;
      width: 100% !important;
      margin-top: -105px;
      border-radius: 16px;
      // animation: slide-up 0.5s ease-in-out;
      // position: relative;
      position: absolute;
      /* Center the top-left position of the img */
      top: 0;
      left: 0;

      /* Center relative to the diving line */
      transform: none;
    }
    .logoimg {
      height: 70px;
      text-align: center;
    }
    .headtext {
      font-size: 21px;
      font-weight: 700;
    }
    .badgestyle {
      background-color: #2b4bb6;
      color: #ffffff;
      border-radius: 34px;
      height: 40px;
      padding: 10px;
      box-shadow: none;
      font-size: 14px;
      margin-bottom: 5px;
    }
    .badgestylegreen {
      background-color: #3ebf1b;
      color: #ffffff;
      border-radius: 34px;
      height: 40px;
      padding: 10px;
      box-shadow: none;
      font-size: 14px;
      margin-bottom: 5px;
    }
  }

  .navsafari {
    background-color: #ffffffe6;
    position: fixed !important;
    top: 0;
    z-index: 9;
    width: 100%;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;

    .call {
      height: 18px;
      border: 1px solid #2b4bb6;
      border-radius: 4px;
      padding: 2px;
    }

    .whatsappstyle {
      color: #25D366;
      // margin-right: 10px;
      padding: 15px;
      position: fixed;
      bottom: 200px;
      right: 0;
      z-index: 999;
      background-color: #fff;
      border-radius: 50% 0% 0% 50%;
      // height: 50px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
      // margin-right: 10px;
    }
    .btn-style {
      background-color: #2b4bb6;
      padding: 2px;
      border-radius: 6px;
      border-color: transparent !important;
      font-weight: 500;
      font-size: 14px;
    }
    .franchisebtn {
      background-color: #3ebf1b;
      padding: 2px;
      border-radius: 6px;
      border-color: transparent !important;
      font-weight: 500;
      font-size: 14px;
    }
    .menustyle {
      color: #2b4bb6 !important;
      font-size: 14px;
      font-weight: 600;
    }

    .navbar-nav .nav-link.active,
    .navbar-nav .nav-link.show {
      color: #2b4bb6;
    }
    .navbar-nav .nav-link,
    .navbar-nav .nav-link.show {
      color: #2b4bb6;
    }
    .navbar-nav .nav-link.active,
    .navbar-nav .nav-link.show {
      &:focus,
      &:hover,
      &:active {
        border-bottom: 2px solid #2b4bb6;
        border: none;
      }
    }
    .navbar-toggler {
      background-color: transparent !important;
      border: none;
      padding: 6px;
    }
    .dropdown-menu::before {
      content: "";
      position: absolute;
      top: -20px;
      left: 10px;
      border: 10px solid transparent;
      border-bottom-color: #ddd;
    }
    .dropdown-menu::after {
      content: "";
      position: absolute;
      top: -18px;
      left: 10px;
      border: 10px solid transparent;
      border-bottom-color: #fff;
    }
    .dropdown-divider {
      margin: 0px;
      border-top: 1px solid #edf1f4;
    }
    .navbar-toggler-icon {
      display: inline-block;
      width: 1em;
      height: 1em;
      vertical-align: middle;
      background-image: var(--bs-navbar-toggler-icon-bg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      position: absolute;
      right: 2rem;
      top: 2rem;
    }
    // .navbar-toggler:hover,
    // .navbar-toggler:active,
    // .navbar-toggler:focus {
    //   background-color: #84bbda4d !important;
    //   border-radius: 50%;
    //   padding: 6px;
    //   transition: 0.1s;
    //   border: 1px solid transparent;
    // }
    .logostyle {
      width: 100%;
      height: 50px;
      object-fit: contain;
      object-position: left;
    }
    .offcanvas-title {
      font-size: 15px;
      color: #2b4bb6;
    }
  }
  .paddingmenubar {
    padding-left: 0px !important;
  }
  .contact-style {
    font-size: 16px;
    color: #2b4bb6;
    font-weight: 500;
  }
  .icon-style {
    width: 14px;
    height: 14px;
  }
  .menuhover {
    font-size: 18px !important;
    color: #2b4bb6 !important;
    font-weight: 600 !important;
    transition: width 2s !important;
    width: 0px !important;
    &:hover {
      border-bottom: 3px solid #2b4bb6 !important;
      color: #2b4bb6 !important;
      transition: width 2s !important;
      width: 100px !important;
    }
  }
  .linkstyle {
    font-size: 14px;
    font-weight: 600;
    color: #2b4bb6;
    text-decoration: none;
    transition: width 2s;
    width: 0px;
    &:hover {
      border-bottom: 3px solid #2b4bb6;
      color: #2b4bb6;
      transition: width 2s;
      width: 50px;
    }
  }
  .aboutsafari {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 86px;
    .logostyle {
      width: 100%;
      height: 100px;
      object-fit: contain;
      object-position: left;
    }
    .about {
      background-color: #2b4bb6;
      font-size: 33px;
      font-weight: 800;
      color: #fff;
    }
    .aboutmg {
      margin-top: 10px !important;
    }
    .head {
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      color: #000;
    }
    .text-style {
      font-size: 14px;
      font-weight: 300;
      color: #000;
    }
    .sendmail {
      font-size: 14px;
      font-weight: 800;
      color: #000;
    }
    .boldstyle {
      font-weight: 700;
    }
    .aboutimg {
      background-image: url("../images/aboutbg.webp");
      background-repeat: no-repeat;
      background-size: cover;
      padding: 60px 20px;
    }
    .imgstyle {
      height: 70px;
      margin-bottom: 20px;
    }
    .visionhead {
      font-size: 22px;
      font-weight: 500;
      color: #fff;
    }
    .visiontext {
      font-size: 14px;
      font-weight: 200;
      color: #fff;
    }
    .commonSizeImagesStyle {
      height: 115px;
      margin-bottom: 30px;
    }
    .arrowStyle {
      height: 0px !important;
    }
    .arrowStyle1 {
      height: 34px;
    }
    .newfont {
      font-size: 24px;
      color: #00599d;
      font-family: "Indie Flower", cursive;
    }
    .subhead {
      font-size: 20px;
      font-weight: 700;
    }
    .circle {
      display: none !important;
    }
    .vl {
      display: none !important;
    }

    .adminstyle {
      height: 335px;
    }
    .adminhead {
      font-size: 14px;
      font-weight: 800;
    }
    .admintxt {
      font-size: 14px;
      font-weight: 300;
    }
    .admin-img {
      height: 180px;
      border-radius: 32px 32px 32px 0px;
    }
    .btnstyle {
      background-color: transparent;
      color: #2b4bb6;
      border-radius: 6px;
      border: 1px solid #bdc6cb;
      margin-bottom: 10px;
      &:hover,
      &:focus {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
      }
    }

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      color: #2b4bb6;
      background-color: #beebf8;
      border-color: transparent;
    }
    .btnstyle-active {
      background-color: #beebf8 !important;
      color: #2b4bb6 !important;
      border-radius: 6px;
      border: transparent;
      margin-bottom: 10px;
    }
  }
  .whyussafari {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 86px;
    .about {
      background-color: #2b4bb6;
      font-size: 33px;
      font-weight: 800;
      color: #fff;
    }
    .cardstyle {
      background-color: #e3f6fc;
      border-radius: 16px;
      padding: 20px;
      border: 1px solid transparent;
    }
    .headstyle {
      color: #2b4bb6;
      font-size: 28px;
      font-weight: 700;
      line-height: 34px;
    }
    .textstyle {
      font-size: 14px;
      font-weight: 300;
      color: #000;
    }
    .imgstyle {
      height: 92px;
    }
  }
  .testimonial-safari {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 92px;
    .home-style {
      .styleform {
        margin: 20px 10px;
        border-radius: 12px;
        border: 1px solid #c9e3f5;
        background: #eff7fd;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
        padding: 20px 20px;
      }
      .backgroundlanding {
        background-image: url("../images/bannerlight.webp");
        background-size: cover;
        background-repeat: no-repeat;
        //   height: 81vh;
      }

      .tagline {
        color: #222;
        font-family: "Be Vietnam Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        margin: 0px 10px;
      }
      @media only screen and (max-width: 767px) {
        .bnlandingimg {
          height: 0% !important;
          width: 0% !important;
          // display: none !important;
        }
      }
      .logoimg {
        height: 82px;
        text-align: center;
        animation: slide-up 0.5s ease-in-out;
      }
      .headtext {
        font-size: 21px;
        font-weight: 700;
        animation: slide-up 0.4s ease-in-out;
        margin: 0px 10px;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: none;
        font-size: 14px;
        margin: 5px 10px 0px 10px;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: none;
        font-size: 14px;
        margin: 0px 10px;
      }
    }

    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      margin: 0px 10px;
      .cardstyle {
        height: 480px;
        margin-right: 20px;
      }
      .cardstyle1 {
        height: auto !important;
        box-shadow: 0 0 10px #ccc;
        margin-bottom: 20px;
        .badgesince {
          background-color: #beebf8;
          color: #000;
          font-size: 12px;
          font-weight: 500;
          padding: 3px 10px;
          border-radius: 52px;
          width: fit-content;
        }
      }
      .cardhead {
        font-size: 18px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 14px;
        font-weight: 300;
      }
      .whyusimg {
        height: 65px;
      }
      .whyuscard {
        position: relative;
        overflow: hidden;
        background-color: #f9f9f9; /* Default background color */
        transition: background-color 0.3s ease;
        box-shadow: 0 0 10px #ccc;
        height: 175px;
      }
      .whyuscard::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background-color: #a1e6fe; /* The hover background color */
        transition: left 0.4s ease;
        z-index: 0; /* Make sure it's behind the text */
      }
      .whyuscard:hover::before {
        left: 0;
      }
      .whyuscard .content {
        position: relative;
        z-index: 1; /* Ensure the content stays above the background */
      }
      .quote {
        font-size: 40px;
        color: #f58220;
      }
      .subtitlehead {
        font-size: 16px;
        text-align: center;
        font-weight: 700;
      }
    }
    .about-style {
      .subhead {
        font-size: 24px;
        font-weight: 700;
      }
      .lblstyle {
        font-size: 14px;
        font-weight: 300;
      }
      .fieldstyle {
        font-size: 14px;
        padding: 4px;
      }
    }
    .bgenquery {
      background-image: url("../images/bgenquiry.webp");
      background-size: cover;
      background-repeat: no-repeat;
      height: 150px;
      background-color: #beebf8;
      border-radius: 20px;
      padding: 15px 0px;
      margin: 0px 10px;
    }
    .earnings {
      .earningtxt {
        color: #333;
        font-size: 12px;
        font-weight: 600;
      }
      .earningcard {
        border-radius: 12px;
        background: #2b4bb6;
        padding: 73px 54px;
      }

      .normaltxt {
        color: #fff;
        font-size: 16px;
        font-weight: 300;
      }
    }
    .aboutsub {
      color: #fff;
      font-size: 16px;
      font-weight: 600;
    }
    .lastsection {
      background-image: url("../images/lastpanel.webp");
      background-size: cover;
      background-repeat: no-repeat;
      .womanimg {
        height: 450px;
      }
      .lastpanelcard {
        border-radius: 14px;
        background: #2b4bb5;
        font-size: 19px;
        font-weight: 700;
        color: #fff;
      }
      .pargraph {
        color: #2b4bb5;
        text-align: center;
        font-size: 19px;
        font-weight: 700;
      }
      .txtstyle {
        color: #222;
        font-size: 16px;
        font-weight: 500;
        line-height: 36px;
      }
    }
  }
}

@media only screen and (max-width: 1279px) {
  .navbar-expand-lg .navbar-collapse {
    // display: flex!important;
    flex-basis: -webkit-fill-available !important;
  }
  .safari-style {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 72px;
    .carousel-inner {
      overflow: visible;
    }
    .bgbanner2 {
      background-image: url("../images/banner2.webp");
      background-size: cover;
      background-repeat: no-repeat;
      //  width: 100vw;
      height: 73vh;
    }
    .bgbanner1 {
      background-image: url("../images/banner.webp");
      background-size: cover;
      background-repeat: no-repeat;
      //  width: 100vw;
      height: 73vh;
    }
    .bgbanner3 {
      background-image: url("../images/banner3.webp");
      background-size: cover;
      background-repeat: no-repeat;
      //  width: 100vw;
      height: 73vh;
    }
    // .headerimg {
    //   height: 270px;
    //   margin-top: -208px;
    //   border-radius: 16px;
    //   position: relative;
    // }
    .aboutmg {
      margin-top: 100px !important;
    }
    .dividersection {
      position: relative;
      // height:100vh;
    }
    .headerimg {
      height: 50vh !important;
      width: 100% !important;
      // margin-top: -445px;
      border-radius: 16px;
      // animation: slide-up 0.5s ease-in-out;
      // position: relative;
      position: absolute;

      /* Center the top-left position of the img */
      top: 50%;
      left: 50%;

      /* Center relative to the diving line */
      transform: translate(-50%, -80%);
    }
    .logoimg {
      height: 70px;
      text-align: center;
    }
    .headtext {
      font-size: 24px;
      font-weight: 700;
    }
    .badgestyle {
      background-color: #2b4bb6;
      color: #ffffff;
      border-radius: 34px;
      height: 45px;
      padding: 3px 2px;
      box-shadow: 1px 6px #7ca7c87d;
      font-size: 12px;
    }
    .badgestylegreen {
      background-color: #3ebf1b;
      color: #ffffff;
      border-radius: 34px;
      height: 45px;
      padding: 3px 2px;
      box-shadow: 1px 6px #2dd10045;
      font-size: 12px;
    }
  }
  .navsafari {
    background-color: #ffffffe6;
    position: fixed !important;
    top: 0;
    z-index: 9;
    width: 100%;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .dropdown-item {
      font-size: 12px;
    }
    .call {
      height: 18px;
      border: 1px solid #2b4bb6;
      border-radius: 4px;
      padding: 2px;
    }
    .whatsappstyle {
      color: #25d366;
      padding: 15px;
      position: fixed;
      bottom: 200px;
      right: 0;
      z-index: 999;
      background-color: #fff;
      border-radius: 50% 0% 0% 50%;
      // height: 50px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
      // margin-right: 10px;
    }
    .btn-style {
      background-color: #2b4bb6;
      padding: 2px 10px;
      border-radius: 6px;
      border-color: transparent !important;
      height: 24px;
      font-size: 12px;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .btn-style::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .btn-style:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .btn-style:hover {
      background-color: #2b4bb6; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }

    .franchisebtn {
      background-color: #3ebf1b;
      padding: 2px 10px;
      border-radius: 6px;
      border-color: transparent !important;
      height: 24px;
      font-size: 12px;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }
    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #3ebf1b; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
    .menustyle {
      color: #2b4bb6;
      font-size: 12px;
      font-weight: 600;
    }

    .navbar-nav .nav-link.active,
    .navbar-nav .nav-link.show {
      color: #2b4bb6;
    }
    .navbar-nav .nav-link,
    .navbar-nav .nav-link.show {
      color: #2b4bb6;
    }
    .navbar-nav .nav-link.active,
    .navbar-nav .nav-link.show {
      &:focus,
      &:hover,
      &:active {
        border-bottom: 2px solid #2b4bb6;
        border: none;
      }
    }
    .navbar-toggler {
      background-color: transparent !important;
      border: none;
      padding: 6px;
    }
    .dropdown-menu::before {
      content: "";
      position: absolute;
      top: -20px;
      left: 10px;
      border: 10px solid transparent;
      border-bottom-color: #ddd;
    }
    .dropdown-menu::after {
      content: "";
      position: absolute;
      top: -18px;
      left: 10px;
      border: 10px solid transparent;
      border-bottom-color: #fff;
    }
    .dropdown-divider {
      margin: 0px;
      border-top: 1px solid #edf1f4;
    }
    .navbar-toggler-icon {
      display: inline-block;
      width: 1em;
      height: 1em;
      vertical-align: middle;
      background-image: var(--bs-navbar-toggler-icon-bg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
    }
    .navbar-toggler:hover,
    .navbar-toggler:active,
    .navbar-toggler:focus {
      background-color: #84bbda4d !important;
      border-radius: 50%;
      padding: 6px;
      transition: 0.1s;
      border: 1px solid transparent;
    }
    .logostyle {
      width: 100%;
      height: 45px;
    }
    .offcanvas-title {
      font-size: 15px;
      color: #2b4bb6;
    }
  }
  .contact-style {
    font-size: 12px;
    color: #2b4bb6;
    font-weight: 500;
  }
  .icon-style {
    width: 14px;
    height: 14px;
  }
  .menuhover {
    font-size: 22px !important;
    color: #2b4bb6 !important;
    font-weight: 600 !important;
    transition: width 2s !important;
    width: 0px !important;
    &:hover {
      border-bottom: 3px solid #2b4bb6 !important;
      color: #2b4bb6 !important;
      transition: width 2s !important;
      width: 100px !important;
    }
  }
  .linkstyle {
    font-size: 12px;
    font-weight: 600;
    color: #2b4bb6;
    text-decoration: none;
    transition: width 2s;
    width: 0px;
    &:hover {
      border-bottom: 3px solid #2b4bb6;
      color: #2b4bb6;
      transition: width 2s;
      width: 50px;
    }
  }
  .aboutsafari {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 72px;
    .about {
      background-color: #2b4bb6;
      font-size: 30px;
      font-weight: 800;
      color: #fff;
    }
    .head {
      font-size: 34px;
      font-weight: 700;
      text-align: center;
      color: #000;
    }
    .text-style {
      font-size: 12px;
      font-weight: 300;
      color: #000;
    }
    .boldstyle {
      font-weight: 700;
    }
    .aboutimg {
      background-image: url("../images/aboutbg.webp");
      background-repeat: no-repeat;
      background-size: cover;
      padding: 60px 20px;
    }
    .imgstyle {
      height: 65px;
      margin-bottom: 20px;
    }
    .visionhead {
      font-size: 16px;
      font-weight: 500;
      color: #fff;
    }
    .visiontext {
      font-size: 12px;
      font-weight: 200;
      color: #fff;
    }
    .commonSizeImagesStyle {
      height: 115px;
    }
    .arrowStyle {
      height: 50px !important;
    }
    .arrowStyle1 {
      height: 30px;
    }
    .newfont {
      font-size: 19px;
      color: #00599d;
      font-family: "Indie Flower", cursive;
    }
    .subhead {
      font-size: 19px;
      font-weight: 700;
    }
    .circle {
      color: #2b4bb6;
      // padding: 20px;
      height: 18px;
      border-radius: 50%;
      background-color: #2b4bb6;
      width: 18px;
      display: block !important;
    }
    .vl {
      height: 150px;
      padding: 10px;
      display: block !important;
    }

    .adminstyle {
      height: 260px;
    }
    .adminhead {
      font-size: 12px;
      font-weight: 800;
    }
    .admintxt {
      font-size: 12px;
      font-weight: 300;
    }
    .admin-img {
      height: 200px;
      border-radius: 32px 32px 32px 0px;
    }
    .btnstyle {
      background-color: transparent;
      color: #2b4bb6;
      border-radius: 6px;
      border: 1px solid #bdc6cb;
      margin-bottom: 10px;
      font-size: 12px;
      padding: 5px;
      &:hover,
      &:focus {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
        font-size: 12px;
        padding: 5px;
      }
    }

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      color: #2b4bb6;
      background-color: #beebf8;
      border-color: transparent;
      font-size: 12px;
      padding: 5px;
    }
    .btnstyle-active {
      background-color: #beebf8 !important;
      color: #2b4bb6 !important;
      border-radius: 6px;
      border: transparent;
      margin-bottom: 10px;
      padding: 5px;
      font-size: 12px;
    }
    .contactimg {
      height: 320px;
      text-align: center;
    }
    .contacthead {
      color: #2b4bb6;
      font-size: 12px;
      font-weight: 700;
    }
    .contactico {
      width: 16px;
      height: 16px;
    }
    .direction {
      background-color: #2b4bb6;
      text-align: center;
    }
    .directionhead {
      font-size: 18px;
      color: #2b4bb6;
      font-weight: 800;
      padding: 10px;
    }
    .directiontext {
      font-size: 12px;
      font-weight: 300;
      text-align: right;
      padding: 10px;
    }
    .contactstyle {
      padding: 5px;
      box-shadow: 8px 8px 5px #eaf7fc;
      margin-bottom: 15px;
    }
    .sendmail {
      font-size: 12px;
      font-weight: 800;
      color: #000;
    }
    .textstyle {
      font-size: 12px;
      font-weight: 300;
      color: #000;
    }
    .socialico {
      color: #2b4bb6;
      border: 1px solid #bdc6cb;
      padding: 7px;
      border-radius: 50%;
      height: 14px;
    }
    .textstyle {
      font-size: 12px;
      font-weight: 300;
    }
    .socialtxt {
      font-size: 12px;
    }
    .shareit {
      border: 1px solid #bdc6cb;
      padding: 2px 10px 2px 0px;
      border-radius: 40px;
    }
  }
  .whyussafari {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 72px;
    .about {
      background-color: #2b4bb6;
      font-size: 30px;
      font-weight: 800;
      color: #fff;
    }
    .cardstyle {
      background-color: #e3f6fc;
      border-radius: 16px;
      padding: 20px;
      border: 1px solid transparent;
    }
    .headstyle {
      color: #2b4bb6;
      font-size: 28px;
      font-weight: 700;
      line-height: 35px;
    }
    .textstyle {
      font-size: 12px;
      font-weight: 300;
      color: #000;
    }
    .imgstyle {
      height: 70px;
    }
  }
  .testimonial-safari {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 72px;

    .home-style {
      .styleform {
        border-radius: 12px;
        border: 1px solid #c9e3f5;
        background: #eff7fd;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
        padding: 10px 10px;
      }
      .backgroundlanding {
        background-image: url("../images/bannerlight.webp");
        background-size: cover;
        background-repeat: no-repeat;
        //   height: 81vh;
      }

      .tagline {
        color: #222;
        font-family: "Be Vietnam Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
      }
      .bnlandingimg {
        width: 100%;
        display: block !important;
        height: -webkit-fill-available;
      }
      .logoimg {
        height: 82px;
        text-align: center;
        animation: slide-up 0.5s ease-in-out;
      }
      .headtext {
        font-size: 22px;
        font-weight: 700;
        animation: slide-up 0.4s ease-in-out;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 28px;
        font-size: 12px;
        padding: 4px;
        box-shadow: 2px 9px #7ca7c87d;
        animation: slide-up 0.6s ease-in-out;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 28px;
        padding: 4px;
        font-size: 12px;
        box-shadow: 2px 9px #2dd10045;
        animation: slide-up 0.6s ease-in-out;
      }
    }

    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      .cardstyle {
        height: 480px;
        margin-right: 20px;
      }
      .cardstyle1 {
        height: 305px !important;
        box-shadow: 0 0 10px #ccc;
        margin-bottom: 20px;
        .badgesince {
          background-color: #beebf8;
          color: #000;
          font-size: 12px;
          font-weight: 500;
          padding: 3px 10px;
          border-radius: 52px;
          width: fit-content;
        }
      }
      .cardhead {
        font-size: 16px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 12px;
        font-weight: 300;
      }
      .whyusimg {
        height: 70px;
      }
      .whyuscard {
        position: relative;
        overflow: hidden;
        background-color: #f9f9f9; /* Default background color */
        transition: background-color 0.3s ease;
        box-shadow: 0 0 10px #ccc;
        padding: 15px 0px;
      }
      .whyuscard::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background-color: #a1e6fe; /* The hover background color */
        transition: left 0.4s ease;
        z-index: 0; /* Make sure it's behind the text */
      }
      .whyuscard:hover::before {
        left: 0;
      }
      .whyuscard .content {
        position: relative;
        z-index: 1; /* Ensure the content stays above the background */
      }
      .quote {
        font-size: 40px;
        color: #f58220;
      }
      .subtitlehead {
        font-size: 12px;
        text-align: center;
        font-weight: 700;
      }
    }
    .about-style {
      .subhead {
        font-size: 12px;
        font-weight: 700;
      }
      .lblstyle {
        font-size: 12px;
        font-weight: 300;
        margin-bottom: -10px;
      }
      .fieldstyle {
        font-size: 12px;
        padding: 4px;
        margin-bottom: -10px;
      }
    }
    .bgenquery {
      background-image: url("../images/bgenquiry.webp");
      background-size: cover;
      background-repeat: no-repeat;
      height: 180px;
      background-color: #beebf8;
      border-radius: 20px;
      padding: 30px 0px;
    }
    .earnings {
      .earningtxt {
        color: #333;
        font-size: 12px;
        font-weight: 600;
      }
      .earningcard {
        border-radius: 12px;
        background: #2b4bb6;
        padding: 73px 54px;
      }

      .normaltxt {
        color: #fff;
        font-size: 14px;
        font-weight: 300;
      }
    }
    .aboutsub {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
    }
    .lastsection {
      background-image: url("../images/lastpanel.webp");
      background-size: cover;
      background-repeat: no-repeat;
      .womanimg {
        height: 350px;
      }
      .lastpanelcard {
        border-radius: 14px;
        background: #2b4bb5;
        font-size: 16px;
        font-weight: 700;
        color: #fff;
      }
      .pargraph {
        color: #2b4bb5;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
      }
      .txtstyle {
        color: #222;
        font-size: 14px;
        font-weight: 500;
        line-height: 36px;
      }
    }
  }
}
@media only screen and (min-width: 1280px) {
  .navbar-expand-lg .navbar-collapse {
    // display: flex!important;
    flex-basis: -webkit-fill-available !important;
  }
  .safari-style {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 72px;
    .carousel-inner {
      overflow: visible;
    }
    .bgbanner2 {
      background-image: url("../images/banner2.webp");
      background-size: cover;
      background-repeat: no-repeat;
      //  width: 100vw;
      height: 81vh;
    }
    .bgbanner1 {
      background-image: url("../images/banner.webp");
      background-size: cover;
      background-repeat: no-repeat;
      //  width: 100vw;
      height: 81vh;
    }
    .bgbanner3 {
      background-image: url("../images/banner3.webp");
      background-size: cover;
      background-repeat: no-repeat;
      //  width: 100vw;
      height: 81vh;
    }
    // .headerimg {
    //   height: 400px;
    //   margin-top: -260px;
    //   border-radius: 16px;
    //   position: relative;
    // }
    .aboutmg {
      margin-top: 90px !important;
    }
    .dividersection {
      position: relative;
      // height:100vh;
    }
    .headerimg {
      height: 45vh !important;
      width: 100% !important;
      // margin-top: -445px;
      border-radius: 16px;
      // animation: slide-up 0.5s ease-in-out;
      // position: relative;
      position: absolute;

      /* Center the top-left position of the img */
      top: 50%;
      left: 50%;

      /* Center relative to the diving line */
      transform: translate(-50%, -50%);
    }
    .logoimg {
      height: 70px;
      text-align: center;
    }
    .headtext {
      font-size: 30px;
      font-weight: 700;
    }
    .badgestyle {
      background-color: #2b4bb6;
      color: #ffffff;
      border-radius: 34px;
      height: 26px;
      padding: 4px;
      box-shadow: 1px 6px #7ca7c87d;
      font-size: 12px;
    }
    .badgestylegreen {
      background-color: #3ebf1b;
      color: #ffffff;
      border-radius: 34px;
      height: 26px;
      padding: 4px;
      box-shadow: 1px 6px #2dd10045;
      font-size: 12px;
    }
  }
  .navsafari {
    background-color: #ffffffe6;
    position: fixed !important;
    top: 0;
    z-index: 9;
    width: 100%;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .call {
      height: 24px;
      border: 1px solid #2b4bb6;
      border-radius: 4px;
      padding: 2px;
    }
    .whatsappstyle {
      color: #25d366;
      padding: 15px;
      position: fixed;
      bottom: 200px;
      right: 0;
      z-index: 999;
      background-color: #fff;
      border-radius: 50% 0% 0% 50%;
      // height: 50px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
      // margin-right: 10px;
    }
    .btn-style {
      background-color: #2b4bb6;
      padding: 2px 10px;
      border-radius: 6px;
      border-color: transparent !important;
      height: 24px;
      width: -webkit-max-content;
      font-size: 12px;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .btn-style::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .btn-style:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .btn-style:hover {
      background-color: #2b4bb6; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }

    .franchisebtn {
      background-color: #3ebf1b;
      padding: 2px 10px;
      border-radius: 6px;
      border-color: transparent !important;
      height: 24px;
      font-size: 12px;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }
    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #3ebf1b; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
    .menustyle {
      color: #2b4bb6;
      font-size: 14px;
      font-weight: 600;
    }

    .navbar-nav .nav-link.active,
    .navbar-nav .nav-link.show {
      color: #2b4bb6;
    }
    .navbar-nav .nav-link,
    .navbar-nav .nav-link.show {
      color: #2b4bb6;
    }
    .navbar-nav .nav-link.active,
    .navbar-nav .nav-link.show {
      &:focus,
      &:hover,
      &:active {
        border-bottom: 2px solid #2b4bb6;
        border: none;
      }
    }
    .navbar-toggler {
      background-color: transparent !important;
      border: none;
      padding: 6px;
    }
    .dropdown-menu::before {
      content: "";
      position: absolute;
      top: -20px;
      left: 10px;
      border: 10px solid transparent;
      border-bottom-color: #ddd;
    }
    .dropdown-menu::after {
      content: "";
      position: absolute;
      top: -18px;
      left: 10px;
      border: 10px solid transparent;
      border-bottom-color: #fff;
    }
    .dropdown-divider {
      margin: 0px;
      border-top: 1px solid #edf1f4;
    }
    .navbar-toggler-icon {
      display: inline-block;
      width: 1em;
      height: 1em;
      vertical-align: middle;
      background-image: var(--bs-navbar-toggler-icon-bg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
    }
    .navbar-toggler:hover,
    .navbar-toggler:active,
    .navbar-toggler:focus {
      background-color: #84bbda4d !important;
      border-radius: 50%;
      padding: 6px;
      transition: 0.1s;
      border: 1px solid transparent;
    }
    .logostyle {
      width: 100%;
      height: 45px;
    }
    .offcanvas-title {
      font-size: 15px;
      color: #2b4bb6;
    }
  }
  .contact-style {
    font-size: 14px;
    color: #2b4bb6;
    font-weight: 500;
  }
  .icon-style {
    width: 14px;
    height: 14px;
  }
  .menuhover {
    font-size: 22px !important;
    color: #2b4bb6 !important;
    font-weight: 600 !important;
    transition: width 2s !important;
    width: 0px !important;
    &:hover {
      border-bottom: 3px solid #2b4bb6 !important;
      color: #2b4bb6 !important;
      transition: width 2s !important;
      width: 100px !important;
    }
  }
  .linkstyle {
    font-size: 14px;
    font-weight: 600;
    color: #2b4bb6;
    text-decoration: none;
    transition: width 2s;
    width: 0px;
    &:hover {
      border-bottom: 3px solid #2b4bb6;
      color: #2b4bb6;
      transition: width 2s;
      width: 50px;
    }
  }
  .aboutsafari {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 72px;
    .about {
      background-color: #2b4bb6;
      font-size: 36px;
      font-weight: 800;
      color: #fff;
    }
    .logostyle {
      width: 100%;
      height: max-content;
      object-fit: contain;
      object-position: left;
    }
    .head {
      font-size: 34px;
      font-weight: 700;
      text-align: center;
      color: #000;
    }
    .text-style {
      font-size: 14px;
      font-weight: 300;
      color: #000;
    }
    .boldstyle {
      font-weight: 700;
    }
    .aboutimg {
      background-image: url("../images/aboutbg.webp");
      background-repeat: no-repeat;
      background-size: cover;
      padding: 60px 20px;
    }
    .imgstyle {
      height: 65px;
      margin-bottom: 20px;
    }
    .visionhead {
      font-size: 18px;
      font-weight: 500;
      color: #fff;
    }
    .visiontext {
      font-size: 14px;
      font-weight: 200;
      color: #fff;
    }
    .commonSizeImagesStyle {
      height: 115px;
    }
    .arrowStyle {
      height: 50px !important;
    }
    .arrowStyle1 {
      height: 34px;
    }
    .newfont {
      font-size: 19px;
      color: #00599d;
      font-family: "Indie Flower", cursive;
    }
    .subhead {
      font-size: 19px;
      font-weight: 700;
    }
    .circle {
      color: #2b4bb6;
      // padding: 20px;
      height: 18px;
      border-radius: 50%;
      background-color: #2b4bb6;
      width: 18px;
      display: block !important;
    }
    .vl {
      height: 150px;
      padding: 10px;
      display: block !important;
    }

    .adminstyle {
      height: 340px;
    }
    .adminhead {
      font-size: 14px;
      font-weight: 800;
    }
    .admintxt {
      font-size: 14px;
      font-weight: 300;
    }
    .admin-img {
      height: 250px;
      border-radius: 32px 32px 32px 0px;
    }
    .btnstyle {
      background-color: transparent;
      color: #2b4bb6;
      border-radius: 6px;
      border: 1px solid #bdc6cb;
      margin-bottom: 10px;
      font-size: 14px;
      padding: 5px;
      &:hover,
      &:focus {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
        font-size: 14px;
        padding: 5px;
      }
    }

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      color: #2b4bb6;
      background-color: #beebf8;
      border-color: transparent;
      font-size: 14px;
      padding: 5px;
    }
    .btnstyle-active {
      background-color: #beebf8 !important;
      color: #2b4bb6 !important;
      border-radius: 6px;
      border: transparent;
      margin-bottom: 10px;
      padding: 5px;
      font-size: 14px;
    }
    .contactimg {
      height: 415px;
      text-align: center;
    }
    .contacthead {
      color: #2b4bb6;
      font-size: 16px;
      font-weight: 700;
    }
    .contactico {
      width: 16px;
      height: 16px;
    }
    .direction {
      background-color: #2b4bb6;
      text-align: center;
    }
    .directionhead {
      font-size: 22px;
      color: #2b4bb6;
      font-weight: 800;
      padding: 10px;
    }
    .directiontext {
      font-size: 16px;
      font-weight: 300;
      text-align: right;
      padding: 10px;
    }
    .contactstyle {
      padding: 10px;
      box-shadow: 8px 8px 5px #eaf7fc;
      margin-bottom: 15px;
    }
    .socialico {
      color: #2b4bb6;
      border: 1px solid #bdc6cb;
      padding: 10px;
      border-radius: 50%;
      height: 18px;
    }
    .textstyle {
      font-size: 14px;
      font-weight: 300;
    }
    .socialtxt {
      font-size: 14px;
    }
    .shareit {
      border: 1px solid #bdc6cb;
      padding: 2px 10px 2px 0px;
      border-radius: 40px;
    }
    .sendmail {
      font-size: 14px;
      font-weight: 800;
      color: #000;
    }
  }
  .whyussafari {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 72px;
    .about {
      background-color: #2b4bb6;
      font-size: 36px;
      font-weight: 800;
      color: #fff;
    }
    .cardstyle {
      background-color: #e3f6fc;
      border-radius: 16px;
      padding: 20px;
      border: 1px solid transparent;
    }
    .headstyle {
      color: #2b4bb6;
      font-size: 34px;
      font-weight: 700;
      line-height: 35px;
    }
    .textstyle {
      font-size: 14px;
      font-weight: 300;
      color: #000;
    }
    .imgstyle {
      height: 70px;
    }
  }
  .testimonial-safari {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 72px;
    .home-style {
      .styleform {
        border-radius: 12px;
        border: 1px solid #c9e3f5;
        background: #eff7fd;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
        padding: 10px 15px;
      }
      .backgroundlanding {
        background-image: url("../images/bannerlight.webp");
        background-size: cover;
        background-repeat: no-repeat;
        //   height: 81vh;
      }

      .tagline {
        color: #222;
        font-family: "Be Vietnam Pro";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
      }
      .bnlandingimg {
        width: 100%;
        display: block !important;
      }
      .logoimg {
        height: 82px;
        text-align: center;
        animation: slide-up 0.5s ease-in-out;
      }
      .headtext {
        font-size: 28px;
        font-weight: 700;
        animation: slide-up 0.4s ease-in-out;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 28px;
        padding: 4px;
        box-shadow: 2px 9px #7ca7c87d;
        animation: slide-up 0.6s ease-in-out;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 28px;
        padding: 4px;
        box-shadow: 2px 9px #2dd10045;
        animation: slide-up 0.6s ease-in-out;
      }
    }

    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      .cardstyle {
        height: 480px;
        margin-right: 20px;
      }
      .cardstyle1 {
        height: 305px !important;
        box-shadow: 0 0 10px #ccc;
        margin-bottom: 20px;
        .badgesince {
          background-color: #beebf8;
          color: #000;
          font-size: 12px;
          font-weight: 500;
          padding: 3px 10px;
          border-radius: 52px;
          width: fit-content;
        }
      }
      .cardhead {
        font-size: 20px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 14px;
        font-weight: 300;
      }
      .whyusimg {
        height: 75px;
      }
      .whyuscard {
        position: relative;
        overflow: hidden;
        background-color: #f9f9f9; /* Default background color */
        transition: background-color 0.3s ease;
        box-shadow: 0 0 10px #ccc;
        padding: 15px 0px;
      }
      .whyuscard::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background-color: #a1e6fe; /* The hover background color */
        transition: left 0.4s ease;
        z-index: 0; /* Make sure it's behind the text */
      }
      .whyuscard:hover::before {
        left: 0;
      }
      .whyuscard .content {
        position: relative;
        z-index: 1; /* Ensure the content stays above the background */
      }
      .quote {
        font-size: 50px;
        color: #f58220;
      }
      .subtitlehead {
        font-size: 14px;
        text-align: center;
        font-weight: 700;
      }
    }
    .about-style {
      .subhead {
        font-size: 16px;
        font-weight: 700;
      }
      .lblstyle {
        font-size: 14px;
        font-weight: 300;
      }
      .fieldstyle {
        font-size: 14px;
        padding: 4px;
        margin-bottom: -10px;
      }
    }
    .bgenquery {
      background-image: url("../images/bgenquiry.webp");
      background-size: cover;
      background-repeat: no-repeat;
      height: 250px;
      background-color: #beebf8;
      border-radius: 20px;
      padding: 84px 0px;
    }
    .earnings {
      .earningtxt {
        color: #333;
        font-size: 12px;
        font-weight: 600;
      }
      .earningcard {
        border-radius: 12px;
        background: #2b4bb6;
        padding: 73px 54px;
      }

      .normaltxt {
        color: #fff;
        font-size: 16px;
        font-weight: 300;
      }
    }
    .aboutsub {
      color: #fff;
      font-size: 16px;
      font-weight: 600;
    }
    .lastsection {
      background-image: url("../images/lastpanel.webp");
      background-size: cover;
      background-repeat: no-repeat;
      .womanimg {
        height: 450px;
      }
      .lastpanelcard {
        border-radius: 14px;
        background: #2b4bb5;
        font-size: 19px;
        font-weight: 700;
        color: #fff;
      }
      .pargraph {
        color: #2b4bb5;
        text-align: center;
        font-size: 19px;
        font-weight: 700;
      }
      .txtstyle {
        color: #222;
        font-size: 16px;
        font-weight: 500;
        line-height: 36px;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .safari-style {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 88px;
    [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
    [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
      background-color: transparent;
    }
    .bgbanner2 {
      background-image: url("../images/banner2.webp");
      background-size: cover;
      background-repeat: no-repeat;
      //  width: 100vw;
      height: 80vh;
    }
    .bgbanner1 {
      background-image: url("../images/banner.webp");
      background-size: cover;
      background-repeat: no-repeat;
      //  width: 100vw;
      height: 80vh;
    }
    .bgbanner3 {
      background-image: url("../images/banner3.webp");
      background-size: cover;
      background-repeat: no-repeat;
      //  width: 100vw;
      height: 80vh;
    }
 
    .dividersection {
      position: relative;
      // height:100vh;
    }
    .bannerslik {
   
      .carousel-inner {
        overflow: visible;
      }
      [data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
      [data-bs-theme="dark"].carousel .carousel-control-next-icon,
      [data-bs-theme="dark"].carousel .carousel-control-prev-icon {
        filter: invert(0) grayscale(0);
      }
      .carousel-control-next-icon,
      .carousel-control-prev-icon {
        border-radius: 50%;
        background-color: #3ebf1b;
        padding: 5px;
      }
    }
    .headerimg {
      height: 29.5vh !important;
      width: 100% !important;
      margin-top: -166px;
      border-radius: 16px;
      // animation: slide-up 0.5s ease-in-out;
      // position: relative;
      position: absolute;
      /* Center the top-left position of the img */
      top: 0;
      left: 0;

      /* Center relative to the diving line */
      transform: none;
    }
    .logoimg {
      height: 70px;
      text-align: center;
    }
    .headtext {
      font-size: 21px;
      font-weight: 700;
    }
    .badgestyle {
      background-color: #2b4bb6;
      color: #ffffff;
      border-radius: 34px;
      height: 40px;
      padding: 10px;
      box-shadow: none;
      font-size: 14px;
      margin-bottom: 5px;
    }
    .badgestylegreen {
      background-color: #3ebf1b;
      color: #ffffff;
      border-radius: 34px;
      height: 40px;
      padding: 10px;
      box-shadow: none;
      font-size: 14px;
      margin-bottom: 5px;
    }
  }

  .navsafari {
    background-color: #ffffffe6;
    position: fixed !important;
    top: 0;
    z-index: 9;
    width: 100%;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;

    .call {
      height: 18px;
      border: 1px solid #2b4bb6;
      border-radius: 4px;
      padding: 2px;
    }

    .whatsappstyle {
      color: #25d366;
      padding: 15px;
      position: fixed;
      bottom: 200px;
      right: 0;
      z-index: 999;
      background-color: #fff;
      border-radius: 50% 0% 0% 50%;
      // height: 50px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
      // margin-right: 10px;
    }
    .btn-style {
      background-color: #2b4bb6;
      padding: 2px;
      border-radius: 6px;
      border-color: transparent !important;
      font-weight: 500;
      font-size: 14px;
    }
    .franchisebtn {
      background-color: #3ebf1b;
      padding: 2px;
      border-radius: 6px;
      border-color: transparent !important;
      font-weight: 500;
      font-size: 14px;
    }
    .menustyle {
      color: #2b4bb6 !important;
      font-size: 14px;
      font-weight: 600;
    }

    .navbar-nav .nav-link.active,
    .navbar-nav .nav-link.show {
      color: #2b4bb6;
    }
    .navbar-nav .nav-link,
    .navbar-nav .nav-link.show {
      color: #2b4bb6;
    }
    .navbar-nav .nav-link.active,
    .navbar-nav .nav-link.show {
      &:focus,
      &:hover,
      &:active {
        border-bottom: 2px solid #2b4bb6;
        border: none;
      }
    }
    .navbar-toggler {
      background-color: transparent !important;
      border: none;
      padding: 6px;
    }
    .dropdown-menu::before {
      content: "";
      position: absolute;
      top: -20px;
      left: 10px;
      border: 10px solid transparent;
      border-bottom-color: #ddd;
    }
    .dropdown-menu::after {
      content: "";
      position: absolute;
      top: -18px;
      left: 10px;
      border: 10px solid transparent;
      border-bottom-color: #fff;
    }
    .dropdown-divider {
      margin: 0px;
      border-top: 1px solid #edf1f4;
    }
    .navbar-toggler-icon {
      display: inline-block;
      width: 1em;
      height: 1em;
      vertical-align: middle;
      background-image: var(--bs-navbar-toggler-icon-bg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      position: absolute;
      right: 2rem;
      top: 2rem;
    }
    // .navbar-toggler:hover,
    // .navbar-toggler:active,
    // .navbar-toggler:focus {
    //   background-color: #84bbda4d !important;
    //   border-radius: 50%;
    //   padding: 6px;
    //   transition: 0.1s;
    //   border: 1px solid transparent;
    // }
    .logostyle {
      width: 100%;
      height: 50px;
      object-fit: contain;
      object-position: left;
    }
    .offcanvas-title {
      font-size: 15px;
      color: #2b4bb6;
    }
  }
  .paddingmenubar {
    padding-left: 0px !important;
  }
  .contact-style {
    font-size: 16px;
    color: #2b4bb6;
    font-weight: 500;
  }
  .icon-style {
    width: 14px;
    height: 14px;
  }
  .menuhover {
    font-size: 18px !important;
    color: #2b4bb6 !important;
    font-weight: 600 !important;
    transition: width 2s !important;
    width: 0px !important;
    &:hover {
      border-bottom: 3px solid #2b4bb6 !important;
      color: #2b4bb6 !important;
      transition: width 2s !important;
      width: 100px !important;
    }
  }
  .linkstyle {
    font-size: 14px;
    font-weight: 600;
    color: #2b4bb6;
    text-decoration: none;
    transition: width 2s;
    width: 0px;
    &:hover {
      border-bottom: 3px solid #2b4bb6;
      color: #2b4bb6;
      transition: width 2s;
      width: 50px;
    }
  }
  .aboutsafari {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 86px;
    .pdmob {
      padding: 0px 20px;
    }
    .sendmail {
      font-size: 14px;
      font-weight: 800;
      color: #000;
    }
    .logostyle {
      width: 100%;
      height: 100px;
      object-fit: contain;
      object-position: left;
    }
    .about {
      background-color: #2b4bb6;
      font-size: 33px;
      font-weight: 800;
      color: #fff;
    }
    .aboutmg {
      margin-top: 10px !important;
    }
    .head {
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      color: #000;
    }
    .text-style {
      font-size: 14px;
      font-weight: 300;
      color: #000;
    }
    .boldstyle {
      font-weight: 700;
    }
    .aboutimg {
      background-image: url("../images/aboutbg.webp");
      background-repeat: no-repeat;
      background-size: cover;
      padding: 60px 20px;
    }
    .imgstyle {
      height: 70px;
      margin-bottom: 20px;
    }
    .visionhead {
      font-size: 22px;
      font-weight: 500;
      color: #fff;
    }
    .visiontext {
      font-size: 14px;
      font-weight: 200;
      color: #fff;
    }
    .commonSizeImagesStyle {
      height: 115px;
      margin-bottom: 30px;
    }
    .arrowStyle {
      height: 0px !important;
    }
    .arrowStyle1 {
      height: 34px;
    }
    .newfont {
      font-size: 24px;
      color: #00599d;
      font-family: "Indie Flower", cursive;
    }
    .subhead {
      font-size: 20px;
      font-weight: 700;
    }
    .circle {
      display: none !important;
    }
    .vl {
      display: none !important;
    }
    .adminstyle {
      height: 335px;
    }
    .adminhead {
      font-size: 14px;
      font-weight: 800;
    }
    .admintxt {
      font-size: 14px;
      font-weight: 300;
    }
    .admin-img {
      height: 180px;
      border-radius: 32px 32px 32px 0px;
    }
    .btnstyle {
      background-color: transparent;
      color: #2b4bb6;
      border-radius: 6px;
      border: 1px solid #bdc6cb;
      margin-bottom: 10px;
      &:hover,
      &:focus {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
      }
    }

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      color: #2b4bb6;
      background-color: #beebf8;
      border-color: transparent;
    }
    .btnstyle-active {
      background-color: #beebf8 !important;
      color: #2b4bb6 !important;
      border-radius: 6px;
      border: transparent;
      margin-bottom: 10px;
    }
    .socialico {
      color: #2b4bb6;
      border: 1px solid #bdc6cb;
      padding: 5px;
      border-radius: 50%;
      height: 16px;
    }
    .socialtxt {
      font-size: 12px;
    }
    .textstyle {
      font-size: 14px;
      font-weight: 300;
    }
    .shareit {
      border: 1px solid #bdc6cb;
      padding: 2px 5px 2px 0px;
      border-radius: 40px;
      font-size: 14px;
    }
    .direction {
      background-color: #2b4bb6;
      text-align: center;
    }
    .directionhead {
      font-size: 14px;
      color: #2b4bb6;
      font-weight: 800;
      padding: 0px;
    }
    .directiontext {
      font-size: 12px;
      font-weight: 300;
      text-align: right;
      padding: 0px;
    }
  }
  .whyussafari {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 86px;
    .about {
      background-color: #2b4bb6;
      font-size: 33px;
      font-weight: 800;
      color: #fff;
    }
    .cardstyle {
      background-color: #e3f6fc;
      border-radius: 16px;
      padding: 20px;
      border: 1px solid transparent;
    }
    .headstyle {
      color: #2b4bb6;
      font-size: 28px;
      font-weight: 700;
      line-height: 34px;
    }
    .textstyle {
      font-size: 14px;
      font-weight: 300;
      color: #000;
    }
    .imgstyle {
      height: 92px;
    }
  }
  .testimonial-safari {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 92px;
    .home-style {
      .styleform {
        margin: 20px 10px;
        border-radius: 12px;
        border: 1px solid #c9e3f5;
        background: #eff7fd;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
        padding: 20px 20px;
      }
      .backgroundlanding {
        background-image: url("../images/bannerlight.webp");
        background-size: cover;
        background-repeat: no-repeat;
        //   height: 81vh;
      }

      .tagline {
        color: #222;
        font-family: "Be Vietnam Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        margin: 0px 10px;
      }
      @media only screen and (max-width: 767px) {
        .bnlandingimg {
          height: 0% !important;
          width: 0% !important;
          // display: none !important;
        }
      }
      .logoimg {
        height: 82px;
        text-align: center;
        animation: slide-up 0.5s ease-in-out;
      }
      .headtext {
        font-size: 21px;
        font-weight: 700;
        animation: slide-up 0.4s ease-in-out;
        margin: 0px 10px;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: none;
        font-size: 14px;
        margin: 5px 10px 0px 10px;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: none;
        font-size: 14px;
        margin: 0px 10px;
      }
    }

    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      margin: 0px 10px;
      .cardstyle {
        height: 480px;
        margin-right: 20px;
      }
      .cardstyle1 {
        height: auto !important;
        box-shadow: 0 0 10px #ccc;
        margin-bottom: 20px;
        .badgesince {
          background-color: #beebf8;
          color: #000;
          font-size: 12px;
          font-weight: 500;
          padding: 3px 10px;
          border-radius: 52px;
          width: fit-content;
        }
      }
      .cardhead {
        font-size: 18px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 14px;
        font-weight: 300;
      }
      .whyusimg {
        height: 65px;
      }
      .whyuscard {
        position: relative;
        overflow: hidden;
        background-color: #f9f9f9; /* Default background color */
        transition: background-color 0.3s ease;
        box-shadow: 0 0 10px #ccc;
        height: 195px;
      }
      .whyuscard::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background-color: #a1e6fe; /* The hover background color */
        transition: left 0.4s ease;
        z-index: 0; /* Make sure it's behind the text */
      }
      .whyuscard:hover::before {
        left: 0;
      }
      .whyuscard .content {
        position: relative;
        z-index: 1; /* Ensure the content stays above the background */
      }
      .quote {
        font-size: 40px;
        color: #f58220;
      }
      .subtitlehead {
        font-size: 16px;
        text-align: center;
        font-weight: 700;
      }
    }
    .about-style {
      .subhead {
        font-size: 24px;
        font-weight: 700;
      }
      .lblstyle {
        font-size: 14px;
        font-weight: 300;
      }
      .fieldstyle {
        font-size: 14px;
        padding: 4px;
      }
    }
    .bgenquery {
      background-image: url("../images/bgenquiry.webp");
      background-size: cover;
      background-repeat: no-repeat;
      height: 150px;
      background-color: #beebf8;
      border-radius: 20px;
      padding: 15px 0px;
      margin: 0px 10px;
    }
    .earnings {
      margin:0px 10px;
      .earningtxt {
        color: #333;
        font-size: 12px;
        font-weight: 600;
      }
      .earningcard {
        border-radius: 12px;
        background: #2b4bb6;
        padding: 73px 54px;
      }

      .normaltxt {
        color: #fff;
        font-size: 16px;
        font-weight: 300;
      }
    }
    .aboutsub {
      color: #fff;
      font-size: 16px;
      font-weight: 600;
    }
    .lastsection {
      background-image: url("../images/lastpanel.webp");
      background-size: cover;
      background-repeat: no-repeat;
      .womanimg {
        height: 450px;
      }
      .lastpanelcard {
        border-radius: 14px;
        background: #2b4bb5;
        font-size: 19px;
        font-weight: 700;
        color: #fff;
      }
      .pargraph {
        color: #2b4bb5;
        text-align: center;
        font-size: 19px;
        font-weight: 700;
      }
      .txtstyle {
        color: #222;
        font-size: 16px;
        font-weight: 500;
        line-height: 36px;
      }
    }
  }
  
 
}


