@media (-webkit-min-device-pixel-ratio: 1) and (-webkit-max-device-pixel-ratio: 1.24) {
.businesspartner{
  font-family: "Be Vietnam Pro", sans-serif;
  font-style: normal;
  width: 100%;
  margin-top: 92px;
  background-image: url("../images/banner.webp");
  background-size: cover;
  background-repeat: no-repeat;
  //  width: 100vw;
  // height: 81vh;
  /* Add this to your CSS file */
.spinner-border.custom-spinner {
  color: white;
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: orange;
  // border-radius: 50%;
  // padding: 0.5rem;

}
.Toastify__toast-container{
  top:auto;
  left:50%;
  transform: translateX(-50%);
}
}
@media (min-width:992px) and (max-width:1280px){
  .businesspartner{
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 72px;
    background-image: url("../images/banner.webp");
    background-size: cover;
    background-repeat: no-repeat;
    //  width: 100vw;
    // height: 81vh;
  }
}
@media (min-width:1281px) and (max-width:1368px){
  .businesspartner{
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 83px;
    background-image: url("../images/banner.webp");
    background-size: cover;
    background-repeat: no-repeat;
    //  width: 100vw;
    // height: 81vh;
  }
}
@media (min-width:1369px) and (max-width:1440px){
  .businesspartner{
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 83px;
    background-image: url("../images/banner.webp");
    background-size: cover;
    background-repeat: no-repeat;
    //  width: 100vw;
    // height: 81vh;
  }
}
@media (min-width:1441px) and (max-width:1668px){
  .businesspartner{
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 83px;
    background-image: url("../images/banner.webp");
    background-size: cover;
    background-repeat: no-repeat;
    //  width: 100vw;
    // height: 81vh;
  }
}
}


@media (-webkit-min-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.49) {
  .businesspartner{
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 88px;
    background-image: url("../images/banner.webp");
    background-size: cover;
    background-repeat: no-repeat;
    //  width: 100vw;
    // height: 81vh;
  }}

  @media (-webkit-min-device-pixel-ratio: 1.50) {
    .businesspartner{
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 92px;
      background-image: url("../images/banner.webp");
      background-size: cover;
      background-repeat: no-repeat;
      //  width: 100vw;
      // height: 81vh;
      /* Add this to your CSS file */
    .spinner-border.custom-spinner {
      color: white;
      width: 1rem;
      height: 1rem;
      border-width: 0.2em;
    }
    
    .spinner-container {
      display: flex;
      align-items: center;
      justify-content: center;
      // background-color: orange;
      // border-radius: 50%;
      // padding: 0.5rem;
    
    }
    .Toastify__toast-container{
      top:auto;
      left:50%;
      transform: translateX(-50%);
    }
    }
    @media (min-width:992px) and (max-width:1280px){
      .businesspartner{
        font-family: "Be Vietnam Pro", sans-serif;
        font-style: normal;
        width: 100%;
        margin-top: 72px;
        background-image: url("../images/banner.webp");
        background-size: cover;
        background-repeat: no-repeat;
        //  width: 100vw;
        // height: 81vh;
      }
    }
    @media (min-width:1281px) and (max-width:1368px){
      .businesspartner{
        font-family: "Be Vietnam Pro", sans-serif;
        font-style: normal;
        width: 100%;
        margin-top: 83px;
        background-image: url("../images/banner.webp");
        background-size: cover;
        background-repeat: no-repeat;
        //  width: 100vw;
        // height: 81vh;
      }
    }
    @media (min-width:1369px) and (max-width:1440px){
      .businesspartner{
        font-family: "Be Vietnam Pro", sans-serif;
        font-style: normal;
        width: 100%;
        margin-top: 72px;
        background-image: url("../images/banner.webp");
        background-size: cover;
        background-repeat: no-repeat;
        //  width: 100vw;
        // height: 81vh;
      }
    }
    @media (min-width:1441px) and (max-width:1668px){
      .businesspartner{
        font-family: "Be Vietnam Pro", sans-serif;
        font-style: normal;
        width: 100%;
        margin-top: 83px;
        background-image: url("../images/banner.webp");
        background-size: cover;
        background-repeat: no-repeat;
        //  width: 100vw;
        // height: 81vh;
      }
    }
    }
//mob businesspartner
  @media (min-width:768px) and (max-width:991px) {
    .businesspartner{
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 88px;
      background-image: url("../images/banner.webp");
      background-size: cover;
      background-repeat: no-repeat;
      //  width: 100vw;
      // height: 81vh;
    }
    .pdmob{
      padding: 0px 20px;
    }
  }
  @media (min-width:541px) and (max-width:767px) {
    .businesspartner{
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 88px;
      background-image: url("../images/banner.webp");
      background-size: cover;
      background-repeat: no-repeat;
      //  width: 100vw;
      // height: 81vh;
    }
    .pdmob{
      padding: 0px 20px;
    }
  }
  @media (min-width:416px) and (max-width:540px) {
    .businesspartner{
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 88px;
      background-image: url("../images/banner.webp");
      background-size: cover;
      background-repeat: no-repeat;
      //  width: 100vw;
      // height: 81vh;
    }
    .pdmob{
      padding: 0px 20px;
    }
  }
  @media (min-width:412px) and (max-width:415px) {
    .businesspartner{
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 88px;
      background-image: url("../images/banner.webp");
      background-size: cover;
      background-repeat: no-repeat;
      //  width: 100vw;
      // height: 81vh;
    }
    .pdmob{
      padding: 0px 20px;
    }
  }
  @media (min-width:390px) and (max-width:411px) {
    .businesspartner{
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 88px;
      background-image: url("../images/banner.webp");
      background-size: cover;
      background-repeat: no-repeat;
      //  width: 100vw;
      // height: 81vh;
    }
    .pdmob{
      padding: 0px 20px;
    }
  }
  @media (max-width:389px) {
    .businesspartner{
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 88px;
      background-image: url("../images/banner.webp");
      background-size: cover;
      background-repeat: no-repeat;
      //  width: 100vw;
      // height: 81vh;
    }
    .pdmob{
      padding: 0px 20px;
    }
  }
  button.gm-ui-hover-effect {
     height: 20px !important;
    span{
      margin: 5px 12px !important;
    }
  }
  .gm-style .gm-style-iw-a {
    position: absolute;
    width: 400px;
    height: 0;
    top:-18px !important;
}