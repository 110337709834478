@media (-webkit-min-device-pixel-ratio: 1) and (-webkit-max-device-pixel-ratio: 1.24) {
  //Banner
  .home-style {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 92px;
    [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
    [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
      background-color: transparent;
    }
    .bgbannerstyle{
      background-size: cover;
      background-repeat: no-repeat;
      height: 81vh;
      transition: background-image 0.3s ease-in-out;
    }
    .dividersection {
      position: relative;
      // height:100vh;
    }
    .bannerslik {
      .carousel-inner {
        overflow: visible;
      }
      [data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
      [data-bs-theme="dark"].carousel .carousel-control-next-icon,
      [data-bs-theme="dark"].carousel .carousel-control-prev-icon {
        filter: invert(0) grayscale(0);
      }
      .carousel-control-next-icon,
      .carousel-control-prev-icon {
        border-radius: 50%;
        background-color: #3ebf1b;
        padding: 5px;
      }
    }
    .headerimg {
      height: 50vh;
      width: 90% !important;
      border-radius: 16px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -75%);
    }
    .logoimg {
      height: 82px;
      text-align: center;
      animation: slide-up 0.5s ease-in-out;
    }
    .headtext {
      font-size: 40px;
      font-weight: 700;
      animation: slide-up 0.4s ease-in-out;
    }
    .badgestyle {
      background-color: #2b4bb6;
      color: #ffffff;
      border-radius: 34px;
      height: 45px;
      padding: 11px;
      box-shadow: 2px 9px #7ca7c87d;
      animation: slide-up 0.6s ease-in-out;
    }
    .badgestylegreen {
      background-color: #3ebf1b;
      color: #ffffff;
      border-radius: 34px;
      height: 45px;
      padding: 11px;
      box-shadow: 2px 9px #2dd10045;
      animation: slide-up 0.6s ease-in-out;
    }
    .subtitlehead {
      font-size: 20px;
      text-align: center;
      font-weight: 700;
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .home-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
   
      .bgbannerstyle{
        background-size: cover;
        background-repeat: no-repeat;
        height: 72vh;
      }
      .dividersection {
        position: relative;
        // height:100vh;
      }
      .headerimg {
        height: 45vh;
        width: 95% !important;
        // margin-top: -445px;
        border-radius: 16px;
        //  animation: slide-up 0.5s ease-in-out;
        // position: relative;
        position: absolute;

        /* Center the top-left position of the img */
        top: 50%;
        left: 50%;

        /* Center relative to the diving line */
        transform: translate(-50%, -70%);
      }
      .logoimg {
        height: 82px;
        text-align: center;
      }
      .headtext {
        font-size: 40px;
        font-weight: 700;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 45px;
        padding: 11px;
        box-shadow: 2px 9px #7ca7c87d;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 45px;
        padding: 11px;
        box-shadow: 2px 9px #2dd10045;
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .home-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      .bgbannerstyle{
        background-size: cover;
        background-repeat: no-repeat;
        height: 81vh;
      }
      .dividersection {
        position: relative;
        // height:100vh;
      }
      .headerimg {
        height: 50vh;
        width: 90% !important;
        // margin-top: -445px;
        border-radius: 16px;
        //  animation: slide-up 0.5s ease-in-out;
        // position: relative;
        position: absolute;

        /* Center the top-left position of the img */
        top: 50%;
        left: 50%;

        /* Center relative to the diving line */
        transform: translate(-50%, -70%);
      }
      .logoimg {
        height: 82px;
        text-align: center;
      }
      .headtext {
        font-size: 38px;
        font-weight: 700;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 40px;
        padding: 7px;
        box-shadow: 2px 9px #7ca7c87d;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 40px;
        padding: 7px;
        box-shadow: 2px 9px #2dd10045;
      }
    }
  }
  @media (min-width: 1401px) and (max-width: 1440px) {
    .home-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      .bgbannerstyle{
        background-size: cover;
        background-repeat: no-repeat;
        height: 72vh;
      }
    
      .dividersection {
        position: relative;
        // height:100vh;
      }
      .headerimg {
        height: 45vh;
        width: 90% !important;
        // margin-top: -445px;
        border-radius: 16px;
        //  animation: slide-up 0.5s ease-in-out;
        // position: relative;
        position: absolute;

        /* Center the top-left position of the img */
        top: 50%;
        left: 50%;

        /* Center relative to the diving line */
        transform: translate(-50%, -70%);
      }
      .logoimg {
        height: 70px;
        text-align: center;
      }
      .headtext {
        font-size: 34px;
        font-weight: 700;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: 1px 6px #7ca7c87d;
        font-size: 13px;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: 1px 6px #2dd10045;
        font-size: 13px;
      }
    }
  }
  @media (min-pxwidth: 1369px) and (max-width: 1400px) {
    .home-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      .bgbannerstyle{
        background-size: cover;
        background-repeat: no-repeat;
        height: 72vh;
      }
   
      .dividersection {
        position: relative;
        // height:100vh;
      }
      .headerimg {
        height: 36vh;
        width: 90% !important;
        // margin-top: -445px;
        border-radius: 16px;
        //  animation: slide-up 0.5s ease-in-out;
        // position: relative;
        position: absolute;

        /* Center the top-left position of the img */
        top: 50%;
        left: 50%;

        /* Center relative to the diving line */
        transform: translate(-50%, -60%);
      }
      .logoimg {
        height: 70px;
        text-align: center;
      }
      .headtext {
        font-size: 34px;
        font-weight: 700;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: 1px 6px #7ca7c87d;
        font-size: 13px;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: 1px 6px #2dd10045;
        font-size: 13px;
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1368px) {
    .home-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 83px;
      .bgbannerstyle{
        background-size: cover;
        background-repeat: no-repeat;
        height: 81vh;
      }
    
      .dividersection {
        position: relative;
        // height:100vh;
      }
      .headerimg {
        height: 50vh;
        width: 90% !important;
        // margin-top: -445px;
        border-radius: 16px;
        //  animation: slide-up 0.5s ease-in-out;
        // position: relative;
        position: absolute;

        /* Center the top-left position of the img */
        top: 50%;
        left: 50%;

        /* Center relative to the diving line */
        transform: translate(-50%, -70%);
      }
      .logoimg {
        height: 70px;
        text-align: center;
      }
      .headtext {
        font-size: 30px;
        font-weight: 700;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 26px;
        padding: 4px;
        box-shadow: 1px 6px #7ca7c87d;
        font-size: 12px;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 26px;
        padding: 4px;
        box-shadow: 1px 6px #2dd10045;
        font-size: 12px;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .home-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 72px;
      .bgbannerstyle{
        background-size: cover;
        background-repeat: no-repeat;
        height: 77vh;
      }
      // .headerimg {
      //   height: 340px;
      //   margin-top: -235px;
      //   border-radius: 16px;
      //   position: relative;
      // }
      .dividersection {
        position: relative;
        // height:100vh;
      }
      .headerimg {
        height: 42vh;
        width: 80% !important;
        // margin-top: -445px;
        border-radius: 16px;
        //  animation: slide-up 0.5s ease-in-out;
        // position: relative;
        position: absolute;

        /* Center the top-left position of the img */
        top: 50%;
        left: 50%;

        /* Center relative to the diving line */
        transform: translate(-50%, -70%);
      }
      .logoimg {
        height: 70px;
        text-align: center;
      }
      .headtext {
        font-size: 30px;
        font-weight: 700;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 26px;
        padding: 4px;
        box-shadow: 1px 6px #7ca7c87d;
        font-size: 12px;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 26px;
        padding: 4px;
        box-shadow: 1px 6px #2dd10045;
        font-size: 12px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .home-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 72px;
      .bgbannerstyle{
        background-size: cover;
        background-repeat: no-repeat;
        height: 73vh;
      }
      // .headerimg {
      //   height: 270px;
      //   margin-top: -208px;
      //   border-radius: 16px;
      //   position: relative;
      // }
      .dividersection {
        position: relative;
        // height:100vh;
      }
      .headerimg {
        height: 35vh;
        width: 80% !important;
        // margin-top: -445px;
        border-radius: 16px;
        //  animation: slide-up 0.5s ease-in-out;
        // position: relative;
        position: absolute;

        /* Center the top-left position of the img */
        top: 50%;
        left: 50%;

        /* Center relative to the diving line */
        transform: translate(-50%, -84%);
      }
      .logoimg {
        height: 70px;
        text-align: center;
      }
      .headtext {
        font-size: 24px;
        font-weight: 700;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 54px;
        // padding:3px 2px;
        box-shadow: 1px 6px #7ca7c87d;
        font-size: 12px;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 54px;
        // padding: 3px 2px;
        box-shadow: 1px 6px #2dd10045;
        font-size: 12px;
      }
    }
  }

  //about
  .aboutus {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .topsticky {
      padding: 15px;
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 999;
    }
    .topstickycb {
      padding: 15px;
      position: fixed;
      bottom: 90px;
      right: 0;
      z-index: 999;
      background-color: #fff;
      border-radius: 50% 0% 0% 50%;
      height: 60px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2),
        0 0px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .aboutmg {
      margin-top: 90px;
    }
    .bgcolor {
      background-color: #2b4bb6;
      // margin-top: -190px;
    }
    .textstyle {
      font-size: 18px;
      color: white;
      font-weight: 200;
    }
    .franchisebtn {
      background-color: #ff9133;
      padding: 8px 24px;
      border-radius: 6px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }

    .box {
      opacity: 1; /* Initially hide the boxes */
      transform: translateY(0);
      border-radius: 24px 24px 0px 0px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.06),
        hwb(0deg 60% 40% / 0)
      );
      padding: 20px 30px;
      border-radius: 24px 24px 0px 0px;
    }

    .box.animate {
      animation: slide-up 0.5s ease-in-out forwards;
    }

    @keyframes slide-up {
      0% {
        transform: translateY(20%);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }

    .boxtext {
      color: rgba(255, 255, 255, 1); /* Full opacity */
      z-index: 1;
      // position: relative;
      font-size: 20px;
    }

    /* Optional: Ensure the heading has full opacity and visibility as well */
    .box h1 {
      color: rgba(255, 255, 255, 1); /* Full opacity */
      z-index: 1;
      // position: relative;
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .aboutus {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .topsticky {
        padding: 15px;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 999;
      }
      .bgcolor {
        background-color: #2b4bb6;
        // margin-top: -190px;
      }
      .textstyle {
        font-size: 18px;
        color: white;
        font-weight: 200;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 8px 24px;
        border-radius: 6px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }

      .box {
        border-radius: 24px 24px 0px 0px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.06),
          hwb(0deg 60% 40% / 0)
        );
        padding: 20px 30px;
        border-radius: 24px 24px 0px 0px;
      }

      .boxtext {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
        font-size: 19px;
      }

      /* Optional: Ensure the heading has full opacity and visibility as well */
      .box h1 {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .aboutus {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .topsticky {
        padding: 15px;
        position: fixed;
        bottom: -22px;
        right: 0;
        z-index: 999;
      }
      .bgcolor {
        background-color: #2b4bb6;
        // margin-top: -190px;
      }
      .textstyle {
        font-size: 16px;
        color: white;
        font-weight: 200;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 8px 24px;
        border-radius: 6px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }

      .box {
        border-radius: 24px 24px 0px 0px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.06),
          hwb(0deg 60% 40% / 0)
        );
        padding: 20px 30px;
        border-radius: 24px 24px 0px 0px;
      }

      .boxtext {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
        font-size: 17px;
      }

      /* Optional: Ensure the heading has full opacity and visibility as well */
      .box h1 {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1440px) {
    .aboutus {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .topsticky {
        padding: 15px;
        position: fixed;
        bottom: -22px;
        right: 0;
        z-index: 999;
      }
      .bgcolor {
        background-color: #2b4bb6;
        // margin-top: -190px;
      }
      .textstyle {
        font-size: 14px;
        color: white;
        font-weight: 200;
      }

      .franchisebtn {
        background-color: #ff9133;
        padding: 4px 24px;
        border-radius: 6px;
        // height: 30px;
        font-size: 14px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }

      .box {
        border-radius: 24px 24px 0px 0px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.06),
          hwb(0deg 60% 40% / 0)
        );
        padding: 10px 10px;
        border-radius: 24px 24px 0px 0px;
      }

      .boxtext {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
        font-size: 16px;
      }

      /* Optional: Ensure the heading has full opacity and visibility as well */
      .box h1 {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .aboutus {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .topsticky {
        padding: 15px;
        position: fixed;
        bottom: -30px;
        right: 0;
        z-index: 999;
      }
      .bgcolor {
        background-color: #2b4bb6;
        // margin-top: -190px;
      }
      .textstyle {
        font-size: 12px;
        color: white;
        font-weight: 200;
      }

      .franchisebtn {
        background-color: #ff9133;
        padding: 2px 24px;
        border-radius: 6px;
        height: 24px;
        font-size: 12px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }

      .box {
        border-radius: 24px 24px 0px 0px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.06),
          hwb(0deg 60% 40% / 0)
        );
        padding: 10px 10px;
        border-radius: 24px 24px 0px 0px;
      }

      .boxtext {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
        font-size: 16px;
      }

      /* Optional: Ensure the heading has full opacity and visibility as well */
      .box h1 {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .aboutus {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .topsticky {
        padding: 15px;
        position: fixed;
        bottom: -28px;
        right: 0;
        z-index: 999;
      }
      .bgcolor {
        background-color: #2b4bb6;
        // margin-top: -190px;
      }
      .textstyle {
        font-size: 12px;
        color: white;
        font-weight: 200;
      }

      .franchisebtn {
        background-color: #ff9133;
        padding: 2px 20px;
        border-radius: 6px;
        height: 24px;
        font-size: 12px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }

      .box {
        border-radius: 24px 24px 0px 0px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.06),
          hwb(0deg 60% 40% / 0)
        );
        padding: 10px 10px;
        border-radius: 24px 24px 0px 0px;
      }

      .boxtext {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
        font-size: 12px;
      }

      /* Optional: Ensure the heading has full opacity and visibility as well */
      .box h1 {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
      }
    }
  }
  //our milestone
  .ourmilestone {
    font-style: normal;
    font-family: "Be Vietnam Pro", sans-serif;
 
    [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
    [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
      background-color: transparent !important;
      filter: invert(0) grayscale(0) !important;
    }
    .carouselimg {
      height: 200px;
    }
    .ffamily {
      font-family: "Indie Flower", cursive;
      color: #2b4bb6;
    }
    .flag {
      height: 30px;
    }
    .cardstyle {
      height: 480px;
      margin-right: 20px;
    }
    .cardhead {
      font-size: 24px;
      font-weight: 700;
    }
    .cardtext {
      font-size: 18px;
      font-weight: 300;
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      // .carousel-indicators{
      //   position: relative;
      //   justify-content: end;
      // }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }
   
      .carouselimg {
        height: 200px;
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #2b4bb6;
      }
      .flag {
        height: 30px;
      }
      .cardstyle {
        height: 480px;
      }
      .cardstyle1 {
        height: 330px;
        box-shadow: 0 0 10px #ccc;
        .badgesince {
          background-color: #beebf8;
          color: #000;
          font-size: 16px;
          font-weight: 500;
          padding: 3px 10px;
          border-radius: 52px;
          width: fit-content;
        }
      }
      .cardhead {
        font-size: 24px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 18px;
        font-weight: 300;
      }
      .whyusimg {
        height: 100px;
      }
      .whyuscard {
        position: relative;
        overflow: hidden;
        background-color: #f9f9f9; /* Default background color */
        transition: background-color 0.3s ease;
        box-shadow: 0 0 10px #ccc;
        padding: 37px 0px;
      }
      .whyuscard::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background-color: #a1e6fe; /* The hover background color */
        transition: left 0.4s ease;
        z-index: 0; /* Make sure it's behind the text */
      }
      .whyuscard:hover::before {
        left: 0;
      }
      .whyuscard .content {
        position: relative;
        z-index: 1; /* Ensure the content stays above the background */
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      // .carousel-indicators{
      //   position: relative;
      //   justify-content: end;
      // }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }

      .carouselimg {
        height: 165px;
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #2b4bb6;
      }
      .flag {
        height: 30px;
      }
      .cardstyle {
        height: 430px;
      }
      .cardhead {
        font-size: 24px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 16px;
        font-weight: 300;
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1440px) {
    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      // .carousel-indicators{
      //   position: relative;
      //   justify-content: end;
      // }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }
      .carousel-control-next-icon {
        background-image: url("../images/rightarrow.png");
        filter: invert(0) grayscale(0) !important;
        background-color: #00b8ff;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon {
        background-image: url("../images/leftarrow.png");
        background-color: #00b8ff;
        filter: invert(0) grayscale(0) !important;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      //   .carousel-indicators [data-bs-target] {
      //     // box-sizing: content-box;
      //     flex: 0 1 auto;
      //     width: 46px;
      //     height: 46px;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     border-radius: 50% !important;
      //     background-color: #c9ebf9 !important;
      //     // background-clip: padding-box;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     opacity: 0.5;
      //     transition: opacity 0.6s ease;
      //     // background-image: url("../images/leftarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      //   .carousel-indicators .active {
      //     opacity: 1;
      //     flex: 0 1 auto;
      //     background-color: #00B8FF !important;
      //     border-radius: 50%;
      //     height: 46px;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     width: 46px;
      //     // background-image: url("../images/rightarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      .carouselimg {
        height: 130px;
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #2b4bb6;
      }
      .flag {
        height: 30px;
      }
      .cardstyle {
        height: 370px;
      }
      .cardhead {
        font-size: 19px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 14px;
        font-weight: 300;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      // .carousel-indicators{
      //   position: relative;
      //   justify-content: end;
      // }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }
      .carousel-control-next-icon {
        background-image: url("../images/rightarrow.png");
        filter: invert(0) grayscale(0) !important;
        background-color: #00b8ff;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon {
        background-image: url("../images/leftarrow.png");
        background-color: #00b8ff;
        filter: invert(0) grayscale(0) !important;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      //   .carousel-indicators [data-bs-target] {
      //     // box-sizing: content-box;
      //     flex: 0 1 auto;
      //     width: 46px;
      //     height: 46px;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     border-radius: 50% !important;
      //     background-color: #c9ebf9 !important;
      //     // background-clip: padding-box;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     opacity: 0.5;
      //     transition: opacity 0.6s ease;
      //     // background-image: url("../images/leftarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      //   .carousel-indicators .active {
      //     opacity: 1;
      //     flex: 0 1 auto;
      //     background-color: #00B8FF !important;
      //     border-radius: 50%;
      //     height: 46px;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     width: 46px;
      //     // background-image: url("../images/rightarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      .carouselimg {
        height: 130px;
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #2b4bb6;
      }
      .flag {
        height: 30px;
      }
      .cardstyle {
        height: 345px;
      }
      .cardhead {
        font-size: 18px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 12px;
        font-weight: 300;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      // .carousel-indicators{
      //   position: relative;
      //   justify-content: end;
      // }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }
      .carousel-control-next-icon {
        background-image: url("../images/rightarrow.png");
        filter: invert(0) grayscale(0) !important;
        background-color: #00b8ff;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon {
        background-image: url("../images/leftarrow.png");
        background-color: #00b8ff;
        filter: invert(0) grayscale(0) !important;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      //   .carousel-indicators [data-bs-target] {
      //     // box-sizing: content-box;
      //     flex: 0 1 auto;
      //     width: 46px;
      //     height: 46px;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     border-radius: 50% !important;
      //     background-color: #c9ebf9 !important;
      //     // background-clip: padding-box;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     opacity: 0.5;
      //     transition: opacity 0.6s ease;
      //     // background-image: url("../images/leftarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      //   .carousel-indicators .active {
      //     opacity: 1;
      //     flex: 0 1 auto;
      //     background-color: #00B8FF !important;
      //     border-radius: 50%;
      //     height: 46px;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     width: 46px;
      //     // background-image: url("../images/rightarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      .carouselimg {
        height: 100px;
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #2b4bb6;
      }
      .flag {
        height: 30px;
      }
      .cardstyle {
        height: 345px;
      }
      .cardhead {
        font-size: 14px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 12px;
        font-weight: 300;
      }
    }
  }

  //blogs
  .blogs {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .textstyle {
      font-size: 18px;
    }
    .cardhead {
      font-size: 24px;
      font-weight: 700;
    }
    .cardstyle {
      opacity: 0; /* Initially hide the boxes */
      transform: translateY(20%);
      border-radius: 16px;
      &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
    }
    .cardstyle.animate {
      animation: slide-up 0.5s ease-in-out forwards;
    }

    @keyframes slide-up {
      0% {
        transform: translateY(20%);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
    .card-img-overlay {
      position: absolute;
      background: #fff;
      color: #000;
      top: 75%;
      right: 0;
      bottom: 0;
      text-align: start;
      left: 0;
      margin: 20px;
      padding: var(--bs-card-img-overlay-padding);
      border-radius: var(--bs-card-inner-border-radius);
    }
    .bluestyle {
      background-color: #beebf8;
      font-size: 14px;
      font-weight: 500;
      color: #000;
      border-radius: 6px;
      width: max-content;
      padding: 2px 4px;
    }

    .franchisebtn {
      background-color: #ff9133;
      padding: 8px 24px;
      border-radius: 6px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .blogs {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .textstyle {
        font-size: 18px;
      }
      .cardhead {
        font-size: 24px;
        font-weight: 700;
      }
      .cardstyle {
        border-radius: 16px;
        &:hover {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
      }
      .card-img-overlay {
        position: absolute;
        background: #fff;
        color: #000;
        top: 63%;
        right: 0;
        bottom: 0;
        text-align: start;
        left: 0;
        margin: 20px;
        padding: var(--bs-card-img-overlay-padding);
        border-radius: var(--bs-card-inner-border-radius);
      }
      .bluestyle {
        background-color: #beebf8;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        border-radius: 6px;
        width: max-content;
        padding: 2px 4px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 8px 24px;
        border-radius: 6px;
        // font-size: 14px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white !important; /* Ensure text color remains visible on hover */
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .blogs {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .textstyle {
        font-size: 16px;
      }
      .cardhead {
        font-size: 24px;
        font-weight: 700;
      }
      .cardstyle {
        border-radius: 16px;
        &:hover {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
      }
      .card-img-overlay {
        position: absolute;
        background: #fff;
        color: #000;
        top: 63%;
        right: 0;
        bottom: 0;
        text-align: start;
        left: 0;
        margin: 20px;
        padding: var(--bs-card-img-overlay-padding);
        border-radius: var(--bs-card-inner-border-radius);
      }
      .bluestyle {
        background-color: #beebf8;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        border-radius: 6px;
        width: max-content;
        padding: 2px 4px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 8px 19px;
        border-radius: 6px;
        // font-size: 14px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white !important; /* Ensure text color remains visible on hover */
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1440px) {
    .blogs {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .textstyle {
        font-size: 12px;
      }
      .cardhead {
        font-size: 20px;
        font-weight: 700;
      }
      .cardstyle {
        border-radius: 16px;
        &:hover {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
      }
      .card-img-overlay {
        position: absolute;
        background: #fff;
        color: #000;
        top: 58%;
        right: 0;
        bottom: 0;
        text-align: start;
        left: 0;
        margin: 20px;
        padding: var(--bs-card-img-overlay-padding);
        border-radius: var(--bs-card-inner-border-radius);
      }
      .bluestyle {
        background-color: #beebf8;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        border-radius: 6px;
        width: max-content;
        padding: 2px 4px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 4px 10px;
        border-radius: 6px;
        font-size: 14px;
        // height: 24px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white !important; /* Ensure text color remains visible on hover */
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .blogs {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .textstyle {
        font-size: 12px;
      }
      .cardhead {
        font-size: 18px;
        font-weight: 700;
      }
      .cardstyle {
        border-radius: 16px;
        &:hover {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
      }
      .card-img-overlay {
        position: absolute;
        background: #fff;
        color: #000;
        top: 55%;
        right: 0;
        bottom: 0;
        text-align: start;
        left: 0;
        margin: 20px;
        padding: var(--bs-card-img-overlay-padding);
        border-radius: var(--bs-card-inner-border-radius);
      }
      .bluestyle {
        background-color: #beebf8;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        border-radius: 6px;
        width: max-content;
        padding: 2px 4px;
      }

      .franchisebtn {
        background-color: #ff9133;
        padding: 8px;
        border-radius: 6px;
        font-size: 12px;
        height: 24px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white !important; /* Ensure text color remains visible on hover */
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .blogs {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .textstyle {
        font-size: 12px;
      }
      .cardhead {
        font-size: 14px;
        font-weight: 700;
      }
      .cardstyle {
        border-radius: 16px;
        &:hover {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
      }
      .card-img-overlay {
        position: absolute;
        background: #fff;
        color: #000;
        top: 55%;
        right: 0;
        bottom: 0;
        text-align: start;
        left: 0;
        margin: 20px;
        padding: 7px;
        border-radius: var(--bs-card-inner-border-radius);
      }
      .bluestyle {
        background-color: #beebf8;
        font-size: 12px;
        font-weight: 500;
        color: #000;
        border-radius: 6px;
        width: max-content;
        padding: 2px 4px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 2px 1px;
        border-radius: 6px;
        font-size: 12px;
        height: 24px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white !important; /* Ensure text color remains visible on hover */
      }
    }
  }

  //testimonials
  .testimonials {
    
    .videostyle {
      height: 255px;
      border-radius: 20px;
      border: 5px solid #2b4bb6;
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .testimonials {
      .videostyle {
        height: 221px;
        border-radius: 20px;
        margin-bottom: 20px;
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .testimonials {
      .videostyle {
        height: 212px;
        border-radius: 20px;
        margin-bottom: 20px;
      }
    }
  }
  @media (min-width: 1401px) and (max-width: 1440px) {
    .testimonials {
      .videostyle {
        height: 187px;
        border-radius: 20px;
        margin-bottom: 20px;
      }
    }
  }
  @media (min-width: 1369px) and (max-width: 1400px) {
    .testimonials {
      .videostyle {
        height: 178px;
        border-radius: 20px;
        margin-bottom: 20px;
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1368px) {
    .testimonials {
      .videostyle {
        height: 173px;
        border-radius: 20px;
        margin-bottom: 20px;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .testimonials {
      .videostyle {
        height: 168px;
        border-radius: 20px;
        margin-bottom: 20px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .testimonials {
      .videostyle {
        height: 132px;
        border-radius: 20px;
        margin-bottom: 20px;
      }
    }
  }

  //clientspeaks
  .cliendspeak {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    background-color: #2b4bb6;
    .head {
      font-size: 36px;
      font-weight: 700;
      text-align: center;
      color: #fff;
    }
    .textstyle {
      font-size: 18px;
      color: #fff;
      font-weight: 200;
    }
    .cardstyle {
      border-radius: 16px;
      background-color: #a1e6fe;
    }
    .testimonialimg {
      border-radius: 16px 0px 0px 16px;
    }
    .cardtxt {
      font-size: 18px;
      font-weight: 300;
      text-align: start;
      color: #000;
    }
    .cardhead {
      font-size: 24px;
      font-weight: 500;
      text-align: start;
      color: #000;
    }
    .badgesince {
      background-color: #beebf8;
      color: #000;
      font-size: 16px;
      font-weight: 500;
      padding: 3px 10px;
      border-radius: 52px;
      height: 28px;
    }
    [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
    [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
      background-color: transparent !important;
      filter: invert(0) grayscale(0) !important;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      display: inline-block;
      width: 2rem;
      height: 2rem;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100% 100%;
      //  background: black;
      padding: 22px;
      border-radius: 50%;
      margin-top: 20px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .carousel-control-prev,
    .carousel-control-next {
      position: relative;
      /* top: 0px; */
      bottom: -50px;
      z-index: 1;
      display: contents;
      align-items: center;
      justify-content: center;
      /* width: 15%; */
      padding: 0;
      color: #fff;
      text-align: center;
      background: none;
      border: 0;
      opacity: 0.5;
      transition: opacity 0.15s ease;
    }
    .carousel-control-next,
    .carousel-control-prev {
      position: relative;
      /* top: 0px; */
      bottom: -50px;
      z-index: 1;
      display: contents;
      align-items: center;
      justify-content: center;
      /* width: 15%; */
      padding: 0;
      color: #fff;
      text-align: center;
      background: none;
      border: 0;
      opacity: 0.5;
      transition: opacity 0.15s ease;
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .cliendspeak {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      background-color: #2b4bb6;
      .head {
        font-size: 36px;
        font-weight: 700;
        text-align: center;
        color: #fff;
      }
      .textstyle {
        font-size: 18px;
        color: #fff;
        font-weight: 200;
      }
      .cardstyle {
        border-radius: 16px;
        background-color: #a1e6fe;
      }
      .testimonialimg {
        border-radius: 16px 0px 0px 16px;
      }
      .cardtxt {
        font-size: 18px;
        font-weight: 300;
        text-align: start;
        color: #000;
      }
      .cardhead {
        font-size: 24px;
        font-weight: 500;
        text-align: start;
        color: #000;
      }
      .badgesince {
        background-color: #beebf8;
        color: #000;
        font-size: 15px;
        font-weight: 500;
        padding: 2px;
        border-radius: 52px;
        height: 28px;
      }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .cliendspeak {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      background-color: #2b4bb6;
      .head {
        font-size: 36px;
        font-weight: 700;
        text-align: center;
        color: #fff;
      }
      .textstyle {
        font-size: 16px;
        color: #fff;
        font-weight: 200;
      }
      .cardstyle {
        border-radius: 16px;
        background-color: #a1e6fe;
      }
      .testimonialimg {
        border-radius: 16px 0px 0px 16px;
        height: 290px;
      }
      .cardtxt {
        font-size: 16px;
        font-weight: 300;
        text-align: start;
        color: #000;
      }
      .cardhead {
        font-size: 24px;
        font-weight: 500;
        text-align: start;
        color: #000;
      }
      .badgesince {
        background-color: #beebf8;
        color: #000;
        font-size: 15px;
        font-weight: 500;
        padding: 2px;
        border-radius: 52px;
        height: 28px;
      }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }

      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1440px) {
    .cliendspeak {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      background-color: #2b4bb6;
      .head {
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        color: #fff;
      }
      .textstyle {
        font-size: 14px;
        color: #fff;
        font-weight: 200;
      }
      .cardstyle {
        border-radius: 16px;
        background-color: #a1e6fe;
      }
      .testimonialimg {
        border-radius: 16px 0px 0px 16px;
        height: 230px;
      }
      .cardtxt {
        font-size: 14px;
        font-weight: 300;
        text-align: start;
        color: #000;
      }
      .cardhead {
        font-size: 20px;
        font-weight: 500;
        text-align: start;
        color: #000;
      }
      .badgesince {
        background-color: #beebf8;
        color: #000;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 5px;
        border-radius: 52px;
        height: 23px;
      }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }
      
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .cliendspeak {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      background-color: #2b4bb6;
      .head {
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        color: #fff;
      }
      .textstyle {
        font-size: 12px;
        color: #fff;
        font-weight: 200;
      }
      .cardstyle {
        border-radius: 16px;
        background-color: #a1e6fe;
      }
      .testimonialimg {
        border-radius: 16px 0px 0px 16px;
        height: 230px;
      }
      .cardtxt {
        font-size: 12px;
        font-weight: 300;
        text-align: start;
        color: #000;
      }
      .cardhead {
        font-size: 18px;
        font-weight: 500;
        text-align: start;
        color: #000;
      }
      .badgesince {
        background-color: #beebf8;
        color: #000;
        font-size: 11px;
        font-weight: 500;
        padding: 2px 3px;
        border-radius: 52px;
        height: 23px;
      }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }
      
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .cliendspeak {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      background-color: #2b4bb6;
      .head {
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        color: #fff;
      }
      .textstyle {
        font-size: 12px;
        color: #fff;
        font-weight: 200;
      }
      .cardstyle {
        border-radius: 16px;
        background-color: #a1e6fe;
      }
      .testimonialimg {
        border-radius: 16px 0px 0px 16px;
        height: 199px;
      }
      .cardtxt {
        font-size: 12px;
        font-weight: 300;
        text-align: start;
        color: #000;
      }
      .cardhead {
        font-size: 18px;
        font-weight: 500;
        text-align: start;
        color: #000;
      }
      .badgesince {
        background-color: #beebf8;
        color: #000;
        font-size: 11px;
        font-weight: 500;
        padding: 2px 2px;
        border-radius: 52px;
        height: 38px;
      }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }

      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
    }
  }
  //Partner
  .partner {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    background-color: #f2f8fa;
    .partnerimg {
      height: 160px;
      width: 100%;
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .partner {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .partnerimg {
        height: 140px;
        width: 100%;
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .partner {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .partnerimg {
        height: 110px;
        width: 100%;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1440px) {
    .partner {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .partnerimg {
        height: 100px;
        width: 100%;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .partner {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .partnerimg {
        height: 80px;
        width: 100%;
      }
    }
  }
}

//banner mob/tab
//mrunal
@media (max-width: 389px) {
  .home-style {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 78px;
    .bgbannerstyle{
      background-size: cover;
      background-repeat: no-repeat;
      height: 74vh;
    }
    .headerimg {
      height: 25vh;
      margin-top: -165px;
      border-radius: 16px;
      position: relative;
      width: 100% !important;
      top: 0;
      left: 0;

      /* Center relative to the diving line */
      transform: none;
    }
    // .dividersection{
    //   position: relative;
    //   // height:100vh;
    // }
    // .headerimg {
    //   height: 28vh;
    //    width: 95% !important;
    //   // margin-top: -445px;
    //    border-radius: 16px;
    //    animation: slide-up 0.5s ease-in-out;
    //   // position: relative;
    //   position: absolute;

    //   /* Center the top-left position of the img */
    //   top: 50%;
    //   left:50%;

    //     /* Center relative to the diving line */
    //   transform: translate(-50%, -165%);
    // }
    .logoimg {
      height: 70px;
      text-align: center;
    }
    .headtext {
      font-size: 21px;
      font-weight: 700;
    }
    .badgestyle {
      background-color: #2b4bb6;
      color: #ffffff;
      border-radius: 34px;
      height: 30px;
      padding: 4px;
      box-shadow: none;
      font-size: 14px;
      margin-bottom: 5px;
    }
    .badgestylegreen {
      background-color: #3ebf1b;
      color: #ffffff;
      border-radius: 34px;
      height: 30px;
      padding: 4px;
      box-shadow: none;
      font-size: 14px;
      margin-bottom: 5px;
    }
    .bannerslik {
      .carousel-inner {
        overflow: visible;
      }
      [data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
      [data-bs-theme="dark"].carousel .carousel-control-next-icon,
      [data-bs-theme="dark"].carousel .carousel-control-prev-icon {
        filter: invert(0) grayscale(0);
      }
      .carousel-control-next-icon,
      .carousel-control-prev-icon {
        border-radius: 50%;
        background-color: #3ebf1b;
        padding: 5px;
      }
    }
    [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
    [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
      background-color: transparent;
    }
  }
}
//onkar
@media (min-width: 390px) and (max-width: 411px) {
  .home-style {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 78px;
    .bgbannerstyle{
      background-size: cover;
      background-repeat: no-repeat;
      height: 62vh;
    }
    [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
    [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
      background-color: transparent;
    }
    // .headerimg {
    //   height: 187px;
    //   margin-top: -155px;
    //   border-radius: 16px;
    //   position: relative;
    // }
    .headerimg {
      height: 25vh;
      margin-top: -166px;
      border-radius: 16px;
      position: relative;
      width: 100% !important;
      top: 0;
      left: 0;

      /* Center relative to the diving line */
      transform: none;
    }
    // .dividersection{
    //   position: relative;
    //   // height:100vh;
    // }
    // .headerimg {
    //   height: 25vh;
    //    width: 95% !important;
    //   // margin-top: -445px;
    //    border-radius: 16px;
    //    animation: slide-up 0.5s ease-in-out;
    //   // position: relative;
    //   position: absolute;

    //   /* Center the top-left position of the img */
    //   top: 50%;
    //   left:50%;

    //     /* Center relative to the diving line */
    //   transform: translate(-50%, -165%);
    // }
    .logoimg {
      height: 70px;
      text-align: center;
    }
    .headtext {
      font-size: 21px;
      font-weight: 700;
    }
    .badgestyle {
      background-color: #2b4bb6;
      color: #ffffff;
      border-radius: 34px;
      height: 30px;
      padding: 4px;
      box-shadow: none;
      font-size: 14px;
      margin-bottom: 5px;
    }
    .badgestylegreen {
      background-color: #3ebf1b;
      color: #ffffff;
      border-radius: 34px;
      height: 30px;
      padding: 4px;
      box-shadow: none;
      font-size: 14px;
      margin-bottom: 5px;
    }
    .bannerslik {
      .carousel-inner {
        overflow: visible;
      }
      [data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
      [data-bs-theme="dark"].carousel .carousel-control-next-icon,
      [data-bs-theme="dark"].carousel .carousel-control-prev-icon {
        filter: invert(0) grayscale(0);
      }
      .carousel-control-next-icon,
      .carousel-control-prev-icon {
        border-radius: 50%;
        background-color: #3ebf1b;
        padding: 5px;
      }
    }
  }
}
//sneha
@media (min-width: 412px) and (max-width: 415px) {
  .home-style {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 78px;
    .bgbannerstyle{
      background-size: cover;
      background-repeat: no-repeat;
      height: 54vh;
    }
    .headerimg {
      height: 25vh;
      margin-top: -160px;
      border-radius: 16px;
      position: relative;
      width: 100% !important;
      top: 0;
      left: 0;

      /* Center relative to the diving line */
      transform: translate(0px, 19px);
    }
    .logoimg {
      height: 70px;
      text-align: center;
    }
    .headtext {
      font-size: 21px;
      font-weight: 700;
    }
    .badgestyle {
      background-color: #2b4bb6;
      color: #ffffff;
      border-radius: 34px;
      height: 30px;
      padding: 4px;
      box-shadow: none;
      font-size: 14px;
      margin-bottom: 5px;
    }
    .badgestylegreen {
      background-color: #3ebf1b;
      color: #ffffff;
      border-radius: 34px;
      height: 30px;
      padding: 4px;
      box-shadow: none;
      font-size: 14px;
      margin-bottom: 5px;
    }
    .bannerslik {
      .carousel-inner {
        overflow: visible;
      }
      [data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
      [data-bs-theme="dark"].carousel .carousel-control-next-icon,
      [data-bs-theme="dark"].carousel .carousel-control-prev-icon {
        filter: invert(0) grayscale(0);
      }
      .carousel-control-next-icon,
      .carousel-control-prev-icon {
        border-radius: 50%;
        background-color: #3ebf1b;
        padding: 5px;
      }
    }
    [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
    [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
      background-color: transparent;
    }
  }
}
//harish
@media (min-width: 416px) and (max-width: 430px) {
  .home-style {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 78px;
    .bgbannerstyle{
      background-size: cover;
      background-repeat: no-repeat;
      height: 54vh;
    }
    .headerimg {
      height: 22vh;
      margin-top: -137px;
      border-radius: 16px;
      position: relative;
      transform: translate(0px, -23px);
      top: 0;
      left: 0;
      width: 100% !important;
    }
    // .headerimg {
    //   height: 35vh;
    //   margin-top: -175px;
    //   border-radius: 16px;
    //   position: relative;
    //   width: 100% !important;
    //   top: 0;
    //   left:0;

    //     /* Center relative to the diving line */
    //   transform: translate(0px,19px);
    // }
    // .dividersection{
    //   position: relative;
    //   // height:100vh;
    // }
    // .headerimg {
    //   height: 23vh;
    //    width: 95% !important;
    //   // margin-top: -445px;
    //    border-radius: 16px;
    //    animation: slide-up 0.5s ease-in-out;
    //   // position: relative;
    //   position: absolute;

    //   /* Center the top-left position of the img */
    //   top: 50%;
    //   left:50%;

    //     /* Center relative to the diving line */
    //   transform: translate(-50%, -150%);
    // }
    .logoimg {
      height: 70px;
      text-align: center;
    }
    .headtext {
      font-size: 21px;
      font-weight: 700;
    }
    .badgestyle {
      background-color: #2b4bb6;
      color: #ffffff;
      border-radius: 34px;
      height: 35px;
      padding: 7px 0px;
      box-shadow: none;
      font-size: 14px;
      margin-bottom: 5px;
    }
    .badgestylegreen {
      background-color: #3ebf1b;
      color: #ffffff;
      border-radius: 34px;
      height: 35px;
      padding: 7px 0px;
      box-shadow: none;
      font-size: 14px;
      margin-bottom: 5px;
    }
    .bannerslik {
      .carousel-inner {
        overflow: visible;
      }
      [data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
      [data-bs-theme="dark"].carousel .carousel-control-next-icon,
      [data-bs-theme="dark"].carousel .carousel-control-prev-icon {
        filter: invert(0) grayscale(0);
      }
      .carousel-control-next-icon,
      .carousel-control-prev-icon {
        border-radius: 50%;
        background-color: #3ebf1b;
        padding: 5px;
      }
    }
    [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
    [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
      background-color: transparent;
    }
  }
}
//rahul 720
@media (min-width: 431px) and (max-width: 767px) {
  .home-style {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 93px;
    .bgbannerstyle{
      background-size: cover;
      background-repeat: no-repeat;
      height: 50vh;
    }
    .headerimg {
      height: 23vh;
      margin-top: -240px;
      border-radius: 16px;
      position: relative;
      width: 100% !important;
      top: 0;
      left: 0;
      transform: translate(0px, 65px);
    }
    // .headerimg {
    //   height: 22vh;
    //   margin-top: -330px;
    //   border-radius: 16px;
    //   position: relative;
    //   transform: translate(0px,65px);
    //   top: 0;
    //   left: 0;
    //   width: 100% !important;
    // }
    // .dividersection{
    //   position: relative;
    //   // height:100vh;
    // }
    // .headerimg {
    //   height: 36vh;
    //    width: 95% !important;
    //   // margin-top: -445px;
    //    border-radius: 16px;
    //    animation: slide-up 0.5s ease-in-out;
    //   // position: relative;
    //   position: absolute;

    //   /* Center the top-left position of the img */
    //   top: 50%;
    //   left:50%;

    //     /* Center relative to the diving line */
    //   transform: translate(-50%, -150%);
    // }
    .logoimg {
      height: 70px;
      text-align: center;
    }
    .headtext {
      font-size: 21px;
      font-weight: 700;
    }
    .badgestyle {
      background-color: #2b4bb6;
      color: #ffffff;
      border-radius: 34px;
      height: 40px;
      // padding:3px 2px;
      box-shadow: none;
      font-size: 14px;
      margin-bottom: 5px;
    }
    .badgestylegreen {
      background-color: #3ebf1b;
      color: #ffffff;
      border-radius: 34px;
      height: 40px;
      // padding: 3px 2px;
      box-shadow: none;
      font-size: 14px;
      margin-bottom: 5px;
    }
    .bannerslik {
      .carousel-inner {
        overflow: visible;
      }
      [data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
      [data-bs-theme="dark"].carousel .carousel-control-next-icon,
      [data-bs-theme="dark"].carousel .carousel-control-prev-icon {
        filter: invert(0) grayscale(0);
      }
      .carousel-control-next-icon,
      .carousel-control-prev-icon {
        border-radius: 50%;
        background-color: #3ebf1b;
        padding: 5px;
      }
    }
    [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
    [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
      background-color: transparent;
    }
  }
}
@media (min-width: 820px) and (max-width: 991px) {
  .home-style {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 92px;
    .bannerslik {
      .carousel-inner {
        overflow: visible;
      }
      [data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
      [data-bs-theme="dark"].carousel .carousel-control-next-icon,
      [data-bs-theme="dark"].carousel .carousel-control-prev-icon {
        filter: invert(0) grayscale(0);
      }
      .carousel-control-next-icon,
      .carousel-control-prev-icon {
        border-radius: 50%;
        background-color: #3ebf1b;
        padding: 5px;
      }
    }
    [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
    [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
      background-color: transparent;
    }
    .bgbannerstyle{
      background-size: cover;
      background-repeat: no-repeat;
      height: 59vh;
    }
    // .headerimg {
    //   height: 430px;
    //   margin-top: -309px;
    //   border-radius: 16px;
    //   position: relative;
    // }
    .dividersection {
      position: relative;
      // height:100vh;
    }
    .headerimg {
      height: 29vh;
      width: 90% !important;
      // margin-top: -445px;
      border-radius: 16px;
      //  animation: slide-up 0.5s ease-in-out;
      // position: relative;
      position: absolute;

      /* Center the top-left position of the img */
      top: 50%;
      left: 50%;

      /* Center relative to the diving line */
      transform: translate(-50%, -60%);
    }
    .logoimg {
      height: 70px;
      text-align: center;
    }
    .headtext {
      font-size: 36px;
      font-weight: 700;
    }
    .badgestyle {
      background-color: #2b4bb6;
      color: #ffffff;
      border-radius: 34px;
      height: 70px;
      // padding:3px 2px;
      box-shadow: none;
      font-size: 16px;
      margin-bottom: 5px;
    }
    .badgestylegreen {
      background-color: #3ebf1b;
      color: #ffffff;
      border-radius: 34px;
      height: 70px;
      // padding: 3px 2px;
      box-shadow: none;
      font-size: 16px;
      margin-bottom: 5px;
    }
  }
}
@media (min-width: 768px) and (max-width: 819px) {
  .home-style {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 92px;
    .bannerslik {
      .carousel-inner {
        overflow: visible;
      }
      [data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
      [data-bs-theme="dark"].carousel .carousel-control-next-icon,
      [data-bs-theme="dark"].carousel .carousel-control-prev-icon {
        filter: invert(0) grayscale(0);
      }
      .carousel-control-next-icon,
      .carousel-control-prev-icon {
        border-radius: 50%;
        background-color: #3ebf1b;
        padding: 5px;
      }
    }
    [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
    [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
      background-color: transparent;
    }
    .bgbannerstyle{
      background-size: cover;
      background-repeat: no-repeat;
      height: 57vh;
    }
    // .headerimg {
    //   height: 430px;
    //   margin-top: -309px;
    //   border-radius: 16px;
    //   position: relative;
    // }
    .dividersection {
      position: relative;
      // height:100vh;
    }
    .headerimg {
      height: 27vh;
      width: 80% !important;
      // margin-top: -445px;
      border-radius: 16px;
      //  animation: slide-up 0.5s ease-in-out;
      // position: relative;
      position: absolute;

      /* Center the top-left position of the img */
      top: 50%;
      left: 50%;

      /* Center relative to the diving line */
      transform: translate(-50%, -65%);
    }
    .logoimg {
      height: 70px;
      text-align: center;
    }
    .headtext {
      font-size: 36px;
      font-weight: 700;
    }
    .badgestyle {
      background-color: #2b4bb6;
      color: #ffffff;
      border-radius: 34px;
      height: 70px;
      // padding:3px 2px;
      box-shadow: none;
      font-size: 16px;
      margin-bottom: 5px;
    }
    .badgestylegreen {
      background-color: #3ebf1b;
      color: #ffffff;
      border-radius: 34px;
      height: 70px;
      // padding: 3px 2px;
      box-shadow: none;
      font-size: 16px;
      margin-bottom: 5px;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.49) {
  //banner
  .home-style {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 88px;
    [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
    [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
      background-color: transparent;
    }
    .bgbannerstyle{
      background-size: cover;
      background-repeat: no-repeat;
      height: 81vh;
    }
    .dividersection {
      position: relative;
      // height:100vh;
    }
    .bannerslik {
      //   .slick-track {
      //      width:100% !important;
      //      position: absolute;

      //   }
      //   .slick-list {
      //      position: relative;
      //     display: block;
      //     overflow: visible;
      //     margin: 0;
      //     padding: 0;
      // }
      .carousel-inner {
        overflow: visible;
      }
      [data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
      [data-bs-theme="dark"].carousel .carousel-control-next-icon,
      [data-bs-theme="dark"].carousel .carousel-control-prev-icon {
        filter: invert(0) grayscale(0);
      }
      .carousel-control-next-icon,
      .carousel-control-prev-icon {
        border-radius: 50%;
        background-color: #3ebf1b;
        padding: 5px;
      }
    }
    .headerimg {
      height: 46vh;
      width: 90% !important;
      //  width: 100% !important;
      // margin-top: -445px;
      border-radius: 16px;
      //  animation: slide-up 0.5s ease-in-out;
      // position: relative;
      position: absolute;

      /* Center the top-left position of the img */
      top: 50%;
      left: 50%;

      /* Center relative to the diving line */
      transform: translate(-50%, -75%);
    }
    .logoimg {
      height: 82px;
      text-align: center;
      animation: slide-up 0.5s ease-in-out;
    }
    .headtext {
      font-size: 32px;
      font-weight: 700;
      animation: slide-up 0.4s ease-in-out;
    }
    .badgestyle {
      background-color: #2b4bb6;
      color: #ffffff;
      border-radius: 34px;
      height: 35px;
      font-size: 13px;
      padding: 7px 2px;
      box-shadow: 2px 9px #7ca7c87d;
      animation: slide-up 0.6s ease-in-out;
    }
    .badgestylegreen {
      background-color: #3ebf1b;
      color: #ffffff;
      border-radius: 34px;
      height: 35px;
      font-size: 13px;
      padding: 7px 2px;
      box-shadow: 2px 9px #2dd10045;
      animation: slide-up 0.6s ease-in-out;
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .home-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      .bgbannerstyle{
        background-size: cover;
        background-repeat: no-repeat;
        height: 72vh;
      }
      .dividersection {
        position: relative;
        // height:100vh;
      }
      .headerimg {
        height: 45vh;
        width: 95% !important;
        // margin-top: -445px;
        border-radius: 16px;
        //  animation: slide-up 0.5s ease-in-out;
        // position: relative;
        position: absolute;

        /* Center the top-left position of the img */
        top: 50%;
        left: 50%;

        /* Center relative to the diving line */
        transform: translate(-50%, -70%);
      }
      .logoimg {
        height: 82px;
        text-align: center;
      }
      .headtext {
        font-size: 40px;
        font-weight: 700;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 45px;
        padding: 11px;
        box-shadow: 2px 9px #7ca7c87d;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 45px;
        padding: 11px;
        box-shadow: 2px 9px #2dd10045;
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .home-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 88px;
      .bgbannerstyle{
        background-size: cover;
        background-repeat: no-repeat;
        height: 81vh;
      }
      .dividersection {
        position: relative;
        // height:100vh;
      }
      .headerimg {
        height: 50vh;
        width: 90% !important;
        // margin-top: -445px;
        border-radius: 16px;
        //  animation: slide-up 0.5s ease-in-out;
        // position: relative;
        position: absolute;

        /* Center the top-left position of the img */
        top: 50%;
        left: 50%;

        /* Center relative to the diving line */
        transform: translate(-50%, -70%);
      }
      .logoimg {
        height: 82px;
        text-align: center;
      }
      .headtext {
        font-size: 37px;
        font-weight: 700;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 36px;
        font-size: 14px;
        padding: 7px;
        box-shadow: 2px 9px #7ca7c87d;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 36px;
        font-size: 14px;
        padding: 7px;
        box-shadow: 2px 9px #2dd10045;
      }
    }
  }
  @media (min-width: 1401px) and (max-width: 1440px) {
    .home-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      .bgbannerstyle{
        background-size: cover;
        background-repeat: no-repeat;
        height: 72vh;
      }
      .dividersection {
        position: relative;
        // height:100vh;
      }
      .headerimg {
        height: 45vh;
        width: 90% !important;
        // margin-top: -445px;
        border-radius: 16px;
        //  animation: slide-up 0.5s ease-in-out;
        // position: relative;
        position: absolute;

        /* Center the top-left position of the img */
        top: 50%;
        left: 50%;

        /* Center relative to the diving line */
        transform: translate(-50%, -70%);
      }
      .logoimg {
        height: 70px;
        text-align: center;
      }
      .headtext {
        font-size: 34px;
        font-weight: 700;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: 1px 6px #7ca7c87d;
        font-size: 13px;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: 1px 6px #2dd10045;
        font-size: 13px;
      }
    }
  }
  @media (min-width: 1369px) and (max-width: 1400px) {
    .home-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      .bgbannerstyle{
        background-size: cover;
        background-repeat: no-repeat;
        height: 72vh;
      }
      .dividersection {
        position: relative;
        // height:100vh;
      }
      .headerimg {
        height: 36vh;
        width: 90% !important;
        // margin-top: -445px;
        border-radius: 16px;
        //  animation: slide-up 0.5s ease-in-out;
        // position: relative;
        position: absolute;

        /* Center the top-left position of the img */
        top: 50%;
        left: 50%;

        /* Center relative to the diving line */
        transform: translate(-50%, -60%);
      }
      .logoimg {
        height: 70px;
        text-align: center;
      }
      .headtext {
        font-size: 34px;
        font-weight: 700;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: 1px 6px #7ca7c87d;
        font-size: 13px;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: 1px 6px #2dd10045;
        font-size: 13px;
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1368px) {
    .home-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 83px;
      .bgbannerstyle{
        background-size: cover;
        background-repeat: no-repeat;
        height: 81vh;
      }
      // .headerimg {
      //   height: 365px;
      //   margin-top: -250px;
      //   border-radius: 16px;
      //   position: relative;
      // }
      .dividersection {
        position: relative;
        // height:100vh;
      }
      .headerimg {
        height: 45vh;
        width: 90% !important;
        // margin-top: -445px;
        border-radius: 16px;
        //  animation: slide-up 0.5s ease-in-out;
        // position: relative;
        position: absolute;

        /* Center the top-left position of the img */
        top: 50%;
        left: 50%;

        /* Center relative to the diving line */
        transform: translate(-50%, -70%);
      }
      .logoimg {
        height: 70px;
        text-align: center;
      }
      .headtext {
        font-size: 30px;
        font-weight: 700;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 26px;
        padding: 4px;
        box-shadow: 1px 6px #7ca7c87d;
        font-size: 12px;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 26px;
        padding: 4px;
        box-shadow: 1px 6px #2dd10045;
        font-size: 12px;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .home-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 72px;
      .bgbannerstyle{
        background-size: cover;
        background-repeat: no-repeat;
        height: 77vh;
      }
      .dividersection {
        position: relative;
        // height:100vh;
      }
      .headerimg {
        height: 42vh;
        width: 80% !important;
        // margin-top: -445px;
        border-radius: 16px;
        //  animation: slide-up 0.5s ease-in-out;
        // position: relative;
        position: absolute;

        /* Center the top-left position of the img */
        top: 50%;
        left: 50%;

        /* Center relative to the diving line */
        transform: translate(-50%, -70%);
      }
      .logoimg {
        height: 70px;
        text-align: center;
      }
      .headtext {
        font-size: 30px;
        font-weight: 700;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 26px;
        padding: 4px;
        box-shadow: 1px 6px #7ca7c87d;
        font-size: 12px;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 26px;
        padding: 4px;
        box-shadow: 1px 6px #2dd10045;
        font-size: 12px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .home-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 72px;
      .bgbannerstyle{
        background-size: cover;
        background-repeat: no-repeat;
        height: 73vh;
      }
      .dividersection {
        position: relative;
        // height:100vh;
      }
      .headerimg {
        height: 35vh;
        width: 80% !important;
        // margin-top: -445px;
        border-radius: 16px;
        //  animation: slide-up 0.5s ease-in-out;
        // position: relative;
        position: absolute;

        /* Center the top-left position of the img */
        top: 50%;
        left: 50%;

        /* Center relative to the diving line */
        transform: translate(-50%, -84%);
      }
      .logoimg {
        height: 70px;
        text-align: center;
      }
      .headtext {
        font-size: 24px;
        font-weight: 700;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 54px;
        // padding:3px 2px;
        box-shadow: 1px 6px #7ca7c87d;
        font-size: 12px;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 54px;
        // padding: 3px 2px;
        box-shadow: 1px 6px #2dd10045;
        font-size: 12px;
      }
    }
  }
  //about
  .aboutus {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .topsticky {
      padding: 15px;
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 999;
    }
    .aboutmg {
      margin-top: 90px;
    }
    .bgcolor {
      background-color: #2b4bb6;
      // margin-top: -190px;
    }
    .textstyle {
      font-size: 18px;
      color: white;
      font-weight: 200;
    }
    .franchisebtn {
      background-color: #ff9133;
      padding: 8px 24px;
      border-radius: 6px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }

    .box {
      opacity: 1; /* Initially hide the boxes */
      transform: translateY(0);
      border-radius: 24px 24px 0px 0px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.06),
        hwb(0deg 60% 40% / 0)
      );
      padding: 20px 30px;
      border-radius: 24px 24px 0px 0px;
    }

    .box.animate {
      animation: slide-up 0.5s ease-in-out forwards;
    }

    @keyframes slide-up {
      0% {
        transform: translateY(20%);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }

    .boxtext {
      color: rgba(255, 255, 255, 1); /* Full opacity */
      z-index: 1;
      // position: relative;
      font-size: 20px;
    }

    /* Optional: Ensure the heading has full opacity and visibility as well */
    .box h1 {
      color: rgba(255, 255, 255, 1); /* Full opacity */
      z-index: 1;
      // position: relative;
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .aboutus {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .topsticky {
        padding: 15px;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 999;
      }
      .bgcolor {
        background-color: #2b4bb6;
        // margin-top: -190px;
      }
      .textstyle {
        font-size: 18px;
        color: white;
        font-weight: 200;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 8px 24px;
        border-radius: 6px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }

      .box {
        border-radius: 24px 24px 0px 0px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.06),
          hwb(0deg 60% 40% / 0)
        );
        padding: 20px 30px;
        border-radius: 24px 24px 0px 0px;
      }

      .boxtext {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
        font-size: 19px;
      }

      /* Optional: Ensure the heading has full opacity and visibility as well */
      .box h1 {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .aboutus {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .topsticky {
        padding: 15px;
        position: fixed;
        bottom: -22px;
        right: 0;
        z-index: 999;
      }
      .bgcolor {
        background-color: #2b4bb6;
        // margin-top: -190px;
      }
      .textstyle {
        font-size: 16px;
        color: white;
        font-weight: 200;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 8px 24px;
        border-radius: 6px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }

      .box {
        border-radius: 24px 24px 0px 0px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.06),
          hwb(0deg 60% 40% / 0)
        );
        padding: 20px 30px;
        border-radius: 24px 24px 0px 0px;
      }

      .boxtext {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
        font-size: 16px;
      }

      /* Optional: Ensure the heading has full opacity and visibility as well */
      .box h1 {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1440px) {
    .aboutus {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .topsticky {
        padding: 15px;
        position: fixed;
        bottom: -22px;
        right: 0;
        z-index: 999;
      }
      .bgcolor {
        background-color: #2b4bb6;
        // margin-top: -190px;
      }
      .textstyle {
        font-size: 14px;
        color: white;
        font-weight: 200;
      }

      .franchisebtn {
        background-color: #ff9133;
        padding: 4px 24px;
        border-radius: 6px;
        // height: 30px;
        font-size: 14px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }

      .box {
        border-radius: 24px 24px 0px 0px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.06),
          hwb(0deg 60% 40% / 0)
        );
        padding: 10px 10px;
        border-radius: 24px 24px 0px 0px;
      }

      .boxtext {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
        font-size: 16px;
      }

      /* Optional: Ensure the heading has full opacity and visibility as well */
      .box h1 {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .aboutus {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .topsticky {
        padding: 15px;
        position: fixed;
        bottom: -30px;
        right: 0;
        z-index: 999;
      }
      .bgcolor {
        background-color: #2b4bb6;
        // margin-top: -190px;
      }
      .textstyle {
        font-size: 12px;
        color: white;
        font-weight: 200;
      }

      .franchisebtn {
        background-color: #ff9133;
        padding: 2px 24px;
        border-radius: 6px;
        height: 24px;
        font-size: 12px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }

      .box {
        border-radius: 24px 24px 0px 0px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.06),
          hwb(0deg 60% 40% / 0)
        );
        padding: 10px 10px;
        border-radius: 24px 24px 0px 0px;
      }

      .boxtext {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
        font-size: 16px;
      }

      /* Optional: Ensure the heading has full opacity and visibility as well */
      .box h1 {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .aboutus {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .topsticky {
        padding: 15px;
        position: fixed;
        bottom: -28px;
        right: 0;
        z-index: 999;
      }
      .bgcolor {
        background-color: #2b4bb6;
        // margin-top: -190px;
      }
      .textstyle {
        font-size: 12px;
        color: white;
        font-weight: 200;
      }

      .franchisebtn {
        background-color: #ff9133;
        padding: 2px 20px;
        border-radius: 6px;
        height: 24px;
        font-size: 12px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }

      .box {
        border-radius: 24px 24px 0px 0px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.06),
          hwb(0deg 60% 40% / 0)
        );
        padding: 10px 10px;
        border-radius: 24px 24px 0px 0px;
      }

      .boxtext {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
        font-size: 12px;
      }

      /* Optional: Ensure the heading has full opacity and visibility as well */
      .box h1 {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
      }
    }
  }

  //our milestone
  .ourmilestone {
    font-style: normal;
    font-family: "Be Vietnam Pro", sans-serif;
    // .carousel-indicators{
    //   position: relative;
    //   justify-content: end;
    // }

    // .slick-slide {
    //   width: 466px !important;
    // }
    [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
    [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
      background-color: transparent !important;
      filter: invert(0) grayscale(0) !important;
    }
    .carousel-control-next-icon {
      background-image: url("../images/rightarrow.png");
      filter: invert(0) grayscale(0) !important;
      background-color: #00b8ff;
      background-repeat: no-repeat !important;
      background-size: 30px !important;
    }
    .carousel-control-prev-icon {
      background-image: url("../images/leftarrow.png");
      background-color: #00b8ff;
      filter: invert(0) grayscale(0) !important;
      background-repeat: no-repeat !important;
      background-size: 30px !important;
    }
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      display: inline-block;
      width: 2rem;
      height: 2rem;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100% 100%;
      //  background: black;
      padding: 22px;
      border-radius: 50%;
      margin-top: 20px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .carousel-control-prev,
    .carousel-control-next {
      position: relative;
      /* top: 0px; */
      bottom: -50px;
      z-index: 1;
      display: contents;
      align-items: center;
      justify-content: center;
      /* width: 15%; */
      padding: 0;
      color: #fff;
      text-align: center;
      background: none;
      border: 0;
      opacity: 0.5;
      transition: opacity 0.15s ease;
    }
    .carousel-control-next,
    .carousel-control-prev {
      position: relative;
      /* top: 0px; */
      bottom: -50px;
      z-index: 1;
      display: contents;
      align-items: center;
      justify-content: center;
      /* width: 15%; */
      padding: 0;
      color: #fff;
      text-align: center;
      background: none;
      border: 0;
      opacity: 0.5;
      transition: opacity 0.15s ease;
    }
    //   .carousel-indicators [data-bs-target] {
    //     // box-sizing: content-box;
    //     flex: 0 1 auto;
    //     width: 46px;
    //     height: 46px;
    //     padding: 0;
    //     margin-right: 3px;
    //     margin-left: 3px;
    //     text-indent: -999px;
    //     cursor: pointer;
    //     border-radius: 50% !important;
    //     background-color: #c9ebf9 !important;
    //     // background-clip: padding-box;
    //     border: 0;
    //     border-top: 10px solid transparent;
    //     border-bottom: 10px solid transparent;
    //     opacity: 0.5;
    //     transition: opacity 0.6s ease;
    //     // background-image: url("../images/leftarrow.png");
    //     background-repeat: no-repeat;
    //     background-size: 30px;
    //     background-position-y: center;
    //     transform: rotate(180deg)
    // }
    //   .carousel-indicators .active {
    //     opacity: 1;
    //     flex: 0 1 auto;
    //     background-color: #00B8FF !important;
    //     border-radius: 50%;
    //     height: 46px;
    //     border: 0;
    //     border-top: 10px solid transparent;
    //     border-bottom: 10px solid transparent;
    //     padding: 0;
    //     margin-right: 3px;
    //     margin-left: 3px;
    //     text-indent: -999px;
    //     cursor: pointer;
    //     width: 46px;
    //     // background-image: url("../images/rightarrow.png");
    //     background-repeat: no-repeat;
    //     background-size: 30px;
    //     background-position-y: center;
    //     transform: rotate(180deg)
    // }
    .carouselimg {
      height: 200px;
    }
    .ffamily {
      font-family: "Indie Flower", cursive;
      color: #2b4bb6;
    }
    .flag {
      height: 30px;
    }
    .cardstyle {
      height: 480px;
      margin-right: 20px;
    }
    .cardhead {
      font-size: 24px;
      font-weight: 700;
    }
    .cardtext {
      font-size: 18px;
      font-weight: 300;
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      // .carousel-indicators{
      //   position: relative;
      //   justify-content: end;
      // }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }
      .carousel-control-next-icon {
        background-image: url("../images/rightarrow.png");
        filter: invert(0) grayscale(0) !important;
        background-color: #00b8ff;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon {
        background-image: url("../images/leftarrow.png");
        background-color: #00b8ff;
        filter: invert(0) grayscale(0) !important;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      //   .carousel-indicators [data-bs-target] {
      //     // box-sizing: content-box;
      //     flex: 0 1 auto;
      //     width: 46px;
      //     height: 46px;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     border-radius: 50% !important;
      //     background-color: #c9ebf9 !important;
      //     // background-clip: padding-box;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     opacity: 0.5;
      //     transition: opacity 0.6s ease;
      //     // background-image: url("../images/leftarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      //   .carousel-indicators .active {
      //     opacity: 1;
      //     flex: 0 1 auto;
      //     background-color: #00B8FF !important;
      //     border-radius: 50%;
      //     height: 46px;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     width: 46px;
      //     // background-image: url("../images/rightarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      .carouselimg {
        height: 200px;
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #2b4bb6;
      }
      .flag {
        height: 30px;
      }
      .cardstyle {
        height: 480px;
      }
      .cardhead {
        font-size: 24px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 18px;
        font-weight: 300;
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      // .carousel-indicators{
      //   position: relative;
      //   justify-content: end;
      // }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }
      .carousel-control-next-icon {
        background-image: url("../images/rightarrow.png");
        filter: invert(0) grayscale(0) !important;
        background-color: #00b8ff;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon {
        background-image: url("../images/leftarrow.png");
        background-color: #00b8ff;
        filter: invert(0) grayscale(0) !important;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      //   .carousel-indicators [data-bs-target] {
      //     // box-sizing: content-box;
      //     flex: 0 1 auto;
      //     width: 46px;
      //     height: 46px;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     border-radius: 50% !important;
      //     background-color: #c9ebf9 !important;
      //     // background-clip: padding-box;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     opacity: 0.5;
      //     transition: opacity 0.6s ease;
      //     // background-image: url("../images/leftarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      //   .carousel-indicators .active {
      //     opacity: 1;
      //     flex: 0 1 auto;
      //     background-color: #00B8FF !important;
      //     border-radius: 50%;
      //     height: 46px;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     width: 46px;
      //     // background-image: url("../images/rightarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      .carouselimg {
        height: 155px;
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #2b4bb6;
      }
      .flag {
        height: 30px;
      }
      .cardstyle {
        height: 425px;
      }
      .cardhead {
        font-size: 22px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 16px;
        font-weight: 300;
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1440px) {
    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      // .carousel-indicators{
      //   position: relative;
      //   justify-content: end;
      // }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }
      .carousel-control-next-icon {
        background-image: url("../images/rightarrow.png");
        filter: invert(0) grayscale(0) !important;
        background-color: #00b8ff;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon {
        background-image: url("../images/leftarrow.png");
        background-color: #00b8ff;
        filter: invert(0) grayscale(0) !important;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      //   .carousel-indicators [data-bs-target] {
      //     // box-sizing: content-box;
      //     flex: 0 1 auto;
      //     width: 46px;
      //     height: 46px;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     border-radius: 50% !important;
      //     background-color: #c9ebf9 !important;
      //     // background-clip: padding-box;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     opacity: 0.5;
      //     transition: opacity 0.6s ease;
      //     // background-image: url("../images/leftarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      //   .carousel-indicators .active {
      //     opacity: 1;
      //     flex: 0 1 auto;
      //     background-color: #00B8FF !important;
      //     border-radius: 50%;
      //     height: 46px;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     width: 46px;
      //     // background-image: url("../images/rightarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      .carouselimg {
        height: 120px;
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #2b4bb6;
      }
      .flag {
        height: 30px;
      }
      .cardstyle {
        height: 369px;
      }
      .cardhead {
        font-size: 19px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 14px;
        font-weight: 300;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      // .carousel-indicators{
      //   position: relative;
      //   justify-content: end;
      // }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }
      .carousel-control-next-icon {
        background-image: url("../images/rightarrow.png");
        filter: invert(0) grayscale(0) !important;
        background-color: #00b8ff;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon {
        background-image: url("../images/leftarrow.png");
        background-color: #00b8ff;
        filter: invert(0) grayscale(0) !important;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      //   .carousel-indicators [data-bs-target] {
      //     // box-sizing: content-box;
      //     flex: 0 1 auto;
      //     width: 46px;
      //     height: 46px;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     border-radius: 50% !important;
      //     background-color: #c9ebf9 !important;
      //     // background-clip: padding-box;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     opacity: 0.5;
      //     transition: opacity 0.6s ease;
      //     // background-image: url("../images/leftarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      //   .carousel-indicators .active {
      //     opacity: 1;
      //     flex: 0 1 auto;
      //     background-color: #00B8FF !important;
      //     border-radius: 50%;
      //     height: 46px;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     width: 46px;
      //     // background-image: url("../images/rightarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      .carouselimg {
        height: 130px;
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #2b4bb6;
      }
      .flag {
        height: 30px;
      }
      .cardstyle {
        height: 345px;
      }
      .cardhead {
        font-size: 18px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 12px;
        font-weight: 300;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      // .carousel-indicators{
      //   position: relative;
      //   justify-content: end;
      // }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }
      .carousel-control-next-icon {
        background-image: url("../images/rightarrow.png");
        filter: invert(0) grayscale(0) !important;
        background-color: #00b8ff;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon {
        background-image: url("../images/leftarrow.png");
        background-color: #00b8ff;
        filter: invert(0) grayscale(0) !important;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      //   .carousel-indicators [data-bs-target] {
      //     // box-sizing: content-box;
      //     flex: 0 1 auto;
      //     width: 46px;
      //     height: 46px;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     border-radius: 50% !important;
      //     background-color: #c9ebf9 !important;
      //     // background-clip: padding-box;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     opacity: 0.5;
      //     transition: opacity 0.6s ease;
      //     // background-image: url("../images/leftarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      //   .carousel-indicators .active {
      //     opacity: 1;
      //     flex: 0 1 auto;
      //     background-color: #00B8FF !important;
      //     border-radius: 50%;
      //     height: 46px;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     width: 46px;
      //     // background-image: url("../images/rightarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      .carouselimg {
        height: 100px;
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #2b4bb6;
      }
      .flag {
        height: 30px;
      }
      .cardstyle {
        height: 345px;
      }
      .cardhead {
        font-size: 14px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 12px;
        font-weight: 300;
      }
    }
  }

  //blogs
  .blogs {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .textstyle {
      font-size: 18px;
    }
    .cardhead {
      font-size: 24px;
      font-weight: 700;
    }
    .cardstyle {
      opacity: 0; /* Initially hide the boxes */
      transform: translateY(20%);
      border-radius: 16px;
      &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
    }
    .cardstyle.animate {
      animation: slide-up 0.5s ease-in-out forwards;
    }

    @keyframes slide-up {
      0% {
        transform: translateY(20%);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
    .card-img-overlay {
      position: absolute;
      background: #fff;
      color: #000;
      top: 75%;
      right: 0;
      bottom: 0;
      text-align: start;
      left: 0;
      margin: 20px;
      padding: var(--bs-card-img-overlay-padding);
      border-radius: var(--bs-card-inner-border-radius);
    }
    .bluestyle {
      background-color: #beebf8;
      font-size: 14px;
      font-weight: 500;
      color: #000;
      border-radius: 6px;
      width: max-content;
      padding: 2px 4px;
    }

    .franchisebtn {
      background-color: #ff9133;
      padding: 8px 24px;
      border-radius: 6px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .blogs {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .textstyle {
        font-size: 18px;
      }
      .cardhead {
        font-size: 24px;
        font-weight: 700;
      }
      .cardstyle {
        border-radius: 16px;
        &:hover {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
      }
      .card-img-overlay {
        position: absolute;
        background: #fff;
        color: #000;
        top: 63%;
        right: 0;
        bottom: 0;
        text-align: start;
        left: 0;
        margin: 20px;
        padding: var(--bs-card-img-overlay-padding);
        border-radius: var(--bs-card-inner-border-radius);
      }
      .bluestyle {
        background-color: #beebf8;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        border-radius: 6px;
        width: max-content;
        padding: 2px 4px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 8px 24px;
        border-radius: 6px;
        // font-size: 14px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white !important; /* Ensure text color remains visible on hover */
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .blogs {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .textstyle {
        font-size: 16px;
      }
      .cardhead {
        font-size: 20px;
        font-weight: 700;
      }
      .cardstyle {
        border-radius: 16px;
        &:hover {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
      }
      .card-img-overlay {
        position: absolute;
        background: #fff;
        color: #000;
        top: 63%;
        right: 0;
        bottom: 0;
        text-align: start;
        left: 0;
        margin: 20px;
        padding: var(--bs-card-img-overlay-padding);
        border-radius: var(--bs-card-inner-border-radius);
      }
      .bluestyle {
        background-color: #beebf8;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        border-radius: 6px;
        width: max-content;
        padding: 2px 4px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 8px 16px;
        border-radius: 6px;
        // font-size: 14px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white !important; /* Ensure text color remains visible on hover */
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1440px) {
    .blogs {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .textstyle {
        font-size: 12px;
      }
      .cardhead {
        font-size: 20px;
        font-weight: 700;
      }
      .cardstyle {
        border-radius: 16px;
        &:hover {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
      }
      .card-img-overlay {
        position: absolute;
        background: #fff;
        color: #000;
        top: 58%;
        right: 0;
        bottom: 0;
        text-align: start;
        left: 0;
        margin: 20px;
        padding: var(--bs-card-img-overlay-padding);
        border-radius: var(--bs-card-inner-border-radius);
      }
      .bluestyle {
        background-color: #beebf8;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        border-radius: 6px;
        width: max-content;
        padding: 2px 4px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 4px 10px;
        border-radius: 6px;
        font-size: 14px;
        // height: 24px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white !important; /* Ensure text color remains visible on hover */
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .blogs {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .textstyle {
        font-size: 12px;
      }
      .cardhead {
        font-size: 18px;
        font-weight: 700;
      }
      .cardstyle {
        border-radius: 16px;
        &:hover {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
      }
      .card-img-overlay {
        position: absolute;
        background: #fff;
        color: #000;
        top: 55%;
        right: 0;
        bottom: 0;
        text-align: start;
        left: 0;
        margin: 20px;
        padding: var(--bs-card-img-overlay-padding);
        border-radius: var(--bs-card-inner-border-radius);
      }
      .bluestyle {
        background-color: #beebf8;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        border-radius: 6px;
        width: max-content;
        padding: 2px 4px;
      }

      .franchisebtn {
        background-color: #ff9133;
        padding: 8px;
        border-radius: 6px;
        font-size: 12px;
        height: 24px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white !important; /* Ensure text color remains visible on hover */
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .blogs {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .textstyle {
        font-size: 12px;
      }
      .cardhead {
        font-size: 14px;
        font-weight: 700;
      }
      .cardstyle {
        border-radius: 16px;
        &:hover {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
      }
      .card-img-overlay {
        position: absolute;
        background: #fff;
        color: #000;
        top: 55%;
        right: 0;
        bottom: 0;
        text-align: start;
        left: 0;
        margin: 20px;
        padding: 7px;
        border-radius: var(--bs-card-inner-border-radius);
      }
      .bluestyle {
        background-color: #beebf8;
        font-size: 12px;
        font-weight: 500;
        color: #000;
        border-radius: 6px;
        width: max-content;
        padding: 2px 4px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 2px 1px;
        border-radius: 6px;
        font-size: 12px;
        height: 24px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white !important; /* Ensure text color remains visible on hover */
      }
    }
  }

  //testimonials
  .testimonials {
    .videostyle {
      height: 255px;
      border-radius: 20px;
      border: 5px solid #2b4bb6;
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .testimonials {
      .videostyle {
        height: 221px;
        border-radius: 20px;
        margin-bottom: 20px;
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .testimonials {
      .videostyle {
        height: 203px;
        border-radius: 20px;
        margin-bottom: 20px;
      }
    }
  }
  @media (min-width: 1401px) and (max-width: 1440px) {
    .testimonials {
      .videostyle {
        height: 187px;
        border-radius: 20px;
        margin-bottom: 20px;
      }
    }
  }
  @media (min-width: 1369px) and (max-width: 1400px) {
    .testimonials {
      .videostyle {
        height: 178px;
        border-radius: 20px;
        margin-bottom: 20px;
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1368px) {
    .testimonials {
      .videostyle {
        height: 173px;
        border-radius: 20px;
        margin-bottom: 20px;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .testimonials {
      .videostyle {
        height: 168px;
        border-radius: 20px;
        margin-bottom: 20px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .testimonials {
      .videostyle {
        height: 132px;
        border-radius: 20px;
        margin-bottom: 20px;
      }
    }
  }

  //clientspeaks
  .cliendspeak {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    background-color: #2b4bb6;
    .head {
      font-size: 36px;
      font-weight: 700;
      text-align: center;
      color: #fff;
    }
    .textstyle {
      font-size: 18px;
      color: #fff;
      font-weight: 200;
    }
    .cardstyle {
      border-radius: 16px;
      background-color: #a1e6fe;
    }
    .testimonialimg {
      border-radius: 16px 0px 0px 16px;
    }
    .cardtxt {
      font-size: 18px;
      font-weight: 300;
      text-align: start;
      color: #000;
    }
    .cardhead {
      font-size: 24px;
      font-weight: 500;
      text-align: start;
      color: #000;
    }
    .badgesince {
      background-color: #beebf8;
      color: #000;
      font-size: 16px;
      font-weight: 500;
      padding: 3px 10px;
      border-radius: 52px;
      height: 28px;
    }
    [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
    [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
      background-color: transparent !important;
      filter: invert(0) grayscale(0) !important;
    }
   
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      display: inline-block;
      width: 2rem;
      height: 2rem;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100% 100%;
      //  background: black;
      padding: 22px;
      border-radius: 50%;
      margin-top: 20px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .carousel-control-prev,
    .carousel-control-next {
      position: relative;
      /* top: 0px; */
      bottom: -50px;
      z-index: 1;
      display: contents;
      align-items: center;
      justify-content: center;
      /* width: 15%; */
      padding: 0;
      color: #fff;
      text-align: center;
      background: none;
      border: 0;
      opacity: 0.5;
      transition: opacity 0.15s ease;
    }
    .carousel-control-next,
    .carousel-control-prev {
      position: relative;
      /* top: 0px; */
      bottom: -50px;
      z-index: 1;
      display: contents;
      align-items: center;
      justify-content: center;
      /* width: 15%; */
      padding: 0;
      color: #fff;
      text-align: center;
      background: none;
      border: 0;
      opacity: 0.5;
      transition: opacity 0.15s ease;
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .cliendspeak {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      background-color: #2b4bb6;
      .head {
        font-size: 36px;
        font-weight: 700;
        text-align: center;
        color: #fff;
      }
      .textstyle {
        font-size: 18px;
        color: #fff;
        font-weight: 200;
      }
      .cardstyle {
        border-radius: 16px;
        background-color: #a1e6fe;
      }
      .testimonialimg {
        border-radius: 16px 0px 0px 16px;
      }
      .cardtxt {
        font-size: 18px;
        font-weight: 300;
        text-align: start;
        color: #000;
      }
      .cardhead {
        font-size: 24px;
        font-weight: 500;
        text-align: start;
        color: #000;
      }
      .badgesince {
        background-color: #beebf8;
        color: #000;
        font-size: 15px;
        font-weight: 500;
        padding: 2px;
        border-radius: 52px;
        height: 28px;
      }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }

      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .cliendspeak {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      background-color: #2b4bb6;
      .head {
        font-size: 36px;
        font-weight: 700;
        text-align: center;
        color: #fff;
      }
      .textstyle {
        font-size: 16px;
        color: #fff;
        font-weight: 200;
      }
      .cardstyle {
        border-radius: 16px;
        background-color: #a1e6fe;
      }
      .testimonialimg {
        border-radius: 16px 0px 0px 16px;
        height: 290px;
      }
      .cardtxt {
        font-size: 16px;
        font-weight: 300;
        text-align: start;
        color: #000;
      }
      .cardhead {
        font-size: 24px;
        font-weight: 500;
        text-align: start;
        color: #000;
      }
      .badgesince {
        background-color: #beebf8;
        color: #000;
        font-size: 14px;
        font-weight: 500;
        padding: 2px;
        border-radius: 52px;
        height: 27px;
      }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }

      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1440px) {
    .cliendspeak {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      background-color: #2b4bb6;
      .head {
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        color: #fff;
      }
      .textstyle {
        font-size: 14px;
        color: #fff;
        font-weight: 200;
      }
      .cardstyle {
        border-radius: 16px;
        background-color: #a1e6fe;
      }
      .testimonialimg {
        border-radius: 16px 0px 0px 16px;
        height: 230px;
      }
      .cardtxt {
        font-size: 14px;
        font-weight: 300;
        text-align: start;
        color: #000;
      }
      .cardhead {
        font-size: 20px;
        font-weight: 500;
        text-align: start;
        color: #000;
      }
      .badgesince {
        background-color: #beebf8;
        color: #000;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 5px;
        border-radius: 52px;
        height: 23px;
      }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }
   
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .cliendspeak {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      background-color: #2b4bb6;
      .head {
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        color: #fff;
      }
      .textstyle {
        font-size: 12px;
        color: #fff;
        font-weight: 200;
      }
      .cardstyle {
        border-radius: 16px;
        background-color: #a1e6fe;
      }
      .testimonialimg {
        border-radius: 16px 0px 0px 16px;
        height: 230px;
      }
      .cardtxt {
        font-size: 12px;
        font-weight: 300;
        text-align: start;
        color: #000;
      }
      .cardhead {
        font-size: 18px;
        font-weight: 500;
        text-align: start;
        color: #000;
      }
      .badgesince {
        background-color: #beebf8;
        color: #000;
        font-size: 11px;
        font-weight: 500;
        padding: 2px 3px;
        border-radius: 52px;
        height: 23px;
      }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }
  
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .cliendspeak {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      background-color: #2b4bb6;
      .head {
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        color: #fff;
      }
      .textstyle {
        font-size: 12px;
        color: #fff;
        font-weight: 200;
      }
      .cardstyle {
        border-radius: 16px;
        background-color: #a1e6fe;
      }
      .testimonialimg {
        border-radius: 16px 0px 0px 16px;
        height: 199px;
      }
      .cardtxt {
        font-size: 12px;
        font-weight: 300;
        text-align: start;
        color: #000;
      }
      .cardhead {
        font-size: 18px;
        font-weight: 500;
        text-align: start;
        color: #000;
      }
      .badgesince {
        background-color: #beebf8;
        color: #000;
        font-size: 11px;
        font-weight: 500;
        padding: 2px 2px;
        border-radius: 52px;
        height: 38px;
      }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }
  
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
    }
  }

  //Partner
  .partner {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    background-color: #f2f8fa;
    .partnerimg {
      height: 160px;
      width: 100%;
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .partner {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .partnerimg {
        height: 140px;
        width: 100%;
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .partner {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .partnerimg {
        height: 110px;
        width: 100%;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1440px) {
    .partner {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .partnerimg {
        height: 100px;
        width: 100%;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .partner {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .partnerimg {
        height: 80px;
        width: 100%;
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 1.5) {
  @media (min-width: 1681px) {
    .home-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 88px;
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent;
      }
      .bgbannerstyle{
        background-size: cover;
        background-repeat: no-repeat;
        height: 81vh;
      }
      .dividersection {
        position: relative;
      }
      .bannerslik {
        //   .slick-track {
        //      width:100% !important;
        //      position: absolute;

        //   }
        //   .slick-list {
        //      position: relative;
        //     display: block;
        //     overflow: visible;
        //     margin: 0;
        //     padding: 0;
        // }
        .carousel-inner {
          overflow: visible;
        }
        [data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
        [data-bs-theme="dark"].carousel .carousel-control-next-icon,
        [data-bs-theme="dark"].carousel .carousel-control-prev-icon {
          filter: invert(0) grayscale(0);
        }
        .carousel-control-next-icon,
        .carousel-control-prev-icon {
          border-radius: 50%;
          background-color: #3ebf1b;
          padding: 5px;
        }
      }
      .headerimg {
        height: 50vh;
        width: 90% !important;
        border-radius: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -75%);
      }
      .logoimg {
        height: 82px;
        text-align: center;
        animation: slide-up 0.5s ease-in-out;
      }
      .headtext {
        font-size: 32px;
        font-weight: 700;
        animation: slide-up 0.4s ease-in-out;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 38px;
        padding: 5px 2px;
        box-shadow: 2px 9px #7ca7c87d;
        animation: slide-up 0.6s ease-in-out;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 38px;
        padding: 5px 2px;
        box-shadow: 2px 9px #2dd10045;
        animation: slide-up 0.6s ease-in-out;
      }
    }
    // .aboutus {
    //   font-family: "Be Vietnam Pro", sans-serif;
    //   font-style: normal;
    //   .topsticky {
    //     padding: 15px;
    //     position: fixed;
    //     bottom: 0;
    //     right: 0;
    //     z-index: 999;
    //   }
    //   .aboutmg {
    //     margin-top: 90px;
    //   }
    //   .bgcolor {
    //     background-color: #2b4bb6;
    //   }
    //   .textstyle {
    //     font-size: 14px !important;
    //     color: white;
    //     font-weight: 200;
    //   }
    //   .franchisebtn {
    //     background-color: #ff9133;
    //     padding: 8px 24px;
    //     border-radius: 6px;
    //     border-color: transparent !important;
    //     position: relative;
    //     overflow: hidden;
    //     z-index: 1;
    //     color: white;
    //     text-align: center;
    //     display: inline-flex;
    //     align-items: center;
    //     justify-content: center;
    //     cursor: pointer;
    //     transition: all 0.4s ease-in-out;
    //   }
    //   .franchisebtn::before {
    //     content: "";
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     width: 200%;
    //     height: 200%;
    //     background-color: rgba(255, 255, 255, 0.1);
    //     border-radius: 50%;
    //     transform: translate(-50%, -50%) scale(0);
    //     transition: transform 0.4s ease-in-out;
    //     z-index: 0;
    //   }
    //   .franchisebtn:hover::before {
    //     transform: translate(-50%, -50%) scale(1);
    //   }
    //   .franchisebtn:hover {
    //     background-color: #ff9133;
    //     color: white;
    //   }
    //   .box {
    //     opacity: 1;
    //     transform: translateY(0);
    //     border-radius: 24px 24px 0px 0px;
    //     background: linear-gradient(
    //       180deg,
    //       rgba(255, 255, 255, 0.06),
    //       hwb(0deg 60% 40% / 0)
    //     );
    //     padding: 20px 30px;
    //     border-radius: 24px 24px 0px 0px;
    //   }
    //   .box.animate {
    //     animation: slide-up 0.5s ease-in-out forwards;
    //   }
    //   @keyframes slide-up {
    //     0% {
    //       transform: translateY(20%);
    //       opacity: 0;
    //     }
    //     100% {
    //       transform: translateY(0);
    //       opacity: 1;
    //     }
    //   }
    //   .boxtext {
    //     color: rgba(255, 255, 255, 1);
    //     z-index: 1;
    //     font-size: 16px !important;
    //   }
    //   .box h1 {
    //     color: rgba(255, 255, 255, 1);
    //     z-index: 1;
    //   }
    // }
    // .ourmilestone {
    //   font-style: normal;
    //   font-family: "Be Vietnam Pro", sans-serif;
    //   [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
    //   [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
    //     background-color: transparent !important;
    //     filter: invert(0) grayscale(0) !important;
    //   }
    //   .carousel-control-next-icon {
    //     background-image: url("../images/rightarrow.png");
    //     filter: invert(0) grayscale(0) !important;
    //     background-color: #00b8ff;
    //     background-repeat: no-repeat !important;
    //     background-size: 30px !important;
    //   }
    //   .carousel-control-prev-icon {
    //     background-image: url("../images/leftarrow.png");
    //     background-color: #00b8ff;
    //     filter: invert(0) grayscale(0) !important;
    //     background-repeat: no-repeat !important;
    //     background-size: 30px !important;
    //   }
    //   .carousel-control-prev-icon,
    //   .carousel-control-next-icon {
    //     display: inline-block;
    //     width: 2rem;
    //     height: 2rem;
    //     background-repeat: no-repeat;
    //     background-position: 50%;
    //     background-size: 100% 100%;
    //     padding: 22px;
    //     border-radius: 50%;
    //     margin-top: 20px;
    //     margin-left: 5px;
    //     margin-right: 5px;
    //   }
    //   .carousel-control-prev,
    //   .carousel-control-next {
    //     position: relative;
    //     bottom: -50px;
    //     z-index: 1;
    //     display: contents;
    //     align-items: center;
    //     justify-content: center;
    //     padding: 0;
    //     color: #fff;
    //     text-align: center;
    //     background: none;
    //     border: 0;
    //     opacity: 0.5;
    //     transition: opacity 0.15s ease;
    //   }
    //   .carouselimg {
    //     height: 150px !important;
    //   }
    //   .ffamily {
    //     font-family: "Indie Flower", cursive;
    //     color: #2b4bb6;
    //   }
    //   .flag {
    //     height: 30px;
    //   }
    //   .cardstyle {
    //     height: 400px !important;
    //     margin-right: 20px;
    //   }
    //   .cardhead {
    //     font-size: 22px !important;
    //     font-weight: 700;
    //   }
    //   .cardtext {
    //     font-size: 14px !important;
    //     font-weight: 300;
    //   }
    // }
    // .blogs {
    //   font-family: "Be Vietnam Pro", sans-serif;
    //   font-style: normal;
    //   .textstyle {
    //     font-size: 14px !important;
    //   }
    //   .cardhead {
    //     font-size: 20px !important;
    //     font-weight: 700;
    //   }
    //   .cardstyle {
    //     opacity: 0;
    //     transform: translateY(20%);
    //     border-radius: 16px;
    //     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    //     animation: slide-up 0.5s ease-in-out forwards;
    //   }
    //   @keyframes slide-up {
    //     0% {
    //       transform: translateY(20%);
    //       opacity: 0;
    //     }
    //     100% {
    //       transform: translateY(0);
    //       opacity: 1;
    //     }
    //   }
    //   .btn-outline-primary {
    //     border-radius: 24px;
    //   }
    //   .line-breaker {
    //     margin-top: 3px;
    //     border-top: 3px solid #3ebf1b;
    //     width: 25%;
    //     display: block;
    //   }
    // }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .home-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      .bgbannerstyle{
        background-size: cover;
        background-repeat: no-repeat;
        height: 72vh;
      }
      .dividersection {
        position: relative;
        // height:100vh;
      }
      .headerimg {
        height: 45vh;
        width: 95% !important;
        // margin-top: -445px;
        border-radius: 16px;
        //  animation: slide-up 0.5s ease-in-out;
        // position: relative;
        position: absolute;

        /* Center the top-left position of the img */
        top: 50%;
        left: 50%;

        /* Center relative to the diving line */
        transform: translate(-50%, -70%);
      }
      .carousel-inner {
        overflow: visible;
      }
      .logoimg {
        height: 82px;
        text-align: center;
      }
      .headtext {
        font-size: 40px;
        font-weight: 700;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 45px;
        padding: 11px;
        box-shadow: 2px 9px #7ca7c87d;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 45px;
        padding: 11px;
        box-shadow: 2px 9px #2dd10045;
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .home-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      .bgbannerstyle{
        background-size: cover;
        background-repeat: no-repeat;
        height: 81vh;
      }
      .dividersection {
        position: relative;
        // height:100vh;
      }
      .headerimg {
        height: 50vh;
        width: 90% !important;
        // margin-top: -445px;
        border-radius: 16px;
        //  animation: slide-up 0.5s ease-in-out;
        // position: relative;
        position: absolute;

        /* Center the top-left position of the img */
        top: 50%;
        left: 50%;

        /* Center relative to the diving line */
        transform: translate(-50%, -70%);
      }
      .carousel-inner {
        overflow: visible;
      }
      .logoimg {
        height: 82px;
        text-align: center;
      }
      .headtext {
        font-size: 38px;
        font-weight: 700;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 40px;
        padding: 7px;
        box-shadow: 2px 9px #7ca7c87d;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 40px;
        padding: 7px;
        box-shadow: 2px 9px #2dd10045;
      }
    }
  }
  @media (min-width: 1401px) and (max-width: 1440px) {
    .home-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      .bgbannerstyle{
        background-size: cover;
        background-repeat: no-repeat;
        height: 72vh;
      }
    
      .dividersection {
        position: relative;
        // height:100vh;
      }
      .carousel-inner {
        overflow: visible;
      }
      .headerimg {
        height: 45vh;
        width: 90% !important;
        // margin-top: -445px;
        border-radius: 16px;
        //  animation: slide-up 0.5s ease-in-out;
        // position: relative;
        position: absolute;

        /* Center the top-left position of the img */
        top: 50%;
        left: 50%;

        /* Center relative to the diving line */
        transform: translate(-50%, -70%);
      }
      .logoimg {
        height: 70px;
        text-align: center;
      }
      .headtext {
        font-size: 34px;
        font-weight: 700;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: 1px 6px #7ca7c87d;
        font-size: 13px;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: 1px 6px #2dd10045;
        font-size: 13px;
      }
    }
  }
  @media (min-pxwidth: 1369px) and (max-width: 1400px) {
    .home-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      .carousel-inner {
        overflow: visible;
      }
      .bgbannerstyle{
        background-size: cover;
        background-repeat: no-repeat;
        height: 72vh;
      }
   
      .dividersection {
        position: relative;
        // height:100vh;
      }
      .headerimg {
        height: 36vh;
        width: 90% !important;
        // margin-top: -445px;
        border-radius: 16px;
        //  animation: slide-up 0.5s ease-in-out;
        // position: relative;
        position: absolute;

        /* Center the top-left position of the img */
        top: 50%;
        left: 50%;

        /* Center relative to the diving line */
        transform: translate(-50%, -60%);
      }
      .logoimg {
        height: 70px;
        text-align: center;
      }
      .headtext {
        font-size: 34px;
        font-weight: 700;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: 1px 6px #7ca7c87d;
        font-size: 13px;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: 1px 6px #2dd10045;
        font-size: 13px;
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1368px) {
    .home-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 83px;
      .carousel-inner {
        overflow: visible;
      }
      .bgbannerstyle{
        background-size: cover;
        background-repeat: no-repeat;
        height: 81vh;
      }
    
      .dividersection {
        position: relative;
        // height:100vh;
      }
      .headerimg {
        height: 50vh;
        width: 90% !important;
        // margin-top: -445px;
        border-radius: 16px;
        //  animation: slide-up 0.5s ease-in-out;
        // position: relative;
        position: absolute;

        /* Center the top-left position of the img */
        top: 50%;
        left: 50%;

        /* Center relative to the diving line */
        transform: translate(-50%, -70%);
      }
      .logoimg {
        height: 70px;
        text-align: center;
      }
      .headtext {
        font-size: 30px;
        font-weight: 700;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 26px;
        padding: 4px;
        box-shadow: 1px 6px #7ca7c87d;
        font-size: 12px;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 26px;
        padding: 4px;
        box-shadow: 1px 6px #2dd10045;
        font-size: 12px;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .home-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 72px;
      .carousel-inner {
        overflow: visible;
      }
      .bgbannerstyle{
        background-size: cover;
        background-repeat: no-repeat;
        height: 77vh;
      }
      .dividersection {
        position: relative;
        // height:100vh;
      }
      .headerimg {
        height: 42vh;
        width: 80% !important;
        // margin-top: -445px;
        border-radius: 16px;
        //  animation: slide-up 0.5s ease-in-out;
        // position: relative;
        position: absolute;

        /* Center the top-left position of the img */
        top: 50%;
        left: 50%;

        /* Center relative to the diving line */
        transform: translate(-50%, -70%);
      }
      .logoimg {
        height: 70px;
        text-align: center;
      }
      .headtext {
        font-size: 25px;
        font-weight: 700;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 26px;
        padding: 4px;
        box-shadow: 1px 6px #7ca7c87d;
        font-size: 12px;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 26px;
        padding: 4px;
        box-shadow: 1px 6px #2dd10045;
        font-size: 12px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .home-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 72px;
      .carousel-inner {
        overflow: visible;
      }
      .bgbannerstyle{
        background-size: cover;
        background-repeat: no-repeat;
        height: 73vh;
      }
      .dividersection {
        position: relative;
        // height:100vh;
      }
      .headerimg {
        height: 35vh;
        width: 80% !important;
        // margin-top: -445px;
        border-radius: 16px;
        //  animation: slide-up 0.5s ease-in-out;
        // position: relative;
        position: absolute;

        /* Center the top-left position of the img */
        top: 50%;
        left: 50%;

        /* Center relative to the diving line */
        transform: translate(-50%, -84%);
      }
      .logoimg {
        height: 70px;
        text-align: center;
      }
      .headtext {
        font-size: 24px;
        font-weight: 700;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 54px;
        // padding:3px 2px;
        box-shadow: 1px 6px #7ca7c87d;
        font-size: 12px;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 54px;
        // padding: 3px 2px;
        box-shadow: 1px 6px #2dd10045;
        font-size: 12px;
      }
    }
  }
  //about
  .aboutus {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .topsticky {
      padding: 15px;
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 999;
    }
    .topstickycb {
      padding: 15px;
      position: fixed;
      bottom: 90px;
      right: 0;
      z-index: 999;
    }
    .aboutmg {
      margin-top: 90px;
    }
    .bgcolor {
      background-color: #2b4bb6;
      // margin-top: -190px;
    }
    .textstyle {
      font-size: 18px;
      color: white;
      font-weight: 200;
    }
    .franchisebtn {
      background-color: #ff9133;
      padding: 8px 24px;
      border-radius: 6px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }

    .box {
      opacity: 1; /* Initially hide the boxes */
      transform: translateY(0);
      border-radius: 24px 24px 0px 0px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.06),
        hwb(0deg 60% 40% / 0)
      );
      padding: 20px 30px;
      border-radius: 24px 24px 0px 0px;
    }

    .box.animate {
      animation: slide-up 0.5s ease-in-out forwards;
    }

    @keyframes slide-up {
      0% {
        transform: translateY(20%);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }

    .boxtext {
      color: rgba(255, 255, 255, 1); /* Full opacity */
      z-index: 1;
      // position: relative;
      font-size: 20px;
    }

    /* Optional: Ensure the heading has full opacity and visibility as well */
    .box h1 {
      color: rgba(255, 255, 255, 1); /* Full opacity */
      z-index: 1;
      // position: relative;
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .aboutus {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .topsticky {
        padding: 15px;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 999;
      }
      .bgcolor {
        background-color: #2b4bb6;
        // margin-top: -190px;
      }
      .textstyle {
        font-size: 18px;
        color: white;
        font-weight: 200;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 8px 24px;
        border-radius: 6px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }

      .box {
        border-radius: 24px 24px 0px 0px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.06),
          hwb(0deg 60% 40% / 0)
        );
        padding: 20px 30px;
        border-radius: 24px 24px 0px 0px;
      }

      .boxtext {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
        font-size: 19px;
      }

      /* Optional: Ensure the heading has full opacity and visibility as well */
      .box h1 {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .aboutus {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .topsticky {
        padding: 15px;
        position: fixed;
        bottom: -22px;
        right: 0;
        z-index: 999;
      }
      .bgcolor {
        background-color: #2b4bb6;
        // margin-top: -190px;
      }
      .textstyle {
        font-size: 16px;
        color: white;
        font-weight: 200;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 8px 24px;
        border-radius: 6px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }

      .box {
        border-radius: 24px 24px 0px 0px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.06),
          hwb(0deg 60% 40% / 0)
        );
        padding: 20px 30px;
        border-radius: 24px 24px 0px 0px;
      }

      .boxtext {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
        font-size: 17px;
      }

      /* Optional: Ensure the heading has full opacity and visibility as well */
      .box h1 {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1440px) {
    .aboutus {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .topsticky {
        padding: 15px;
        position: fixed;
        bottom: -22px;
        right: 0;
        z-index: 999;
      }
      .bgcolor {
        background-color: #2b4bb6;
        // margin-top: -190px;
      }
      .textstyle {
        font-size: 14px;
        color: white;
        font-weight: 200;
      }

      .franchisebtn {
        background-color: #ff9133;
        padding: 4px 24px;
        border-radius: 6px;
        // height: 30px;
        font-size: 14px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }

      .box {
        border-radius: 24px 24px 0px 0px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.06),
          hwb(0deg 60% 40% / 0)
        );
        padding: 10px 10px;
        border-radius: 24px 24px 0px 0px;
      }

      .boxtext {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
        font-size: 16px;
      }

      /* Optional: Ensure the heading has full opacity and visibility as well */
      .box h1 {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .aboutus {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .topsticky {
        padding: 15px;
        position: fixed;
        bottom: -30px;
        right: 0;
        z-index: 999;
      }
      .bgcolor {
        background-color: #2b4bb6;
        // margin-top: -190px;
      }
      .textstyle {
        font-size: 12px;
        color: white;
        font-weight: 200;
      }

      .franchisebtn {
        background-color: #ff9133;
        padding: 2px 24px;
        border-radius: 6px;
        height: 24px;
        font-size: 12px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }

      .box {
        border-radius: 24px 24px 0px 0px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.06),
          hwb(0deg 60% 40% / 0)
        );
        padding: 10px 10px;
        border-radius: 24px 24px 0px 0px;
      }

      .boxtext {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
        font-size: 13px;
      }

      /* Optional: Ensure the heading has full opacity and visibility as well */
      .box h1 {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .aboutus {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .topsticky {
        padding: 15px;
        position: fixed;
        bottom: -28px;
        right: 0;
        z-index: 999;
      }
      .bgcolor {
        background-color: #2b4bb6;
        // margin-top: -190px;
      }
      .textstyle {
        font-size: 12px;
        color: white;
        font-weight: 200;
      }

      .franchisebtn {
        background-color: #ff9133;
        padding: 2px 20px;
        border-radius: 6px;
        height: 24px;
        font-size: 12px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }

      .box {
        border-radius: 24px 24px 0px 0px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.06),
          hwb(0deg 60% 40% / 0)
        );
        padding: 10px 10px;
        border-radius: 24px 24px 0px 0px;
      }

      .boxtext {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
        font-size: 12px;
      }

      /* Optional: Ensure the heading has full opacity and visibility as well */
      .box h1 {
        color: rgba(255, 255, 255, 1); /* Full opacity */
        z-index: 1;
      }
    }
  }
  //our milestone
  .ourmilestone {
    font-style: normal;
    font-family: "Be Vietnam Pro", sans-serif;
    // .carousel-indicators{
    //   position: relative;
    //   justify-content: end;
    // }

    // .slick-slide {
    //   width: 466px !important;
    // }
    [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
    [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
      background-color: transparent !important;
      filter: invert(0) grayscale(0) !important;
    }
    .carousel-control-next-icon {
      background-image: url("../images/rightarrow.png");
      filter: invert(0) grayscale(0) !important;
      background-color: #00b8ff;
      background-repeat: no-repeat !important;
      background-size: 30px !important;
    }
    .carousel-control-prev-icon {
      background-image: url("../images/leftarrow.png");
      background-color: #00b8ff;
      filter: invert(0) grayscale(0) !important;
      background-repeat: no-repeat !important;
      background-size: 30px !important;
    }
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      display: inline-block;
      width: 2rem;
      height: 2rem;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100% 100%;
      //  background: black;
      padding: 22px;
      border-radius: 50%;
      margin-top: 20px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .carousel-control-prev,
    .carousel-control-next {
      position: relative;
      /* top: 0px; */
      bottom: -50px;
      z-index: 1;
      display: contents;
      align-items: center;
      justify-content: center;
      /* width: 15%; */
      padding: 0;
      color: #fff;
      text-align: center;
      background: none;
      border: 0;
      opacity: 0.5;
      transition: opacity 0.15s ease;
    }
    .carousel-control-next,
    .carousel-control-prev {
      position: relative;
      /* top: 0px; */
      bottom: -50px;
      z-index: 1;
      display: contents;
      align-items: center;
      justify-content: center;
      /* width: 15%; */
      padding: 0;
      color: #fff;
      text-align: center;
      background: none;
      border: 0;
      opacity: 0.5;
      transition: opacity 0.15s ease;
    }
    //   .carousel-indicators [data-bs-target] {
    //     // box-sizing: content-box;
    //     flex: 0 1 auto;
    //     width: 46px;
    //     height: 46px;
    //     padding: 0;
    //     margin-right: 3px;
    //     margin-left: 3px;
    //     text-indent: -999px;
    //     cursor: pointer;
    //     border-radius: 50% !important;
    //     background-color: #c9ebf9 !important;
    //     // background-clip: padding-box;
    //     border: 0;
    //     border-top: 10px solid transparent;
    //     border-bottom: 10px solid transparent;
    //     opacity: 0.5;
    //     transition: opacity 0.6s ease;
    //     // background-image: url("../images/leftarrow.png");
    //     background-repeat: no-repeat;
    //     background-size: 30px;
    //     background-position-y: center;
    //     transform: rotate(180deg)
    // }
    //   .carousel-indicators .active {
    //     opacity: 1;
    //     flex: 0 1 auto;
    //     background-color: #00B8FF !important;
    //     border-radius: 50%;
    //     height: 46px;
    //     border: 0;
    //     border-top: 10px solid transparent;
    //     border-bottom: 10px solid transparent;
    //     padding: 0;
    //     margin-right: 3px;
    //     margin-left: 3px;
    //     text-indent: -999px;
    //     cursor: pointer;
    //     width: 46px;
    //     // background-image: url("../images/rightarrow.png");
    //     background-repeat: no-repeat;
    //     background-size: 30px;
    //     background-position-y: center;
    //     transform: rotate(180deg)
    // }
    .carouselimg {
      height: 200px;
    }
    .ffamily {
      font-family: "Indie Flower", cursive;
      color: #2b4bb6;
    }
    .flag {
      height: 30px;
    }
    .cardstyle {
      height: 480px;
      margin-right: 20px;
    }
    .cardhead {
      font-size: 24px;
      font-weight: 700;
    }
    .cardtext {
      font-size: 18px;
      font-weight: 300;
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      // .carousel-indicators{
      //   position: relative;
      //   justify-content: end;
      // }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }
      .carousel-control-next-icon {
        background-image: url("../images/rightarrow.png");
        filter: invert(0) grayscale(0) !important;
        background-color: #00b8ff;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon {
        background-image: url("../images/leftarrow.png");
        background-color: #00b8ff;
        filter: invert(0) grayscale(0) !important;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      //   .carousel-indicators [data-bs-target] {
      //     // box-sizing: content-box;
      //     flex: 0 1 auto;
      //     width: 46px;
      //     height: 46px;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     border-radius: 50% !important;
      //     background-color: #c9ebf9 !important;
      //     // background-clip: padding-box;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     opacity: 0.5;
      //     transition: opacity 0.6s ease;
      //     // background-image: url("../images/leftarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      //   .carousel-indicators .active {
      //     opacity: 1;
      //     flex: 0 1 auto;
      //     background-color: #00B8FF !important;
      //     border-radius: 50%;
      //     height: 46px;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     width: 46px;
      //     // background-image: url("../images/rightarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      .carouselimg {
        height: 200px;
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #2b4bb6;
      }
      .flag {
        height: 30px;
      }
      .cardstyle {
        height: 480px;
      }
      .cardhead {
        font-size: 24px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 18px;
        font-weight: 300;
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      // .carousel-indicators{
      //   position: relative;
      //   justify-content: end;
      // }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }
      .carousel-control-next-icon {
        background-image: url("../images/rightarrow.png");
        filter: invert(0) grayscale(0) !important;
        background-color: #00b8ff;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon {
        background-image: url("../images/leftarrow.png");
        background-color: #00b8ff;
        filter: invert(0) grayscale(0) !important;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      //   .carousel-indicators [data-bs-target] {
      //     // box-sizing: content-box;
      //     flex: 0 1 auto;
      //     width: 46px;
      //     height: 46px;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     border-radius: 50% !important;
      //     background-color: #c9ebf9 !important;
      //     // background-clip: padding-box;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     opacity: 0.5;
      //     transition: opacity 0.6s ease;
      //     // background-image: url("../images/leftarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      //   .carousel-indicators .active {
      //     opacity: 1;
      //     flex: 0 1 auto;
      //     background-color: #00B8FF !important;
      //     border-radius: 50%;
      //     height: 46px;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     width: 46px;
      //     // background-image: url("../images/rightarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      .carouselimg {
        height: 165px;
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #2b4bb6;
      }
      .flag {
        height: 30px;
      }
      .cardstyle {
        height: 430px;
      }
      .cardhead {
        font-size: 24px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 16px;
        font-weight: 300;
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1440px) {
    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      // .carousel-indicators{
      //   position: relative;
      //   justify-content: end;
      // }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }
      .carousel-control-next-icon {
        background-image: url("../images/rightarrow.png");
        filter: invert(0) grayscale(0) !important;
        background-color: #00b8ff;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon {
        background-image: url("../images/leftarrow.png");
        background-color: #00b8ff;
        filter: invert(0) grayscale(0) !important;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      //   .carousel-indicators [data-bs-target] {
      //     // box-sizing: content-box;
      //     flex: 0 1 auto;
      //     width: 46px;
      //     height: 46px;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     border-radius: 50% !important;
      //     background-color: #c9ebf9 !important;
      //     // background-clip: padding-box;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     opacity: 0.5;
      //     transition: opacity 0.6s ease;
      //     // background-image: url("../images/leftarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      //   .carousel-indicators .active {
      //     opacity: 1;
      //     flex: 0 1 auto;
      //     background-color: #00B8FF !important;
      //     border-radius: 50%;
      //     height: 46px;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     width: 46px;
      //     // background-image: url("../images/rightarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      .carouselimg {
        height: 130px;
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #2b4bb6;
      }
      .flag {
        height: 30px;
      }
      .cardstyle {
        height: 370px;
      }
      .cardhead {
        font-size: 19px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 14px;
        font-weight: 300;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      // .carousel-indicators{
      //   position: relative;
      //   justify-content: end;
      // }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }
      .carousel-control-next-icon {
        background-image: url("../images/rightarrow.png");
        filter: invert(0) grayscale(0) !important;
        background-color: #00b8ff;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon {
        background-image: url("../images/leftarrow.png");
        background-color: #00b8ff;
        filter: invert(0) grayscale(0) !important;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      //   .carousel-indicators [data-bs-target] {
      //     // box-sizing: content-box;
      //     flex: 0 1 auto;
      //     width: 46px;
      //     height: 46px;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     border-radius: 50% !important;
      //     background-color: #c9ebf9 !important;
      //     // background-clip: padding-box;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     opacity: 0.5;
      //     transition: opacity 0.6s ease;
      //     // background-image: url("../images/leftarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      //   .carousel-indicators .active {
      //     opacity: 1;
      //     flex: 0 1 auto;
      //     background-color: #00B8FF !important;
      //     border-radius: 50%;
      //     height: 46px;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     width: 46px;
      //     // background-image: url("../images/rightarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      .carouselimg {
        height: 115px;
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #2b4bb6;
      }
      .flag {
        height: 30px;
      }
      .cardstyle {
        height: 345px;
      }
      .cardhead {
        font-size: 16px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 12px;
        font-weight: 300;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      // .carousel-indicators{
      //   position: relative;
      //   justify-content: end;
      // }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }
      .carousel-control-next-icon {
        background-image: url("../images/rightarrow.png");
        filter: invert(0) grayscale(0) !important;
        background-color: #00b8ff;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon {
        background-image: url("../images/leftarrow.png");
        background-color: #00b8ff;
        filter: invert(0) grayscale(0) !important;
        background-repeat: no-repeat !important;
        background-size: 30px !important;
      }
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      //   .carousel-indicators [data-bs-target] {
      //     // box-sizing: content-box;
      //     flex: 0 1 auto;
      //     width: 46px;
      //     height: 46px;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     border-radius: 50% !important;
      //     background-color: #c9ebf9 !important;
      //     // background-clip: padding-box;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     opacity: 0.5;
      //     transition: opacity 0.6s ease;
      //     // background-image: url("../images/leftarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      //   .carousel-indicators .active {
      //     opacity: 1;
      //     flex: 0 1 auto;
      //     background-color: #00B8FF !important;
      //     border-radius: 50%;
      //     height: 46px;
      //     border: 0;
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //     padding: 0;
      //     margin-right: 3px;
      //     margin-left: 3px;
      //     text-indent: -999px;
      //     cursor: pointer;
      //     width: 46px;
      //     // background-image: url("../images/rightarrow.png");
      //     background-repeat: no-repeat;
      //     background-size: 30px;
      //     background-position-y: center;
      //     transform: rotate(180deg)
      // }
      .carouselimg {
        height: 100px;
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #2b4bb6;
      }
      .flag {
        height: 30px;
      }
      .cardstyle {
        height: 345px;
      }
      .cardhead {
        font-size: 14px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 12px;
        font-weight: 300;
      }
    }
  }

  //blogs
  .blogs {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .textstyle {
      font-size: 18px;
    }
    .cardhead {
      font-size: 24px;
      font-weight: 700;
    }
    .cardstyle {
      opacity: 0; /* Initially hide the boxes */
      transform: translateY(20%);
      border-radius: 16px;
      &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
    }
    .cardstyle.animate {
      animation: slide-up 0.5s ease-in-out forwards;
    }

    @keyframes slide-up {
      0% {
        transform: translateY(20%);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
    .card-img-overlay {
      position: absolute;
      background: #fff;
      color: #000;
      top: 65%;
      right: 0;
      bottom: 0;
      text-align: start;
      left: 0;
      margin: 20px;
      padding: var(--bs-card-img-overlay-padding);
      border-radius: var(--bs-card-inner-border-radius);
    }
    .bluestyle {
      background-color: #beebf8;
      font-size: 14px;
      font-weight: 500;
      color: #000;
      border-radius: 6px;
      width: max-content;
      padding: 2px 4px;
    }

    .franchisebtn {
      background-color: #ff9133;
      padding: 8px 24px;
      border-radius: 6px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .blogs {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .textstyle {
        font-size: 18px;
      }
      .cardhead {
        font-size: 24px;
        font-weight: 700;
      }
      .cardstyle {
        border-radius: 16px;
        &:hover {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
      }
      .card-img-overlay {
        position: absolute;
        background: #fff;
        color: #000;
        top: 63%;
        right: 0;
        bottom: 0;
        text-align: start;
        left: 0;
        margin: 20px;
        padding: var(--bs-card-img-overlay-padding);
        border-radius: var(--bs-card-inner-border-radius);
      }
      .bluestyle {
        background-color: #beebf8;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        border-radius: 6px;
        width: max-content;
        padding: 2px 4px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 8px 24px;
        border-radius: 6px;
        // font-size: 14px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white !important; /* Ensure text color remains visible on hover */
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .blogs {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .textstyle {
        font-size: 16px;
      }
      .cardhead {
        font-size: 24px;
        font-weight: 700;
      }
      .cardstyle {
        border-radius: 16px;
        &:hover {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
      }
      .card-img-overlay {
        position: absolute;
        background: #fff;
        color: #000;
        top: 63%;
        right: 0;
        bottom: 0;
        text-align: start;
        left: 0;
        margin: 20px;
        padding: var(--bs-card-img-overlay-padding);
        border-radius: var(--bs-card-inner-border-radius);
      }
      .bluestyle {
        background-color: #beebf8;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        border-radius: 6px;
        width: max-content;
        padding: 2px 4px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 8px 19px;
        border-radius: 6px;
        // font-size: 14px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white !important; /* Ensure text color remains visible on hover */
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1440px) {
    .blogs {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .textstyle {
        font-size: 12px;
      }
      .cardhead {
        font-size: 20px;
        font-weight: 700;
      }
      .cardstyle {
        border-radius: 16px;
        &:hover {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
      }
      .card-img-overlay {
        position: absolute;
        background: #fff;
        color: #000;
        top: 58%;
        right: 0;
        bottom: 0;
        text-align: start;
        left: 0;
        margin: 20px;
        padding: var(--bs-card-img-overlay-padding);
        border-radius: var(--bs-card-inner-border-radius);
      }
      .bluestyle {
        background-color: #beebf8;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        border-radius: 6px;
        width: max-content;
        padding: 2px 4px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 4px 10px;
        border-radius: 6px;
        font-size: 14px;
        // height: 24px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white !important; /* Ensure text color remains visible on hover */
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .blogs {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .textstyle {
        font-size: 12px;
      }
      .cardhead {
        font-size: 18px;
        font-weight: 700;
      }
      .cardstyle {
        border-radius: 16px;
        &:hover {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
      }
      .card-img-overlay {
        position: absolute;
        background: #fff;
        color: #000;
        top: 55%;
        right: 0;
        bottom: 0;
        text-align: start;
        left: 0;
        margin: 20px;
        padding: var(--bs-card-img-overlay-padding);
        border-radius: var(--bs-card-inner-border-radius);
      }
      .bluestyle {
        background-color: #beebf8;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        border-radius: 6px;
        width: max-content;
        padding: 2px 4px;
      }

      .franchisebtn {
        background-color: #ff9133;
        padding: 8px;
        border-radius: 6px;
        font-size: 12px;
        height: 24px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white !important; /* Ensure text color remains visible on hover */
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .blogs {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .textstyle {
        font-size: 12px;
      }
      .cardhead {
        font-size: 14px;
        font-weight: 700;
      }
      .cardstyle {
        border-radius: 16px;
        &:hover {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
      }
      .card-img-overlay {
        position: absolute;
        background: #fff;
        color: #000;
        top: 55%;
        right: 0;
        bottom: 0;
        text-align: start;
        left: 0;
        margin: 20px;
        padding: 7px;
        border-radius: var(--bs-card-inner-border-radius);
      }
      .bluestyle {
        background-color: #beebf8;
        font-size: 12px;
        font-weight: 500;
        color: #000;
        border-radius: 6px;
        width: max-content;
        padding: 2px 4px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 2px 1px;
        border-radius: 6px;
        font-size: 12px;
        height: 24px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white !important; /* Ensure text color remains visible on hover */
      }
    }
  }

  //testimonials
  .testimonials {
    .videostyle {
      height: 255px;
      border-radius: 20px;
      border: 5px solid #2b4bb6;
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .testimonials {
      .videostyle {
        height: 221px;
        border-radius: 20px;
        margin-bottom: 20px;
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .testimonials {
      .videostyle {
        height: 212px;
        border-radius: 20px;
        margin-bottom: 20px;
      }
    }
  }
  @media (min-width: 1401px) and (max-width: 1440px) {
    .testimonials {
      .videostyle {
        height: 187px;
        border-radius: 20px;
        margin-bottom: 20px;
      }
    }
  }
  @media (min-width: 1369px) and (max-width: 1400px) {
    .testimonials {
      .videostyle {
        height: 178px;
        border-radius: 20px;
        margin-bottom: 20px;
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1368px) {
    .testimonials {
      .videostyle {
        height: 173px;
        border-radius: 20px;
        margin-bottom: 20px;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .testimonials {
      .videostyle {
        height: 168px;
        border-radius: 20px;
        margin-bottom: 20px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .testimonials {
      .videostyle {
        height: 132px;
        border-radius: 20px;
        margin-bottom: 20px;
      }
    }
  }

  //clientspeaks
  .cliendspeak {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    background-color: #2b4bb6;
    .head {
      font-size: 36px;
      font-weight: 700;
      text-align: center;
      color: #fff;
    }
    .textstyle {
      font-size: 18px;
      color: #fff;
      font-weight: 200;
    }
    .cardstyle {
      border-radius: 16px;
      background-color: #a1e6fe;
    }
    .testimonialimg {
      border-radius: 16px 0px 0px 16px;
    }
    .cardtxt {
      font-size: 18px;
      font-weight: 300;
      text-align: start;
      color: #000;
    }
    .cardhead {
      font-size: 24px;
      font-weight: 500;
      text-align: start;
      color: #000;
    }
    .badgesince {
      background-color: #beebf8;
      color: #000;
      font-size: 16px;
      font-weight: 500;
      padding: 3px 10px;
      border-radius: 52px;
      height: 28px;
    }
    [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
    [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
      background-color: transparent !important;
      filter: invert(0) grayscale(0) !important;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      display: inline-block;
      width: 2rem;
      height: 2rem;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100% 100%;
      //  background: black;
      padding: 22px;
      border-radius: 50%;
      margin-top: 20px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .carousel-control-prev,
    .carousel-control-next {
      position: relative;
      /* top: 0px; */
      bottom: -50px;
      z-index: 1;
      display: contents;
      align-items: center;
      justify-content: center;
      /* width: 15%; */
      padding: 0;
      color: #fff;
      text-align: center;
      background: none;
      border: 0;
      opacity: 0.5;
      transition: opacity 0.15s ease;
    }
    .carousel-control-next,
    .carousel-control-prev {
      position: relative;
      /* top: 0px; */
      bottom: -50px;
      z-index: 1;
      display: contents;
      align-items: center;
      justify-content: center;
      /* width: 15%; */
      padding: 0;
      color: #fff;
      text-align: center;
      background: none;
      border: 0;
      opacity: 0.5;
      transition: opacity 0.15s ease;
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .cliendspeak {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      background-color: #2b4bb6;
      .head {
        font-size: 36px;
        font-weight: 700;
        text-align: center;
        color: #fff;
      }
      .textstyle {
        font-size: 18px;
        color: #fff;
        font-weight: 200;
      }
      .cardstyle {
        border-radius: 16px;
        background-color: #a1e6fe;
      }
      .testimonialimg {
        border-radius: 16px 0px 0px 16px;
      }
      .cardtxt {
        font-size: 18px;
        font-weight: 300;
        text-align: start;
        color: #000;
      }
      .cardhead {
        font-size: 24px;
        font-weight: 500;
        text-align: start;
        color: #000;
      }
      .badgesince {
        background-color: #beebf8;
        color: #000;
        font-size: 15px;
        font-weight: 500;
        padding: 2px;
        border-radius: 52px;
        height: 28px;
      }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }
   
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .cliendspeak {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      background-color: #2b4bb6;
      .head {
        font-size: 36px;
        font-weight: 700;
        text-align: center;
        color: #fff;
      }
      .textstyle {
        font-size: 16px;
        color: #fff;
        font-weight: 200;
      }
      .cardstyle {
        border-radius: 16px;
        background-color: #a1e6fe;
      }
      .testimonialimg {
        border-radius: 16px 0px 0px 16px;
        height: 290px;
      }
      .cardtxt {
        font-size: 16px;
        font-weight: 300;
        text-align: start;
        color: #000;
      }
      .cardhead {
        font-size: 24px;
        font-weight: 500;
        text-align: start;
        color: #000;
      }
      .badgesince {
        background-color: #beebf8;
        color: #000;
        font-size: 15px;
        font-weight: 500;
        padding: 2px;
        border-radius: 52px;
        height: 28px;
      }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }

      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1440px) {
    .cliendspeak {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      background-color: #2b4bb6;
      .head {
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        color: #fff;
      }
      .textstyle {
        font-size: 14px;
        color: #fff;
        font-weight: 200;
      }
      .cardstyle {
        border-radius: 16px;
        background-color: #a1e6fe;
      }
      .testimonialimg {
        border-radius: 16px 0px 0px 16px;
        height: 230px;
      }
      .cardtxt {
        font-size: 14px;
        font-weight: 300;
        text-align: start;
        color: #000;
      }
      .cardhead {
        font-size: 20px;
        font-weight: 500;
        text-align: start;
        color: #000;
      }
      .badgesince {
        background-color: #beebf8;
        color: #000;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 5px;
        border-radius: 52px;
        height: 23px;
      }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }

      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .cliendspeak {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      background-color: #2b4bb6;
      .head {
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        color: #fff;
      }
      .textstyle {
        font-size: 12px;
        color: #fff;
        font-weight: 200;
      }
      .cardstyle {
        border-radius: 16px;
        background-color: #a1e6fe;
      }
      .testimonialimg {
        border-radius: 16px 0px 0px 16px;
        height: 230px;
      }
      .cardtxt {
        font-size: 12px;
        font-weight: 300;
        text-align: start;
        color: #000;
      }
      .cardhead {
        font-size: 18px;
        font-weight: 500;
        text-align: start;
        color: #000;
      }
      .badgesince {
        background-color: #beebf8;
        color: #000;
        font-size: 11px;
        font-weight: 500;
        padding: 2px 3px;
        border-radius: 52px;
        height: 23px;
      }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }

      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .cliendspeak {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      background-color: #2b4bb6;
      .head {
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        color: #fff;
      }
      .textstyle {
        font-size: 12px;
        color: #fff;
        font-weight: 200;
      }
      .cardstyle {
        border-radius: 16px;
        background-color: #a1e6fe;
      }
      .testimonialimg {
        border-radius: 16px 0px 0px 16px;
        height: 199px;
      }
      .cardtxt {
        font-size: 12px;
        font-weight: 300;
        text-align: start;
        color: #000;
      }
      .cardhead {
        font-size: 18px;
        font-weight: 500;
        text-align: start;
        color: #000;
      }
      .badgesince {
        background-color: #beebf8;
        color: #000;
        font-size: 11px;
        font-weight: 500;
        padding: 2px 2px;
        border-radius: 52px;
        height: 38px;
      }
      [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
      [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
        background-color: transparent !important;
        filter: invert(0) grayscale(0) !important;
      }

      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        //  background: black;
        padding: 22px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .carousel-control-prev,
      .carousel-control-next {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
      .carousel-control-next,
      .carousel-control-prev {
        position: relative;
        /* top: 0px; */
        bottom: -50px;
        z-index: 1;
        display: contents;
        align-items: center;
        justify-content: center;
        /* width: 15%; */
        padding: 0;
        color: #fff;
        text-align: center;
        background: none;
        border: 0;
        opacity: 0.5;
        transition: opacity 0.15s ease;
      }
    }
  }
  //Partner
  .partner {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    background-color: #f2f8fa;
    .partnerimg {
      height: 160px;
      width: 100%;
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .partner {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .partnerimg {
        height: 140px;
        width: 100%;
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .partner {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .partnerimg {
        height: 110px;
        width: 100%;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1440px) {
    .partner {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .partnerimg {
        height: 100px;
        width: 100%;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .partner {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .partnerimg {
        height: 80px;
        width: 100%;
      }
    }
  }
}

//about mob/tab
@media (max-width: 389px) {
  .aboutus {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .bgcolor {
      background-color: #2b4bb6;
      margin-top: -60px;
      padding: 0px 15px;
    }
    .aboutmg {
      margin-top: 30px !important;
    }
    .textstyle {
      font-size: 14px;
      color: white;
      font-weight: 200;
    }
    .franchisebtn {
      background-color: #ff9133;
      padding: 4px 24px;
      border-radius: 6px;
      height: 30px;
      font-size: 12px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }

    .box {
      border-radius: 24px 24px 0px 0px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.06),
        hwb(0deg 60% 40% / 0)
      );
      padding: 10px 10px;
      border-radius: 24px 24px 0px 0px;
      margin-top: 7px;
    }

    .boxtext {
      color: rgba(255, 255, 255, 1); /* Full opacity */
      z-index: 1;
      font-size: 14px;
    }

    /* Optional: Ensure the heading has full opacity and visibility as well */
    .box h1 {
      color: rgba(255, 255, 255, 1); /* Full opacity */
      z-index: 1;
    }
  }
}
@media (min-width: 390px) and (max-width: 411px) {
  .aboutus {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .bgcolor {
      background-color: #2b4bb6;
      margin-top: -78px;
      padding: 0px 15px;
    }
    .aboutmg {
      margin-top: 80px !important;
    }
    .textstyle {
      font-size: 14px;
      color: white;
      font-weight: 200;
    }
    .franchisebtn {
      background-color: #ff9133;
      padding: 4px 24px;
      border-radius: 6px;
      height: 30px;
      font-size: 12px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }

    .box {
      border-radius: 24px 24px 0px 0px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.06),
        hwb(0deg 60% 40% / 0)
      );
      padding: 10px 10px;
      border-radius: 24px 24px 0px 0px;
      margin-top: 7px;
    }

    .boxtext {
      color: rgba(255, 255, 255, 1); /* Full opacity */
      z-index: 1;
      font-size: 14px;
    }

    /* Optional: Ensure the heading has full opacity and visibility as well */
    .box h1 {
      color: rgba(255, 255, 255, 1); /* Full opacity */
      z-index: 1;
    }
  }
}
@media (min-width: 412px) and (max-width: 415px) {
  .aboutus {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .bgcolor {
      background-color: #2b4bb6;
      margin-top: -138px;
      padding: 0px 15px;
    }
    .aboutmg {
      margin-top: 115px !important;
    }
    .textstyle {
      font-size: 14px;
      color: white;
      font-weight: 200;
    }
    .franchisebtn {
      background-color: #ff9133;
      padding: 4px 24px;
      border-radius: 6px;
      height: 30px;
      font-size: 12px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }

    .box {
      border-radius: 24px 24px 0px 0px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.06),
        hwb(0deg 60% 40% / 0)
      );
      padding: 10px 10px;
      border-radius: 24px 24px 0px 0px;
      margin-top: 7px;
    }

    .boxtext {
      color: rgba(255, 255, 255, 1); /* Full opacity */
      z-index: 1;
      font-size: 14px;
    }

    /* Optional: Ensure the heading has full opacity and visibility as well */
    .box h1 {
      color: rgba(255, 255, 255, 1); /* Full opacity */
      z-index: 1;
    }
  }
}
@media (min-width: 416px) and (max-width: 430px) {
  .aboutus {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .bgcolor {
      background-color: #2b4bb6;
      margin-top: -76px;
      padding: 0px 15px;
    }
    .aboutmg {
      margin-top: 20px !important;
    }
    .textstyle {
      font-size: 16px;
      color: white;
      font-weight: 200;
    }

    .franchisebtn {
      background-color: #ff9133;
      padding: 4px 24px;
      border-radius: 6px;
      height: 36px;
      font-size: 15px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }

    .box {
      border-radius: 24px 24px 0px 0px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.06),
        hwb(0deg 60% 40% / 0)
      );
      padding: 10px 10px;
      border-radius: 24px 24px 0px 0px;
      margin-top: 7px;
    }

    .boxtext {
      color: rgba(255, 255, 255, 1); /* Full opacity */
      z-index: 1;
      font-size: 14px;
    }

    /* Optional: Ensure the heading has full opacity and visibility as well */
    .box h1 {
      color: rgba(255, 255, 255, 1); /* Full opacity */
      z-index: 1;
    }
  }
}
@media (min-width: 431px) and (max-width: 767px) {
  .aboutus {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .bgcolor {
      background-color: #2b4bb6;
      margin-top: -25px;
      padding: 0px 15px;
    }
    .aboutmg {
      margin-top: 60px !important;
    }
    .textstyle {
      font-size: 16px;
      color: white;
      font-weight: 200;
    }

    .franchisebtn {
      background-color: #ff9133;
      padding: 4px 24px;
      border-radius: 6px;
      height: 36px;
      font-size: 15px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }

    .box {
      border-radius: 24px 24px 0px 0px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.06),
        hwb(0deg 60% 40% / 0)
      );
      padding: 10px 10px;
      border-radius: 24px 24px 0px 0px;
      margin-top: 7px;
    }

    .boxtext {
      color: rgba(255, 255, 255, 1); /* Full opacity */
      z-index: 1;
      font-size: 14px;
    }

    /* Optional: Ensure the heading has full opacity and visibility as well */
    .box h1 {
      color: rgba(255, 255, 255, 1); /* Full opacity */
      z-index: 1;
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .aboutus {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .bgcolor {
      background-color: #2b4bb6;
      // margin-top: -190px;
      padding: 0px 15px;
    }
    .textstyle {
      font-size: 14px;
      color: white;
      font-weight: 200;
    }

    .franchisebtn {
      background-color: #ff9133;
      padding: 4px 24px;
      border-radius: 6px;
      height: 36px;
      font-size: 14px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }

    .box {
      border-radius: 24px 24px 0px 0px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.06),
        hwb(0deg 60% 40% / 0)
      );
      padding: 10px 10px;
      border-radius: 24px 24px 0px 0px;
      margin-top: 7px;
    }

    .boxtext {
      color: rgba(255, 255, 255, 1); /* Full opacity */
      z-index: 1;
      font-size: 11px;
    }

    /* Optional: Ensure the heading has full opacity and visibility as well */
    .box h1 {
      color: rgba(255, 255, 255, 1); /* Full opacity */
      z-index: 1;
    }
  }
}
//our milestonemob/tab

@media (max-width: 430px) {
  .ourmilestone {
    font-style: normal;
    font-family: "Be Vietnam Pro", sans-serif;
    // .carousel-indicators{
    //   position: relative;
    //   justify-content: end;
    // }
    [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
    [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
      background-color: transparent !important;
      filter: invert(0) grayscale(0) !important;
    }
    .carousel-control-next-icon {
      background-image: url("../images/rightarrow.png");
      filter: invert(0) grayscale(0) !important;
      background-color: #00b8ff;
      background-repeat: no-repeat !important;
      background-size: 30px !important;
    }
    .carousel-control-prev-icon {
      background-image: url("../images/leftarrow.png");
      background-color: #00b8ff;
      filter: invert(0) grayscale(0) !important;
      background-repeat: no-repeat !important;
      background-size: 30px !important;
    }
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      display: inline-block;
      width: 2rem;
      height: 2rem;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100% 100%;
      //  background: black;
      padding: 22px;
      border-radius: 50%;
      margin-top: 20px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .carousel-control-prev,
    .carousel-control-next {
      position: relative;
      /* top: 0px; */
      bottom: -50px;
      z-index: 1;
      display: contents;
      align-items: center;
      justify-content: center;
      /* width: 15%; */
      padding: 0;
      color: #fff;
      text-align: center;
      background: none;
      border: 0;
      opacity: 0.5;
      transition: opacity 0.15s ease;
    }
    .carousel-control-next,
    .carousel-control-prev {
      position: relative;
      /* top: 0px; */
      bottom: -50px;
      z-index: 1;
      display: contents;
      align-items: center;
      justify-content: center;
      /* width: 15%; */
      padding: 0;
      color: #fff;
      text-align: center;
      background: none;
      border: 0;
      opacity: 0.5;
      transition: opacity 0.15s ease;
    }
    .carouselimg {
      height: 140px;
    }
    .ffamily {
      font-family: "Indie Flower", cursive;
      color: #2b4bb6;
    }
    .flag {
      height: 30px;
    }
    .cardstyle {
      height: 375px;
      margin-right: 0px;
    }
    .cardstyle1 {
      height: auto;
      box-shadow: 0 0 10px #ccc;
      margin-bottom: 20px;
      .badgesince {
        background-color: #beebf8;
        color: #000;
        font-size: 14px;
        font-weight: 500;
        padding: 3px 10px;
        border-radius: 52px;
        width: fit-content;
      }
    }
    .cardhead {
      font-size: 20px;
      font-weight: 700;
    }
    .cardtext {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      font-size: 14px;
      font-weight: 300;
    }
  }
}
@media (min-width: 431px) and (max-width: 767px) {
  .ourmilestone {
    font-style: normal;
    font-family: "Be Vietnam Pro", sans-serif;
    // .carousel-indicators{
    //   position: relative;
    //   justify-content: end;
    // }
    [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
    [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
      background-color: transparent !important;
      filter: invert(0) grayscale(0) !important;
    }
    .carousel-control-next-icon {
      background-image: url("../images/rightarrow.png");
      filter: invert(0) grayscale(0) !important;
      background-color: #00b8ff;
      background-repeat: no-repeat !important;
      background-size: 30px !important;
    }
    .carousel-control-prev-icon {
      background-image: url("../images/leftarrow.png");
      background-color: #00b8ff;
      filter: invert(0) grayscale(0) !important;
      background-repeat: no-repeat !important;
      background-size: 30px !important;
    }
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      display: inline-block;
      width: 2rem;
      height: 2rem;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100% 100%;
      //  background: black;
      padding: 22px;
      border-radius: 50%;
      margin-top: 20px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .carousel-control-prev,
    .carousel-control-next {
      position: relative;
      /* top: 0px; */
      bottom: -50px;
      z-index: 1;
      display: contents;
      align-items: center;
      justify-content: center;
      /* width: 15%; */
      padding: 0;
      color: #fff;
      text-align: center;
      background: none;
      border: 0;
      opacity: 0.5;
      transition: opacity 0.15s ease;
    }
    .carousel-control-next,
    .carousel-control-prev {
      position: relative;
      /* top: 0px; */
      bottom: -50px;
      z-index: 1;
      display: contents;
      align-items: center;
      justify-content: center;
      /* width: 15%; */
      padding: 0;
      color: #fff;
      text-align: center;
      background: none;
      border: 0;
      opacity: 0.5;
      transition: opacity 0.15s ease;
    }
    //   .carousel-indicators [data-bs-target] {
    //     // box-sizing: content-box;
    //     flex: 0 1 auto;
    //     width: 46px;
    //     height: 46px;
    //     padding: 0;
    //     margin-right: 3px;
    //     margin-left: 3px;
    //     text-indent: -999px;
    //     cursor: pointer;
    //     border-radius: 50% !important;
    //     background-color: #c9ebf9 !important;
    //     // background-clip: padding-box;
    //     border: 0;
    //     border-top: 10px solid transparent;
    //     border-bottom: 10px solid transparent;
    //     opacity: 0.5;
    //     transition: opacity 0.6s ease;
    //     // background-image: url("../images/leftarrow.png");
    //     background-repeat: no-repeat;
    //     background-size: 30px;
    //     background-position-y: center;
    //     transform: rotate(180deg)
    // }
    //   .carousel-indicators .active {
    //     opacity: 1;
    //     flex: 0 1 auto;
    //     background-color: #00B8FF !important;
    //     border-radius: 50%;
    //     height: 46px;
    //     border: 0;
    //     border-top: 10px solid transparent;
    //     border-bottom: 10px solid transparent;
    //     padding: 0;
    //     margin-right: 3px;
    //     margin-left: 3px;
    //     text-indent: -999px;
    //     cursor: pointer;
    //     width: 46px;
    //     // background-image: url("../images/rightarrow.png");
    //     background-repeat: no-repeat;
    //     background-size: 30px;
    //     background-position-y: center;
    //     transform: rotate(180deg)
    // }
    .carouselimg {
      height: 105px;
    }
    .ffamily {
      font-family: "Indie Flower", cursive;
      color: #2b4bb6;
    }
    .flag {
      height: 16px;
    }
    .cardstyle {
      height: 370px;
      margin-right: 10px;
    }
    .cardstyle1 {
      height: auto;
      box-shadow: 0 0 10px #ccc;
      margin-bottom: 20px;
      .badgesince {
        background-color: #beebf8;
        color: #000;
        font-size: 14px;
        font-weight: 500;
        padding: 3px 10px;
        border-radius: 52px;
        width: fit-content;
      }
    }
    .cardhead {
      font-size: 16px;
      font-weight: 700;
    }
    .cardtext {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      font-size: 14px;
      font-weight: 300;
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .ourmilestone {
    font-style: normal;
    font-family: "Be Vietnam Pro", sans-serif;
    // .carousel-indicators{
    //   position: relative;
    //   justify-content: end;
    // }
    [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
    [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
      background-color: transparent !important;
      filter: invert(0) grayscale(0) !important;
    }
    .carousel-control-next-icon {
      background-image: url("../images/rightarrow.png");
      filter: invert(0) grayscale(0) !important;
      background-color: #00b8ff;
      background-repeat: no-repeat !important;
      background-size: 30px !important;
    }
    .carousel-control-prev-icon {
      background-image: url("../images/leftarrow.png");
      background-color: #00b8ff;
      filter: invert(0) grayscale(0) !important;
      background-repeat: no-repeat !important;
      background-size: 30px !important;
    }
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      display: inline-block;
      width: 2rem;
      height: 2rem;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100% 100%;
      //  background: black;
      padding: 22px;
      border-radius: 50%;
      margin-top: 20px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .carousel-control-prev,
    .carousel-control-next {
      position: relative;
      /* top: 0px; */
      bottom: -50px;
      z-index: 1;
      display: contents;
      align-items: center;
      justify-content: center;
      /* width: 15%; */
      padding: 0;
      color: #fff;
      text-align: center;
      background: none;
      border: 0;
      opacity: 0.5;
      transition: opacity 0.15s ease;
    }
    .carousel-control-next,
    .carousel-control-prev {
      position: relative;
      /* top: 0px; */
      bottom: -50px;
      z-index: 1;
      display: contents;
      align-items: center;
      justify-content: center;
      /* width: 15%; */
      padding: 0;
      color: #fff;
      text-align: center;
      background: none;
      border: 0;
      opacity: 0.5;
      transition: opacity 0.15s ease;
    }
    //   .carousel-indicators [data-bs-target] {
    //     // box-sizing: content-box;
    //     flex: 0 1 auto;
    //     width: 46px;
    //     height: 46px;
    //     padding: 0;
    //     margin-right: 3px;
    //     margin-left: 3px;
    //     text-indent: -999px;
    //     cursor: pointer;
    //     border-radius: 50% !important;
    //     background-color: #c9ebf9 !important;
    //     // background-clip: padding-box;
    //     border: 0;
    //     border-top: 10px solid transparent;
    //     border-bottom: 10px solid transparent;
    //     opacity: 0.5;
    //     transition: opacity 0.6s ease;
    //     // background-image: url("../images/leftarrow.png");
    //     background-repeat: no-repeat;
    //     background-size: 30px;
    //     background-position-y: center;
    //     transform: rotate(180deg)
    // }
    //   .carousel-indicators .active {
    //     opacity: 1;
    //     flex: 0 1 auto;
    //     background-color: #00B8FF !important;
    //     border-radius: 50%;
    //     height: 46px;
    //     border: 0;
    //     border-top: 10px solid transparent;
    //     border-bottom: 10px solid transparent;
    //     padding: 0;
    //     margin-right: 3px;
    //     margin-left: 3px;
    //     text-indent: -999px;
    //     cursor: pointer;
    //     width: 46px;
    //     // background-image: url("../images/rightarrow.png");
    //     background-repeat: no-repeat;
    //     background-size: 30px;
    //     background-position-y: center;
    //     transform: rotate(180deg)
    // }
    .carouselimg {
      height: 90px;
    }
    .ffamily {
      font-family: "Indie Flower", cursive;
      color: #2b4bb6;
    }
    .flag {
      height: 19px;
    }
    .cardstyle {
      height: 370px;
      margin-right: 10px;
    }
    .cardstyle1 {
      height: 330px;
      box-shadow: 0 0 10px #ccc;
      margin-bottom: 20px;
      .badgesince {
        background-color: #beebf8;
        color: #000;
        font-size: 14px;
        font-weight: 500;
        padding: 3px 10px;
        border-radius: 52px;
        width: fit-content;
      }
    }
    .cardhead {
      font-size: 15px;
      font-weight: 700;
    }
    .cardtext {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      font-size: 14px;
      font-weight: 300;
    }
  }
}
.benifits {
  font-family: "Be Vietnam Pro", sans-serif;
  font-style: normal;
  margin-top: 70px;

  .franchisesection {
    height: 600px;
    background-color: #ff9133;
    border-radius: 0px 32px 32px 0px;
    padding-top: 175px;
    transition: transform 1s ease-out, opacity 1s ease-out;
    transform: translateX(-100%);
  }

  .partnersection {
    height: 430px;
    background-color: #2b4bb6;
    border-radius: 32px;
    padding: 50px 100px 0px 100px;
    background-size: cover;
    // transition: transform 1s ease-out, opacity 1s ease-out;
    // transform: translateX(100%);
  }

  .support {
    height: 430px;
    background-color: #2b4bb6;
    border-radius: 32px;
    padding: 0px 100px 0px 100px;
    background-size: cover;
  }
  .businessimg {
    height: 330px;
    // position: absolute;
    // margin-top: -136px;
    // transition: transform 1s ease-out, opacity 1s ease-out;
  }
  .franchisesection.animate-left,
  .partnersection.animate-right {
    opacity: 1;
    transform: translateX(0);
  }
  /* Animation when scrolled into view */
  .franchisesection.animate-left {
    transform: translateX(0);
    opacity: 1;
  }
  // .partnersection.animate-right {
  //   transform: translateX(0);
  //   opacity: 1;
  // }
  .partnerimg {
    height: 380px;
    // position: absolute;
    // margin-top: -136px;
    // transition: transform 1s ease-out, opacity 1s ease-out;
  }
  .imgindent {
    text-align: end;
  }
  .img1 {
    position: absolute;
    z-index: 1;
  }
  .img2 {
    // position: absolute;
    z-index: 1;
  }
  .img1.animate-left,
  .img2.animate-right {
    opacity: 1;
    transform: translateX(0);
    z-index: 9;
  }
  .img1 {
    transform: translateX(-100%);
  }
  .img2 {
    // transform: translateX(100%);
  }
  /* Animation when scrolled into view */
  .img1.animate-left {
    transform: translateX(0);
    opacity: 1;
  }

  .img2.animate-right {
    transform: translateX(0);
    opacity: 1;
  }

  .subhead {
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
  }
  .tick {
    height: 24px;
  }
  .liststyle {
    font-size: 18px;
    color: #ffffff;
    font-weight: 400;
  }
  .benifitsbtn {
    background-color: #ffffff;
    font-size: 17px;
    font-weight: 500;
    color: #00599d;
    border-radius: 6px;
    height: 50px;
    border: none;
    padding: 2px 30px;
    &:hover,
    &:active,
    &:focus {
      background-color: #ffffff !important;
      font-size: 17px;
      font-weight: 500;
      color: #00599d !important;
      border-radius: 6px;
      height: 50px;
      border: none;
      padding: 2px 30px;
    }
  }
}
@media (min-width: 1601px) and (max-width: 1680px) {
  .benifits {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 20px;
    .franchisesection {
      height: 600px;
      background-color: #ff9133;
      border-radius: 0px 32px 32px 0px;
      padding-top: 175px;
    }
    .partnersection {
      height: 365px;
      background-color: #2b4bb6;
      border-radius: 32px;
      padding: 30px 40px;
    }

    .support {
      height: 380px;
      background-color: #2b4bb6;
      border-radius: 32px;
      padding: 0px 100px 0px 100px;
      background-size: cover;
    }
    .partnerimg {
      height: 299px;
      // position: absolute;
      margin-top: 36px;
    }
    .businessimg {
      height: 299px;
    }
    .subhead {
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
    }
    .tick {
      height: 24px;
    }
    .liststyle {
      font-size: 18px;
      color: #ffffff;
      font-weight: 400;
    }
    .benifitsbtn {
      background-color: #ffffff;
      font-size: 17px;
      font-weight: 500;
      color: #00599d;
      border-radius: 6px;
      height: 50px;
      border: none;
      padding: 2px 30px;
      &:hover,
      &:active,
      &:focus {
        background-color: #ffffff !important;
        font-size: 17px;
        font-weight: 500;
        color: #00599d !important;
        border-radius: 6px;
        height: 50px;
        border: none;
        padding: 2px 30px;
      }
    }
  }
}
@media (min-width: 1441px) and (max-width: 1600px) {
  .benifits {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 50px;
    .benifithead {
      font-size: 34px;
    }
    .franchisesection {
      height: 580px;
      background-color: #ff9133;
      border-radius: 0px 32px 32px 0px;
      padding-top: 175px;
    }
    .partnersection {
      height: 355px;
      background-color: #2b4bb6;
      border-radius: 32px;
      padding: 30px 40px;
    }
    .support {
      height: 380px;
      background-color: #2b4bb6;
      border-radius: 32px;
      padding: 0px 100px 0px 100px;
      background-size: cover;
    }
    .partnerimg {
      height: 300px;
      // position: absolute;
      margin-top: 25px;
    }
    .businessimg {
      height: 299px;
    }
    .subhead {
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
    }
    .tick {
      height: 20px;
    }
    .liststyle {
      font-size: 16px;
      color: #ffffff;
      font-weight: 400;
    }
    .benifitsbtn {
      background-color: #ffffff;
      font-size: 17px;
      font-weight: 500;
      color: #00599d;
      border-radius: 6px;
      height: 50px;
      border: none;
      padding: 2px 30px;
      &:hover,
      &:active,
      &:focus {
        background-color: #ffffff !important;
        font-size: 17px;
        font-weight: 500;
        color: #00599d !important;
        border-radius: 6px;
        height: 50px;
        border: none;
        padding: 2px 30px;
      }
    }
  }
}
@media (min-width: 1281px) and (max-width: 1440px) {
  .benifits {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 70px;
    .franchisesection {
      height: 470px;
      background-color: #ff9133;
      border-radius: 0px 32px 32px 0px;
      padding-top: 120px;
    }
    .benifithead {
      font-size: 30px;
    }
    .partnersection {
      height: 300px;
      background-color: #2b4bb6;
      border-radius: 32px;
      padding: 20px 40px;
    }
    .support {
      height: 326px;
      background-color: #2b4bb6;
      border-radius: 32px;
      padding: 0px 100px 0px 100px;
      background-size: cover;
    }
    .businessimg {
      height: 245px;
    }
    .partnerimg {
      height: 245px;
      // position: absolute;
      margin-top: 35px;
    }

    .subhead {
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
    }
    .tick {
      height: 24px;
    }
    .liststyle {
      font-size: 14px;
      color: #ffffff;
      font-weight: 400;
    }
    .benifitsbtn {
      background-color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      color: #00599d;
      border-radius: 6px;
      height: 32px;
      border: none;
      padding: 2px 30px;
      &:hover,
      &:active,
      &:focus {
        background-color: #ffffff !important;
        font-size: 14px;
        font-weight: 500;
        color: #00599d !important;
        border-radius: 6px;
        height: 32px;
        border: none;
        padding: 2px 30px;
      }
    }
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .benifits {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 55px;
    .franchisesection {
      height: 470px;
      background-color: #ff9133;
      border-radius: 0px 32px 32px 0px;
      padding-top: 120px;
    }
    .benifithead {
      font-size: 28px;
    }
    .partnersection {
      height: 284px;
      background-color: #2b4bb6;
      border-radius: 32px;
      padding: 20px 40px;
    }
    .support {
      height: 285px;
      background-color: #2b4bb6;
      border-radius: 32px;
      padding: 0px 100px 0px 100px;
      background-size: cover;
    }
    .businessimg {
      height: 200px;
    }
    .partnerimg {
      height: 250px;
      // position: absolute;
      margin-top: 14px;
    }

    .subhead {
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
    }
    .tick {
      height: 16px;
    }
    .liststyle {
      font-size: 14px;
      color: #ffffff;
      font-weight: 400;
    }
    .benifitsbtn {
      background-color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      color: #00599d;
      border-radius: 6px;
      height: 32px;
      border: none;
      padding: 2px 30px;
      &:hover,
      &:active,
      &:focus {
        background-color: #ffffff !important;
        font-size: 14px;
        font-weight: 500;
        color: #00599d !important;
        border-radius: 6px;
        height: 32px;
        border: none;
        padding: 2px 30px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
  .benifits {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 70px;
    .franchisesection {
      height: 430px;
      background-color: #ff9133;
      border-radius: 0px 32px 32px 0px;
      padding-top: 120px;
    }
    .benifithead {
      font-size: 22px;
    }
    .partnersection {
      height: 225px;
      background-color: #2b4bb6;
      border-radius: 32px;
      padding: 5px 20px 0px 20px !important;
    }
    .support {
      height: 240px;
      background-color: #2b4bb6;
      border-radius: 32px;
      padding: 15px 30px;
      background-size: cover;
    }
    .businessimg {
      height: 175px;
    }
    .partnerimg {
      height: 186px;
      // position: absolute;
      margin-top: 34px;
    }

    .subhead {
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
    }
    .tick {
      height: 16px;
    }
    .liststyle {
      font-size: 12px;
      color: #ffffff;
      font-weight: 400;
    }
    .benifitsbtn {
      background-color: #ffffff;
      font-size: 12px;
      font-weight: 500;
      color: #00599d;
      border-radius: 6px;
      height: 30px;
      border: none;
      padding: 2px 30px;
      &:hover,
      &:active,
      &:focus {
        background-color: #ffffff !important;
        font-size: 12px;
        font-weight: 500;
        color: #00599d !important;
        border-radius: 6px;
        height: 30px;
        border: none;
        padding: 2px 30px;
      }
    }
  }
}
@media (max-width: 389px) {
  .benifits {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 70px;
    .franchisesection {
      height: 430px;
      background-color: #ff9133;
      border-radius: 0px 32px 32px 0px;
      padding-top: 120px;
      padding-left: 20px;
      margin-bottom: 95px;
    }
    .benifithead {
      font-size: 24px;
    }
    .partnersection {
      height: 480px;
      background-color: #2b4bb6;
      border-radius: 32px;
      // padding-top: 120px;
      padding: 20px 20px;
    }
    .support {
      height: 480px;
      background-color: #2b4bb6;
      border-radius: 32px;
      padding: 20px 20px;
      background-size: cover;
      margin: 0px 10px;
    }
    .partnerimg {
      height: 180px;
      // position: absolute;
      margin-top: 20px;
      // padding-left: 50px;
      text-align: center !important;
    }
    .businessimg {
      height: 180px;
    }
    .imgindent {
      text-align: center;
    }
    .subhead {
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
    }
    .tick {
      height: 18px;
    }
    .liststyle {
      font-size: 14px;
      color: #ffffff;
      font-weight: 400;
    }
    .benifitsbtn {
      background-color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      color: #00599d;
      border-radius: 6px;
      height: 30px;
      border: none;
      padding: 2px 30px;
      &:hover,
      &:active,
      &:focus {
        background-color: #ffffff !important;
        font-size: 14px;
        font-weight: 500;
        color: #00599d !important;
        border-radius: 6px;
        height: 30px;
        border: none;
        padding: 2px 30px;
      }
    }
  }
}
@media (min-width: 390px) and (max-width: 430px) {
  .benifits {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 70px;
    .franchisesection {
      height: 430px;
      background-color: #ff9133;
      border-radius: 0px 32px 32px 0px;
      padding-top: 120px;
      padding-left: 20px;
      margin-bottom: 95px;
    }
    .benifithead {
      font-size: 24px;
    }
    .support {
      height: 480px;
      background-color: #2b4bb6;
      border-radius: 32px;
      padding: 20px 20px;
      background-size: cover;
      margin: 0px 10px;
    }
    .partnersection {
      height: 460px;
      background-color: #2b4bb6;
      border-radius: 32px;
      // padding-top: 120px;
      padding: 20px 20px;
    }
    .partnerimg {
      height: 180px;
      // position: absolute;
      margin-top: 30px;
      // padding-left: 50px;
      text-align: center !important;
    }
    .businessimg {
      height: 180px;
    }
    .imgindent {
      text-align: center;
    }
    .subhead {
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
    }
    .tick {
      height: 20px;
    }
    .liststyle {
      font-size: 14px;
      color: #ffffff;
      font-weight: 400;
    }
    .benifitsbtn {
      background-color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      color: #00599d;
      border-radius: 6px;
      height: 30px;
      border: none;
      padding: 2px 30px;
      &:hover,
      &:active,
      &:focus {
        background-color: #ffffff !important;
        font-size: 14px;
        font-weight: 500;
        color: #00599d !important;
        border-radius: 6px;
        height: 30px;
        border: none;
        padding: 2px 30px;
      }
    }
  }
}
@media (min-width: 431px) and (max-width: 767px) {
  .benifits {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 70px;
    .franchisesection {
      height: 430px;
      background-color: #ff9133;
      border-radius: 0px 32px 32px 0px;
      padding-top: 120px;
      padding-left: 20px;
      margin-bottom: 95px;
    }
    .benifithead {
      font-size: 36px;
    }
    .support {
      height: 480px;
      background-color: #2b4bb6;
      border-radius: 32px;
      padding: 30px 20px;
      background-size: cover;
      margin: 0px 10px;
    }
    .partnersection {
      height: 518px;
      background-color: #2b4bb6;
      border-radius: 32px;
      // padding-top: 120px;
      padding: 20px 20px 0px 20px;
    }
    .partnerimg {
      height: 200px;
      // position: absolute;
      // margin-top: -91px;
      // padding-left: 50px;
    }
    .businessimg {
      height: 200px;
    }
    .imgindent {
      text-align: center;
      margin-top: 10px;
    }
    .subhead {
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
    }
    .tick {
      height: 20px;
    }
    .liststyle {
      font-size: 14px;
      color: #ffffff;
      font-weight: 400;
    }
    .benifitsbtn {
      background-color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      color: #00599d;
      border-radius: 6px;
      height: 30px;
      border: none;
      padding: 2px 30px;
      &:hover,
      &:active,
      &:focus {
        background-color: #ffffff !important;
        font-size: 14px;
        font-weight: 500;
        color: #00599d !important;
        border-radius: 6px;
        height: 30px;
        border: none;
        padding: 2px 30px;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .benifits {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 70px;
    .franchisesection {
      height: 430px;
      background-color: #ff9133;
      border-radius: 0px 32px 32px 0px;
      padding-top: 120px;
      padding-left: 20px;
      margin-bottom: 95px;
    }
    .benifithead {
      font-size: 34px;
    }
    .support {
      height: 300px;
      background-color: #2b4bb6;
      border-radius: 32px;
      padding: 30px 30px;
      background-size: cover;
    }

    .partnersection {
      height: 330px;
      background-color: #2b4bb6;
      border-radius: 32px;
      // padding-top: 120px;
      padding: 20px;
    }
    .partnerimg {
      height: 300px;
      // position: absolute;
      margin-top: 10px;
      // padding-left: 50px;
    }
    .businessimg {
      height: 180px;
    }
    .subhead {
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
    }
    .tick {
      height: 20px;
    }
    .liststyle {
      font-size: 14px;
      color: #ffffff;
      font-weight: 400;
    }
    .benifitsbtn {
      background-color: #ffffff !important;
      font-size: 14px;
      font-weight: 500;
      color: #00599d;
      border-radius: 6px;
      height: 30px;
      border: none;
      padding: 2px 30px;
      &:hover,
      &:active,
      &:focus {
        background-color: #ffffff !important;
        font-size: 14px;
        font-weight: 500;
        color: #00599d !important;
        border-radius: 6px;
        height: 30px;
        border: none;
        padding: 2px 30px;
      }
    }
  }
}

//blogs
@media (max-width: 430px) {
  .blogs {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .textstyle {
      font-size: 14px;
    }
    .cardhead {
      font-size: 13px !important;
      font-weight: 700;
      margin-bottom: 4px;
    }
    .cardstyle {
      border-radius: 16px;

      &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
    }
    .cardimg {
      height: 190px;
    }
    .card-img-overlay {
      position: absolute;
      background: #fff;
      color: #000;
      top: 58%;
      right: 0;
      bottom: 0;
      text-align: start;
      left: 0;
      margin: 5px;
      padding: 1px 3px;
      border-radius: var(--bs-card-inner-border-radius);
    }
    .bluestyle {
      background-color: #beebf8;
      font-size: 10px;
      font-weight: 500;
      color: #000;
      border-radius: 6px;
      width: max-content;
      padding: 2px;
    }
    .franchisebtn {
      background-color: #ff9133;
      padding: 5px;
      border-radius: 6px;
      font-size: 14px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white !important; /* Ensure text color remains visible on hover */
    }
  }
}
@media (min-width: 431px) and (max-width: 767px) {
  .blogs {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .textstyle {
      font-size: 14px;
    }
    .cardhead {
      font-size: 14px;
      font-weight: 700;
    }
    .cardstyle {
      border-radius: 16px;

      &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
    }
    .cardimg {
      height: 190px;
    }
    .card-img-overlay {
      position: absolute;
      background: #fff;
      color: #000;
      top: 58%;
      right: 0;
      bottom: 0;
      text-align: start;
      left: 0;
      margin: 5px;
      padding: 1px 3px;
      border-radius: var(--bs-card-inner-border-radius);
    }
    .bluestyle {
      background-color: #beebf8;
      font-size: 12px;
      font-weight: 500;
      color: #000;
      border-radius: 6px;
      width: max-content;
      padding: 2px 4px;
    }
    .franchisebtn {
      background-color: #ff9133;
      padding: 5px 20px;
      border-radius: 6px;
      font-size: 14px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white !important; /* Ensure text color remains visible on hover */
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .blogs {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .textstyle {
      font-size: 14px;
    }
    .cardhead {
      font-size: 22px;
      font-weight: 700;
    }
    .cardstyle {
      border-radius: 16px;

      &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
    }
    .cardimg {
      height: 350px;
    }
    .card-img-overlay {
      position: absolute;
      background: #fff;
      color: #000;
      top: 70%;
      right: 0;
      bottom: 0;
      text-align: start;
      left: 0;
      margin: 5px;
      padding: 1px 3px;
      border-radius: var(--bs-card-inner-border-radius);
    }
    .bluestyle {
      background-color: #beebf8;
      font-size: 14px;
      font-weight: 500;
      color: #000;
      border-radius: 6px;
      width: max-content;
      padding: 2px 4px;
    }
    .franchisebtn {
      background-color: #ff9133;
      padding: 5px 20px;
      border-radius: 6px;
      //  height: 24px;
      font-size: 14px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
  }
}

//testimonials
@media (min-width: 768px) and (max-width: 991px) {
  .testimonials {
    .videostyle {
      height: 203px;
      border-radius: 20px;
      margin-bottom: 20px;
    }
  }
}
@media (min-width: 540px) and (max-width: 767px) {
  .testimonials {
    .videostyle {
      height: 137px;
      border-radius: 20px;
      margin-bottom: 20px;
    }
  }
}
@media (min-width: 416px) and (max-width: 430px) {
  .testimonials {
    .videostyle {
      height: 106px;
      border-radius: 20px;
      margin-bottom: 20px;
    }
  }
}
@media (min-width: 376px) and (max-width: 415px) {
  .testimonials {
    .videostyle {
      height: 103px;
      border-radius: 20px;
      margin-bottom: 20px;
    }
  }
}
@media (max-width: 375px) {
  .testimonials {
    .videostyle {
      height: 93px;
      border-radius: 20px;
      margin-bottom: 20px;
    }
  }
}

//clientspeaks
@media (max-width: 430px) {
  .cliendspeak {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    background-color: #2b4bb6;
    .head {
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      color: #fff;
    }
    .textstyle {
      font-size: 14px;
      color: #fff;
      font-weight: 200;
    }
    .cardstyle {
      border-radius: 16px;
      background-color: #a1e6fe;
      padding: 14px;
      margin: 0px 10px;
    }
    .testimonialimg {
      border-radius: 16px 0px 0px 16px;
      height: 199px;
    }
    .cardtxt {
      font-size: 14px;
      font-weight: 300;
      text-align: start;
      color: #000;
    }
    .cardhead {
      font-size: 18px;
      font-weight: 500;
      text-align: start;
      color: #000;
    }
    .badgesince {
      background-color: #beebf8;
      color: #000;
      font-size: 11px;
      font-weight: 500;
      padding: 0px;
      border-radius: 52px;
      height: 20px;
    }
    [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
    [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
      background-color: transparent !important;
      filter: invert(0) grayscale(0) !important;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      display: inline-block;
      width: 2rem;
      height: 2rem;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100% 100%;
      //  background: black;
      padding: 22px;
      border-radius: 50%;
      margin-top: 20px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .carousel-control-prev,
    .carousel-control-next {
      position: relative;
      /* top: 0px; */
      bottom: -50px;
      z-index: 1;
      display: contents;
      align-items: center;
      justify-content: center;
      /* width: 15%; */
      padding: 0;
      color: #fff;
      text-align: center;
      background: none;
      border: 0;
      opacity: 0.5;
      transition: opacity 0.15s ease;
    }
    .carousel-control-next,
    .carousel-control-prev {
      position: relative;
      /* top: 0px; */
      bottom: -50px;
      z-index: 1;
      display: contents;
      align-items: center;
      justify-content: center;
      /* width: 15%; */
      padding: 0;
      color: #fff;
      text-align: center;
      background: none;
      border: 0;
      opacity: 0.5;
      transition: opacity 0.15s ease;
    }
  }
}
@media (min-width: 431px) and (max-width: 767px) {
  .cliendspeak {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    background-color: #2b4bb6;
    .head {
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      color: #fff;
    }
    .textstyle {
      font-size: 14px;
      color: #fff;
      font-weight: 200;
    }
    .cardstyle {
      border-radius: 16px;
      background-color: #a1e6fe;
      padding: 14px;
      margin: 0px 10px;
    }
    .testimonialimg {
      border-radius: 16px 0px 0px 16px;
      height: 199px;
    }
    .cardtxt {
      font-size: 14px;
      font-weight: 300;
      text-align: start;
      color: #000;
    }
    .cardhead {
      font-size: 20px;
      font-weight: 500;
      text-align: start;
      color: #000;
    }
    .badgesince {
      background-color: #beebf8;
      color: #000;
      font-size: 14px;
      font-weight: 500;
      padding: 0px;
      border-radius: 52px;
      height: 20px;
    }
    [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
    [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
      background-color: transparent !important;
      filter: invert(0) grayscale(0) !important;
    }
 
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      display: inline-block;
      width: 2rem;
      height: 2rem;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100% 100%;
      //  background: black;
      padding: 22px;
      border-radius: 50%;
      margin-top: 20px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .carousel-control-prev,
    .carousel-control-next {
      position: relative;
      /* top: 0px; */
      bottom: -50px;
      z-index: 1;
      display: contents;
      align-items: center;
      justify-content: center;
      /* width: 15%; */
      padding: 0;
      color: #fff;
      text-align: center;
      background: none;
      border: 0;
      opacity: 0.5;
      transition: opacity 0.15s ease;
    }
    .carousel-control-next,
    .carousel-control-prev {
      position: relative;
      /* top: 0px; */
      bottom: -50px;
      z-index: 1;
      display: contents;
      align-items: center;
      justify-content: center;
      /* width: 15%; */
      padding: 0;
      color: #fff;
      text-align: center;
      background: none;
      border: 0;
      opacity: 0.5;
      transition: opacity 0.15s ease;
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .cliendspeak {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    background-color: #2b4bb6;
    .head {
      font-size: 36px;
      font-weight: 700;
      text-align: center;
      color: #fff;
    }
    .textstyle {
      font-size: 14px;
      color: #fff;
      font-weight: 200;
    }
    .cardstyle {
      border-radius: 16px;
      background-color: #a1e6fe;
      padding: 14px;
      margin: 0px 10px;
    }
    .testimonialimg {
      border-radius: 16px 0px 0px 16px;
      height: 199px;
    }
    .cardtxt {
      font-size: 14px;
      font-weight: 300;
      text-align: start;
      color: #000;
    }
    .cardhead {
      font-size: 24px;
      font-weight: 500;
      text-align: start;
      color: #000;
    }
    .badgesince {
      background-color: #beebf8;
      color: #000;
      font-size: 16px;
      font-weight: 500;
      padding: 0px;
      border-radius: 52px;
      height: 26px;
    }
    [data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
    [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
      background-color: transparent !important;
      filter: invert(0) grayscale(0) !important;
    }
 
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      display: inline-block;
      width: 2rem;
      height: 2rem;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100% 100%;
      //  background: black;
      padding: 22px;
      border-radius: 50%;
      margin-top: 20px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .carousel-control-prev,
    .carousel-control-next {
      position: relative;
      /* top: 0px; */
      bottom: -50px;
      z-index: 1;
      display: contents;
      align-items: center;
      justify-content: center;
      /* width: 15%; */
      padding: 0;
      color: #fff;
      text-align: center;
      background: none;
      border: 0;
      opacity: 0.5;
      transition: opacity 0.15s ease;
    }
    .carousel-control-next,
    .carousel-control-prev {
      position: relative;
      /* top: 0px; */
      bottom: -50px;
      z-index: 1;
      display: contents;
      align-items: center;
      justify-content: center;
      /* width: 15%; */
      padding: 0;
      color: #fff;
      text-align: center;
      background: none;
      border: 0;
      opacity: 0.5;
      transition: opacity 0.15s ease;
    }
  }
}

//Partner
@media (max-width: 389px) {
  .partner {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .partnerimg {
      height: 100px;
      width: 100%;
    }
  }
}
@media (min-width: 390px) and (max-width: 430px) {
  .partner {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .partnerimg {
      height: 110px;
      width: 100%;
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .partner {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .partnerimg {
      height: 135px;
      width: 100%;
    }
  }
}

//Search
.search {
  font-family: "Be Vietnam Pro", sans-serif;
  font-style: normal;
  // background-image: url("../images/neareststore.png") ;
  // background-size: cover;
  // background-repeat: no-repeat;
  .franchisebtn {
    background-color: #ff9133;
    padding: 8px 24px;
    border-radius: 6px;
    // font-size: 14px;
    border-color: transparent !important;
    position: relative;
    overflow: hidden;
    z-index: 1;
    color: white; /* Ensure the text color is set for visibility */
    text-align: center; /* Center align text horizontally */
    display: inline-flex; /* Aligns text vertically */
    align-items: center; /* Aligns text vertically */
    justify-content: center; /* Aligns text horizontally */
    cursor: pointer;
    transition: all 0.4s ease-in-out;
  }

  .franchisebtn::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200%;
    height: 200%;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.4s ease-in-out;
    z-index: 0;
  }

  .franchisebtn:hover::before {
    transform: translate(-50%, -50%) scale(1);
  }

  .franchisebtn:hover {
    background-color: #ff9133; /* Ensure background color remains the same */
    color: white !important; /* Ensure text color remains visible on hover */
  }
}
@media (min-width: 1441px) and (max-width: 1600px) {
  .search {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    // background-image:  url("../images/.png");
    // .franchisebtn {
    //   background-color: #ff9133;
    //   padding: 8px 24px;
    //   border-radius: 6px;
    //   border-color: transparent !important;
    //   font-weight: 500;
    //   font-size: 16px;

    // }
    .franchisebtn {
      background-color: #ff9133;
      padding: 8px 24px;
      border-radius: 6px;
      //  font-size: 16px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white !important; /* Ensure text color remains visible on hover */
    }
    .searchfield {
      font-size: 16px;
    }
  }
}
@media (min-width: 1281px) and (max-width: 1440px) {
  .search {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;

    .franchisebtn {
      background-color: #ff9133;
      padding: 4px 10px;
      border-radius: 6px;
      font-size: 14px;
      // height: 24px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white !important; /* Ensure text color remains visible on hover */
    }
    .searchfield {
      font-size: 14px;
    }
  }
}
@media (min-width: 992px) and (max-width: 1280px) {
  .search {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .franchisebtn {
      background-color: #ff9133;
      padding: 8px;
      border-radius: 6px;
      font-size: 12px;
      // height: 24px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white !important; /* Ensure text color remains visible on hover */
    }
    .searchfield {
      font-size: 12px;
    }
  }
}
@media (max-width: 767px) {
  .search {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    // .franchisebtn {
    //   background-color: #ff9133;
    //   padding: 8px 24px;
    //   border-radius: 6px;
    //   border-color: transparent !important;
    //   font-weight: 500;
    //   font-size: 14px;

    // }
    .franchisebtn {
      background-color: #ff9133;
      padding: 8px 24px;
      border-radius: 6px;
      font-size: 14px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white !important; /* Ensure text color remains visible on hover */
    }
    .searchfield {
      font-size: 14px;
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .search {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    .franchisebtn {
      background-color: #ff9133;
      padding: 5px 20px;
      border-radius: 6px;
      //  height: 24px;
      font-size: 14px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white !important; /* Ensure text color remains visible on hover */
    }
    .searchfield {
      font-size: 14px;
    }
  }
}

//common for images
.bgImageStyle {
  background-size: cover;
  background-repeat: no-repeat;
  align-content: center;
  text-align: center;
  img {
    cursor: pointer;
  }
}
.cliendspeak{
.carousel-control-next-icon {
  background-image: url("../images/arrowright.webp");
  filter: invert(0) grayscale(0) !important;
  background-color: #fff;
  background-repeat: no-repeat !important;
  background-size: 30px !important;
}
.carousel-control-prev-icon {
  background-image: url("../images/arrowleft.webp");
  background-color: #fff;
  filter: invert(0) grayscale(0) !important;
  background-repeat: no-repeat !important;
  background-size: 30px !important;
}
}