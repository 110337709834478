// @keyframes slide-up {
//     0% {
//       transform: translateY(20%);
//     }
//     100% {
//       transform: translateY(0);
//     }
//   }
 
@keyframes slide-in-left {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

//   /* Animation for card moving from left to right */
// .left-to-right {
//     animation: moveLeftToRight 5s infinite alternate;
// }

// /* Animation for card moving from right to left */
// .right-to-left {
//     animation: moveRightToLeft 5s infinite alternate;
// }

// @keyframes moveLeftToRight {
//     0% {
//         left: 0;
//     }
//     100% {
//         left: calc(100vw - 100px); /* 100vw - card width */
//     }
// }

// @keyframes moveRightToLeft {
//     0% {
//         right: 0;
//     }
//     100% {
//         right: calc(100vw - 100px); /* 100vw - card width */
//     }
// }


