@media (-webkit-min-device-pixel-ratio: 1) and (-webkit-max-device-pixel-ratio: 1.24) {
.whyus-style{
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 92px;
    .about{
        background-color: #2B4BB6;
        font-size: 40px;
        font-weight: 800;
        color: #fff;
    }
    .cardstyle{
        background-color: #E3F6FC;
        border-radius: 16px;
        padding: 20px;
        border: 1px solid transparent;
    }
    .headstyle{
        color: #2B4BB6;
        font-size: 36px;
        font-weight: 700;
        line-height: 42px;
    }
    .textstyle{
        font-size: 18px;
        font-weight: 300;
        color: #000;
    }
    .imgstyle{
        height: 92px;
    }

}
@media (min-width:992px) and (max-width:1280px){
    .whyus-style{
        font-family: "Be Vietnam Pro", sans-serif;
        font-style: normal;
        margin-top: 72px;
        .about{
            background-color: #2B4BB6;
            font-size: 32px;
            font-weight: 800;
            color: #fff;
        }
        .cardstyle{
            background-color: #E3F6FC;
            border-radius: 16px;
            padding: 20px;
            border: 1px solid transparent;
        }
        .headstyle{
            color: #2B4BB6;
            font-size: 30px;
            font-weight: 700;
            line-height: 35px;
        }
        .textstyle{
            font-size: 12px;
            font-weight: 300;
            color: #000;
        }
        .imgstyle{
            height: 70px;
        }
    
    } 
}
@media (min-width:1281px) and (max-width:1368px){
    .whyus-style{
        font-family: "Be Vietnam Pro", sans-serif;
        font-style: normal;
        margin-top: 82px;
        .about{
            background-color: #2B4BB6;
            font-size: 36px;
            font-weight: 800;
            color: #fff;
        }
        .cardstyle{
            background-color: #E3F6FC;
            border-radius: 16px;
            padding: 20px;
            border: 1px solid transparent;
        }
        .headstyle{
            color: #2B4BB6;
            font-size: 34px;
            font-weight: 700;
            line-height: 35px;
        }
        .textstyle{
            font-size: 14px;
            font-weight: 300;
            color: #000;
        }
        .imgstyle{
            height: 70px;
        }
    
    } 
}
@media (min-width:1369px) and (max-width:1440px){
    .whyus-style{
        font-family: "Be Vietnam Pro", sans-serif;
        font-style: normal;
        margin-top: 91px;
        .about{
            background-color: #2B4BB6;
            font-size: 36px;
            font-weight: 800;
            color: #fff;
        }
        .cardstyle{
            background-color: #E3F6FC;
            border-radius: 16px;
            padding: 20px;
            border: 1px solid transparent;
        }
        .headstyle{
            color: #2B4BB6;
            font-size: 34px;
            font-weight: 700;
            line-height: 35px;
        }
        .textstyle{
            font-size: 14px;
            font-weight: 300;
            color: #000;
        }
        .imgstyle{
            height: 70px;
        }
    
    } 
}
@media (min-width:1441px) and (max-width:1600px){
    .whyus-style{
        font-family: "Be Vietnam Pro", sans-serif;
        font-style: normal;
        margin-top: 91px;
        .about{
            background-color: #2B4BB6;
            font-size: 38px;
            font-weight: 800;
            color: #fff;
        }
        .cardstyle{
            background-color: #E3F6FC;
            border-radius: 16px;
            padding: 20px;
            border: 1px solid transparent;
        }
        .headstyle{
            color: #2B4BB6;
            font-size: 36px;
            font-weight: 700;
            line-height: 40px;
        }
        .textstyle{
            font-size: 16px;
            font-weight: 300;
            color: #000;
        }
        .imgstyle{
            height: 92px;
        }
    
    } 
}
@media (min-width:1601px) and (max-width:1680px){
    .whyus-style{
        font-family: "Be Vietnam Pro", sans-serif;
        font-style: normal;
        margin-top: 91px;
        .about{
            background-color: #2B4BB6;
            font-size: 40px;
            font-weight: 800;
            color: #fff;
        }
        .cardstyle{
            background-color: #E3F6FC;
            border-radius: 16px;
            padding: 20px;
            border: 1px solid transparent;
        }
        .headstyle{
            color: #2B4BB6;
            font-size: 36px;
            font-weight: 700;
            line-height: 40px;
        }
        .textstyle{
            font-size: 18px;
            font-weight: 300;
            color: #000;
        }
        .imgstyle{
            height: 92px;
        }
    
    } 
}
}

@media (-webkit-min-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.49) {
    .whyus-style{
        font-family: "Be Vietnam Pro", sans-serif;
        font-style: normal;
        margin-top: 92px;
        .about{
            background-color: #2B4BB6;
            font-size: 40px;
            font-weight: 800;
            color: #fff;
        }
        .cardstyle{
            background-color: #E3F6FC;
            border-radius: 16px;
            padding: 20px;
            border: 1px solid transparent;
        }
        .headstyle{
            color: #2B4BB6;
            font-size: 36px;
            font-weight: 700;
            line-height: 42px;
        }
        .textstyle{
            font-size: 18px;
            font-weight: 300;
            color: #000;
        }
        .imgstyle{
            height: 92px;
        }
    
    }
    @media (min-width:992px) and (max-width:1280px){
        .whyus-style{
            font-family: "Be Vietnam Pro", sans-serif;
            font-style: normal;
            margin-top: 72px;
            .about{
                background-color: #2B4BB6;
                font-size: 32px;
                font-weight: 800;
                color: #fff;
            }
            .cardstyle{
                background-color: #E3F6FC;
                border-radius: 16px;
                padding: 20px;
                border: 1px solid transparent;
            }
            .headstyle{
                color: #2B4BB6;
                font-size: 30px;
                font-weight: 700;
                line-height: 35px;
            }
            .textstyle{
                font-size: 12px;
                font-weight: 300;
                color: #000;
            }
            .imgstyle{
                height: 70px;
            }
        
        } 
    }
    @media (min-width:1281px) and (max-width:1368px){
        .whyus-style{
            font-family: "Be Vietnam Pro", sans-serif;
            font-style: normal;
            margin-top: 87px;
            .about{
                background-color: #2B4BB6;
                font-size: 36px;
                font-weight: 800;
                color: #fff;
            }
            .cardstyle{
                background-color: #E3F6FC;
                border-radius: 16px;
                padding: 20px;
                border: 1px solid transparent;
            }
            .headstyle{
                color: #2B4BB6;
                font-size: 30px;
                font-weight: 700;
                line-height: 35px;
            }
            .textstyle{
                font-size: 14px;
                font-weight: 300;
                color: #000;
            }
            .imgstyle{
                height: 70px;
            }
        
        } 
    }
    @media (min-width:1369px) and (max-width:1440px){
        .whyus-style{
            font-family: "Be Vietnam Pro", sans-serif;
            font-style: normal;
            margin-top: 91px;
            .about{
                background-color: #2B4BB6;
                font-size: 36px;
                font-weight: 800;
                color: #fff;
            }
            .cardstyle{
                background-color: #E3F6FC;
                border-radius: 16px;
                padding: 20px;
                border: 1px solid transparent;
            }
            .headstyle{
                color: #2B4BB6;
                font-size: 34px;
                font-weight: 700;
                line-height: 35px;
            }
            .textstyle{
                font-size: 14px;
                font-weight: 300;
                color: #000;
            }
            .imgstyle{
                height: 70px;
            }
        
        } 
    }
    @media (min-width:1441px) and (max-width:1600px){
        .whyus-style{
            font-family: "Be Vietnam Pro", sans-serif;
            font-style: normal;
            margin-top: 87px;
            .about{
                background-color: #2B4BB6;
                font-size: 38px;
                font-weight: 800;
                color: #fff;
            }
            .cardstyle{
                background-color: #E3F6FC;
                border-radius: 16px;
                padding: 20px;
                border: 1px solid transparent;
            }
            .headstyle{
                color: #2B4BB6;
                font-size: 36px;
                font-weight: 700;
                line-height: 40px;
            }
            .textstyle{
                font-size: 16px;
                font-weight: 300;
                color: #000;
            }
            .imgstyle{
                height: 92px;
            }
        
        } 
    }
    @media (min-width:1601px) and (max-width:1680px){
        .whyus-style{
            font-family: "Be Vietnam Pro", sans-serif;
            font-style: normal;
            margin-top: 91px;
            .about{
                background-color: #2B4BB6;
                font-size: 40px;
                font-weight: 800;
                color: #fff;
            }
            .cardstyle{
                background-color: #E3F6FC;
                border-radius: 16px;
                padding: 20px;
                border: 1px solid transparent;
            }
            .headstyle{
                color: #2B4BB6;
                font-size: 36px;
                font-weight: 700;
                line-height: 40px;
            }
            .textstyle{
                font-size: 18px;
                font-weight: 300;
                color: #000;
            }
            .imgstyle{
                height: 92px;
            }
        
        } 
    }
}
@media (-webkit-min-device-pixel-ratio: 1.50) {
    .whyus-style{
        font-family: "Be Vietnam Pro", sans-serif;
        font-style: normal;
        margin-top: 92px;
        .about{
            background-color: #2B4BB6;
            font-size: 40px;
            font-weight: 800;
            color: #fff;
        }
        .cardstyle{
            background-color: #E3F6FC;
            border-radius: 16px;
            padding: 20px;
            border: 1px solid transparent;
        }
        .headstyle{
            color: #2B4BB6;
            font-size: 36px;
            font-weight: 700;
            line-height: 42px;
        }
        .textstyle{
            font-size: 18px;
            font-weight: 300;
            color: #000;
        }
        .imgstyle{
            height: 92px;
        }
    
    }
    @media (min-width:992px) and (max-width:1280px){
        .whyus-style{
            font-family: "Be Vietnam Pro", sans-serif;
            font-style: normal;
            margin-top: 72px;
            .about{
                background-color: #2B4BB6;
                font-size: 32px;
                font-weight: 800;
                color: #fff;
            }
            .cardstyle{
                background-color: #E3F6FC;
                border-radius: 16px;
                padding: 20px;
                border: 1px solid transparent;
            }
            .headstyle{
                color: #2B4BB6;
                font-size: 30px;
                font-weight: 700;
                line-height: 35px;
            }
            .textstyle{
                font-size: 12px;
                font-weight: 300;
                color: #000;
            }
            .imgstyle{
                height: 70px;
            }
        
        } 
    }
    @media (min-width:1281px) and (max-width:1368px){
        .whyus-style{
            font-family: "Be Vietnam Pro", sans-serif;
            font-style: normal;
            margin-top: 82px;
            .about{
                background-color: #2B4BB6;
                font-size: 36px;
                font-weight: 800;
                color: #fff;
            }
            .cardstyle{
                background-color: #E3F6FC;
                border-radius: 16px;
                padding: 20px;
                border: 1px solid transparent;
            }
            .headstyle{
                color: #2B4BB6;
                font-size: 34px;
                font-weight: 700;
                line-height: 35px;
            }
            .textstyle{
                font-size: 14px;
                font-weight: 300;
                color: #000;
            }
            .imgstyle{
                height: 70px;
            }
        
        } 
    }
    @media (min-width:1369px) and (max-width:1440px){
        .whyus-style{
            font-family: "Be Vietnam Pro", sans-serif;
            font-style: normal;
            margin-top: 91px;
            .about{
                background-color: #2B4BB6;
                font-size: 36px;
                font-weight: 800;
                color: #fff;
            }
            .cardstyle{
                background-color: #E3F6FC;
                border-radius: 16px;
                padding: 20px;
                border: 1px solid transparent;
            }
            .headstyle{
                color: #2B4BB6;
                font-size: 34px;
                font-weight: 700;
                line-height: 35px;
            }
            .textstyle{
                font-size: 14px;
                font-weight: 300;
                color: #000;
            }
            .imgstyle{
                height: 70px;
            }
        
        } 
    }
    @media (min-width:1441px) and (max-width:1600px){
        .whyus-style{
            font-family: "Be Vietnam Pro", sans-serif;
            font-style: normal;
            margin-top: 91px;
            .about{
                background-color: #2B4BB6;
                font-size: 38px;
                font-weight: 800;
                color: #fff;
            }
            .cardstyle{
                background-color: #E3F6FC;
                border-radius: 16px;
                padding: 20px;
                border: 1px solid transparent;
            }
            .headstyle{
                color: #2B4BB6;
                font-size: 36px;
                font-weight: 700;
                line-height: 40px;
            }
            .textstyle{
                font-size: 16px;
                font-weight: 300;
                color: #000;
            }
            .imgstyle{
                height: 92px;
            }
        
        } 
    }
    @media (min-width:1601px) and (max-width:1680px){
        .whyus-style{
            font-family: "Be Vietnam Pro", sans-serif;
            font-style: normal;
            margin-top: 91px;
            .about{
                background-color: #2B4BB6;
                font-size: 40px;
                font-weight: 800;
                color: #fff;
            }
            .cardstyle{
                background-color: #E3F6FC;
                border-radius: 16px;
                padding: 20px;
                border: 1px solid transparent;
            }
            .headstyle{
                color: #2B4BB6;
                font-size: 36px;
                font-weight: 700;
                line-height: 40px;
            }
            .textstyle{
                font-size: 18px;
                font-weight: 300;
                color: #000;
            }
            .imgstyle{
                height: 92px;
            }
        
        } 
    }
}


    
@media (max-width:539px) {
    .whyus-style{
        font-family: "Be Vietnam Pro", sans-serif;
        font-style: normal;
        margin-top: 92px;
        .about{
            background-color: #2B4BB6;
            font-size: 33px;
            font-weight: 800;
            color: #fff;
        }
        .cardstyle{
            background-color: #E3F6FC;
            border-radius: 16px;
            padding: 20px;
            border: 1px solid transparent;
        }
        .headstyle{
            color: #2B4BB6;
            font-size: 28px;
            font-weight: 700;
            line-height: 34px;
        }
        .textstyle{
            font-size: 14px;
            font-weight: 300;
            color: #000;
        }
        .imgstyle{
            height: 92px;
        }
    
    }
}
@media (min-width:540px) and (max-width:767px){
    .whyus-style{
        font-family: "Be Vietnam Pro", sans-serif;
        font-style: normal;
        margin-top: 92px;
        .about{
            background-color: #2B4BB6;
            font-size: 40px;
            font-weight: 800;
            color: #fff;
        }
        .cardstyle{
            background-color: #E3F6FC;
            border-radius: 16px;
            padding: 20px;
            border: 1px solid transparent;
        }
        .headstyle{
            color: #2B4BB6;
            font-size: 36px;
            font-weight: 700;
            line-height: 38px;
        }
        .textstyle{
            font-size: 16px;
            font-weight: 300;
            color: #000;
        }
        .imgstyle{
            height: 92px;
        }
    
    } 
}
@media (min-width:768px) and (max-width:991px){
    .whyus-style{
        font-family: "Be Vietnam Pro", sans-serif;
        font-style: normal;
        margin-top: 92px;
        .about{
            background-color: #2B4BB6;
            font-size: 40px;
            font-weight: 800;
            color: #fff;
        }
        .cardstyle{
            background-color: #E3F6FC;
            border-radius: 16px;
            padding: 20px;
            border: 1px solid transparent;
        }
        .headstyle{
            color: #2B4BB6;
            font-size: 30px;
            font-weight: 700;
            line-height: 35px;
        }
        .textstyle{
            font-size: 14px;
            font-weight: 300;
            color: #000;
        }
        .imgstyle{
            height: 70px;
        }
    
    } 
}