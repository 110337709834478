@media (-webkit-min-device-pixel-ratio: 1) and (-webkit-max-device-pixel-ratio: 1.24) {
  .testimonial-fra {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 92px;
    .home-style {
      .styleform {
        border-radius: 12px;
        border: 1px solid #c9e3f5;
        background: #eff7fd;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
        padding: 30px 36px;
      }
      .tagline {
        color: #222;
        font-family: "Be Vietnam Pro";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
      }
      .bnlandingimg {
        width: 100%;
      }
      .logoimg {
        height: 82px;
        text-align: center;
        animation: slide-up 0.5s ease-in-out;
      }
      .headtext {
        font-size: 40px;
        font-weight: 700;
        animation: slide-up 0.4s ease-in-out;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 45px;
        padding: 11px;
        box-shadow: 2px 9px #7ca7c87d;
        animation: slide-up 0.6s ease-in-out;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 45px;
        padding: 11px;
        box-shadow: 2px 9px #2dd10045;
        animation: slide-up 0.6s ease-in-out;
      }
    }

    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      .cardstyle {
        height: 480px;
        margin-right: 20px;
      }
      .cardstyle1 {
        height: 330px;
        box-shadow: 0 0 10px #ccc;
        margin-bottom: 20px;
        .badgesince {
          background-color: #beebf8;
          color: #000;
          font-size: 16px;
          font-weight: 500;
          padding: 3px 10px;
          border-radius: 52px;
          width: fit-content;
        }
      }
      .cardhead {
        font-size: 24px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 18px;
        font-weight: 300;
      }
      .whyusimg {
        height: 100px;
      }
      .whyuscard {
        position: relative;
        overflow: hidden;
        background-color: #f9f9f9; /* Default background color */
        transition: background-color 0.3s ease;
        box-shadow: 0 0 10px #ccc;
        padding: 37px 0px;
        height: 260px;
      }
      .whyuscard::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background-color: #a1e6fe; /* The hover background color */
        transition: left 0.4s ease;
        z-index: 0; /* Make sure it's behind the text */
      }
      .whyuscard:hover::before {
        left: 0;
      }
      .whyuscard .content {
        position: relative;
        z-index: 1; /* Ensure the content stays above the background */
      }
      .quote {
        font-size: 60px;
        color: #f58220;
      }
      .subtitlehead {
        font-size: 20px;
        text-align: center;
        font-weight: 700;
      }
    }
    .about-style {
      .subhead {
        font-size: 24px;
        font-weight: 700;
      }
      .lblstyle {
        font-size: 18px;
        font-weight: 300;
      }
    }
    .bgenquery {
      background-image: url("../images/bgenquiry.webp");
      background-size: cover;
      background-repeat: no-repeat;
      height: 300px;
      background-color: #beebf8;
      border-radius: 20px;
      padding: 84px 0px;
    }
    .earnings {
      .earningtxt {
        color: #333;
        font-size: 18px;
        font-weight: 600;
      }
      .earningcard {
        border-radius: 12px;
        background: #2b4bb6;
        padding: 80px 54px;
      }

      .normaltxt {
        color: #fff;
        font-size: 22px;
        font-weight: 300;
      }
    }
    .aboutsub {
      color: #fff;
      font-size: 22px;
      font-weight: 600;
    }
    .lastsection {
      background-size: cover;
      background-repeat: no-repeat;
      .womanimg {
        height: 579px;
      }
      .lastpanelcard {
        border-radius: 14px;
        background: #2b4bb5;
        font-size: 32px;
        font-weight: 700;
        color: #fff;
      }
      .pargraph {
        color: #2b4bb5;
        text-align: center;
        font-size: 30px;
        font-weight: 700;
      }
      .txtstyle {
        color: #222;
        font-size: 20px;
        font-weight: 500;
        line-height: 36px;
      }
    }
    .tblstyle{
      .tablestyle{ 
        box-shadow: 2px 7px 8px #dcdcdc;
        th{
        background-image: linear-gradient(180deg, #6578b8,#a1e6fe);
        color:#000;
       
      }
      body{
        border: 1px solid;
      }
    }
      .table-striped>tbody>tr:nth-of-type(odd)>* {
        --bs-table-color-type: var(--bs-table-striped-color);
        --bs-table-bg-type: #ddf6ff9e;
      }
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .testimonial-fra {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 92px;

      .home-style {
        .styleform {
          border-radius: 12px;
          border: 1px solid #c9e3f5;
          background: #eff7fd;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
          padding: 30px 30px;
        }
        .tagline {
          color: #222;
          font-family: "Be Vietnam Pro";
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
        }
        .bnlandingimg {
          width: 100%;
        }
        .logoimg {
          height: 82px;
          text-align: center;
          animation: slide-up 0.5s ease-in-out;
        }
        .headtext {
          font-size: 38px;
          font-weight: 700;
          animation: slide-up 0.4s ease-in-out;
        }
        .badgestyle {
          background-color: #2b4bb6;
          color: #ffffff;
          border-radius: 34px;
          height: 45px;
          padding: 11px;
          box-shadow: 2px 9px #7ca7c87d;
          animation: slide-up 0.6s ease-in-out;
        }
        .badgestylegreen {
          background-color: #3ebf1b;
          color: #ffffff;
          border-radius: 34px;
          height: 45px;
          padding: 11px;
          box-shadow: 2px 9px #2dd10045;
          animation: slide-up 0.6s ease-in-out;
        }
      }

      .ourmilestone {
        font-style: normal;
        font-family: "Be Vietnam Pro", sans-serif;
        .cardstyle {
          height: 480px;
          margin-right: 20px;
        }
        .cardstyle1 {
          height: 350px;
          box-shadow: 0 0 10px #ccc;
          margin-bottom: 20px;
          .badgesince {
            background-color: #beebf8;
            color: #000;
            font-size: 16px;
            font-weight: 500;
            padding: 3px 10px;
            border-radius: 52px;
            width: fit-content;
          }
        }
        .cardhead {
          font-size: 24px;
          font-weight: 700;
        }
        .cardtext {
          font-size: 18px;
          font-weight: 300;
        }
        .whyusimg {
          height: 95px;
        }
        .whyuscard {
          position: relative;
          overflow: hidden;
          background-color: #f9f9f9; /* Default background color */
          transition: background-color 0.3s ease;
          box-shadow: 0 0 10px #ccc;
          padding: 37px 0px;
          height: 260px;
        }
        .whyuscard::before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background-color: #a1e6fe; /* The hover background color */
          transition: left 0.4s ease;
          z-index: 0; /* Make sure it's behind the text */
        }
        .whyuscard:hover::before {
          left: 0;
        }
        .whyuscard .content {
          position: relative;
          z-index: 1; /* Ensure the content stays above the background */
        }
        .quote {
          font-size: 60px;
          color: #f58220;
        }
        .subtitlehead {
          font-size: 20px;
          text-align: center;
          font-weight: 700;
        }
      }
      .about-style {
        .subhead {
          font-size: 22px;
          font-weight: 700;
        }
        .lblstyle {
          font-size: 16px;
          font-weight: 300;
        }
        .fieldstyle {
          font-size: 16px;
          padding: 4px;
        }
      }
      .bgenquery {
        background-image: url("../images/bgenquiry.webp");
        background-size: cover;
        background-repeat: no-repeat;
        height: 300px;
        background-color: #beebf8;
        border-radius: 20px;
        padding: 84px 0px;
      }
      .earnings {
        .earningtxt {
          color: #333;
          font-size: 18px;
          font-weight: 600;
        }
        .earningcard {
          border-radius: 12px;
          background: #2b4bb6;
          padding: 80px 54px;
        }

        .normaltxt {
          color: #fff;
          font-size: 22px;
          font-weight: 300;
        }
      }
      .aboutsub {
        color: #fff;
        font-size: 22px;
        font-weight: 600;
      }
      .lastsection {
        background-size: cover;
        background-repeat: no-repeat;
        .womanimg {
          height: 579px;
        }
        .lastpanelcard {
          border-radius: 14px;
          background: #2b4bb5;
          font-size: 32px;
          font-weight: 700;
          color: #fff;
        }
        .pargraph {
          color: #2b4bb5;
          text-align: center;
          font-size: 30px;
          font-weight: 700;
        }
        .txtstyle {
          color: #222;
          font-size: 20px;
          font-weight: 500;
          line-height: 36px;
        }
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .testimonial-fra {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 92px;
      .home-style {
        .styleform {
          border-radius: 12px;
          border: 1px solid #c9e3f5;
          background: #eff7fd;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
          padding: 30px 25px;
        }
        .tagline {
          color: #222;
          font-family: "Be Vietnam Pro";
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
        }
        .bnlandingimg {
          width: 100%;
        }
        .logoimg {
          height: 82px;
          text-align: center;
          animation: slide-up 0.5s ease-in-out;
        }
        .headtext {
          font-size: 36px;
          font-weight: 700;
          animation: slide-up 0.4s ease-in-out;
        }
        .badgestyle {
          background-color: #2b4bb6;
          color: #ffffff;
          border-radius: 34px;
          height: 40px;
          padding: 8px;
          box-shadow: 2px 9px #7ca7c87d;
          animation: slide-up 0.6s ease-in-out;
        }
        .badgestylegreen {
          background-color: #3ebf1b;
          color: #ffffff;
          border-radius: 34px;
          height: 40px;
          padding: 8px;
          box-shadow: 2px 9px #2dd10045;
          animation: slide-up 0.6s ease-in-out;
        }
      }

      .ourmilestone {
        font-style: normal;
        font-family: "Be Vietnam Pro", sans-serif;
        .cardstyle {
          height: 480px;
          margin-right: 20px;
        }
        .cardstyle1 {
          height: 350px;
          box-shadow: 0 0 10px #ccc;
          margin-bottom: 20px;
          .badgesince {
            background-color: #beebf8;
            color: #000;
            font-size: 16px;
            font-weight: 500;
            padding: 3px 10px;
            border-radius: 52px;
            width: fit-content;
          }
        }
        .cardhead {
          font-size: 24px;
          font-weight: 700;
        }
        .cardtext {
          font-size: 16px;
          font-weight: 300;
        }
        .whyusimg {
          height: 95px;
        }
        .whyuscard {
          position: relative;
          overflow: hidden;
          background-color: #f9f9f9; /* Default background color */
          transition: background-color 0.3s ease;
          box-shadow: 0 0 10px #ccc;
          padding: 37px 0px;
          height: 240px;
        }
        .whyuscard::before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background-color: #a1e6fe; /* The hover background color */
          transition: left 0.4s ease;
          z-index: 0; /* Make sure it's behind the text */
        }
        .whyuscard:hover::before {
          left: 0;
        }
        .whyuscard .content {
          position: relative;
          z-index: 1; /* Ensure the content stays above the background */
        }
        .quote {
          font-size: 60px;
          color: #f58220;
        }
        .subtitlehead {
          font-size: 16px;
          text-align: center;
          font-weight: 700;
        }
      }
      .about-style {
        .subhead {
          font-size: 22px;
          font-weight: 700;
        }
        .lblstyle {
          font-size: 16px;
          font-weight: 300;
        }
        .fieldstyle {
          font-size: 16px;
          padding: 4px;
        }
      }
      .bgenquery {
        background-image: url("../images/bgenquiry.webp");
        background-size: cover;
        background-repeat: no-repeat;
        height: 300px;
        background-color: #beebf8;
        border-radius: 20px;
        padding: 84px 0px;
      }
      .earnings {
        .earningtxt {
          color: #333;
          font-size: 16px;
          font-weight: 600;
        }
        .earningcard {
          border-radius: 12px;
          background: #2b4bb6;
          padding: 91px 54px;
        }
        .normaltxt {
          color: #fff;
          font-size: 20px;
          font-weight: 300;
        }
      }
      .aboutsub {
        color: #fff;
        font-size: 20px;
        font-weight: 600;
      }
      .lastsection {
        background-size: cover;
        background-repeat: no-repeat;
        .womanimg {
          height: 560px;
        }
        .lastpanelcard {
          border-radius: 14px;
          background: #2b4bb5;
          font-size: 25px;
          font-weight: 700;
          color: #fff;
        }
        .pargraph {
          color: #2b4bb5;
          text-align: center;
          font-size: 24px;
          font-weight: 700;
        }
        .txtstyle {
          color: #222;
          font-size: 18px;
          font-weight: 500;
          line-height: 36px;
        }
      }
    }
  }
  @media (min-width: 1369px) and (max-width: 1440px) {
    .testimonial-fra {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 92px;
      .home-style {
        .styleform {
          border-radius: 12px;
          border: 1px solid #c9e3f5;
          background: #eff7fd;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
          padding: 20px 25px;
        }
        .tagline {
          color: #222;
          font-family: "Be Vietnam Pro";
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
        }
        .bnlandingimg {
          width: 100%;
        }
        .logoimg {
          height: 82px;
          text-align: center;
          animation: slide-up 0.5s ease-in-out;
        }
        .headtext {
          font-size: 34px;
          font-weight: 700;
          animation: slide-up 0.4s ease-in-out;
        }
        .badgestyle {
          background-color: #2b4bb6;
          color: #ffffff;
          border-radius: 34px;
          height: 28px;
          padding: 4px;
          box-shadow: 2px 9px #7ca7c87d;
          animation: slide-up 0.6s ease-in-out;
        }
        .badgestylegreen {
          background-color: #3ebf1b;
          color: #ffffff;
          border-radius: 34px;
          height: 28px;
          padding: 4px;
          box-shadow: 2px 9px #2dd10045;
          animation: slide-up 0.6s ease-in-out;
        }
      }

      .ourmilestone {
        font-style: normal;
        font-family: "Be Vietnam Pro", sans-serif;
        .cardstyle {
          height: 480px;
          margin-right: 20px;
        }
        .cardstyle1 {
          height: 305px;
          box-shadow: 0 0 10px #ccc;
          margin-bottom: 20px;
          .badgesince {
            background-color: #beebf8;
            color: #000;
            font-size: 12px;
            font-weight: 500;
            padding: 3px 10px;
            border-radius: 52px;
            width: fit-content;
          }
        }
        .cardhead {
          font-size: 20px;
          font-weight: 700;
        }
        .cardtext {
          font-size: 14px;
          font-weight: 300;
        }
        .whyusimg {
          height: 85px;
        }
        .whyuscard {
          position: relative;
          overflow: hidden;
          background-color: #f9f9f9; /* Default background color */
          transition: background-color 0.3s ease;
          box-shadow: 0 0 10px #ccc;
          padding: 30px 0px;
          height: 225px;
        }
        .whyuscard::before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background-color: #a1e6fe; /* The hover background color */
          transition: left 0.4s ease;
          z-index: 0; /* Make sure it's behind the text */
        }
        .whyuscard:hover::before {
          left: 0;
        }
        .whyuscard .content {
          position: relative;
          z-index: 1; /* Ensure the content stays above the background */
        }
        .quote {
          font-size: 60px;
          color: #f58220;
        }
        .subtitlehead {
          font-size: 14px;
          text-align: center;
          font-weight: 700;
        }
      }
      .about-style {
        .subhead {
          font-size: 19px;
          font-weight: 700;
        }
        .lblstyle {
          font-size: 14px;
          font-weight: 300;
        }
        .fieldstyle {
          font-size: 14px;
          padding: 4px;
        }
      }
      .bgenquery {
        background-image: url("../images/bgenquiry.webp");
        background-size: cover;
        background-repeat: no-repeat;
        height: 300px;
        background-color: #beebf8;
        border-radius: 20px;
        padding: 84px 0px;
      }
      .earnings {
        .earningtxt {
          color: #333;
          font-size: 14px;
          font-weight: 600;
        }
        .earningcard {
          border-radius: 12px;
          background: #2b4bb6;
          padding: 91px 54px;
        }

        .normaltxt {
          color: #fff;
          font-size: 18px;
          font-weight: 300;
        }
      }
      .aboutsub {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
      }
      .lastsection {
        background-size: cover;
        background-repeat: no-repeat;
        .womanimg {
          height: 465px;
        }
        .lastpanelcard {
          border-radius: 14px;
          background: #2b4bb5;
          font-size: 22px;
          font-weight: 700;
          color: #fff;
        }
        .pargraph {
          color: #2b4bb5;
          text-align: center;
          font-size: 21px;
          font-weight: 700;
        }
        .txtstyle {
          color: #222;
          font-size: 16px;
          font-weight: 500;
          line-height: 36px;
        }
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1368px) {
    .testimonial-fra {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 83px;
      .home-style {
        .styleform {
          border-radius: 12px;
          border: 1px solid #c9e3f5;
          background: #eff7fd;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
          padding: 15px 15px;
        }
        .tagline {
          color: #222;
          font-family: "Be Vietnam Pro";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
        }
        .bnlandingimg {
          width: 100%;
        }
        .logoimg {
          height: 82px;
          text-align: center;
          animation: slide-up 0.5s ease-in-out;
        }
        .headtext {
          font-size: 32px;
          font-weight: 700;
          animation: slide-up 0.4s ease-in-out;
        }
        .badgestyle {
          background-color: #2b4bb6;
          color: #ffffff;
          border-radius: 34px;
          height: 28px;
          padding: 4px;
          box-shadow: 2px 9px #7ca7c87d;
          animation: slide-up 0.6s ease-in-out;
        }
        .badgestylegreen {
          background-color: #3ebf1b;
          color: #ffffff;
          border-radius: 34px;
          height: 28px;
          padding: 4px;
          box-shadow: 2px 9px #2dd10045;
          animation: slide-up 0.6s ease-in-out;
        }
      }

      .ourmilestone {
        font-style: normal;
        font-family: "Be Vietnam Pro", sans-serif;
        .cardstyle {
          height: 480px;
          margin-right: 20px;
        }
        .cardstyle1 {
          height: 305px;
          box-shadow: 0 0 10px #ccc;
          margin-bottom: 20px;
          .badgesince {
            background-color: #beebf8;
            color: #000;
            font-size: 12px;
            font-weight: 500;
            padding: 3px 10px;
            border-radius: 52px;
            width: fit-content;
          }
        }
        .cardhead {
          font-size: 20px;
          font-weight: 700;
        }
        .cardtext {
          font-size: 14px;
          font-weight: 300;
        }
        .whyusimg {
          height: 75px;
        }
        .whyuscard {
          position: relative;
          overflow: hidden;
          background-color: #f9f9f9; /* Default background color */
          transition: background-color 0.3s ease;
          box-shadow: 0 0 10px #ccc;
          padding: 25px 0px;
          height: 215px;
        }
        .whyuscard::before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background-color: #a1e6fe; /* The hover background color */
          transition: left 0.4s ease;
          z-index: 0; /* Make sure it's behind the text */
        }
        .whyuscard:hover::before {
          left: 0;
        }
        .whyuscard .content {
          position: relative;
          z-index: 1; /* Ensure the content stays above the background */
        }
        .quote {
          font-size: 50px;
          color: #f58220;
        }
        .subtitlehead {
          font-size: 14px;
          text-align: center;
          font-weight: 700;
        }
      }
      .about-style {
        .subhead {
          font-size: 18px;
          font-weight: 700;
        }
        .lblstyle {
          font-size: 14px;
          font-weight: 300;
        }
        .fieldstyle {
          font-size: 14px;
          padding: 4px;
        }
      }
      .bgenquery {
        background-image: url("../images/bgenquiry.webp");
        background-size: cover;
        background-repeat: no-repeat;
        height: 220px;
        background-color: #beebf8;
        border-radius: 20px;
        padding: 55px 0px;
      }
      .earnings {
        .earningtxt {
          color: #333;
          font-size: 14px;
          font-weight: 600;
        }
        .earningcard {
          border-radius: 12px;
          background: #2b4bb6;
          padding: 91px 54px;
        }

        .normaltxt {
          color: #fff;
          font-size: 18px;
          font-weight: 300;
        }
      }
      .aboutsub {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
      }
      .lastsection {
        background-size: cover;
        background-repeat: no-repeat;
        .womanimg {
          height: 450px;
        }
        .lastpanelcard {
          border-radius: 14px;
          background: #2b4bb5;
          font-size: 19px;
          font-weight: 700;
          color: #fff;
        }
        .pargraph {
          color: #2b4bb5;
          text-align: center;
          font-size: 19px;
          font-weight: 700;
        }
        .txtstyle {
          color: #222;
          font-size: 16px;
          font-weight: 500;
          line-height: 36px;
        }
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .testimonial-fra {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 72px;
      .home-style {
        .styleform {
          border-radius: 12px;
          border: 1px solid #c9e3f5;
          background: #eff7fd;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
          padding: 10px 15px;
        }
        .tagline {
          color: #222;
          font-family: "Be Vietnam Pro";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
        }
        .bnlandingimg {
          width: 100%;
        }
        .logoimg {
          height: 82px;
          text-align: center;
          animation: slide-up 0.5s ease-in-out;
        }
        .headtext {
          font-size: 29px;
          font-weight: 700;
          animation: slide-up 0.4s ease-in-out;
        }
        .badgestyle {
          background-color: #2b4bb6;
          color: #ffffff;
          border-radius: 34px;
          height: 28px;
          padding: 4px;
          box-shadow: 2px 9px #7ca7c87d;
          animation: slide-up 0.6s ease-in-out;
        }
        .badgestylegreen {
          background-color: #3ebf1b;
          color: #ffffff;
          border-radius: 34px;
          height: 28px;
          padding: 4px;
          box-shadow: 2px 9px #2dd10045;
          animation: slide-up 0.6s ease-in-out;
        }
      }

      .ourmilestone {
        font-style: normal;
        font-family: "Be Vietnam Pro", sans-serif;
        .cardstyle {
          height: 480px;
          margin-right: 20px;
        }
        .cardstyle1 {
          height: 270px;
          box-shadow: 0 0 10px #ccc;
          margin-bottom: 20px;
          .badgesince {
            background-color: #beebf8;
            color: #000;
            font-size: 12px;
            font-weight: 500;
            padding: 2px 10px;
            border-radius: 52px;
            width: fit-content;
          }
        }
        .cardhead {
          font-size: 18px;
          font-weight: 700;
        }
        .cardtext {
          font-size: 12px;
          font-weight: 300;
        }
        .whyusimg {
          height: 70px;
        }
        .whyuscard {
          position: relative;
          overflow: hidden;
          background-color: #f9f9f9; /* Default background color */
          transition: background-color 0.3s ease;
          box-shadow: 0 0 10px #ccc;
          padding: 25px 0px;
          height: 210px;
        }
        .whyuscard::before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background-color: #a1e6fe; /* The hover background color */
          transition: left 0.4s ease;
          z-index: 0; /* Make sure it's behind the text */
        }
        .whyuscard:hover::before {
          left: 0;
        }
        .whyuscard .content {
          position: relative;
          z-index: 1; /* Ensure the content stays above the background */
        }
        .quote {
          font-size: 40px;
          color: #f58220;
        }
        .subtitlehead {
          font-size: 14px;
          text-align: center;
          font-weight: 700;
        }
      }
      .about-style {
        .subhead {
          font-size: 18px;
          font-weight: 700;
        }
        .lblstyle {
          font-size: 12px;
          font-weight: 300;
        }
        .fieldstyle {
          font-size: 12px;
          padding: 4px;
        }
      }
      .bgenquery {
        background-image: url("../images/bgenquiry.webp");
        background-size: cover;
        background-repeat: no-repeat;
        height: 195px;
        background-color: #beebf8;
        border-radius: 20px;
        padding: 55px 0px;
      }
      .earnings {
        .earningtxt {
          color: #333;
          font-size: 12px;
          font-weight: 600;
        }
        .earningcard {
          border-radius: 12px;
          background: #2b4bb6;
          padding: 91px 54px;
        }

        .normaltxt {
          color: #fff;
          font-size: 16px;
          font-weight: 300;
        }
      }
      .aboutsub {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
      }
      .lastsection {
        background-size: cover;
        background-repeat: no-repeat;
        .womanimg {
          height: 450px;
        }
        .lastpanelcard {
          border-radius: 14px;
          background: #2b4bb5;
          font-size: 19px;
          font-weight: 700;
          color: #fff;
        }
        .pargraph {
          color: #2b4bb5;
          text-align: center;
          font-size: 19px;
          font-weight: 700;
        }
        .txtstyle {
          color: #222;
          font-size: 16px;
          font-weight: 500;
          line-height: 36px;
        }
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .testimonial-fra {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 72px;
      .invalid-feedback{
        font-size: 12px;
      }
      .home-style {
        .styleform {
          border-radius: 12px;
          border: 1px solid #c9e3f5;
          background: #eff7fd;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
          padding: 10px 15px;
        }
        .tagline {
          color: #222;
          font-family: "Be Vietnam Pro";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
        }
        .bnlandingimg {
          width: 100%;
          height: 355px;
        }
        .logoimg {
          height: 82px;
          text-align: center;
          animation: slide-up 0.5s ease-in-out;
        }
        .headtext {
          font-size: 22px;
          font-weight: 700;
          animation: slide-up 0.4s ease-in-out;
        }
        .badgestyle {
          background-color: #2b4bb6;
          color: #ffffff;
          border-radius: 34px;
          height: 26px;
          padding: 4px;
          box-shadow: 2px 9px #7ca7c87d;
          animation: slide-up 0.6s ease-in-out;
        }
        .badgestylegreen {
          background-color: #3ebf1b;
          color: #ffffff;
          border-radius: 34px;
          height: 26px;
          padding: 4px;
          box-shadow: 2px 9px #2dd10045;
          animation: slide-up 0.6s ease-in-out;
        }
      }

      .ourmilestone {
        font-style: normal;
        font-family: "Be Vietnam Pro", sans-serif;
        .cardstyle {
          height: 480px;
          margin-right: 20px;
        }
        .cardstyle1 {
          height: 285px;
          box-shadow: 0 0 10px #ccc;
          margin-bottom: 20px;
          .badgesince {
            background-color: #beebf8;
            color: #000;
            font-size: 12px;
            font-weight: 500;
            padding: 2px 10px;
            border-radius: 52px;
            width: fit-content;
          }
        }
        .cardhead {
          font-size: 18px;
          font-weight: 700;
        }
        .cardtext {
          font-size: 12px;
          font-weight: 300;
        }
        .whyusimg {
          height: 60px;
        }
        .whyuscard {
          position: relative;
          overflow: hidden;
          background-color: #f9f9f9; /* Default background color */
          transition: background-color 0.3s ease;
          box-shadow: 0 0 10px #ccc;
          padding: 25px 0px;
          height: 180px;
        }
        .whyuscard::before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background-color: #a1e6fe; /* The hover background color */
          transition: left 0.4s ease;
          z-index: 0; /* Make sure it's behind the text */
        }
        .whyuscard:hover::before {
          left: 0;
        }
        .whyuscard .content {
          position: relative;
          z-index: 1; /* Ensure the content stays above the background */
        }
        .quote {
          font-size: 40px;
          color: #f58220;
        }
        .subtitlehead {
          font-size: 11px;
          text-align: center;
          font-weight: 700;
        }
      }
      .about-style {
        .subhead {
          font-size: 14px;
          font-weight: 700;
        }
        .lblstyle {
          font-size: 12px;
          font-weight: 300;
        }
        .fieldstyle {
          font-size: 12px;
          padding: 4px;
           margin-bottom: -5px;
        }
      }
      .bgenquery {
        background-image: url("../images/bgenquiry.webp");
        background-size: cover;
        background-repeat: no-repeat;
        height: 195px;
        background-color: #beebf8;
        border-radius: 20px;
        padding: 55px 0px;
      }
      .earnings {
        .earningtxt {
          color: #333;
          font-size: 12px;
          font-weight: 600;
        }
        .earningcard {
          border-radius: 12px;
          background: #2b4bb6;
          padding: 91px 54px;
        }

        .normaltxt {
          color: #fff;
          font-size: 14px;
          font-weight: 300;
        }
      }
      .aboutsub {
        color: #fff;
        font-size: 14px;
        font-weight: 600;
      }
      .lastsection {
        background-size: cover;
        background-repeat: no-repeat;
        .womanimg {
          height: 400px;
        }
        .lastpanelcard {
          border-radius: 14px;
          background: #2b4bb5;
          font-size: 18px;
          font-weight: 700;
          color: #fff;
        }
        .pargraph {
          color: #2b4bb5;
          text-align: center;
          font-size: 18px;
          font-weight: 700;
        }
        .txtstyle {
          color: #222;
          font-size: 14px;
          font-weight: 500;
          line-height: 36px;
        }
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.49) {
  .testimonial-fra {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 92px;
    .home-style {
      .styleform {
        border-radius: 12px;
        border: 1px solid #c9e3f5;
        background: #eff7fd;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
        padding: 30px 36px;
      }
      .tagline {
        color: #222;
        font-family: "Be Vietnam Pro";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
      }
      .bnlandingimg {
        width: 100%;
      }
      .logoimg {
        height: 82px;
        text-align: center;
        animation: slide-up 0.5s ease-in-out;
      }
      .headtext {
        font-size: 40px;
        font-weight: 700;
        animation: slide-up 0.4s ease-in-out;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 45px;
        padding: 11px;
        box-shadow: 2px 9px #7ca7c87d;
        animation: slide-up 0.6s ease-in-out;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 45px;
        padding: 11px;
        box-shadow: 2px 9px #2dd10045;
        animation: slide-up 0.6s ease-in-out;
      }
    }

    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      .cardstyle {
        height: 480px;
        margin-right: 20px;
      }
      .cardstyle1 {
        height: 330px;
        box-shadow: 0 0 10px #ccc;
        margin-bottom: 20px;
        .badgesince {
          background-color: #beebf8;
          color: #000;
          font-size: 16px;
          font-weight: 500;
          padding: 3px 10px;
          border-radius: 52px;
          width: fit-content;
        }
      }
      .cardhead {
        font-size: 24px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 18px;
        font-weight: 300;
      }
      .whyusimg {
        height: 100px;
      }
      .whyuscard {
        position: relative;
        overflow: hidden;
        background-color: #f9f9f9; /* Default background color */
        transition: background-color 0.3s ease;
        box-shadow: 0 0 10px #ccc;
        padding: 37px 0px;
        height: 260px;
      }
      .whyuscard::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background-color: #a1e6fe; /* The hover background color */
        transition: left 0.4s ease;
        z-index: 0; /* Make sure it's behind the text */
      }
      .whyuscard:hover::before {
        left: 0;
      }
      .whyuscard .content {
        position: relative;
        z-index: 1; /* Ensure the content stays above the background */
      }
      .quote {
        font-size: 60px;
        color: #f58220;
      }
      .subtitlehead {
        font-size: 20px;
        text-align: center;
        font-weight: 700;
      }
    }
    .about-style {
      .subhead {
        font-size: 24px;
        font-weight: 700;
      }
      .lblstyle {
        font-size: 18px;
        font-weight: 300;
      }
    }
    .bgenquery {
      background-image: url("../images/bgenquiry.webp");
      background-size: cover;
      background-repeat: no-repeat;
      height: 300px;
      background-color: #beebf8;
      border-radius: 20px;
      padding: 84px 0px;
    }
    .earnings {
      .earningtxt {
        color: #333;
        font-size: 18px;
        font-weight: 600;
      }
      .earningcard {
        border-radius: 12px;
        background: #2b4bb6;
        padding: 80px 54px;
      }

      .normaltxt {
        color: #fff;
        font-size: 22px;
        font-weight: 300;
      }
    }
    .aboutsub {
      color: #fff;
      font-size: 22px;
      font-weight: 600;
    }
    .lastsection {
      background-size: cover;
      background-repeat: no-repeat;
      .womanimg {
        height: 579px;
      }
      .lastpanelcard {
        border-radius: 14px;
        background: #2b4bb5;
        font-size: 32px;
        font-weight: 700;
        color: #fff;
      }
      .pargraph {
        color: #2b4bb5;
        text-align: center;
        font-size: 30px;
        font-weight: 700;
      }
      .txtstyle {
        color: #222;
        font-size: 20px;
        font-weight: 500;
        line-height: 36px;
      }
    }
    .tblstyle{
      .tablestyle{ 
        box-shadow: 2px 7px 8px #dcdcdc;
        th{
        background-image: linear-gradient(180deg, #6578b8,#a1e6fe);
        color:#000;
       
      }
      body{
        border: 1px solid;
      }
    }
      .table-striped>tbody>tr:nth-of-type(odd)>* {
        --bs-table-color-type: var(--bs-table-striped-color);
        --bs-table-bg-type: #ddf6ff9e;
      }
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .testimonial-fra {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 92px;

      .home-style {
        .styleform {
          border-radius: 12px;
          border: 1px solid #c9e3f5;
          background: #eff7fd;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
          padding: 30px 30px;
        }
        .tagline {
          color: #222;
          font-family: "Be Vietnam Pro";
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
        }
        .bnlandingimg {
          width: 100%;
        }
        .logoimg {
          height: 82px;
          text-align: center;
          animation: slide-up 0.5s ease-in-out;
        }
        .headtext {
          font-size: 38px;
          font-weight: 700;
          animation: slide-up 0.4s ease-in-out;
        }
        .badgestyle {
          background-color: #2b4bb6;
          color: #ffffff;
          border-radius: 34px;
          height: 45px;
          padding: 11px;
          box-shadow: 2px 9px #7ca7c87d;
          animation: slide-up 0.6s ease-in-out;
        }
        .badgestylegreen {
          background-color: #3ebf1b;
          color: #ffffff;
          border-radius: 34px;
          height: 45px;
          padding: 11px;
          box-shadow: 2px 9px #2dd10045;
          animation: slide-up 0.6s ease-in-out;
        }
      }

      .ourmilestone {
        font-style: normal;
        font-family: "Be Vietnam Pro", sans-serif;
        .cardstyle {
          height: 480px;
          margin-right: 20px;
        }
        .cardstyle1 {
          height: 350px;
          box-shadow: 0 0 10px #ccc;
          margin-bottom: 20px;
          .badgesince {
            background-color: #beebf8;
            color: #000;
            font-size: 16px;
            font-weight: 500;
            padding: 3px 10px;
            border-radius: 52px;
            width: fit-content;
          }
        }
        .cardhead {
          font-size: 24px;
          font-weight: 700;
        }
        .cardtext {
          font-size: 18px;
          font-weight: 300;
        }
        .whyusimg {
          height: 95px;
        }
        .whyuscard {
          position: relative;
          overflow: hidden;
          background-color: #f9f9f9; /* Default background color */
          transition: background-color 0.3s ease;
          box-shadow: 0 0 10px #ccc;
          padding: 37px 0px;
          height: 260px;
        }
        .whyuscard::before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background-color: #a1e6fe; /* The hover background color */
          transition: left 0.4s ease;
          z-index: 0; /* Make sure it's behind the text */
        }
        .whyuscard:hover::before {
          left: 0;
        }
        .whyuscard .content {
          position: relative;
          z-index: 1; /* Ensure the content stays above the background */
        }
        .quote {
          font-size: 60px;
          color: #f58220;
        }
        .subtitlehead {
          font-size: 20px;
          text-align: center;
          font-weight: 700;
        }
      }
      .about-style {
        .subhead {
          font-size: 22px;
          font-weight: 700;
        }
        .lblstyle {
          font-size: 16px;
          font-weight: 300;
        }
        .fieldstyle {
          font-size: 16px;
          padding: 4px;
        }
      }
      .bgenquery {
        background-image: url("../images/bgenquiry.webp");
        background-size: cover;
        background-repeat: no-repeat;
        height: 300px;
        background-color: #beebf8;
        border-radius: 20px;
        padding: 84px 0px;
      }
      .earnings {
        .earningtxt {
          color: #333;
          font-size: 18px;
          font-weight: 600;
        }
        .earningcard {
          border-radius: 12px;
          background: #2b4bb6;
          padding: 80px 54px;
        }

        .normaltxt {
          color: #fff;
          font-size: 22px;
          font-weight: 300;
        }
      }
      .aboutsub {
        color: #fff;
        font-size: 22px;
        font-weight: 600;
      }
      .lastsection {
        background-size: cover;
        background-repeat: no-repeat;
        .womanimg {
          height: 579px;
        }
        .lastpanelcard {
          border-radius: 14px;
          background: #2b4bb5;
          font-size: 32px;
          font-weight: 700;
          color: #fff;
        }
        .pargraph {
          color: #2b4bb5;
          text-align: center;
          font-size: 30px;
          font-weight: 700;
        }
        .txtstyle {
          color: #222;
          font-size: 20px;
          font-weight: 500;
          line-height: 36px;
        }
      }
      .tblstyle{
        .tablestyle{ 
          box-shadow: 2px 7px 8px #dcdcdc;
          th{
          background-image: linear-gradient(180deg, #6578b8,#a1e6fe);
          color:#000;
         
        }
        body{
          border: 1px solid;
        }
      }
        .table-striped>tbody>tr:nth-of-type(odd)>* {
          --bs-table-color-type: var(--bs-table-striped-color);
          --bs-table-bg-type: #ddf6ff9e;
        }
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .testimonial-fra {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 88px;

      .home-style {
        .styleform {
          border-radius: 12px;
          border: 1px solid #c9e3f5;
          background: #eff7fd;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
          padding: 20px 20px;
        }

        .tagline {
          color: #222;
          font-family: "Be Vietnam Pro";
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
        }
        .bnlandingimg {
          width: 100%;
        }
        .logoimg {
          height: 82px;
          text-align: center;
          animation: slide-up 0.5s ease-in-out;
        }
        .headtext {
          font-size: 32px;
          font-weight: 700;
          animation: slide-up 0.4s ease-in-out;
        }
        .badgestyle {
          background-color: #2b4bb6;
          color: #ffffff;
          border-radius: 34px;
          height: 40px;
          padding: 8px;
          box-shadow: 2px 9px #7ca7c87d;
          animation: slide-up 0.6s ease-in-out;
        }
        .badgestylegreen {
          background-color: #3ebf1b;
          color: #ffffff;
          border-radius: 34px;
          height: 40px;
          padding: 8px;
          box-shadow: 2px 9px #2dd10045;
          animation: slide-up 0.6s ease-in-out;
        }
      }

      .ourmilestone {
        font-style: normal;
        font-family: "Be Vietnam Pro", sans-serif;
        .cardstyle {
          height: 480px;
          margin-right: 20px;
        }
        .cardstyle1 {
          height: 350px;
          box-shadow: 0 0 10px #ccc;
          margin-bottom: 20px;
          .badgesince {
            background-color: #beebf8;
            color: #000;
            font-size: 16px;
            font-weight: 500;
            padding: 3px 10px;
            border-radius: 52px;
            width: fit-content;
          }
        }
        .cardhead {
          font-size: 24px;
          font-weight: 700;
        }
        .cardtext {
          font-size: 16px;
          font-weight: 300;
        }
        .whyusimg {
          height: 85px;
        }
        .whyuscard {
          position: relative;
          overflow: hidden;
          background-color: #f9f9f9; /* Default background color */
          transition: background-color 0.3s ease;
          box-shadow: 0 0 10px #ccc;
          padding: 37px 0px;
          height: 260px;
        }
        .whyuscard::before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background-color: #a1e6fe; /* The hover background color */
          transition: left 0.4s ease;
          z-index: 0; /* Make sure it's behind the text */
        }
        .whyuscard:hover::before {
          left: 0;
        }
        .whyuscard .content {
          position: relative;
          z-index: 1; /* Ensure the content stays above the background */
        }
        .quote {
          font-size: 60px;
          color: #f58220;
        }
        .subtitlehead {
          font-size: 16px;
          text-align: center;
          font-weight: 700;
        }
      }
      .about-style {
        .subhead {
          font-size: 22px;
          font-weight: 700;
        }
        .lblstyle {
          font-size: 16px;
          font-weight: 300;
        }
        .fieldstyle {
          font-size: 16px;
          padding: 4px;
        }
      }
      .bgenquery {
        background-image: url("../images/bgenquiry.webp");
        background-size: cover;
        background-repeat: no-repeat;
        height: 232px;
        background-color: #beebf8;
        border-radius: 20px;
        padding: 50px 0px;
      }
      .earnings {
        .earningtxt {
          color: #333;
          font-size: 16px;
          font-weight: 600;
        }
        .earningcard {
          border-radius: 12px;
          background: #2b4bb6;
          padding: 91px 54px;
        }
        .normaltxt {
          color: #fff;
          font-size: 20px;
          font-weight: 300;
        }
      }
      .aboutsub {
        color: #fff;
        font-size: 20px;
        font-weight: 600;
      }
      .lastsection {
        background-size: cover;
        background-repeat: no-repeat;
        .womanimg {
          height: 500px;
        }
        .lastpanelcard {
          border-radius: 14px;
          background: #2b4bb5;
          font-size: 25px;
          font-weight: 700;
          color: #fff;
        }
        .pargraph {
          color: #2b4bb5;
          text-align: center;
          font-size: 24px;
          font-weight: 700;
        }
        .txtstyle {
          color: #222;
          font-size: 18px;
          font-weight: 500;
          line-height: 36px;
        }
      }
      .tblstyle{
        .tablestyle{ 
          box-shadow: 2px 7px 8px #dcdcdc;
          th{
          background-image: linear-gradient(180deg, #6578b8,#a1e6fe);
          color:#000;
         
        }
        body{
          border: 1px solid;
        }
      }
        .table-striped>tbody>tr:nth-of-type(odd)>* {
          --bs-table-color-type: var(--bs-table-striped-color);
          --bs-table-bg-type: #ddf6ff9e;
        }
      }
    }
  }
  @media (min-width: 1369px) and (max-width: 1440px) {
    .testimonial-fra {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 92px;

      .home-style {
        .styleform {
          border-radius: 12px;
          border: 1px solid #c9e3f5;
          background: #eff7fd;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
          padding: 20px 25px;
        }
    
        .tagline {
          color: #222;
          font-family: "Be Vietnam Pro";
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
        }
        .bnlandingimg {
          width: 100%;
        }
        .logoimg {
          height: 82px;
          text-align: center;
          animation: slide-up 0.5s ease-in-out;
        }
        .headtext {
          font-size: 34px;
          font-weight: 700;
          animation: slide-up 0.4s ease-in-out;
        }
        .badgestyle {
          background-color: #2b4bb6;
          color: #ffffff;
          border-radius: 34px;
          height: 28px;
          padding: 4px;
          box-shadow: 2px 9px #7ca7c87d;
          animation: slide-up 0.6s ease-in-out;
        }
        .badgestylegreen {
          background-color: #3ebf1b;
          color: #ffffff;
          border-radius: 34px;
          height: 28px;
          padding: 4px;
          box-shadow: 2px 9px #2dd10045;
          animation: slide-up 0.6s ease-in-out;
        }
      }

      .ourmilestone {
        font-style: normal;
        font-family: "Be Vietnam Pro", sans-serif;
        .cardstyle {
          height: 480px;
          margin-right: 20px;
        }
        .cardstyle1 {
          height: 305px;
          box-shadow: 0 0 10px #ccc;
          margin-bottom: 20px;
          .badgesince {
            background-color: #beebf8;
            color: #000;
            font-size: 12px;
            font-weight: 500;
            padding: 3px 10px;
            border-radius: 52px;
            width: fit-content;
          }
        }
        .cardhead {
          font-size: 20px;
          font-weight: 700;
        }
        .cardtext {
          font-size: 14px;
          font-weight: 300;
        }
        .whyusimg {
          height: 85px;
        }
        .whyuscard {
          position: relative;
          overflow: hidden;
          background-color: #f9f9f9; /* Default background color */
          transition: background-color 0.3s ease;
          box-shadow: 0 0 10px #ccc;
          padding: 30px 0px;
          height: 260px;
        }
        .whyuscard::before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background-color: #a1e6fe; /* The hover background color */
          transition: left 0.4s ease;
          z-index: 0; /* Make sure it's behind the text */
        }
        .whyuscard:hover::before {
          left: 0;
        }
        .whyuscard .content {
          position: relative;
          z-index: 1; /* Ensure the content stays above the background */
        }
        .quote {
          font-size: 60px;
          color: #f58220;
        }
        .subtitlehead {
          font-size: 14px;
          text-align: center;
          font-weight: 700;
        }
      }
      .about-style {
        .subhead {
          font-size: 19px;
          font-weight: 700;
        }
        .lblstyle {
          font-size: 14px;
          font-weight: 300;
        }
        .fieldstyle {
          font-size: 14px;
          padding: 4px;
        }
      }
      .bgenquery {
        background-image: url("../images/bgenquiry.webp");
        background-size: cover;
        background-repeat: no-repeat;
        height: 300px;
        background-color: #beebf8;
        border-radius: 20px;
        padding: 84px 0px;
      }
      .earnings {
        .earningtxt {
          color: #333;
          font-size: 14px;
          font-weight: 600;
        }
        .earningcard {
          border-radius: 12px;
          background: #2b4bb6;
          padding: 91px 54px;
        }

        .normaltxt {
          color: #fff;
          font-size: 18px;
          font-weight: 300;
        }
      }
      .aboutsub {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
      }
      .lastsection {
        background-size: cover;
        background-repeat: no-repeat;
        .womanimg {
          height: 450px;
        }
        .lastpanelcard {
          border-radius: 14px;
          background: #2b4bb5;
          font-size: 19px;
          font-weight: 700;
          color: #fff;
        }
        .pargraph {
          color: #2b4bb5;
          text-align: center;
          font-size: 19px;
          font-weight: 700;
        }
        .txtstyle {
          color: #222;
          font-size: 16px;
          font-weight: 500;
          line-height: 36px;
        }
      }
      .tblstyle{
        .tablestyle{ 
          box-shadow: 2px 7px 8px #dcdcdc;
          th{
          background-image: linear-gradient(180deg, #6578b8,#a1e6fe);
          color:#000;
         
        }
        body{
          border: 1px solid;
        }
      }
        .table-striped>tbody>tr:nth-of-type(odd)>* {
          --bs-table-color-type: var(--bs-table-striped-color);
          --bs-table-bg-type: #ddf6ff9e;
        }
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1368px) {
    .testimonial-fra {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 83px;
      .home-style {
        .styleform {
          border-radius: 12px;
          border: 1px solid #c9e3f5;
          background: #eff7fd;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
          padding: 15px 15px;
        }

        .tagline {
          color: #222;
          font-family: "Be Vietnam Pro";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
        }
        .bnlandingimg {
          width: 100%;
        }
        .logoimg {
          height: 82px;
          text-align: center;
          animation: slide-up 0.5s ease-in-out;
        }
        .headtext {
          font-size: 30px;
          font-weight: 700;
          animation: slide-up 0.4s ease-in-out;
        }
        .badgestyle {
          background-color: #2b4bb6;
          color: #ffffff;
          border-radius: 34px;
          height: 28px;
          padding: 4px;
          box-shadow: 2px 9px #7ca7c87d;
          animation: slide-up 0.6s ease-in-out;
        }
        .badgestylegreen {
          background-color: #3ebf1b;
          color: #ffffff;
          border-radius: 34px;
          height: 28px;
          padding: 4px;
          box-shadow: 2px 9px #2dd10045;
          animation: slide-up 0.6s ease-in-out;
        }
      }

      .ourmilestone {
        font-style: normal;
        font-family: "Be Vietnam Pro", sans-serif;
        .cardstyle {
          height: 480px;
          margin-right: 20px;
        }
        .cardstyle1 {
          height: 305px;
          box-shadow: 0 0 10px #ccc;
          margin-bottom: 20px;
          .badgesince {
            background-color: #beebf8;
            color: #000;
            font-size: 12px;
            font-weight: 500;
            padding: 3px 10px;
            border-radius: 52px;
            width: fit-content;
          }
        }
        .cardhead {
          font-size: 20px;
          font-weight: 700;
        }
        .cardtext {
          font-size: 14px;
          font-weight: 300;
        }
        .whyusimg {
          height: 75px;
        }
        .whyuscard {
          position: relative;
          overflow: hidden;
          background-color: #f9f9f9; /* Default background color */
          transition: background-color 0.3s ease;
          box-shadow: 0 0 10px #ccc;
          padding: 25px 0px;
          height: 230px;
        }
        .whyuscard::before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background-color: #a1e6fe; /* The hover background color */
          transition: left 0.4s ease;
          z-index: 0; /* Make sure it's behind the text */
        }
        .whyuscard:hover::before {
          left: 0;
        }
        .whyuscard .content {
          position: relative;
          z-index: 1; /* Ensure the content stays above the background */
        }
        .quote {
          font-size: 50px;
          color: #f58220;
        }
        .subtitlehead {
          font-size: 14px;
          text-align: center;
          font-weight: 700;
        }
      }
      .about-style {
        .subhead {
          font-size: 18px;
          font-weight: 700;
        }
        .lblstyle {
          font-size: 14px;
          font-weight: 300;
        }
        .fieldstyle {
          font-size: 14px;
          padding: 4px;
        }
      }
      .bgenquery {
        background-image: url("../images/bgenquiry.webp");
        background-size: cover;
        background-repeat: no-repeat;
        height: 220px;
        background-color: #beebf8;
        border-radius: 20px;
        padding: 55px 0px;
      }
      .earnings {
        .earningtxt {
          color: #333;
          font-size: 14px;
          font-weight: 600;
        }
        .earningcard {
          border-radius: 12px;
          background: #2b4bb6;
          padding: 91px 54px;
        }

        .normaltxt {
          color: #fff;
          font-size: 18px;
          font-weight: 300;
        }
      }
      .aboutsub {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
      }
      .lastsection {
        background-size: cover;
        background-repeat: no-repeat;
        .womanimg {
          height: 450px;
        }
        .lastpanelcard {
          border-radius: 14px;
          background: #2b4bb5;
          font-size: 19px;
          font-weight: 700;
          color: #fff;
        }
        .pargraph {
          color: #2b4bb5;
          text-align: center;
          font-size: 19px;
          font-weight: 700;
        }
        .txtstyle {
          color: #222;
          font-size: 16px;
          font-weight: 500;
          line-height: 36px;
        }
      }
      .tblstyle{
        .tablestyle{ 
          box-shadow: 2px 7px 8px #dcdcdc;
          th{
          background-image: linear-gradient(180deg, #6578b8,#a1e6fe);
          color:#000;
         
        }
        body{
          border: 1px solid;
        }
      }
        .table-striped>tbody>tr:nth-of-type(odd)>* {
          --bs-table-color-type: var(--bs-table-striped-color);
          --bs-table-bg-type: #ddf6ff9e;
        }
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .testimonial-fra {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 72px;
      .home-style {
        .styleform {
          border-radius: 12px;
          border: 1px solid #c9e3f5;
          background: #eff7fd;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
          padding: 10px 15px;
        }
        .tagline {
          color: #222;
          font-family: "Be Vietnam Pro";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
        }
        .bnlandingimg {
          width: 100%;
        }
        .logoimg {
          height: 82px;
          text-align: center;
          animation: slide-up 0.5s ease-in-out;
        }
        .headtext {
          font-size: 20px;
          font-weight: 700;
          animation: slide-up 0.4s ease-in-out;
        }
        .badgestyle {
          background-color: #2b4bb6;
          color: #ffffff;
          border-radius: 34px;
          height: 28px;
          padding: 4px;
          box-shadow: 2px 9px #7ca7c87d;
          animation: slide-up 0.6s ease-in-out;
        }
        .badgestylegreen {
          background-color: #3ebf1b;
          color: #ffffff;
          border-radius: 34px;
          height: 28px;
          padding: 4px;
          box-shadow: 2px 9px #2dd10045;
          animation: slide-up 0.6s ease-in-out;
        }
      }

      .ourmilestone {
        font-style: normal;
        font-family: "Be Vietnam Pro", sans-serif;
        .cardstyle {
          height: 480px;
          margin-right: 20px;
        }
        .cardstyle1 {
          height: 270px;
          box-shadow: 0 0 10px #ccc;
          margin-bottom: 20px;
          .badgesince {
            background-color: #beebf8;
            color: #000;
            font-size: 12px;
            font-weight: 500;
            padding: 2px 10px;
            border-radius: 52px;
            width: fit-content;
          }
        }
        .cardhead {
          font-size: 18px;
          font-weight: 700;
        }
        .cardtext {
          font-size: 12px;
          font-weight: 300;
        }
        .whyusimg {
          height: 70px;
        }
        .whyuscard {
          position: relative;
          overflow: hidden;
          background-color: #f9f9f9; /* Default background color */
          transition: background-color 0.3s ease;
          box-shadow: 0 0 10px #ccc;
          padding: 25px 0px;
          height: 180px;
        }
        .whyuscard::before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background-color: #a1e6fe; /* The hover background color */
          transition: left 0.4s ease;
          z-index: 0; /* Make sure it's behind the text */
        }
        .whyuscard:hover::before {
          left: 0;
        }
        .whyuscard .content {
          position: relative;
          z-index: 1; /* Ensure the content stays above the background */
        }
        .quote {
          font-size: 40px;
          color: #f58220;
        }
        .subtitlehead {
          font-size: 14px;
          text-align: center;
          font-weight: 700;
        }
      }
      .about-style {
        .subhead {
          font-size: 18px;
          font-weight: 700;
        }
        .lblstyle {
          font-size: 12px;
          font-weight: 300;
        }
        .fieldstyle {
          font-size: 12px;
          padding: 4px;
        }
      }
      .bgenquery {
        background-image: url("../images/bgenquiry.webp");
        background-size: cover;
        background-repeat: no-repeat;
        height: 195px;
        background-color: #beebf8;
        border-radius: 20px;
        padding: 55px 0px;
      }
      .earnings {
        .earningtxt {
          color: #333;
          font-size: 12px;
          font-weight: 600;
        }
        .earningcard {
          border-radius: 12px;
          background: #2b4bb6;
          padding: 77px 54px;
        }

        .normaltxt {
          color: #fff;
          font-size: 16px;
          font-weight: 300;
        }
      }
      .aboutsub {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
      }
      .lastsection {
        background-size: cover;
        background-repeat: no-repeat;
        .womanimg {
          height: 370px;
        }
        .lastpanelcard {
          border-radius: 14px;
          background: #2b4bb5;
          font-size: 16px;
          font-weight: 700;
          color: #fff;
        }
        .pargraph {
          color: #2b4bb5;
          text-align: center;
          font-size: 16px;
          font-weight: 700;
        }
        .txtstyle {
          color: #222;
          font-size: 14px;
          font-weight: 500;
          line-height: 36px;
        }
      }
      .tblstyle{
        .tablestyle{ 
          box-shadow: 2px 7px 8px #dcdcdc;
          th{
          background-image: linear-gradient(180deg, #6578b8,#a1e6fe);
          color:#000;
         
        }
        body{
          border: 1px solid;
        }
      }
        .table-striped>tbody>tr:nth-of-type(odd)>* {
          --bs-table-color-type: var(--bs-table-striped-color);
          --bs-table-bg-type: #ddf6ff9e;
        }
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .testimonial-fra {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 72px;
      .invalid-feedback{
        font-size: 12px;
      }
      .home-style {
        .styleform {
          border-radius: 12px;
          border: 1px solid #c9e3f5;
          background: #eff7fd;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
          padding: 10px 15px;
        }

        .tagline {
          color: #222;
          font-family: "Be Vietnam Pro";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
        }
        .bnlandingimg {
          width: 100%;
          height: 390px;
        }
        .logoimg {
          height: 82px;
          text-align: center;
          animation: slide-up 0.5s ease-in-out;
        }
        .headtext {
          font-size: 22px;
          font-weight: 700;
          animation: slide-up 0.4s ease-in-out;
        }
        .badgestyle {
          background-color: #2b4bb6;
          color: #ffffff;
          border-radius: 34px;
          height: 26px;
          padding: 4px;
          box-shadow: 2px 9px #7ca7c87d;
          animation: slide-up 0.6s ease-in-out;
        }
        .badgestylegreen {
          background-color: #3ebf1b;
          color: #ffffff;
          border-radius: 34px;
          height: 26px;
          padding: 4px;
          box-shadow: 2px 9px #2dd10045;
          animation: slide-up 0.6s ease-in-out;
        }
      }

      .ourmilestone {
        font-style: normal;
        font-family: "Be Vietnam Pro", sans-serif;
        .cardstyle {
          height: 480px;
          margin-right: 20px;
        }
        .cardstyle1 {
          height: 285px;
          box-shadow: 0 0 10px #ccc;
          margin-bottom: 20px;
          .badgesince {
            background-color: #beebf8;
            color: #000;
            font-size: 12px;
            font-weight: 500;
            padding: 2px 10px;
            border-radius: 52px;
            width: fit-content;
          }
        }
        .cardhead {
          font-size: 18px;
          font-weight: 700;
        }
        .cardtext {
          font-size: 12px;
          font-weight: 300;
        }
        .whyusimg {
          height: 60px;
        }
        .whyuscard {
          position: relative;
          overflow: hidden;
          background-color: #f9f9f9; /* Default background color */
          transition: background-color 0.3s ease;
          box-shadow: 0 0 10px #ccc;
          padding: 25px 0px;
          height: 180px;
        }
        .whyuscard::before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background-color: #a1e6fe; /* The hover background color */
          transition: left 0.4s ease;
          z-index: 0; /* Make sure it's behind the text */
        }
        .whyuscard:hover::before {
          left: 0;
        }
        .whyuscard .content {
          position: relative;
          z-index: 1; /* Ensure the content stays above the background */
        }
        .quote {
          font-size: 40px;
          color: #f58220;
        }
        .subtitlehead {
          font-size: 11px;
          text-align: center;
          font-weight: 700;
        }
      }
      .about-style {
        .subhead {
          font-size: 14px;
          font-weight: 700;
        }
        .lblstyle {
          font-size: 12px;
          font-weight: 300;
        }
        .fieldstyle {
          font-size: 12px;
          padding: 4px;
        }
      }
      .bgenquery {
        background-image: url("../images/bgenquiry.webp");
        background-size: cover;
        background-repeat: no-repeat;
        height: 195px;
        background-color: #beebf8;
        border-radius: 20px;
        padding: 55px 0px;
      }
      .earnings {
        .earningtxt {
          color: #333;
          font-size: 12px;
          font-weight: 600;
        }
        .earningcard {
          border-radius: 12px;
          background: #2b4bb6;
          padding: 91px 54px;
        }

        .normaltxt {
          color: #fff;
          font-size: 16px;
          font-weight: 300;
        }
      }
      .aboutsub {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
      }
      .lastsection {
        background-size: cover;
        background-repeat: no-repeat;
        .womanimg {
          height: 385px;
        }
        .lastpanelcard {
          border-radius: 14px;
          background: #2b4bb5;
          font-size: 16px;
          font-weight: 700;
          color: #fff;
        }
        .pargraph {
          color: #2b4bb5;
          text-align: center;
          font-size: 16px;
          font-weight: 700;
        }
        .txtstyle {
          color: #222;
          font-size: 14px;
          font-weight: 500;
          line-height: 36px;
        }
      }
      .tblstyle{
        .tablestyle{ 
          box-shadow: 2px 7px 8px #dcdcdc;
          th{
          background-image: linear-gradient(180deg, #6578b8,#a1e6fe);
          color:#000;
         
        }
        body{
          border: 1px solid;
        }
      }
        .table-striped>tbody>tr:nth-of-type(odd)>* {
          --bs-table-color-type: var(--bs-table-striped-color);
          --bs-table-bg-type: #ddf6ff9e;
        }
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 1.5) {
  @media (min-width: 1681px) {
    .testimonial-fra {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 92px;
      .home-style {
        .styleform {
          border-radius: 12px;
          border: 1px solid #c9e3f5;
          background: #eff7fd;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
          padding: 30px 36px;
        }

        .tagline {
          color: #222;
          font-family: "Be Vietnam Pro";
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
        }
        .bnlandingimg {
          width: 100%;
        }
        .logoimg {
          height: 82px;
          text-align: center;
          animation: slide-up 0.5s ease-in-out;
        }
        .headtext {
          font-size: 40px;
          font-weight: 700;
          animation: slide-up 0.4s ease-in-out;
        }
        .badgestyle {
          background-color: #2b4bb6;
          color: #ffffff;
          border-radius: 34px;
          height: 45px;
          padding: 11px;
          box-shadow: 2px 9px #7ca7c87d;
          animation: slide-up 0.6s ease-in-out;
        }
        .badgestylegreen {
          background-color: #3ebf1b;
          color: #ffffff;
          border-radius: 34px;
          height: 45px;
          padding: 11px;
          box-shadow: 2px 9px #2dd10045;
          animation: slide-up 0.6s ease-in-out;
        }
      }

      .ourmilestone {
        font-style: normal;
        font-family: "Be Vietnam Pro", sans-serif;
        .cardstyle {
          height: 480px;
          margin-right: 20px;
        }
        .cardstyle1 {
          height: 330px;
          box-shadow: 0 0 10px #ccc;
          margin-bottom: 20px;
          .badgesince {
            background-color: #beebf8;
            color: #000;
            font-size: 16px;
            font-weight: 500;
            padding: 3px 10px;
            border-radius: 52px;
            width: fit-content;
          }
        }
        .cardhead {
          font-size: 24px;
          font-weight: 700;
        }
        .cardtext {
          font-size: 18px;
          font-weight: 300;
        }
        .whyusimg {
          height: 100px;
        }
        .whyuscard {
          position: relative;
          overflow: hidden;
          background-color: #f9f9f9; /* Default background color */
          transition: background-color 0.3s ease;
          box-shadow: 0 0 10px #ccc;
          padding: 37px 0px;
          height: 260px;
        }
        .whyuscard::before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background-color: #a1e6fe; /* The hover background color */
          transition: left 0.4s ease;
          z-index: 0; /* Make sure it's behind the text */
        }
        .whyuscard:hover::before {
          left: 0;
        }
        .whyuscard .content {
          position: relative;
          z-index: 1; /* Ensure the content stays above the background */
        }
        .quote {
          font-size: 60px;
          color: #f58220;
        }
        .subtitlehead {
          font-size: 20px;
          text-align: center;
          font-weight: 700;
        }
      }
      .about-style {
        .subhead {
          font-size: 24px;
          font-weight: 700;
        }
        .lblstyle {
          font-size: 18px;
          font-weight: 300;
        }
      }
      .bgenquery {
        background-image: url("../images/bgenquiry.webp");
        background-size: cover;
        background-repeat: no-repeat;
        height: 300px;
        background-color: #beebf8;
        border-radius: 20px;
        padding: 84px 0px;
      }
      .earnings {
        .earningtxt {
          color: #333;
          font-size: 18px;
          font-weight: 600;
        }
        .earningcard {
          border-radius: 12px;
          background: #2b4bb6;
          padding: 80px 54px;
        }
  
        .normaltxt {
          color: #fff;
          font-size: 22px;
          font-weight: 300;
        }
      }
      .aboutsub {
        color: #fff;
        font-size: 22px;
        font-weight: 600;
      }
      .lastsection {
        background-size: cover;
        background-repeat: no-repeat;
        .womanimg {
          height: 579px;
        }
        .lastpanelcard {
          border-radius: 14px;
          background: #2b4bb5;
          font-size: 32px;
          font-weight: 700;
          color: #fff;
        }
        .pargraph {
          color: #2b4bb5;
          text-align: center;
          font-size: 30px;
          font-weight: 700;
        }
        .txtstyle {
          color: #222;
          font-size: 20px;
          font-weight: 500;
          line-height: 36px;
        }
      }
      .tblstyle{
        .tablestyle{ 
          box-shadow: 2px 7px 8px #dcdcdc;
          th{
          background-image: linear-gradient(180deg, #6578b8,#a1e6fe);
          color:#000;
         
        }
        body{
          border: 1px solid;
        }
      }
        .table-striped>tbody>tr:nth-of-type(odd)>* {
          --bs-table-color-type: var(--bs-table-striped-color);
          --bs-table-bg-type: #ddf6ff9e;
        }
      }
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .testimonial-fra {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 92px;

      .home-style {
        .styleform {
          border-radius: 12px;
          border: 1px solid #c9e3f5;
          background: #eff7fd;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
          padding: 30px 30px;
        }
        .tagline {
          color: #222;
          font-family: "Be Vietnam Pro";
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
        }
        .bnlandingimg {
          width: 100%;
        }
        .logoimg {
          height: 82px;
          text-align: center;
          animation: slide-up 0.5s ease-in-out;
        }
        .headtext {
          font-size: 38px;
          font-weight: 700;
          animation: slide-up 0.4s ease-in-out;
        }
        .badgestyle {
          background-color: #2b4bb6;
          color: #ffffff;
          border-radius: 34px;
          height: 45px;
          padding: 11px;
          box-shadow: 2px 9px #7ca7c87d;
          animation: slide-up 0.6s ease-in-out;
        }
        .badgestylegreen {
          background-color: #3ebf1b;
          color: #ffffff;
          border-radius: 34px;
          height: 45px;
          padding: 11px;
          box-shadow: 2px 9px #2dd10045;
          animation: slide-up 0.6s ease-in-out;
        }
      }

      .ourmilestone {
        font-style: normal;
        font-family: "Be Vietnam Pro", sans-serif;
        .cardstyle {
          height: 480px;
          margin-right: 20px;
        }
        .cardstyle1 {
          height: 350px;
          box-shadow: 0 0 10px #ccc;
          margin-bottom: 20px;
          .badgesince {
            background-color: #beebf8;
            color: #000;
            font-size: 16px;
            font-weight: 500;
            padding: 3px 10px;
            border-radius: 52px;
            width: fit-content;
          }
        }
        .cardhead {
          font-size: 24px;
          font-weight: 700;
        }
        .cardtext {
          font-size: 18px;
          font-weight: 300;
        }
        .whyusimg {
          height: 95px;
        }
        .whyuscard {
          position: relative;
          overflow: hidden;
          background-color: #f9f9f9; /* Default background color */
          transition: background-color 0.3s ease;
          box-shadow: 0 0 10px #ccc;
          padding: 37px 0px;
          height: 260px;
        }
        .whyuscard::before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background-color: #a1e6fe; /* The hover background color */
          transition: left 0.4s ease;
          z-index: 0; /* Make sure it's behind the text */
        }
        .whyuscard:hover::before {
          left: 0;
        }
        .whyuscard .content {
          position: relative;
          z-index: 1; /* Ensure the content stays above the background */
        }
        .quote {
          font-size: 60px;
          color: #f58220;
        }
        .subtitlehead {
          font-size: 20px;
          text-align: center;
          font-weight: 700;
        }
      }
      .about-style {
        .subhead {
          font-size: 22px;
          font-weight: 700;
        }
        .lblstyle {
          font-size: 16px;
          font-weight: 300;
        }
        .fieldstyle {
          font-size: 16px;
          padding: 4px;
        }
      }
      .bgenquery {
        background-image: url("../images/bgenquiry.webp");
        background-size: cover;
        background-repeat: no-repeat;
        height: 300px;
        background-color: #beebf8;
        border-radius: 20px;
        padding: 84px 0px;
      }
      .earnings {
        .earningtxt {
          color: #333;
          font-size: 18px;
          font-weight: 600;
        }
        .earningcard {
          border-radius: 12px;
          background: #2b4bb6;
          padding: 80px 54px;
        }

        .normaltxt {
          color: #fff;
          font-size: 22px;
          font-weight: 300;
        }
      }
      .aboutsub {
        color: #fff;
        font-size: 22px;
        font-weight: 600;
      }
      .lastsection {
        background-size: cover;
        background-repeat: no-repeat;
        .womanimg {
          height: 579px;
        }
        .lastpanelcard {
          border-radius: 14px;
          background: #2b4bb5;
          font-size: 32px;
          font-weight: 700;
          color: #fff;
        }
        .pargraph {
          color: #2b4bb5;
          text-align: center;
          font-size: 30px;
          font-weight: 700;
        }
        .txtstyle {
          color: #222;
          font-size: 20px;
          font-weight: 500;
          line-height: 36px;
        }
      }
      .tblstyle{
        .tablestyle{ 
          box-shadow: 2px 7px 8px #dcdcdc;
          th{
          background-image: linear-gradient(180deg, #6578b8,#a1e6fe);
          color:#000;
         
        }
        body{
          border: 1px solid;
        }
      }
        .table-striped>tbody>tr:nth-of-type(odd)>* {
          --bs-table-color-type: var(--bs-table-striped-color);
          --bs-table-bg-type: #ddf6ff9e;
        }
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .testimonial-fra {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 88px;

      .home-style {
        .styleform {
          border-radius: 12px;
          border: 1px solid #c9e3f5;
          background: #eff7fd;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
          padding: 20px 20px;
        }

        .tagline {
          color: #222;
          font-family: "Be Vietnam Pro";
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
        }
        .bnlandingimg {
          width: 100%;
        }
        .logoimg {
          height: 82px;
          text-align: center;
          animation: slide-up 0.5s ease-in-out;
        }
        .headtext {
          font-size: 32px;
          font-weight: 700;
          animation: slide-up 0.4s ease-in-out;
        }
        .badgestyle {
          background-color: #2b4bb6;
          color: #ffffff;
          border-radius: 34px;
          height: 40px;
          padding: 8px;
          box-shadow: 2px 9px #7ca7c87d;
          animation: slide-up 0.6s ease-in-out;
        }
        .badgestylegreen {
          background-color: #3ebf1b;
          color: #ffffff;
          border-radius: 34px;
          height: 40px;
          padding: 8px;
          box-shadow: 2px 9px #2dd10045;
          animation: slide-up 0.6s ease-in-out;
        }
      }

      .ourmilestone {
        font-style: normal;
        font-family: "Be Vietnam Pro", sans-serif;
        .cardstyle {
          height: 480px;
          margin-right: 20px;
        }
        .cardstyle1 {
          height: 350px;
          box-shadow: 0 0 10px #ccc;
          margin-bottom: 20px;
          .badgesince {
            background-color: #beebf8;
            color: #000;
            font-size: 16px;
            font-weight: 500;
            padding: 3px 10px;
            border-radius: 52px;
            width: fit-content;
          }
        }
        .cardhead {
          font-size: 24px;
          font-weight: 700;
        }
        .cardtext {
          font-size: 16px;
          font-weight: 300;
        }
        .whyusimg {
          height: 85px;
        }
        .whyuscard {
          position: relative;
          overflow: hidden;
          background-color: #f9f9f9; /* Default background color */
          transition: background-color 0.3s ease;
          box-shadow: 0 0 10px #ccc;
          padding: 37px 0px;
          height: 260px;
        }
        .whyuscard::before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background-color: #a1e6fe; /* The hover background color */
          transition: left 0.4s ease;
          z-index: 0; /* Make sure it's behind the text */
        }
        .whyuscard:hover::before {
          left: 0;
        }
        .whyuscard .content {
          position: relative;
          z-index: 1; /* Ensure the content stays above the background */
        }
        .quote {
          font-size: 60px;
          color: #f58220;
        }
        .subtitlehead {
          font-size: 16px;
          text-align: center;
          font-weight: 700;
        }
      }
      .about-style {
        .subhead {
          font-size: 22px;
          font-weight: 700;
        }
        .lblstyle {
          font-size: 16px;
          font-weight: 300;
        }
        .fieldstyle {
          font-size: 16px;
          padding: 4px;
        }
      }
      .bgenquery {
        background-image: url("../images/bgenquiry.webp");
        background-size: cover;
        background-repeat: no-repeat;
        height: 232px;
        background-color: #beebf8;
        border-radius: 20px;
        padding: 50px 0px;
      }
      .earnings {
        .earningtxt {
          color: #333;
          font-size: 16px;
          font-weight: 600;
        }
        .earningcard {
          border-radius: 12px;
          background: #2b4bb6;
          padding: 91px 54px;
        }
        .normaltxt {
          color: #fff;
          font-size: 20px;
          font-weight: 300;
        }
      }
      .aboutsub {
        color: #fff;
        font-size: 20px;
        font-weight: 600;
      }
      .lastsection {
        background-size: cover;
        background-repeat: no-repeat;
        .womanimg {
          height: 560px;
        }
        .lastpanelcard {
          border-radius: 14px;
          background: #2b4bb5;
          font-size: 25px;
          font-weight: 700;
          color: #fff;
        }
        .pargraph {
          color: #2b4bb5;
          text-align: center;
          font-size: 24px;
          font-weight: 700;
        }
        .txtstyle {
          color: #222;
          font-size: 18px;
          font-weight: 500;
          line-height: 36px;
        }
      }
      .tblstyle{
        .tablestyle{ 
          box-shadow: 2px 7px 8px #dcdcdc;
          th{
          background-image: linear-gradient(180deg, #6578b8,#a1e6fe);
          color:#000;
         
        }
        body{
          border: 1px solid;
        }
      }
        .table-striped>tbody>tr:nth-of-type(odd)>* {
          --bs-table-color-type: var(--bs-table-striped-color);
          --bs-table-bg-type: #ddf6ff9e;
        }
      }
    }
  }
  @media (min-width: 1369px) and (max-width: 1440px) {
    .testimonial-fra {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 92px;

      .home-style {
        .styleform {
          border-radius: 12px;
          border: 1px solid #c9e3f5;
          background: #eff7fd;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
          padding: 20px 25px;
        }
    .tagline {
          color: #222;
          font-family: "Be Vietnam Pro";
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
        }
        .bnlandingimg {
          width: 100%;
        }
        .logoimg {
          height: 82px;
          text-align: center;
          animation: slide-up 0.5s ease-in-out;
        }
        .headtext {
          font-size: 34px;
          font-weight: 700;
          animation: slide-up 0.4s ease-in-out;
        }
        .badgestyle {
          background-color: #2b4bb6;
          color: #ffffff;
          border-radius: 34px;
          height: 28px;
          padding: 4px;
          box-shadow: 2px 9px #7ca7c87d;
          animation: slide-up 0.6s ease-in-out;
        }
        .badgestylegreen {
          background-color: #3ebf1b;
          color: #ffffff;
          border-radius: 34px;
          height: 28px;
          padding: 4px;
          box-shadow: 2px 9px #2dd10045;
          animation: slide-up 0.6s ease-in-out;
        }
      }

      .ourmilestone {
        font-style: normal;
        font-family: "Be Vietnam Pro", sans-serif;
        .cardstyle {
          height: 480px;
          margin-right: 20px;
        }
        .cardstyle1 {
          height: 305px;
          box-shadow: 0 0 10px #ccc;
          margin-bottom: 20px;
          .badgesince {
            background-color: #beebf8;
            color: #000;
            font-size: 12px;
            font-weight: 500;
            padding: 3px 10px;
            border-radius: 52px;
            width: fit-content;
          }
        }
        .cardhead {
          font-size: 20px;
          font-weight: 700;
        }
        .cardtext {
          font-size: 14px;
          font-weight: 300;
        }
        .whyusimg {
          height: 85px;
        }
        .whyuscard {
          position: relative;
          overflow: hidden;
          background-color: #f9f9f9; /* Default background color */
          transition: background-color 0.3s ease;
          box-shadow: 0 0 10px #ccc;
          padding: 30px 0px;
          height: 260px;
        }
        .whyuscard::before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background-color: #a1e6fe; /* The hover background color */
          transition: left 0.4s ease;
          z-index: 0; /* Make sure it's behind the text */
        }
        .whyuscard:hover::before {
          left: 0;
        }
        .whyuscard .content {
          position: relative;
          z-index: 1; /* Ensure the content stays above the background */
        }
        .quote {
          font-size: 60px;
          color: #f58220;
        }
        .subtitlehead {
          font-size: 14px;
          text-align: center;
          font-weight: 700;
        }
      }
      .about-style {
        .subhead {
          font-size: 19px;
          font-weight: 700;
        }
        .lblstyle {
          font-size: 14px;
          font-weight: 300;
        }
        .fieldstyle {
          font-size: 14px;
          padding: 4px;
        }
      }
      .bgenquery {
        background-image: url("../images/bgenquiry.webp");
        background-size: cover;
        background-repeat: no-repeat;
        height: 300px;
        background-color: #beebf8;
        border-radius: 20px;
        padding: 84px 0px;
      }
      .tblstyle{
        .tablestyle{ 
          box-shadow: 2px 7px 8px #dcdcdc;
          th{
          background-image: linear-gradient(180deg, #6578b8,#a1e6fe);
          color:#000;
         
        }
        body{
          border: 1px solid;
        }
      }
        .table-striped>tbody>tr:nth-of-type(odd)>* {
          --bs-table-color-type: var(--bs-table-striped-color);
          --bs-table-bg-type: #ddf6ff9e;
        }
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1368px) {
    .testimonial-fra {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 83px;
      .home-style {
        .styleform {
          border-radius: 12px;
          border: 1px solid #c9e3f5;
          background: #eff7fd;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
          padding: 15px 15px;
        }

        .tagline {
          color: #222;
          font-family: "Be Vietnam Pro";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
        }
        .bnlandingimg {
          width: 100%;
        }
        .logoimg {
          height: 82px;
          text-align: center;
          animation: slide-up 0.5s ease-in-out;
        }
        .headtext {
          font-size: 30px;
          font-weight: 700;
          animation: slide-up 0.4s ease-in-out;
        }
        .badgestyle {
          background-color: #2b4bb6;
          color: #ffffff;
          border-radius: 34px;
          height: 28px;
          padding: 4px;
          box-shadow: 2px 9px #7ca7c87d;
          animation: slide-up 0.6s ease-in-out;
        }
        .badgestylegreen {
          background-color: #3ebf1b;
          color: #ffffff;
          border-radius: 34px;
          height: 28px;
          padding: 4px;
          box-shadow: 2px 9px #2dd10045;
          animation: slide-up 0.6s ease-in-out;
        }
      }

      .ourmilestone {
        font-style: normal;
        font-family: "Be Vietnam Pro", sans-serif;
        .cardstyle {
          height: 480px;
          margin-right: 20px;
        }
        .cardstyle1 {
          height: 305px;
          box-shadow: 0 0 10px #ccc;
          margin-bottom: 20px;
          .badgesince {
            background-color: #beebf8;
            color: #000;
            font-size: 12px;
            font-weight: 500;
            padding: 3px 10px;
            border-radius: 52px;
            width: fit-content;
          }
        }
        .cardhead {
          font-size: 20px;
          font-weight: 700;
        }
        .cardtext {
          font-size: 14px;
          font-weight: 300;
        }
        .whyusimg {
          height: 75px;
        }
        .whyuscard {
          position: relative;
          overflow: hidden;
          background-color: #f9f9f9; /* Default background color */
          transition: background-color 0.3s ease;
          box-shadow: 0 0 10px #ccc;
          padding: 25px 0px;
          height: 260px;
        }
        .whyuscard::before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background-color: #a1e6fe; /* The hover background color */
          transition: left 0.4s ease;
          z-index: 0; /* Make sure it's behind the text */
        }
        .whyuscard:hover::before {
          left: 0;
        }
        .whyuscard .content {
          position: relative;
          z-index: 1; /* Ensure the content stays above the background */
        }
        .quote {
          font-size: 50px;
          color: #f58220;
        }
        .subtitlehead {
          font-size: 14px;
          text-align: center;
          font-weight: 700;
        }
      }
      .about-style {
        .subhead {
          font-size: 18px;
          font-weight: 700;
        }
        .lblstyle {
          font-size: 14px;
          font-weight: 300;
        }
        .fieldstyle {
          font-size: 14px;
          padding: 4px;
        }
      }
      .bgenquery {
        background-image: url("../images/bgenquiry.webp");
        background-size: cover;
        background-repeat: no-repeat;
        height: 220px;
        background-color: #beebf8;
        border-radius: 20px;
        padding: 55px 0px;
      }
      .tblstyle{
        .tablestyle{ 
          box-shadow: 2px 7px 8px #dcdcdc;
          th{
          background-image: linear-gradient(180deg, #6578b8,#a1e6fe);
          color:#000;
         
        }
        body{
          border: 1px solid;
        }
      }
        .table-striped>tbody>tr:nth-of-type(odd)>* {
          --bs-table-color-type: var(--bs-table-striped-color);
          --bs-table-bg-type: #ddf6ff9e;
        }
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .testimonial-fra {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 72px;
      .home-style {
        .styleform {
          border-radius: 12px;
          border: 1px solid #c9e3f5;
          background: #eff7fd;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
          padding: 10px 15px;
        }

        .tagline {
          color: #222;
          font-family: "Be Vietnam Pro";
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
        }
        .bnlandingimg {
          width: 100%;
        }
        .logoimg {
          height: 82px;
          text-align: center;
          animation: slide-up 0.5s ease-in-out;
        }
        .headtext {
          font-size: 22px;
          font-weight: 700;
          animation: slide-up 0.4s ease-in-out;
        }
        .badgestyle {
          background-color: #2b4bb6;
          color: #ffffff;
          border-radius: 34px;
          height: 28px;
          padding: 4px;
          box-shadow: 2px 9px #7ca7c87d;
          animation: slide-up 0.6s ease-in-out;
        }
        .badgestylegreen {
          background-color: #3ebf1b;
          color: #ffffff;
          border-radius: 34px;
          height: 28px;
          padding: 4px;
          box-shadow: 2px 9px #2dd10045;
          animation: slide-up 0.6s ease-in-out;
        }
      }

      .ourmilestone {
        font-style: normal;
        font-family: "Be Vietnam Pro", sans-serif;
        .cardstyle {
          height: 480px;
          margin-right: 20px;
        }
        .cardstyle1 {
          height: 270px;
          box-shadow: 0 0 10px #ccc;
          margin-bottom: 20px;
          .badgesince {
            background-color: #beebf8;
            color: #000;
            font-size: 12px;
            font-weight: 500;
            padding: 2px 10px;
            border-radius: 52px;
            width: fit-content;
          }
        }
        .cardhead {
          font-size: 18px;
          font-weight: 700;
        }
        .cardtext {
          font-size: 12px;
          font-weight: 300;
        }
        .whyusimg {
          height: 70px;
        }
        .whyuscard {
          position: relative;
          overflow: hidden;
          background-color: #f9f9f9; /* Default background color */
          transition: background-color 0.3s ease;
          box-shadow: 0 0 10px #ccc;
          padding: 25px 0px;
          height: 215px;
        }
        .whyuscard::before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background-color: #a1e6fe; /* The hover background color */
          transition: left 0.4s ease;
          z-index: 0; /* Make sure it's behind the text */
        }
        .whyuscard:hover::before {
          left: 0;
        }
        .whyuscard .content {
          position: relative;
          z-index: 1; /* Ensure the content stays above the background */
        }
        .quote {
          font-size: 40px;
          color: #f58220;
        }
        .subtitlehead {
          font-size: 14px;
          text-align: center;
          font-weight: 700;
        }
      }
      .about-style {
        .subhead {
          font-size: 16px;
          font-weight: 700;
        }
        .lblstyle {
          font-size: 12px;
          font-weight: 300;
        }
        .fieldstyle {
          font-size: 12px;
          padding: 4px;
          // margin-bottom: -10px;
        }
      }
      .bgenquery {
        background-image: url("../images/bgenquiry.webp");
        background-size: cover;
        background-repeat: no-repeat;
        height: 195px;
        background-color: #beebf8;
        border-radius: 20px;
        padding: 55px 0px;
      }
      .earnings {
        .earningtxt {
          color: #333;
          font-size: 12px;
          font-weight: 600;
        }
        .earningcard {
          border-radius: 12px;
          background: #2b4bb6;
          padding: 73px 54px;
        }

        .normaltxt {
          color: #fff;
          font-size: 16px;
          font-weight: 300;
        }
      }
      .aboutsub {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
      }
      .lastsection {
        background-size: cover;
        background-repeat: no-repeat;
        .womanimg {
          height: 450px;
        }
        .lastpanelcard {
          border-radius: 14px;
          background: #2b4bb5;
          font-size: 19px;
          font-weight: 700;
          color: #fff;
        }
        .pargraph {
          color: #2b4bb5;
          text-align: center;
          font-size: 19px;
          font-weight: 700;
        }
        .txtstyle {
          color: #222;
          font-size: 16px;
          font-weight: 500;
          line-height: 36px;
        }
      }
      .tblstyle{
        .tablestyle{ 
          box-shadow: 2px 7px 8px #dcdcdc;
          th{
          background-image: linear-gradient(180deg, #6578b8,#a1e6fe);
          color:#000;
         
        }
        body{
          border: 1px solid;
        }
      }
        .table-striped>tbody>tr:nth-of-type(odd)>* {
          --bs-table-color-type: var(--bs-table-striped-color);
          --bs-table-bg-type: #ddf6ff9e;
        }
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .testimonial-fra {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      width: 100%;
      margin-top: 72px;
      .invalid-feedback{
        font-size: 12px;
      }
      .home-style {
        .styleform {
          border-radius: 12px;
          border: 1px solid #c9e3f5;
          background: #eff7fd;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
          padding: 10px 15px;
        }

        .tagline {
          color: #222;
          font-family: "Be Vietnam Pro";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
        }
        .bnlandingimg {
          width: 100%;
          height: 390px;
        }
        .logoimg {
          height: 82px;
          text-align: center;
          animation: slide-up 0.5s ease-in-out;
        }
        .headtext {
          font-size: 22px;
          font-weight: 700;
          animation: slide-up 0.4s ease-in-out;
        }
        .badgestyle {
          background-color: #2b4bb6;
          color: #ffffff;
          border-radius: 34px;
          height: 26px;
          padding: 4px;
          box-shadow: 2px 9px #7ca7c87d;
          animation: slide-up 0.6s ease-in-out;
        }
        .badgestylegreen {
          background-color: #3ebf1b;
          color: #ffffff;
          border-radius: 34px;
          height: 26px;
          padding: 4px;
          box-shadow: 2px 9px #2dd10045;
          animation: slide-up 0.6s ease-in-out;
        }
      }

      .ourmilestone {
        font-style: normal;
        font-family: "Be Vietnam Pro", sans-serif;
        .cardstyle {
          height: 480px;
          margin-right: 20px;
        }
        .cardstyle1 {
          height: 285px;
          box-shadow: 0 0 10px #ccc;
          margin-bottom: 20px;
          .badgesince {
            background-color: #beebf8;
            color: #000;
            font-size: 12px;
            font-weight: 500;
            padding: 2px 10px;
            border-radius: 52px;
            width: fit-content;
          }
        }
        .cardhead {
          font-size: 18px;
          font-weight: 700;
        }
        .cardtext {
          font-size: 12px;
          font-weight: 300;
        }
        .whyusimg {
          height: 60px;
        }
        .whyuscard {
          position: relative;
          overflow: hidden;
          background-color: #f9f9f9; /* Default background color */
          transition: background-color 0.3s ease;
          box-shadow: 0 0 10px #ccc;
          padding: 25px 0px;
          height: 260px;
        }
        .whyuscard::before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background-color: #a1e6fe; /* The hover background color */
          transition: left 0.4s ease;
          z-index: 0; /* Make sure it's behind the text */
        }
        .whyuscard:hover::before {
          left: 0;
        }
        .whyuscard .content {
          position: relative;
          z-index: 1; /* Ensure the content stays above the background */
        }
        .quote {
          font-size: 40px;
          color: #f58220;
        }
        .subtitlehead {
          font-size: 11px;
          text-align: center;
          font-weight: 700;
        }
      }
      .about-style {
        .subhead {
          font-size: 14px;
          font-weight: 700;
        }
        .lblstyle {
          font-size: 12px;
          font-weight: 300;
        }
        .fieldstyle {
          font-size: 12px;
          padding: 4px;
        }
      }
      .bgenquery {
        background-image: url("../images/bgenquiry.webp");
        background-size: cover;
        background-repeat: no-repeat;
        height: 195px;
        background-color: #beebf8;
        border-radius: 20px;
        padding: 55px 0px;
      }
      .tblstyle{
        .tablestyle{ 
          box-shadow: 2px 7px 8px #dcdcdc;
          th{
          background-image: linear-gradient(180deg, #6578b8,#a1e6fe);
          color:#000;
         
        }
        body{
          border: 1px solid;
        }
      }
        .table-striped>tbody>tr:nth-of-type(odd)>* {
          --bs-table-color-type: var(--bs-table-striped-color);
          --bs-table-bg-type: #ddf6ff9e;
        }
      }
    }
  }
}

@media   (max-width: 389px) {
  .testimonial-fra {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 92px;

    .home-style {
      .styleform {
        margin: 20px 10px;
        border-radius: 12px;
        border: 1px solid #c9e3f5;
        background: #eff7fd;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
        padding: 20px 20px;
      }

      .tagline {
        color: #222;
        font-family: "Be Vietnam Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        margin: 0px 10px;
      }
      .bnlandingimg {
        display: none;
      }
      .logoimg {
        height: 82px;
        text-align: center;
        animation: slide-up 0.5s ease-in-out;
      }
      .headtext {
        font-size: 21px;
        font-weight: 700;
        animation: slide-up 0.4s ease-in-out;
        margin: 0px 10px;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: none;
        font-size: 14px;
        margin: 5px 10px 0px 10px;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: none;
        font-size: 14px;
        margin: 0px 10px;
      }
    }

    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      margin: 0px 10px;
      .cardstyle {
        height: 480px;
        margin-right: 20px;
      }
      .cardstyle1 {
        height: auto;
        box-shadow: 0 0 10px #ccc;
        margin-bottom: 20px;
        .badgesince {
          background-color: #beebf8;
          color: #000;
          font-size: 12px;
          font-weight: 500;
          padding: 3px 10px;
          border-radius: 52px;
          width: fit-content;
        }
      }
      .cardhead {
        font-size: 18px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 14px;
        font-weight: 300;
      }
      .whyusimg {
        height: 65px;
      }
      .whyuscard {
        position: relative;
        overflow: hidden;
        background-color: #f9f9f9; /* Default background color */
        transition: background-color 0.3s ease;
        box-shadow: 0 0 10px #ccc;
        height: 175px;
      }
      .whyuscard::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background-color: #a1e6fe; /* The hover background color */
        transition: left 0.4s ease;
        z-index: 0; /* Make sure it's behind the text */
      }
      .whyuscard:hover::before {
        left: 0;
      }
      .whyuscard .content {
        position: relative;
        z-index: 1; /* Ensure the content stays above the background */
      }
      .quote {
        font-size: 40px;
        color: #f58220;
      }
      .subtitlehead {
        font-size: 14px;
        text-align: center;
        font-weight: 700;
      }
    }
    .about-style {
      .subhead {
        font-size: 24px;
        font-weight: 700;
      }
      .lblstyle {
        font-size: 14px;
        font-weight: 300;
      }
      .fieldstyle {
        font-size: 14px;
        padding: 4px;
      }
    }
    .bgenquery {
      background-image: url("../images/bgenquiry.webp");
      background-size: cover;
      background-repeat: no-repeat;
      height: 150px;
      background-color: #beebf8;
      border-radius: 20px;
      padding: 15px 0px;
      margin: 0px 10px;
    }
    .earnings {
      margin: 0px 10px;
      .earningtxt {
        color: #333;
        font-size: 14px;
        font-weight: 600;
      }
      .earningcard {
        border-radius: 12px;
        background: #2b4bb6;
        padding: 50px 30px;
      }

      .normaltxt {
        color: #fff;
        font-size: 22px;
        font-weight: 300;
      }
    }
    .aboutsub {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
    }
    .lastsection {
      background-size: cover;
      background-repeat: no-repeat;
      padding: 0px 10px;
      .womanimg {
        height: 400px;
      }
      .lastpanelcard {
        border-radius: 14px;
        background: #2b4bb5;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
      }
      .pargraph {
        color: #2b4bb5;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
      }
      .txtstyle {
        color: #222;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }
    .tblstyle{
      .tablestyle{ 
        box-shadow: 2px 7px 8px #dcdcdc;
        th{
        background-image: linear-gradient(180deg, #6578b8,#a1e6fe);
        color:#000;
       
      }
      body{
        border: 1px solid;
      }
    }
      .table-striped>tbody>tr:nth-of-type(odd)>* {
        --bs-table-color-type: var(--bs-table-striped-color);
        --bs-table-bg-type: #ddf6ff9e;
      }
    }
  }
}
@media (min-width: 390px) and (max-width: 415px) {
  .testimonial-fra {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 92px;
    .home-style {
      .styleform {
        margin: 20px 10px;
        border-radius: 12px;
        border: 1px solid #c9e3f5;
        background: #eff7fd;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
        padding: 20px 20px;
      }
     .tagline {
        color: #222;
        font-family: "Be Vietnam Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        margin: 0px 10px;
      }
      .bnlandingimg {
        display: none;
      }
      .logoimg {
        height: 82px;
        text-align: center;
        animation: slide-up 0.5s ease-in-out;
      }
      .headtext {
        font-size: 21px;
        font-weight: 700;
        animation: slide-up 0.4s ease-in-out;
        margin: 0px 10px;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: none;
        font-size: 14px;
        margin: 5px 10px 0px 10px;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: none;
        font-size: 14px;
        margin: 0px 10px;
      }
    }

    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      margin: 0px 10px;
      .cardstyle {
        height: 480px;
        margin-right: 20px;
      }
      .cardstyle1 {
        height: auto;
        box-shadow: 0 0 10px #ccc;
        margin-bottom: 20px;
        .badgesince {
          background-color: #beebf8;
          color: #000;
          font-size: 12px;
          font-weight: 500;
          padding: 3px 10px;
          border-radius: 52px;
          width: fit-content;
        }
      }
      .cardhead {
        font-size: 18px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 14px;
        font-weight: 300;
      }
      .whyusimg {
        height: 65px;
      }
      .whyuscard {
        position: relative;
        overflow: hidden;
        background-color: #f9f9f9; /* Default background color */
        transition: background-color 0.3s ease;
        box-shadow: 0 0 10px #ccc;
        height: 205px;
      }
      .whyuscard::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background-color: #a1e6fe; /* The hover background color */
        transition: left 0.4s ease;
        z-index: 0; /* Make sure it's behind the text */
      }
      .whyuscard:hover::before {
        left: 0;
      }
      .whyuscard .content {
        position: relative;
        z-index: 1; /* Ensure the content stays above the background */
      }
      .quote {
        font-size: 40px;
        color: #f58220;
      }
      .subtitlehead {
        font-size: 14px;
        text-align: center;
        font-weight: 700;
      }
    }
    .about-style {
      .subhead {
        font-size: 24px;
        font-weight: 700;
      }
      .lblstyle {
        font-size: 14px;
        font-weight: 300;
      }
      .fieldstyle {
        font-size: 14px;
        padding: 4px;
      }
    }
    .bgenquery {
      background-image: url("../images/bgenquiry.webp");
      background-size: cover;
      background-repeat: no-repeat;
      height: 150px;
      background-color: #beebf8;
      border-radius: 20px;
      padding: 15px 0px;
      margin: 0px 10px;
    }
    .earnings {
      margin: 0px 10px;
      .earningtxt {
        color: #333;
        font-size: 14px;
        font-weight: 600;
      }
      .earningcard {
        border-radius: 12px;
        background: #2b4bb6;
        padding: 50px 30px;
      }

      .normaltxt {
        color: #fff;
        font-size: 22px;
        font-weight: 300;
      }
    }
    .aboutsub {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
    }
    .lastsection {
      background-size: cover;
      background-repeat: no-repeat;
      padding: 0px 10px;
      .womanimg {
        height: 400px;
      }
      .lastpanelcard {
        border-radius: 14px;
        background: #2b4bb5;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
      }
      .pargraph {
        color: #2b4bb5;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
      }
      .txtstyle {
        color: #222;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }
    .tblstyle{
      .tablestyle{ 
        box-shadow: 2px 7px 8px #dcdcdc;
        th{
        background-image: linear-gradient(180deg, #6578b8,#a1e6fe);
        color:#000;
       
      }
      body{
        border: 1px solid;
      }
    }
      .table-striped>tbody>tr:nth-of-type(odd)>* {
        --bs-table-color-type: var(--bs-table-striped-color);
        --bs-table-bg-type: #ddf6ff9e;
      }
    }
  }
}
@media (min-width: 416px) and (max-width: 430px) {
  .testimonial-fra {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 92px;
    .home-style {
      .styleform {
        margin: 20px 10px;
        border-radius: 12px;
        border: 1px solid #c9e3f5;
        background: #eff7fd;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
        padding: 20px 20px;
      }

      .tagline {
        color: #222;
        font-family: "Be Vietnam Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        margin: 0px 10px;
      }
      .bnlandingimg {
        display: none;
      }
      .logoimg {
        height: 82px;
        text-align: center;
        animation: slide-up 0.5s ease-in-out;
      }
      .headtext {
        font-size: 21px;
        font-weight: 700;
        animation: slide-up 0.4s ease-in-out;
        margin: 0px 10px;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: none;
        font-size: 14px;
        margin: 5px 10px 0px 10px;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: none;
        font-size: 14px;
        margin: 0px 10px;
      }
    }

    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      margin: 0px 10px;
      .cardstyle {
        height: 480px;
        margin-right: 20px;
      }
      .cardstyle1 {
        height: auto;
        box-shadow: 0 0 10px #ccc;
        margin-bottom: 20px;
        .badgesince {
          background-color: #beebf8;
          color: #000;
          font-size: 12px;
          font-weight: 500;
          padding: 3px 10px;
          border-radius: 52px;
          width: fit-content;
        }
      }
      .cardhead {
        font-size: 18px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 14px;
        font-weight: 300;
      }
      .whyusimg {
        height: 65px;
      }
      .whyuscard {
        position: relative;
        overflow: hidden;
        background-color: #f9f9f9; /* Default background color */
        transition: background-color 0.3s ease;
        box-shadow: 0 0 10px #ccc;
        height: 210px;
      }
      .whyuscard::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background-color: #a1e6fe; /* The hover background color */
        transition: left 0.4s ease;
        z-index: 0; /* Make sure it's behind the text */
      }
      .whyuscard:hover::before {
        left: 0;
      }
      .whyuscard .content {
        position: relative;
        z-index: 1; /* Ensure the content stays above the background */
      }
      .quote {
        font-size: 40px;
        color: #f58220;
      }
      .subtitlehead {
        font-size: 14px;
        text-align: center;
        font-weight: 700;
      }
    }
    .about-style {
      .subhead {
        font-size: 24px;
        font-weight: 700;
      }
      .lblstyle {
        font-size: 14px;
        font-weight: 300;
      }
      .fieldstyle {
        font-size: 14px;
        padding: 4px;
      }
    }
    .bgenquery {
      background-image: url("../images/bgenquiry.webp");
      background-size: cover;
      background-repeat: no-repeat;
      height: 150px;
      background-color: #beebf8;
      border-radius: 20px;
      padding: 15px 0px;
      margin: 0px 10px;
    }
    .earnings {
      margin: 0px 10px;
      .earningtxt {
        color: #333;
        font-size: 14px;
        font-weight: 600;
      }
      .earningcard {
        border-radius: 12px;
        background: #2b4bb6;
        padding: 50px 30px;
      }

      .normaltxt {
        color: #fff;
        font-size: 22px;
        font-weight: 300;
      }
    }
    .aboutsub {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
    }
    .lastsection {
      background-size: cover;
      background-repeat: no-repeat;
      padding: 0px 10px;
      .womanimg {
        height: 400px;
      }
      .lastpanelcard {
        border-radius: 14px;
        background: #2b4bb5;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
      }
      .pargraph {
        color: #2b4bb5;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
      }
      .txtstyle {
        color: #222;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }
    .tblstyle{
      .tablestyle{ 
        box-shadow: 2px 7px 8px #dcdcdc;
        th{
        background-image: linear-gradient(180deg, #6578b8,#a1e6fe);
        color:#000;
       
      }
      body{
        border: 1px solid;
      }
    }
      .table-striped>tbody>tr:nth-of-type(odd)>* {
        --bs-table-color-type: var(--bs-table-striped-color);
        --bs-table-bg-type: #ddf6ff9e;
      }
    }
  }
}
@media (min-width: 431px) and (max-width: 540px) {
  .testimonial-fra {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 92px;
    .home-style {
      .styleform {
        margin: 20px 10px;
        border-radius: 12px;
        border: 1px solid #c9e3f5;
        background: #eff7fd;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
        padding: 20px 20px;
      }

      .tagline {
        color: #222;
        font-family: "Be Vietnam Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        margin: 0px 10px;
      }
      .bnlandingimg {
        display: none;
      }
      .logoimg {
        height: 82px;
        text-align: center;
        animation: slide-up 0.5s ease-in-out;
      }
      .headtext {
        font-size: 21px;
        font-weight: 700;
        animation: slide-up 0.4s ease-in-out;
        margin: 0px 10px;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: none;
        font-size: 14px;
        margin: 5px 10px 0px 10px;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: none;
        font-size: 14px;
        margin: 0px 10px;
      }
    }

    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      margin: 0px 10px;
      .cardstyle {
        height: 480px;
        margin-right: 20px;
      }
      .cardstyle1 {
        height: auto;
        box-shadow: 0 0 10px #ccc;
        margin-bottom: 20px;
        .badgesince {
          background-color: #beebf8;
          color: #000;
          font-size: 12px;
          font-weight: 500;
          padding: 3px 10px;
          border-radius: 52px;
          width: fit-content;
        }
      }
      .cardhead {
        font-size: 18px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 14px;
        font-weight: 300;
      }
      .whyusimg {
        height: 65px;
      }
      .whyuscard {
        position: relative;
        overflow: hidden;
        background-color: #f9f9f9; /* Default background color */
        transition: background-color 0.3s ease;
        box-shadow: 0 0 10px #ccc;
        height: 185px;
      }
      .whyuscard::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background-color: #a1e6fe; /* The hover background color */
        transition: left 0.4s ease;
        z-index: 0; /* Make sure it's behind the text */
      }
      .whyuscard:hover::before {
        left: 0;
      }
      .whyuscard .content {
        position: relative;
        z-index: 1; /* Ensure the content stays above the background */
      }
      .quote {
        font-size: 40px;
        color: #f58220;
      }
      .subtitlehead {
        font-size: 14px;
        text-align: center;
        font-weight: 700;
      }
    }
    .about-style {
      .subhead {
        font-size: 24px;
        font-weight: 700;
      }
      .lblstyle {
        font-size: 14px;
        font-weight: 300;
      }
      .fieldstyle {
        font-size: 14px;
        padding: 4px;
      }
    }
    .bgenquery {
      background-image: url("../images/bgenquiry.webp");
      background-size: cover;
      background-repeat: no-repeat;
      height: 150px;
      background-color: #beebf8;
      border-radius: 20px;
      padding: 15px 0px;
      margin: 0px 10px;
    }
    .earnings {
      margin: 0px 10px;
      .earningtxt {
        color: #333;
        font-size: 14px;
        font-weight: 600;
      }
      .earningcard {
        border-radius: 12px;
        background: #2b4bb6;
        padding: 50px 30px;
      }

      .normaltxt {
        color: #fff;
        font-size: 22px;
        font-weight: 300;
      }
    }
    .aboutsub {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
    }
    .lastsection {
      background-size: cover;
      background-repeat: no-repeat;
      padding: 0px 10px;
      .womanimg {
        height: 400px;
      }
      .lastpanelcard {
        border-radius: 14px;
        background: #2b4bb5;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
      }
      .pargraph {
        color: #2b4bb5;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
      }
      .txtstyle {
        color: #222;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }
    .tblstyle{
      .tablestyle{ 
        box-shadow: 2px 7px 8px #dcdcdc;
        th{
        background-image: linear-gradient(180deg, #6578b8,#a1e6fe);
        color:#000;
       
      }
      body{
        border: 1px solid;
      }
    }
      .table-striped>tbody>tr:nth-of-type(odd)>* {
        --bs-table-color-type: var(--bs-table-striped-color);
        --bs-table-bg-type: #ddf6ff9e;
      }
    }
  }
}
@media (min-width: 541px) and (max-width: 767px) {
  .testimonial-fra {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 92px;

    .home-style {
      .styleform {
        margin: 20px 10px;
        border-radius: 12px;
        border: 1px solid #c9e3f5;
        background: #eff7fd;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
        padding: 20px 20px;
      }

      .tagline {
        color: #222;
        font-family: "Be Vietnam Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        margin: 0px 10px;
      }
      .bnlandingimg {
        display: none;
      }
      .logoimg {
        height: 82px;
        text-align: center;
        animation: slide-up 0.5s ease-in-out;
      }
      .headtext {
        font-size: 21px;
        font-weight: 700;
        animation: slide-up 0.4s ease-in-out;
        margin: 0px 10px;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: none;
        font-size: 14px;
        margin: 5px 10px 0px 10px;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: none;
        font-size: 14px;
        margin: 0px 10px;
      }
    }

    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      margin: 0px 10px;
      .cardstyle {
        height: 480px;
        margin-right: 20px;
      }
      .cardstyle1 {
        height: auto;
        box-shadow: 0 0 10px #ccc;
        margin-bottom: 20px;
        .badgesince {
          background-color: #beebf8;
          color: #000;
          font-size: 12px;
          font-weight: 500;
          padding: 3px 10px;
          border-radius: 52px;
          width: fit-content;
        }
      }
      .cardhead {
        font-size: 18px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 14px;
        font-weight: 300;
      }
      .whyusimg {
        height: 65px;
      }
      .whyuscard {
        position: relative;
        overflow: hidden;
        background-color: #f9f9f9; /* Default background color */
        transition: background-color 0.3s ease;
        box-shadow: 0 0 10px #ccc;
        height: 210px;
      }
      .whyuscard::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background-color: #a1e6fe; /* The hover background color */
        transition: left 0.4s ease;
        z-index: 0; /* Make sure it's behind the text */
      }
      .whyuscard:hover::before {
        left: 0;
      }
      .whyuscard .content {
        position: relative;
        z-index: 1; /* Ensure the content stays above the background */
      }
      .quote {
        font-size: 40px;
        color: #f58220;
      }
      .subtitlehead {
        font-size: 16px;
        text-align: center;
        font-weight: 700;
      }
    }
    .about-style {
      .subhead {
        font-size: 24px;
        font-weight: 700;
      }
      .lblstyle {
        font-size: 14px;
        font-weight: 300;
      }
      .fieldstyle {
        font-size: 14px;
        padding: 4px;
      }
    }
    .bgenquery {
      background-image: url("../images/bgenquiry.webp");
      background-size: cover;
      background-repeat: no-repeat;
      height: 165px;
      background-color: #beebf8;
      border-radius: 20px;
      padding: 15px 0px;
      margin: 0px 10px;
    }
    .earnings {
      margin: 0px 10px;
      .earningtxt {
        color: #333;
        font-size: 14px;
        font-weight: 600;
      }
      .earningcard {
        border-radius: 12px;
        background: #2b4bb6;
        padding: 50px 30px;
      }

      .normaltxt {
        color: #fff;
        font-size: 22px;
        font-weight: 300;
      }
    }
    .aboutsub {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
    }
    .lastsection {
      background-size: cover;
      background-repeat: no-repeat;
      padding: 0px 10px;
      .womanimg {
        height: 400px;
      }
      .lastpanelcard {
        border-radius: 14px;
        background: #2b4bb5;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
      }
      .pargraph {
        color: #2b4bb5;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
      }
      .txtstyle {
        color: #222;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }
    .tblstyle{
      .tablestyle{ 
        box-shadow: 2px 7px 8px #dcdcdc;
        th{
        background-image: linear-gradient(180deg, #6578b8,#a1e6fe);
        color:#000;
       
      }
      body{
        border: 1px solid;
      }
    }
      .table-striped>tbody>tr:nth-of-type(odd)>* {
        --bs-table-color-type: var(--bs-table-striped-color);
        --bs-table-bg-type: #ddf6ff9e;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .testimonial-fra {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    width: 100%;
    margin-top: 72px;
    .invalid-feedback{
      font-size: 12px;
    }
    .home-style {
      .styleform {
        border-radius: 12px;
        border: 1px solid #c9e3f5;
        background: #eff7fd;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
        padding: 10px 15px;
      }
  

      .tagline {
        color: #222;
        font-family: "Be Vietnam Pro";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
      }
      .bnlandingimg {
        width: 100%;
        // height: 320px;
        height: -webkit-fill-available;
      }
      .logoimg {
        height: 82px;
        text-align: center;
        animation: slide-up 0.5s ease-in-out;
      }
      .headtext {
        font-size: 16px;
        font-weight: 700;
        animation: slide-up 0.4s ease-in-out;
      }
      .badgestyle {
        background-color: #2b4bb6;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: 2px 9px #7ca7c87d;
        animation: slide-up 0.6s ease-in-out;
        font-size: 13px;
      }
      .badgestylegreen {
        background-color: #3ebf1b;
        color: #ffffff;
        border-radius: 34px;
        height: 30px;
        padding: 4px;
        box-shadow: 2px 9px #2dd10045;
        animation: slide-up 0.6s ease-in-out;
        font-size: 13px;
      }
    }

    .ourmilestone {
      font-style: normal;
      font-family: "Be Vietnam Pro", sans-serif;
      margin: 0px 10px;
      .cardstyle {
        height: 480px;
        margin-right: 20px;
      }
      .cardstyle1 {
        height: 360px;
        box-shadow: 0 0 10px #ccc;
        margin-bottom: 20px;
        .badgesince {
          background-color: #beebf8;
          color: #000;
          font-size: 12px;
          font-weight: 500;
          padding: 2px 10px;
          border-radius: 52px;
          width: fit-content;
        }
      }
      .cardhead {
        font-size: 18px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 14px;
        font-weight: 300;
      }
      .whyusimg {
        height: 60px;
      }
      .whyuscard {
        position: relative;
        overflow: hidden;
        background-color: #f9f9f9; /* Default background color */
        transition: background-color 0.3s ease;
        box-shadow: 0 0 10px #ccc;
        padding: 25px 0px;
        height: 185px;
      }
      .whyuscard::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background-color: #a1e6fe; /* The hover background color */
        transition: left 0.4s ease;
        z-index: 0; /* Make sure it's behind the text */
      }
      .whyuscard:hover::before {
        left: 0;
      }
      .whyuscard .content {
        position: relative;
        z-index: 1; /* Ensure the content stays above the background */
      }
      .quote {
        font-size: 40px;
        color: #f58220;
      }
      .subtitlehead {
        font-size: 11px;
        text-align: center;
        font-weight: 700;
      }
    }
    .about-style {
      .subhead {
        font-size: 14px;
        font-weight: 700;
      }
      .lblstyle {
        font-size: 12px;
        font-weight: 300;
      }
      .fieldstyle {
        font-size: 12px;
        padding: 4px;
        // margin-bottom: -10px;
      }
    }
    .bgenquery {
      background-image: url("../images/bgenquiry.webp");
      background-size: cover;
      background-repeat: no-repeat;
      height: 168px;
      background-color: #beebf8;
      border-radius: 20px;
      padding: 17px 0px;
      margin: 0px 10px;
    }
    .earnings {
      margin: 0px 10px;
      .earningtxt {
        color: #333;
        font-size: 18px;
        font-weight: 600;
      }
      .earningcard {
        border-radius: 12px;
        background: #2b4bb6;
        padding: 80px 54px;
      }

      .normaltxt {
        color: #fff;
        font-size: 22px;
        font-weight: 300;
      }
    }
    .aboutsub {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
    }
    .lastsection {
      background-size: cover;
      background-repeat: no-repeat;
      padding: 0px 10px;
      .womanimg {
        height: 250px;
      }
      .lastpanelcard {
        border-radius: 14px;
        background: #2b4bb5;
        font-size: 15px;
        font-weight: 700;
        color: #fff;
      }
      .pargraph {
        color: #2b4bb5;
        text-align: center;
        font-size: 15px;
        font-weight: 700;
      }
      .txtstyle {
        color: #222;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }
    .tblstyle{
      .tablestyle{ 
        box-shadow: 2px 7px 8px #dcdcdc;
        th{
        background-image: linear-gradient(180deg, #6578b8,#a1e6fe);
        color:#000;
       
      }
      body{
        border: 1px solid;
      }
    }
      .table-striped>tbody>tr:nth-of-type(odd)>* {
        --bs-table-color-type: var(--bs-table-striped-color);
        --bs-table-bg-type: #ddf6ff9e;
      }
    }
  }
}
