@media (-webkit-min-device-pixel-ratio: 1) and (-webkit-max-device-pixel-ratio: 1.24) {
  .about-style {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 92px;
    .logostyle {
      width: 100%;
      height: max-content;
      object-fit: contain;
      object-position: left;
      padding: 150px 0px;
    }
    .about {
      background-color: #2b4bb6;
      font-size: 40px;
      font-weight: 800;
      color: #fff;
    }
    .head {
      font-size: 36px;
      font-weight: 700;
      text-align: center;
      color: #000;
      @media  (max-width: 415px) {
        font-size: 32px;
      }
      @media (min-width: 992px) and (max-width: 1280px) {
        font-size: 30px;
      }
      @media (min-width: 1281px) and (max-width: 1368px) {
        font-size: 34px;
      }
    }
    .text-style {
      font-size: 18px;
      font-weight: 300;
      color: #000;
    }
    .boldstyle {
      font-weight: 700;
    }
    .imgstyle {
      height: 86px;
      margin-bottom: 20px;
    }
    .visionhead {
      font-size: 24px;
      font-weight: 500;
      color: #fff;
    }
    .visiontext {
      font-size: 18px;
      font-weight: 200;
      color: #fff;
    }

    .commonSizeImagesStyle {
      height: 134px;
    }
    .arrowStyle {
      height: 50px;
    }
    .arrowStyle1 {
      height: 34px;
    }
    .newfont {
      font-size: 24px;
      color: #00599d;
      font-family: "Indie Flower", cursive;
    }
    .subhead {
      font-size: 24px;
      font-weight: 700;
    }
    .circle {
      color: #2b4bb6;
      // padding: 20px;
      height: 18px;
      border-radius: 50%;
      background-color: #2b4bb6;
      width: 18px;
    }
    .vl {
      height: 150px;
      padding: 8px;
    }
    @-moz-document url-prefix() {
      .vl {
        width: 18px; /* Specific width for Firefox */
      }
    }
    .adminstyle {
      height: 430px;
    }
    .adminhead {
      font-size: 18px;
      font-weight: 800;
    }
    .admintxt {
      font-size: 18px;
      font-weight: 300;
    }
    .admin-img {
      height: 348px;
      border-radius: 32px 32px 32px 0px;
    }
    .btnstyle {
      background-color: transparent;
      color: #2b4bb6;
      border-radius: 6px;
      border: 1px solid #bdc6cb;
      margin-bottom: 10px;
      &:hover,
      &:focus {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
      }
    }

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      color: #2b4bb6;
      background-color: #beebf8;
      border-color: transparent;
      margin-bottom: 10px;
      border-radius: 6px;
    }
    .btnstyle-active {
      background-color: #beebf8 !important;
      color: #2b4bb6 !important;
      border-radius: 6px;
      border: transparent;
      margin-bottom: 10px;
    }
    .contactimg {
      height: 520px;
      text-align: center;
    }
    .contacthead {
      color: #2b4bb6;
      font-size: 20px;
      font-weight: 700;
    }
    .contactico {
      width: 16px;
      height: 16px;
    }
    .direction {
      background-color: #2b4bb6;
      text-align: center;
    }
    .directionhead {
      font-size: 24px;
      color: #2b4bb6;
      font-weight: 800;
      padding: 10px;
    }
    .directiontext {
      font-size: 18px;
      font-weight: 300;
      text-align: right;
      padding: 10px;
    }
    .contactstyle {
      padding: 15px;
      box-shadow: 8px 8px 5px #eaf7fc;
      margin-bottom: 15px;
    }
    .socialico {
      color: #2b4bb6;
      border: 1px solid #bdc6cb;
      padding: 10px;
      border-radius: 50%;
      height: 18px;
    }
    .textstyle {
      font-size: 18px;
      font-weight: 300;
    }
    .socialtxt {
      font-size: 18px;
    }
    .shareit {
      border: 1px solid #bdc6cb;
      padding: 2px 10px 2px 0px;
      border-radius: 40px;
    }
    .sendmail {
      font-size: 18px;
      font-weight: 800;
      color: #000;
    }

    // .sticky-card {
    //     // top: 20px; /* Adjust this based on your needs */
    //       width: auto;
    //         position: fixed;
    //         overflow: hidden;
    //         // top: 10px;
    //         z-index: 999;
    //         animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;

    //   }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .about-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 92px;
      .logostyle {
        width: 100%;
        height: max-content;
        object-fit: contain;
        object-position: left;
        padding: 150px 0px;
      }
      .about {
        background-color: #2b4bb6;
        font-size: 40px;
        font-weight: 800;
        color: #fff;
      }
      .head {
        font-size: 36px;
        font-weight: 700;
        text-align: center;
        color: #000;
      }
      .text-style {
        font-size: 18px;
        font-weight: 300;
        color: #000;
      }
      .sendmail {
        font-size: 18px;
        font-weight: 800;
        color: #000;
      }
      .socialico {
        color: #2b4bb6;
        border: 1px solid #bdc6cb;
        padding: 10px;
        border-radius: 50%;
        height: 18px;
      }
      .textstyle {
        font-size: 18px;
        font-weight: 300;
      }
      .socialtxt {
        font-size: 18px;
      }
      .shareit {
        border: 1px solid #bdc6cb;
        padding: 2px 10px 2px 0px;
        border-radius: 40px;
      }
      .boldstyle {
        font-weight: 700;
      }

      .imgstyle {
        height: 86px;
        margin-bottom: 20px;
      }
      .visionhead {
        font-size: 18px;
        font-weight: 500;
        color: #fff;
      }
      .visiontext {
        font-size: 18px;
        font-weight: 200;
        color: #fff;
      }
      .commonSizeImagesStyle {
        height: 134px;
      }
      .arrowStyle {
        height: 50px;
      }
      .arrowStyle1 {
        height: 34px;
      }
      .newfont {
        font-size: 24px;
        color: #00599d;
        font-family: "Indie Flower", cursive;
      }
      .subhead {
        font-size: 24px;
        font-weight: 700;
      }
      .circle {
        color: #2b4bb6;
        // padding: 20px;
        height: 18px;
        border-radius: 50%;
        background-color: #2b4bb6;
        width: 18px;
      }
      .vl {
        height: 150px;
        padding: 8px;
      }

      .adminstyle {
        height: 375px;
      }
      .adminhead {
        font-size: 18px;
        font-weight: 800;
      }
      .admintxt {
        font-size: 18px;
        font-weight: 300;
      }
      .admin-img {
        height: 300px;
        border-radius: 32px 32px 32px 0px;
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid #bdc6cb;
        margin-bottom: 10px;
        font-size: 18px;
        padding: 5px;
        &:hover,
        &:focus {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          border: transparent;
          margin-bottom: 10px;
          font-size: 18px;
          padding: 5px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 18px;
        padding: 5px;
        margin-bottom: 10px;
        border-radius: 6px;
      }
      .btnstyle-active {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
        padding: 5px;
        font-size: 18px;
      }
      .contactimg {
        height: 500px;
        text-align: center;
      }
      .contacthead {
        color: #2b4bb6;
        font-size: 18px;
        font-weight: 700;
      }
      .contactico {
        width: 16px;
        height: 16px;
      }
      .direction {
        background-color: #2b4bb6;
        text-align: center;
      }
      .directionhead {
        font-size: 24px;
        color: #2b4bb6;
        font-weight: 800;
        padding: 10px;
      }
      .directiontext {
        font-size: 18px;
        font-weight: 300;
        text-align: right;
        padding: 10px;
      }
      .contactstyle {
        padding: 10px;
        box-shadow: 8px 8px 5px #eaf7fc;
        margin-bottom: 15px;
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .about-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 92px;
      .logostyle {
        width: 100%;
        height: max-content;
        object-fit: contain;
        object-position: left;
        padding: 115px 0px;
      }
      .about {
        background-color: #2b4bb6;
        font-size: 38px;
        font-weight: 800;
        color: #fff;
      }
      .head {
        font-size: 36px;
        font-weight: 700;
        text-align: center;
        color: #000;
      }
      .text-style {
        font-size: 16px;
        font-weight: 300;
        color: #000;
      }
      .sendmail {
        font-size: 16px;
        font-weight: 800;
        color: #000;
      }
      .socialico {
        color: #2b4bb6;
        border: 1px solid #bdc6cb;
        padding: 10px;
        border-radius: 50%;
        height: 18px;
      }
      .textstyle {
        font-size: 16px;
        font-weight: 300;
      }
      .socialtxt {
        font-size: 16px;
      }
      .shareit {
        border: 1px solid #bdc6cb;
        padding: 2px 10px 2px 0px;
        border-radius: 40px;
      }
      .boldstyle {
        font-weight: 700;
      }
  
      .imgstyle {
        height: 80px;
        margin-bottom: 20px;
      }
      .visionhead {
        font-size: 18px;
        font-weight: 500;
        color: #fff;
      }
      .visiontext {
        font-size: 16px;
        font-weight: 200;
        color: #fff;
      }
      .commonSizeImagesStyle {
        height: 134px;
      }
      .arrowStyle {
        height: 50px;
      }
      .arrowStyle1 {
        height: 34px;
      }
      .newfont {
        font-size: 24px;
        color: #00599d;
        font-family: "Indie Flower", cursive;
      }
      .subhead {
        font-size: 24px;
        font-weight: 700;
      }
      .circle {
        color: #2b4bb6;
        // padding: 20px;
        height: 18px;
        border-radius: 50%;
        background-color: #2b4bb6;
        width: 18px;
      }
      .vl {
        height: 150px;
        padding: 8px;
      }

      .adminstyle {
        height: 350px;
      }
      .adminhead {
        font-size: 16px;
        font-weight: 800;
      }
      .admintxt {
        font-size: 16px;
        font-weight: 300;
      }
      .admin-img {
        height: 270px;
        border-radius: 32px 32px 32px 0px;
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid #bdc6cb;
        margin-bottom: 10px;
        font-size: 16px;
        padding: 5px;
        &:hover,
        &:focus {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          border: transparent;
          margin-bottom: 10px;
          font-size: 16px;
          padding: 5px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 16px;
        padding: 5px;
        border-radius: 6px;
        margin-bottom: 10px;
      }
      .btnstyle-active {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
        padding: 5px;
        font-size: 16px;
      }
      .contactimg {
        height: 445px;
        text-align: center;
      }
      .contacthead {
        color: #2b4bb6;
        font-size: 16px;
        font-weight: 700;
      }
      .contactico {
        width: 16px;
        height: 16px;
      }
      .direction {
        background-color: #2b4bb6;
        text-align: center;
      }
      .directionhead {
        font-size: 24px;
        color: #2b4bb6;
        font-weight: 800;
        padding: 10px;
      }
      .directiontext {
        font-size: 16px;
        font-weight: 300;
        text-align: right;
        padding: 10px;
      }
      .contactstyle {
        padding: 10px;
        box-shadow: 8px 8px 5px #eaf7fc;
        margin-bottom: 15px;
      }
    }
  }
  @media (min-width: 1369px) and (max-width: 1440px) {
    .about-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 92px;
      .logostyle {
        width: 100%;
        height: max-content;
        object-fit: contain;
        object-position: left;
        padding: 100px 0px;
      }
      .about {
        background-color: #2b4bb6;
        font-size: 36px;
        font-weight: 800;
        color: #fff;
      }
      .head {
        font-size: 34px;
        font-weight: 700;
        text-align: center;
        color: #000;
      }
      .text-style {
        font-size: 14px;
        font-weight: 300;
        color: #000;
      }
      .sendmail {
        font-size: 14px;
        font-weight: 800;
        color: #000;
      }
      .socialico {
        color: #2b4bb6;
        border: 1px solid #bdc6cb;
        padding: 10px;
        border-radius: 50%;
        height: 18px;
      }
      .textstyle {
        font-size: 14px;
        font-weight: 300;
      }
      .socialtxt {
        font-size: 14px;
      }
      .shareit {
        border: 1px solid #bdc6cb;
        padding: 2px 10px 2px 0px;
        border-radius: 40px;
      }
      .boldstyle {
        font-weight: 700;
      }
 
      .imgstyle {
        height: 65px;
        margin-bottom: 20px;
      }
      .visionhead {
        font-size: 18px;
        font-weight: 500;
        color: #fff;
      }
      .visiontext {
        font-size: 14px;
        font-weight: 200;
        color: #fff;
      }
      .commonSizeImagesStyle {
        height: 115px;
      }
      .arrowStyle {
        height: 50px;
      }
      .arrowStyle1 {
        height: 34px;
      }
      .newfont {
        font-size: 19px;
        color: #00599d;
        font-family: "Indie Flower", cursive;
      }
      .subhead {
        font-size: 19px;
        font-weight: 700;
      }
      .circle {
        color: #2b4bb6;
        // padding: 20px;
        height: 18px;
        border-radius: 50%;
        background-color: #2b4bb6;
        width: 18px;
      }
      .vl {
        height: 150px;
        padding: 8px;
      }

      .adminstyle {
        height: 315px;
      }
      .adminhead {
        font-size: 14px;
        font-weight: 800;
      }
      .admintxt {
        font-size: 14px;
        font-weight: 300;
      }
      .admin-img {
        height: 250px;
        border-radius: 32px 32px 32px 0px;
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid #bdc6cb;
        margin-bottom: 10px;
        font-size: 14px;
        padding: 5px;
        &:hover,
        &:focus {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          border: transparent;
          margin-bottom: 10px;
          font-size: 14px;
          padding: 5px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 14px;
        padding: 5px;
        margin-bottom: 10px;
        border-radius: 6px;
      }
      .btnstyle-active {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
        padding: 5px;
        font-size: 14px;
      }
      .contactimg {
        height: 415px;
        text-align: center;
      }
      .contacthead {
        color: #2b4bb6;
        font-size: 16px;
        font-weight: 700;
      }
      .contactico {
        width: 16px;
        height: 16px;
      }
      .direction {
        background-color: #2b4bb6;
        text-align: center;
      }
      .directionhead {
        font-size: 22px;
        color: #2b4bb6;
        font-weight: 800;
        padding: 10px;
      }
      .directiontext {
        font-size: 16px;
        font-weight: 300;
        text-align: right;
        padding: 10px;
      }
      .contactstyle {
        padding: 10px;
        box-shadow: 8px 8px 5px #eaf7fc;
        margin-bottom: 15px;
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1368px) {
    .about-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 82px;
      .logostyle {
        width: 100%;
        height: max-content;
        object-fit: contain;
        object-position: left;
        padding: 100px 0px;
      }
      .about {
        background-color: #2b4bb6;
        font-size: 36px;
        font-weight: 800;
        color: #fff;
      }
      .head {
        font-size: 34px;
        font-weight: 700;
        text-align: center;
        color: #000;
      }
      .text-style {
        font-size: 14px;
        font-weight: 300;
        color: #000;
      }
      .sendmail {
        font-size: 14px;
        font-weight: 800;
        color: #000;
      }
      .socialico {
        color: #2b4bb6;
        border: 1px solid #bdc6cb;
        padding: 10px;
        border-radius: 50%;
        height: 18px;
      }
      .textstyle {
        font-size: 14px;
        font-weight: 300;
      }
      .socialtxt {
        font-size: 14px;
      }
      .shareit {
        border: 1px solid #bdc6cb;
        padding: 2px 10px 2px 0px;
        border-radius: 40px;
      }
      .boldstyle {
        font-weight: 700;
      }
      .imgstyle {
        height: 65px;
        margin-bottom: 20px;
      }
      .visionhead {
        font-size: 18px;
        font-weight: 500;
        color: #fff;
      }
      .visiontext {
        font-size: 14px;
        font-weight: 200;
        color: #fff;
      }
      .commonSizeImagesStyle {
        height: 115px;
      }
      .arrowStyle {
        height: 50px;
      }
      .arrowStyle1 {
        height: 34px;
      }
      .newfont {
        font-size: 19px;
        color: #00599d;
        font-family: "Indie Flower", cursive;
      }
      .subhead {
        font-size: 19px;
        font-weight: 700;
      }
      .circle {
        color: #2b4bb6;
        // padding: 20px;
        height: 18px;
        border-radius: 50%;
        background-color: #2b4bb6;
        width: 18px;
      }
      .vl {
        height: 150px;
        padding: 8px;
      }

      .adminstyle {
        height: 300px;
      }
      .adminhead {
        font-size: 14px;
        font-weight: 800;
      }
      .admintxt {
        font-size: 14px;
        font-weight: 300;
      }
      .admin-img {
        height: 220px;
        border-radius: 32px 32px 32px 0px;
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid #bdc6cb;
        margin-bottom: 10px;
        font-size: 14px;
        padding: 5px;
        &:hover,
        &:focus {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          border: transparent;
          margin-bottom: 10px;
          font-size: 14px;
          padding: 5px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 14px;
        padding: 5px;
        margin-bottom: 10px;
        border-radius: 6px;
      }
      .btnstyle-active {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
        padding: 5px;
        font-size: 14px;
      }
      .contactimg {
        height: 380px;
        text-align: center;
      }
      .contacthead {
        color: #2b4bb6;
        font-size: 14px;
        font-weight: 700;
      }
      .contactico {
        width: 16px;
        height: 16px;
      }
      .direction {
        background-color: #2b4bb6;
        text-align: center;
      }
      .directionhead {
        font-size: 22px;
        color: #2b4bb6;
        font-weight: 800;
        padding: 10px;
      }
      .directiontext {
        font-size: 14px;
        font-weight: 300;
        text-align: right;
        padding: 10px;
      }
      .contactstyle {
        padding: 5px;
        box-shadow: 8px 8px 5px #eaf7fc;
        margin-bottom: 15px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1280px) {
    .about-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 72px;
      .logostyle {
        width: 100%;
        height: max-content;
        object-fit: contain;
        object-position: left;
        padding: 95px 0px;
      }
      .about {
        background-color: #2b4bb6;
        font-size: 32px;
        font-weight: 800;
        color: #fff;
      }
      .head {
        font-size: 28px;
        font-weight: 700;
        text-align: center;
        color: #000;
      }
      .text-style {
        font-size: 12px;
        font-weight: 300;
        color: #000;
      }
      .sendmail {
        font-size: 12px;
        font-weight: 800;
        color: #000;
      }
      .socialico {
        color: #2b4bb6;
        border: 1px solid #bdc6cb;
        padding: 10px;
        border-radius: 50%;
        height: 17px;
      }
      .textstyle {
        font-size: 12px;
        font-weight: 300;
      }
      .socialtxt {
        font-size: 12px;
      }
      .shareit {
        border: 1px solid #bdc6cb;
        padding: 2px 10px 2px 0px;
        border-radius: 40px;
      }
      .boldstyle {
        font-weight: 700;
      }
      .imgstyle {
        height: 65px;
        margin-bottom: 20px;
      }
      .visionhead {
        font-size: 18px;
        font-weight: 500;
        color: #fff;
      }
      .visiontext {
        font-size: 12px;
        font-weight: 200;
        color: #fff;
      }
      .commonSizeImagesStyle {
        height: 115px;
      }
      .arrowStyle {
        height: 50px;
      }
      .arrowStyle1 {
        height: 34px;
      }
      .newfont {
        font-size: 18px;
        color: #00599d;
        font-family: "Indie Flower", cursive;
      }
      .subhead {
        font-size: 18px;
        font-weight: 700;
      }
      .circle {
        color: #2b4bb6;
        // padding: 20px;
        height: 18px;
        border-radius: 50%;
        background-color: #2b4bb6;
        width: 18px;
      }
      .vl {
        height: 150px;
        padding: 8px;
      }

      .adminstyle {
        height: 260px;
      }
      .adminhead {
        font-size: 12px;
        font-weight: 800;
      }
      .admintxt {
        font-size: 12px;
        font-weight: 300;
      }
      .admin-img {
        height: 200px;
        border-radius: 32px 32px 32px 0px;
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid #bdc6cb;
        margin-bottom: 10px;
        font-size: 12px;
        padding: 5px;
        &:hover,
        &:focus {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          border: transparent;
          margin-bottom: 10px;
          font-size: 12px;
          padding: 5px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 12px;
        padding: 5px;
        margin-bottom: 10px;
      }
      .btnstyle-active {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
        padding: 5px;
        font-size: 12px;
      }
      .contactimg {
        height: 320px;
        text-align: center;
      }
      .contacthead {
        color: #2b4bb6;
        font-size: 12px;
        font-weight: 700;
      }
      .contactico {
        width: 16px;
        height: 16px;
      }
      .direction {
        background-color: #2b4bb6;
        text-align: center;
      }
      .directionhead {
        font-size: 18px;
        color: #2b4bb6;
        font-weight: 800;
        padding: 10px;
      }
      .directiontext {
        font-size: 12px;
        font-weight: 300;
        text-align: right;
        padding: 10px;
      }
      .contactstyle {
        padding: 5px;
        box-shadow: 8px 8px 5px #eaf7fc;
        margin-bottom: 15px;
      }
    }
  }

  .css-1xc3v61-indicatorContainer {
    display: none !important;
  }
  .css-15lsz6c-indicatorContainer {
    display: none !important;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.49) {
  .about-style {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 92px;
    .logostyle {
      width: 100%;
      height: 120px;
      object-fit: contain;
      object-position: left;
    }
    .about {
      background-color: #2b4bb6;
      font-size: 40px;
      font-weight: 800;
      color: #fff;
    }
    .head {
      font-size: 36px;
      font-weight: 700;
      text-align: center;
      color: #000;
      @media  (max-width: 415px) {
        font-size: 32px;
      }
      @media (min-width: 992px) and (max-width: 1280px) {
        font-size: 30px;
      }
      @media (min-width: 1281px) and (max-width: 1368px) {
        font-size: 34px;
      }
    }
    .text-style {
      font-size: 18px;
      font-weight: 300;
      color: #000;
    }
    .boldstyle {
      font-weight: 700;
    }
    .imgstyle {
      height: 86px;
      margin-bottom: 20px;
    }
    .visionhead {
      font-size: 24px;
      font-weight: 500;
      color: #fff;
    }
    .visiontext {
      font-size: 18px;
      font-weight: 200;
      color: #fff;
    }

    .commonSizeImagesStyle {
      height: 134px;
    }
    .arrowStyle {
      height: 50px;
    }
    .arrowStyle1 {
      height: 34px;
    }
    .newfont {
      font-size: 24px;
      color: #00599d;
      font-family: "Indie Flower", cursive;
    }
    .subhead {
      font-size: 24px;
      font-weight: 700;
    }
    .circle {
      color: #2b4bb6;
      // padding: 20px;
      height: 18px;
      border-radius: 50%;
      background-color: #2b4bb6;
      width: 18px;
    }
    .vl {
      height: 150px;
      padding: 8px;
    }
    @-moz-document url-prefix() {
      .vl {
        width: 18px; /* Specific width for Firefox */
      }
    }
    .adminstyle {
      height: 450px;
    }
    .adminhead {
      font-size: 18px;
      font-weight: 800;
    }
    .admintxt {
      font-size: 18px;
      font-weight: 300;
    }
    .admin-img {
      height: 348px;
      border-radius: 32px 32px 32px 0px;
    }
    .btnstyle {
      background-color: transparent;
      color: #2b4bb6;
      border-radius: 6px;
      border: 1px solid #bdc6cb;
      margin-bottom: 10px;
      &:hover,
      &:focus {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
      }
    }

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      color: #2b4bb6;
      background-color: #beebf8;
      border-color: transparent;
      margin-bottom: 10px;
      border-radius: 6px;
    }
    .btnstyle-active {
      background-color: #beebf8 !important;
      color: #2b4bb6 !important;
      border-radius: 6px;
      border: transparent;
      margin-bottom: 10px;
    }
    .contactimg {
      height: 520px;
      text-align: center;
    }
    .contacthead {
      color: #2b4bb6;
      font-size: 20px;
      font-weight: 700;
    }
    .contactico {
      width: 16px;
      height: 16px;
    }
    .direction {
      background-color: #2b4bb6;
      text-align: center;
    }
    .directionhead {
      font-size: 24px;
      color: #2b4bb6;
      font-weight: 800;
      padding: 10px;
    }
    .directiontext {
      font-size: 18px;
      font-weight: 300;
      text-align: right;
      padding: 10px;
    }
    .contactstyle {
      padding: 15px;
      box-shadow: 8px 8px 5px #eaf7fc;
      margin-bottom: 15px;
    }
    .sendmail {
      font-size: 18px;
      font-weight: 800;
      color: #000;
    }
    .socialico {
      color: #2b4bb6;
      border: 1px solid #bdc6cb;
      padding: 10px;
      border-radius: 50%;
      height: 18px;
    }
    .textstyle {
      font-size: 18px;
      font-weight: 300;
    }
    .socialtxt {
      font-size: 18px;
    }
    .shareit {
      border: 1px solid #bdc6cb;
      padding: 2px 10px 2px 0px;
      border-radius: 40px;
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .about-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 92px;
      .logostyle {
        width: 100%;
        height: 110px;
        object-fit: contain;
        object-position: left;
      }
      .about {
        background-color: #2b4bb6;
        font-size: 40px;
        font-weight: 800;
        color: #fff;
      }
      .head {
        font-size: 36px;
        font-weight: 700;
        text-align: center;
        color: #000;
      }
      .text-style {
        font-size: 18px;
        font-weight: 300;
        color: #000;
      }
      .sendmail {
        font-size: 18px;
        font-weight: 800;
        color: #000;
      }
      .boldstyle {
        font-weight: 700;
      }
      .imgstyle {
        height: 86px;
        margin-bottom: 20px;
      }
      .visionhead {
        font-size: 18px;
        font-weight: 500;
        color: #fff;
      }
      .visiontext {
        font-size: 18px;
        font-weight: 200;
        color: #fff;
      }
      .commonSizeImagesStyle {
        height: 134px;
      }
      .arrowStyle {
        height: 50px;
      }
      .arrowStyle1 {
        height: 34px;
      }
      .newfont {
        font-size: 24px;
        color: #00599d;
        font-family: "Indie Flower", cursive;
      }
      .subhead {
        font-size: 24px;
        font-weight: 700;
      }
      .circle {
        color: #2b4bb6;
        // padding: 20px;
        height: 18px;
        border-radius: 50%;
        background-color: #2b4bb6;
        width: 18px;
      }
      .vl {
        height: 150px;
        padding: 8px;
      }

      .adminstyle {
        height: 390px;
      }
      .adminhead {
        font-size: 18px;
        font-weight: 800;
      }
      .admintxt {
        font-size: 18px;
        font-weight: 300;
      }
      .admin-img {
        height: 300px;
        border-radius: 32px 32px 32px 0px;
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid #bdc6cb;
        margin-bottom: 10px;
        font-size: 18px;
        padding: 5px;
        &:hover,
        &:focus {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          border: transparent;
          margin-bottom: 10px;
          font-size: 18px;
          padding: 5px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 18px;
        padding: 5px;
        margin-bottom: 10px;
        border-radius: 6px;
      }
      .btnstyle-active {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
        padding: 5px;
        font-size: 18px;
      }
      .contactimg {
        height: 500px;
        text-align: center;
      }
      .contacthead {
        color: #2b4bb6;
        font-size: 18px;
        font-weight: 700;
      }
      .contactico {
        width: 16px;
        height: 16px;
      }
      .direction {
        background-color: #2b4bb6;
        text-align: center;
      }
      .directionhead {
        font-size: 24px;
        color: #2b4bb6;
        font-weight: 800;
        padding: 10px;
      }
      .directiontext {
        font-size: 18px;
        font-weight: 300;
        text-align: right;
        padding: 10px;
      }
      .contactstyle {
        padding: 10px;
        box-shadow: 8px 8px 5px #eaf7fc;
        margin-bottom: 15px;
      }
  
      .socialico {
        color: #2b4bb6;
        border: 1px solid #bdc6cb;
        padding: 10px;
        border-radius: 50%;
        height: 18px;
      }
      .textstyle {
        font-size: 18px;
        font-weight: 300;
      }
      .socialtxt {
        font-size: 18px;
      }
      .shareit {
        border: 1px solid #bdc6cb;
        padding: 2px 10px 2px 0px;
        border-radius: 40px;
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .about-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 87px;
      .contactimg {
        height: 430px;
        text-align: center;
      }
      .contacthead {
        color: #2b4bb6;
        font-size: 16px;
        font-weight: 700;
      }
      .contactico {
        width: 16px;
        height: 16px;
      }
      .direction {
        background-color: #2b4bb6;
        text-align: center;
      }
      .directionhead {
        font-size: 24px;
        color: #2b4bb6;
        font-weight: 800;
        padding: 10px;
      }
      .directiontext {
        font-size: 16px;
        font-weight: 300;
        text-align: right;
        padding: 10px;
      }
      .logostyle {
        width: 100%;
        height: max-content;
        object-fit: contain;
        object-position: left;
        padding: 100px 0px;
      }
      .about {
        background-color: #2b4bb6;
        font-size: 36px;
        font-weight: 800;
        color: #fff;
      }
      .head {
        font-size: 36px;
        font-weight: 700;
        text-align: center;
        color: #000;
      }
      .text-style {
        font-size: 16px;
        font-weight: 300;
        color: #000;
      }
      .sendmail {
        font-size: 16px;
        font-weight: 800;
        color: #000;
      }
      .boldstyle {
        font-weight: 700;
      }
      .imgstyle {
        height: 80px;
        margin-bottom: 20px;
      }
      .visionhead {
        font-size: 18px;
        font-weight: 500;
        color: #fff;
      }
      .visiontext {
        font-size: 16px;
        font-weight: 200;
        color: #fff;
      }
      .commonSizeImagesStyle {
        height: 134px;
      }
      .arrowStyle {
        height: 50px;
      }
      .arrowStyle1 {
        height: 34px;
      }
      .newfont {
        font-size: 24px;
        color: #00599d;
        font-family: "Indie Flower", cursive;
      }
      .subhead {
        font-size: 24px;
        font-weight: 700;
      }
      .circle {
        color: #2b4bb6;
        // padding: 20px;
        height: 18px;
        border-radius: 50%;
        background-color: #2b4bb6;
        width: 18px;
      }
      .vl {
        height: 150px;
        padding: 8px;
      }

      .adminstyle {
        height: 340px;
      }
      .adminhead {
        font-size: 16px;
        font-weight: 800;
      }
      .admintxt {
        font-size: 16px;
        font-weight: 300;
      }
      .admin-img {
        height: 270px;
        border-radius: 32px 32px 32px 0px;
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid #bdc6cb;
        margin-bottom: 10px;
        font-size: 16px;
        padding: 5px;
        &:hover,
        &:focus {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          border: transparent;
          margin-bottom: 10px;
          font-size: 16px;
          padding: 5px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 16px;
        padding: 5px;
        margin-bottom: 10px;
        border-radius: 6px;
      }
      .btnstyle-active {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
        padding: 5px;
        font-size: 16px;
      }
      .contactimg {
        height: 445px;
        text-align: center;
      }
      .contacthead {
        color: #2b4bb6;
        font-size: 16px;
        font-weight: 700;
      }
      .contactico {
        width: 16px;
        height: 16px;
      }
      .direction {
        background-color: #2b4bb6;
        text-align: center;
      }
      .directionhead {
        font-size: 24px;
        color: #2b4bb6;
        font-weight: 800;
        padding: 10px;
      }
      .directiontext {
        font-size: 16px;
        font-weight: 300;
        text-align: right;
        padding: 10px;
      }
      .contactstyle {
        padding: 7px;
        box-shadow: 8px 8px 5px #eaf7fc;
        margin-bottom: 15px;
      }
  
      .socialico {
        color: #2b4bb6;
        border: 1px solid #bdc6cb;
        padding: 10px;
        border-radius: 50%;
        height: 18px;
      }
      .textstyle {
        font-size: 16px;
        font-weight: 300;
      }
      .socialtxt {
        font-size: 16px;
      }
      .shareit {
        border: 1px solid #bdc6cb;
        padding: 2px 10px 2px 0px;
        border-radius: 40px;
      }
    }
  }
  @media (min-width: 1369px) and (max-width: 1440px) {
    .about-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 92px;
      .logostyle {
        width: 100%;
        height: 100px;
        object-fit: contain;
        object-position: left;
      }
      .about {
        background-color: #2b4bb6;
        font-size: 36px;
        font-weight: 800;
        color: #fff;
      }
      .head {
        font-size: 34px;
        font-weight: 700;
        text-align: center;
        color: #000;
      }
      .text-style {
        font-size: 14px;
        font-weight: 300;
        color: #000;
      }
      .sendmail {
        font-size: 14px;
        font-weight: 800;
        color: #000;
      }
      .socialico {
        color: #2b4bb6;
        border: 1px solid #bdc6cb;
        padding: 10px;
        border-radius: 50%;
        height: 18px;
      }
      .textstyle {
        font-size: 14px;
        font-weight: 300;
      }
      .socialtxt {
        font-size: 14px;
      }
      .shareit {
        border: 1px solid #bdc6cb;
        padding: 2px 10px 2px 0px;
        border-radius: 40px;
      }
      .boldstyle {
        font-weight: 700;
      }
   
      .imgstyle {
        height: 65px;
        margin-bottom: 20px;
      }
      .visionhead {
        font-size: 18px;
        font-weight: 500;
        color: #fff;
      }
      .visiontext {
        font-size: 14px;
        font-weight: 200;
        color: #fff;
      }
      .commonSizeImagesStyle {
        height: 115px;
      }
      .arrowStyle {
        height: 50px;
      }
      .arrowStyle1 {
        height: 34px;
      }
      .newfont {
        font-size: 19px;
        color: #00599d;
        font-family: "Indie Flower", cursive;
      }
      .subhead {
        font-size: 19px;
        font-weight: 700;
      }
      .circle {
        color: #2b4bb6;
        // padding: 20px;
        height: 18px;
        border-radius: 50%;
        background-color: #2b4bb6;
        width: 18px;
      }
      .vl {
        height: 150px;
        padding: 8px;
      }

      .adminstyle {
        height: 340px;
      }
      .adminhead {
        font-size: 14px;
        font-weight: 800;
      }
      .admintxt {
        font-size: 14px;
        font-weight: 300;
      }
      .admin-img {
        height: 250px;
        border-radius: 32px 32px 32px 0px;
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid #bdc6cb;
        margin-bottom: 10px;
        font-size: 14px;
        padding: 5px;
        &:hover,
        &:focus {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          border: transparent;
          margin-bottom: 10px;
          font-size: 14px;
          padding: 5px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 14px;
        padding: 5px;
        margin-bottom: 10px;
        border-radius: 6px;
      }
      .btnstyle-active {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
        padding: 5px;
        font-size: 14px;
      }
      .contactimg {
        height: 415px;
        text-align: center;
      }
      .contacthead {
        color: #2b4bb6;
        font-size: 16px;
        font-weight: 700;
      }
      .contactico {
        width: 16px;
        height: 16px;
      }
      .direction {
        background-color: #2b4bb6;
        text-align: center;
      }
      .directionhead {
        font-size: 22px;
        color: #2b4bb6;
        font-weight: 800;
        padding: 10px;
      }
      .directiontext {
        font-size: 16px;
        font-weight: 300;
        text-align: right;
        padding: 10px;
      }
      .contactstyle {
        padding: 7px;
        box-shadow: 8px 8px 5px #eaf7fc;
        margin-bottom: 15px;
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1368px) {
    .about-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 83px;
      .contactimg {
        height: 350px;
        text-align: center;
      }
      .contacthead {
        color: #2b4bb6;
        font-size: 16px;
        font-weight: 700;
      }
      .contactico {
        width: 16px;
        height: 16px;
      }
      .direction {
        background-color: #2b4bb6;
        text-align: center;
      }
      .directionhead {
        font-size: 24px;
        color: #2b4bb6;
        font-weight: 800;
        padding: 10px;
      }
      .directiontext {
        font-size: 16px;
        font-weight: 300;
        text-align: right;
        padding: 10px;
      }
      .logostyle {
        width: 100%;
        height: max-content;
        object-fit: contain;
        object-position: left;
        padding: 100px 0px;
      }
      .about {
        background-color: #2b4bb6;
        font-size: 36px;
        font-weight: 800;
        color: #fff;
      }
      .head {
        font-size: 34px;
        font-weight: 700;
        text-align: center;
        color: #000;
      }
      .text-style {
        font-size: 14px;
        font-weight: 300;
        color: #000;
      }
      .sendmail {
        font-size: 14px;
        font-weight: 800;
        color: #000;
      }
      .socialico {
        color: #2b4bb6;
        border: 1px solid #bdc6cb;
        padding: 10px;
        border-radius: 50%;
        height: 18px;
      }
      .textstyle {
        font-size: 14px;
        font-weight: 300;
      }
      .socialtxt {
        font-size: 14px;
      }
      .shareit {
        border: 1px solid #bdc6cb;
        padding: 2px 10px 2px 0px;
        border-radius: 40px;
      }
      .boldstyle {
        font-weight: 700;
      }
  
      .imgstyle {
        height: 65px;
        margin-bottom: 20px;
      }
      .visionhead {
        font-size: 18px;
        font-weight: 500;
        color: #fff;
      }
      .visiontext {
        font-size: 14px;
        font-weight: 200;
        color: #fff;
      }
      .commonSizeImagesStyle {
        height: 115px;
      }
      .arrowStyle {
        height: 50px;
      }
      .arrowStyle1 {
        height: 34px;
      }
      .newfont {
        font-size: 19px;
        color: #00599d;
        font-family: "Indie Flower", cursive;
      }
      .subhead {
        font-size: 19px;
        font-weight: 700;
      }
      .circle {
        color: #2b4bb6;
        // padding: 20px;
        height: 18px;
        border-radius: 50%;
        background-color: #2b4bb6;
        width: 18px;
      }
      .vl {
        height: 150px;
        padding: 8px;
      }

      .adminstyle {
        height: 290px;
      }
      .adminhead {
        font-size: 14px;
        font-weight: 800;
      }
      .admintxt {
        font-size: 14px;
        font-weight: 300;
      }
      .admin-img {
        height: 220px;
        border-radius: 32px 32px 32px 0px;
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid #bdc6cb;
        margin-bottom: 10px;
        font-size: 14px;
        padding: 3px;
        &:hover,
        &:focus {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          border: transparent;
          margin-bottom: 10px;
          font-size: 14px;
          padding: 3px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 14px;
        padding: 5px;
        margin-bottom: 10px;
        border-radius: 6px;
      }
      .btnstyle-active {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
        padding: 5px;
        font-size: 14px;
      }
      .contactimg {
        height: 380px;
        text-align: center;
      }
      .contacthead {
        color: #2b4bb6;
        font-size: 14px;
        font-weight: 700;
      }
      .contactico {
        width: 16px;
        height: 16px;
      }
      .direction {
        background-color: #2b4bb6;
        text-align: center;
      }
      .directionhead {
        font-size: 22px;
        color: #2b4bb6;
        font-weight: 800;
        padding: 10px;
      }
      .directiontext {
        font-size: 14px;
        font-weight: 300;
        text-align: right;
        padding: 10px;
      }
      .contactstyle {
        padding: 5px;
        box-shadow: 8px 8px 5px #eaf7fc;
        margin-bottom: 15px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1280px) {
    .about-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 72px;
      .logostyle {
        width: 100%;
        height: 80px;
        object-fit: contain;
        object-position: left;
      }
      .about {
        background-color: #2b4bb6;
        font-size: 32px;
        font-weight: 800;
        color: #fff;
      }
      .head {
        font-size: 28px;
        font-weight: 700;
        text-align: center;
        color: #000;
      }
      .text-style {
        font-size: 12px;
        font-weight: 300;
        color: #000;
      }
      .sendmail {
        font-size: 12px;
        font-weight: 800;
        color: #000;
      }
      .socialico {
        color: #2b4bb6;
        border: 1px solid #bdc6cb;
        padding: 10px;
        border-radius: 50%;
        height: 18px;
      }
      .textstyle {
        font-size: 12px;
        font-weight: 300;
      }
      .socialtxt {
        font-size: 12px;
      }
      .shareit {
        border: 1px solid #bdc6cb;
        padding: 2px 10px 2px 0px;
        border-radius: 40px;
      }
      .boldstyle {
        font-weight: 700;
      }
      .imgstyle {
        height: 65px;
        margin-bottom: 20px;
      }
      .visionhead {
        font-size: 18px;
        font-weight: 500;
        color: #fff;
      }
      .visiontext {
        font-size: 12px;
        font-weight: 200;
        color: #fff;
      }
      .commonSizeImagesStyle {
        height: 115px;
      }
      .arrowStyle {
        height: 50px;
      }
      .arrowStyle1 {
        height: 34px;
      }
      .newfont {
        font-size: 18px;
        color: #00599d;
        font-family: "Indie Flower", cursive;
      }
      .subhead {
        font-size: 18px;
        font-weight: 700;
      }
      .circle {
        color: #2b4bb6;
        // padding: 20px;
        height: 18px;
        border-radius: 50%;
        background-color: #2b4bb6;
        width: 18px;
      }
      .vl {
        height: 150px;
        padding: 8px;
      }

      .adminstyle {
        height: 260px;
      }
      .adminhead {
        font-size: 12px;
        font-weight: 800;
      }
      .admintxt {
        font-size: 12px;
        font-weight: 300;
      }
      .admin-img {
        height: 200px;
        border-radius: 32px 32px 32px 0px;
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid #bdc6cb;
        margin-bottom: 10px;
        font-size: 12px;
        padding: 5px;
        &:hover,
        &:focus {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          border: transparent;
          margin-bottom: 10px;
          font-size: 12px;
          padding: 5px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 12px;
        padding: 5px;
        margin-bottom: 10px;
        border-radius: 6px;
      }
      .btnstyle-active {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
        padding: 5px;
        font-size: 12px;
      }
      .contactimg {
        height: 320px;
        text-align: center;
      }
      .contacthead {
        color: #2b4bb6;
        font-size: 12px;
        font-weight: 700;
      }
      .contactico {
        width: 16px;
        height: 16px;
      }
      .direction {
        background-color: #2b4bb6;
        text-align: center;
      }
      .directionhead {
        font-size: 18px;
        color: #2b4bb6;
        font-weight: 800;
        padding: 10px;
      }
      .directiontext {
        font-size: 12px;
        font-weight: 300;
        text-align: right;
        padding: 10px;
      }
      .contactstyle {
        padding: 5px;
        box-shadow: 8px 8px 5px #eaf7fc;
        margin-bottom: 15px;
      }
    }
  }

  .css-1xc3v61-indicatorContainer {
    display: none !important;
  }
  .css-15lsz6c-indicatorContainer {
    display: none !important;
  }
}
@media (-webkit-min-device-pixel-ratio: 1.50) {
    .about-style {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 92px;
      .logostyle {
        width: 100%;
        height: max-content;
        object-fit: contain;
        object-position: left;
        padding: 150px 0px;
      }
      .about {
        background-color: #2b4bb6;
        font-size: 40px;
        font-weight: 800;
        color: #fff;
      }
      .head {
        font-size: 36px;
        font-weight: 700;
        text-align: center;
        color: #000;
        @media  (max-width: 415px) {
          font-size: 32px;
        }
        @media (min-width: 992px) and (max-width: 1280px) {
          font-size: 30px;
        }
        @media (min-width: 1281px) and (max-width: 1368px) {
          font-size: 34px;
        }
      }
      .text-style {
        font-size: 18px;
        font-weight: 300;
        color: #000;
      }
      .boldstyle {
        font-weight: 700;
      }
  
      .imgstyle {
        height: 86px;
        margin-bottom: 20px;
      }
      .visionhead {
        font-size: 24px;
        font-weight: 500;
        color: #fff;
      }
      .visiontext {
        font-size: 18px;
        font-weight: 200;
        color: #fff;
      }
  
      .commonSizeImagesStyle {
        height: 134px;
      }
      .arrowStyle {
        height: 50px;
      }
      .arrowStyle1 {
        height: 34px;
      }
      .newfont {
        font-size: 24px;
        color: #00599d;
        font-family: "Indie Flower", cursive;
      }
      .subhead {
        font-size: 24px;
        font-weight: 700;
      }
      .circle {
        color: #2b4bb6;
        // padding: 20px;
        height: 18px;
        border-radius: 50%;
        background-color: #2b4bb6;
        width: 18px;
      }
      .vl {
        height: 150px;
        padding: 8px;
      }
      @-moz-document url-prefix() {
        .vl {
          width: 18px; /* Specific width for Firefox */
        }
      }
      .adminstyle {
        height: 420px;
      }
      .adminhead {
        font-size: 18px;
        font-weight: 800;
      }
      .admintxt {
        font-size: 18px;
        font-weight: 300;
      }
      .admin-img {
        height: 348px;
        border-radius: 32px 32px 32px 0px;
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid #bdc6cb;
        margin-bottom: 10px;
        &:hover,
        &:focus {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          border: transparent;
          margin-bottom: 10px;
        }
      }
  
      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        margin-bottom: 10px;
        border-radius: 6px;
      }
      .btnstyle-active {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
      }
      .contactimg {
        height: 520px;
        text-align: center;
      }
      .contacthead {
        color: #2b4bb6;
        font-size: 20px;
        font-weight: 700;
      }
      .contactico {
        width: 16px;
        height: 16px;
      }
      .direction {
        background-color: #2b4bb6;
        text-align: center;
      }
      .directionhead {
        font-size: 24px;
        color: #2b4bb6;
        font-weight: 800;
        padding: 10px;
      }
      .directiontext {
        font-size: 18px;
        font-weight: 300;
        text-align: right;
        padding: 10px;
      }
      .contactstyle {
        padding: 15px;
        box-shadow: 8px 8px 5px #eaf7fc;
        margin-bottom: 15px;
      }
      .socialico {
        color: #2b4bb6;
        border: 1px solid #bdc6cb;
        padding: 10px;
        border-radius: 50%;
        height: 18px;
      }
      .textstyle {
        font-size: 18px;
        font-weight: 300;
      }
      .socialtxt {
        font-size: 18px;
      }
      .shareit {
        border: 1px solid #bdc6cb;
        padding: 2px 10px 2px 0px;
        border-radius: 40px;
      }
      .sendmail {
        font-size: 18px;
        font-weight: 800;
        color: #000;
      }
  
      // .sticky-card {
      //     // top: 20px; /* Adjust this based on your needs */
      //       width: auto;
      //         position: fixed;
      //         overflow: hidden;
      //         // top: 10px;
      //         z-index: 999;
      //         animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  
      //   }
    }
    @media (min-width: 1601px) and (max-width: 1680px) {
      .about-style {
        font-family: "Be Vietnam Pro", sans-serif;
        font-style: normal;
        margin-top: 92px;
        .logostyle {
          width: 100%;
          height: max-content;
          object-fit: contain;
          object-position: left;
          padding: 150px 0px;
        }
        .about {
          background-color: #2b4bb6;
          font-size: 40px;
          font-weight: 800;
          color: #fff;
        }
        .head {
          font-size: 36px;
          font-weight: 700;
          text-align: center;
          color: #000;
        }
        .text-style {
          font-size: 18px;
          font-weight: 300;
          color: #000;
        }
        .sendmail {
          font-size: 18px;
          font-weight: 800;
          color: #000;
        }
        .boldstyle {
          font-weight: 700;
        }
        .imgstyle {
          height: 86px;
          margin-bottom: 20px;
        }
        .visionhead {
          font-size: 18px;
          font-weight: 500;
          color: #fff;
        }
        .visiontext {
          font-size: 18px;
          font-weight: 200;
          color: #fff;
        }
        .commonSizeImagesStyle {
          height: 134px;
        }
        .arrowStyle {
          height: 50px;
        }
        .arrowStyle1 {
          height: 34px;
        }
        .newfont {
          font-size: 24px;
          color: #00599d;
          font-family: "Indie Flower", cursive;
        }
        .subhead {
          font-size: 24px;
          font-weight: 700;
        }
        .circle {
          color: #2b4bb6;
          // padding: 20px;
          height: 18px;
          border-radius: 50%;
          background-color: #2b4bb6;
          width: 18px;
        }
        .vl {
          height: 150px;
          padding: 8px;
        }
  
        .adminstyle {
          height: 390px;
        }
        .adminhead {
          font-size: 18px;
          font-weight: 800;
        }
        .admintxt {
          font-size: 18px;
          font-weight: 300;
        }
        .admin-img {
          height: 300px;
          border-radius: 32px 32px 32px 0px;
        }
        .btnstyle {
          background-color: transparent;
          color: #2b4bb6;
          border-radius: 6px;
          border: 1px solid #bdc6cb;
          margin-bottom: 10px;
          font-size: 18px;
          padding: 5px;
          &:hover,
          &:focus {
            background-color: #beebf8 !important;
            color: #2b4bb6 !important;
            border-radius: 6px;
            border: transparent;
            margin-bottom: 10px;
            font-size: 18px;
            padding: 5px;
          }
        }
  
        .btn-check:checked + .btn,
        .btn.active,
        .btn.show,
        .btn:first-child:active,
        :not(.btn-check) + .btn:active {
          color: #2b4bb6;
          background-color: #beebf8;
          border-color: transparent;
          font-size: 18px;
          padding: 5px;
          margin-bottom: 10px;
          border-radius: 6px;
        }
        .btnstyle-active {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          border: transparent;
          margin-bottom: 10px;
          padding: 5px;
          font-size: 18px;
        }
        .contactimg {
          height: 500px;
          text-align: center;
        }
        .contacthead {
          color: #2b4bb6;
          font-size: 18px;
          font-weight: 700;
        }
        .contactico {
          width: 16px;
          height: 16px;
        }
        .direction {
          background-color: #2b4bb6;
          text-align: center;
        }
        .directionhead {
          font-size: 24px;
          color: #2b4bb6;
          font-weight: 800;
          padding: 10px;
        }
        .directiontext {
          font-size: 18px;
          font-weight: 300;
          text-align: right;
          padding: 10px;
        }
        .contactstyle {
          padding: 10px;
          box-shadow: 8px 8px 5px #eaf7fc;
          margin-bottom: 15px;
        }
        .socialico {
          color: #2b4bb6;
          border: 1px solid #bdc6cb;
          padding: 10px;
          border-radius: 50%;
          height: 18px;
        }
        .textstyle {
          font-size: 18px;
          font-weight: 300;
        }
        .socialtxt {
          font-size: 18px;
        }
        .shareit {
          border: 1px solid #bdc6cb;
          padding: 2px 10px 2px 0px;
          border-radius: 40px;
        }
      
      }
    }
    @media (min-width: 1441px) and (max-width: 1600px) {
      .about-style {
        font-family: "Be Vietnam Pro", sans-serif;
        font-style: normal;
        margin-top: 92px;
        .logostyle {
          width: 100%;
          height: max-content;
          object-fit: contain;
          object-position: left;
          padding: 115px 0px;
        }
        .about {
          background-color: #2b4bb6;
          font-size: 38px;
          font-weight: 800;
          color: #fff;
        }
        .head {
          font-size: 36px;
          font-weight: 700;
          text-align: center;
          color: #000;
        }
        .text-style {
          font-size: 16px;
          font-weight: 300;
          color: #000;
        }
        .sendmail {
          font-size: 16px;
          font-weight: 800;
          color: #000;
        }
        .socialico {
          color: #2b4bb6;
          border: 1px solid #bdc6cb;
          padding: 10px;
          border-radius: 50%;
          height: 18px;
        }
        .textstyle {
          font-size: 16px;
          font-weight: 300;
        }
        .socialtxt {
          font-size: 16px;
        }
        .shareit {
          border: 1px solid #bdc6cb;
          padding: 2px 10px 2px 0px;
          border-radius: 40px;
        }
        .boldstyle {
          font-weight: 700;
        }
        .imgstyle {
          height: 80px;
          margin-bottom: 20px;
        }
        .visionhead {
          font-size: 18px;
          font-weight: 500;
          color: #fff;
        }
        .visiontext {
          font-size: 16px;
          font-weight: 200;
          color: #fff;
        }
        .commonSizeImagesStyle {
          height: 134px;
        }
        .arrowStyle {
          height: 50px;
        }
        .arrowStyle1 {
          height: 34px;
        }
        .newfont {
          font-size: 24px;
          color: #00599d;
          font-family: "Indie Flower", cursive;
        }
        .subhead {
          font-size: 24px;
          font-weight: 700;
        }
        .circle {
          color: #2b4bb6;
          // padding: 20px;
          height: 18px;
          border-radius: 50%;
          background-color: #2b4bb6;
          width: 18px;
        }
        .vl {
          height: 150px;
          padding: 8px;
        }
  
        .adminstyle {
          height: 385px;
        }
        .adminhead {
          font-size: 16px;
          font-weight: 800;
        }
        .admintxt {
          font-size: 16px;
          font-weight: 300;
        }
        .admin-img {
          height: 270px;
          border-radius: 32px 32px 32px 0px;
        }
        .btnstyle {
          background-color: transparent;
          color: #2b4bb6;
          border-radius: 6px;
          border: 1px solid #bdc6cb;
          margin-bottom: 10px;
          font-size: 16px;
          padding: 5px;
          &:hover,
          &:focus {
            background-color: #beebf8 !important;
            color: #2b4bb6 !important;
            border-radius: 6px;
            border: transparent;
            margin-bottom: 10px;
            font-size: 16px;
            padding: 5px;
          }
        }
  
        .btn-check:checked + .btn,
        .btn.active,
        .btn.show,
        .btn:first-child:active,
        :not(.btn-check) + .btn:active {
          color: #2b4bb6;
          background-color: #beebf8;
          border-color: transparent;
          font-size: 16px;
          padding: 5px;
          margin-bottom: 10px;
          border-radius: 6px;
        }
        .btnstyle-active {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          border: transparent;
          margin-bottom: 10px;
          padding: 5px;
          font-size: 16px;
        }
        .contactimg {
          height: 445px;
          text-align: center;
        }
        .contacthead {
          color: #2b4bb6;
          font-size: 16px;
          font-weight: 700;
        }
        .contactico {
          width: 16px;
          height: 16px;
        }
        .direction {
          background-color: #2b4bb6;
          text-align: center;
        }
        .directionhead {
          font-size: 24px;
          color: #2b4bb6;
          font-weight: 800;
          padding: 10px;
        }
        .directiontext {
          font-size: 16px;
          font-weight: 300;
          text-align: right;
          padding: 10px;
        }
        .contactstyle {
          padding: 10px;
          box-shadow: 8px 8px 5px #eaf7fc;
          margin-bottom: 15px;
        }
      }
    }
    @media (min-width: 1369px) and (max-width: 1440px) {
      .about-style {
        font-family: "Be Vietnam Pro", sans-serif;
        font-style: normal;
        margin-top: 92px;
        .logostyle {
          width: 100%;
          height: max-content;
          object-fit: contain;
          object-position: left;
          padding: 100px 0px;
        }
        .about {
          background-color: #2b4bb6;
          font-size: 36px;
          font-weight: 800;
          color: #fff;
        }
        .head {
          font-size: 34px;
          font-weight: 700;
          text-align: center;
          color: #000;
        }
        .text-style {
          font-size: 14px;
          font-weight: 300;
          color: #000;
        }
        .sendmail {
          font-size: 14px;
          font-weight: 800;
          color: #000;
        }
        .socialico {
          color: #2b4bb6;
          border: 1px solid #bdc6cb;
          padding: 10px;
          border-radius: 50%;
          height: 18px;
        }
        .textstyle {
          font-size: 14px;
          font-weight: 300;
        }
        .socialtxt {
          font-size: 14px;
        }
        .shareit {
          border: 1px solid #bdc6cb;
          padding: 2px 10px 2px 0px;
          border-radius: 40px;
        }
        .boldstyle {
          font-weight: 700;
        }
        .imgstyle {
          height: 65px;
          margin-bottom: 20px;
        }
        .visionhead {
          font-size: 18px;
          font-weight: 500;
          color: #fff;
        }
        .visiontext {
          font-size: 14px;
          font-weight: 200;
          color: #fff;
        }
        .commonSizeImagesStyle {
          height: 115px;
        }
        .arrowStyle {
          height: 50px;
        }
        .arrowStyle1 {
          height: 34px;
        }
        .newfont {
          font-size: 19px;
          color: #00599d;
          font-family: "Indie Flower", cursive;
        }
        .subhead {
          font-size: 19px;
          font-weight: 700;
        }
        .circle {
          color: #2b4bb6;
          // padding: 20px;
          height: 18px;
          border-radius: 50%;
          background-color: #2b4bb6;
          width: 18px;
        }
        .vl {
          height: 150px;
          padding: 8px;
        }
  
        .adminstyle {
          height: 340px;
        }
        .adminhead {
          font-size: 14px;
          font-weight: 800;
        }
        .admintxt {
          font-size: 14px;
          font-weight: 300;
        }
        .admin-img {
          height: 250px;
          border-radius: 32px 32px 32px 0px;
        }
        .btnstyle {
          background-color: transparent;
          color: #2b4bb6;
          border-radius: 6px;
          border: 1px solid #bdc6cb;
          margin-bottom: 10px;
          font-size: 14px;
          padding: 5px;
          &:hover,
          &:focus {
            background-color: #beebf8 !important;
            color: #2b4bb6 !important;
            border-radius: 6px;
            border: transparent;
            margin-bottom: 10px;
            font-size: 14px;
            padding: 5px;
          }
        }
  
        .btn-check:checked + .btn,
        .btn.active,
        .btn.show,
        .btn:first-child:active,
        :not(.btn-check) + .btn:active {
          color: #2b4bb6;
          background-color: #beebf8;
          border-color: transparent;
          font-size: 14px;
          padding: 5px;
          margin-bottom: 10px;
          border-radius: 6px;
        }
        .btnstyle-active {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          border: transparent;
          margin-bottom: 10px;
          padding: 5px;
          font-size: 14px;
        }
        .contactimg {
          height: 415px;
          text-align: center;
        }
        .contacthead {
          color: #2b4bb6;
          font-size: 16px;
          font-weight: 700;
        }
        .contactico {
          width: 16px;
          height: 16px;
        }
        .direction {
          background-color: #2b4bb6;
          text-align: center;
        }
        .directionhead {
          font-size: 22px;
          color: #2b4bb6;
          font-weight: 800;
          padding: 10px;
        }
        .directiontext {
          font-size: 16px;
          font-weight: 300;
          text-align: right;
          padding: 10px;
        }
        .contactstyle {
          padding: 10px;
          box-shadow: 8px 8px 5px #eaf7fc;
          margin-bottom: 15px;
        }
      }
    }
    @media (min-width: 1281px) and (max-width: 1368px) {
      .about-style {
        font-family: "Be Vietnam Pro", sans-serif;
        font-style: normal;
        margin-top: 82px;
        .logostyle {
          width: 100%;
          height: max-content;
          object-fit: contain;
          object-position: left;
          padding: 100px 0px;
        }
        .about {
          background-color: #2b4bb6;
          font-size: 36px;
          font-weight: 800;
          color: #fff;
        }
        .head {
          font-size: 34px;
          font-weight: 700;
          text-align: center;
          color: #000;
        }
        .text-style {
          font-size: 14px;
          font-weight: 300;
          color: #000;
        }
        .sendmail {
          font-size: 14px;
          font-weight: 800;
          color: #000;
        }
        .socialico {
          color: #2b4bb6;
          border: 1px solid #bdc6cb;
          padding: 10px;
          border-radius: 50%;
          height: 18px;
        }
        .textstyle {
          font-size: 14px;
          font-weight: 300;
        }
        .socialtxt {
          font-size: 14px;
        }
        .shareit {
          border: 1px solid #bdc6cb;
          padding: 2px 10px 2px 0px;
          border-radius: 40px;
        }
        .boldstyle {
          font-weight: 700;
        }
        .imgstyle {
          height: 65px;
          margin-bottom: 20px;
        }
        .visionhead {
          font-size: 18px;
          font-weight: 500;
          color: #fff;
        }
        .visiontext {
          font-size: 14px;
          font-weight: 200;
          color: #fff;
        }
        .commonSizeImagesStyle {
          height: 115px;
        }
        .arrowStyle {
          height: 50px;
        }
        .arrowStyle1 {
          height: 34px;
        }
        .newfont {
          font-size: 19px;
          color: #00599d;
          font-family: "Indie Flower", cursive;
        }
        .subhead {
          font-size: 19px;
          font-weight: 700;
        }
        .circle {
          color: #2b4bb6;
          // padding: 20px;
          height: 18px;
          border-radius: 50%;
          background-color: #2b4bb6;
          width: 18px;
        }
        .vl {
          height: 150px;
          padding: 8px;
        }
  
        .adminstyle {
          height: 300px;
        }
        .adminhead {
          font-size: 14px;
          font-weight: 800;
        }
        .admintxt {
          font-size: 14px;
          font-weight: 300;
        }
        .admin-img {
          height: 220px;
          border-radius: 32px 32px 32px 0px;
        }
        .btnstyle {
          background-color: transparent;
          color: #2b4bb6;
          border-radius: 6px;
          border: 1px solid #bdc6cb;
          margin-bottom: 10px;
          font-size: 14px;
          padding: 5px;
          &:hover,
          &:focus {
            background-color: #beebf8 !important;
            color: #2b4bb6 !important;
            border-radius: 6px;
            border: transparent;
            margin-bottom: 10px;
            font-size: 14px;
            padding: 5px;
          }
        }
  
        .btn-check:checked + .btn,
        .btn.active,
        .btn.show,
        .btn:first-child:active,
        :not(.btn-check) + .btn:active {
          color: #2b4bb6;
          background-color: #beebf8;
          border-color: transparent;
          font-size: 14px;
          padding: 5px;
          margin-bottom: 10px;
          border-radius: 6px;
        }
        .btnstyle-active {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          border: transparent;
          margin-bottom: 10px;
          padding: 5px;
          font-size: 14px;
        }
        .contactimg {
          height: 380px;
          text-align: center;
        }
        .contacthead {
          color: #2b4bb6;
          font-size: 14px;
          font-weight: 700;
        }
        .contactico {
          width: 16px;
          height: 16px;
        }
        .direction {
          background-color: #2b4bb6;
          text-align: center;
        }
        .directionhead {
          font-size: 22px;
          color: #2b4bb6;
          font-weight: 800;
          padding: 10px;
        }
        .directiontext {
          font-size: 14px;
          font-weight: 300;
          text-align: right;
          padding: 10px;
        }
        .contactstyle {
          padding: 5px;
          box-shadow: 8px 8px 5px #eaf7fc;
          margin-bottom: 15px;
        }
      }
    }
    @media (min-width: 992px) and (max-width: 1280px) {
      .about-style {
        font-family: "Be Vietnam Pro", sans-serif;
        font-style: normal;
        margin-top: 72px;
        .logostyle {
          width: 100%;
          height: max-content;
          object-fit: contain;
          object-position: left;
          padding: 95px 0px;
        }
        .about {
          background-color: #2b4bb6;
          font-size: 32px;
          font-weight: 800;
          color: #fff;
        }
        .head {
          font-size: 28px;
          font-weight: 700;
          text-align: center;
          color: #000;
        }
        .text-style {
          font-size: 12px;
          font-weight: 300;
          color: #000;
        }
        .sendmail {
          font-size: 12px;
          font-weight: 800;
          color: #000;
        }
        .socialico {
          color: #2b4bb6;
          border: 1px solid #bdc6cb;
          padding: 10px;
          border-radius: 50%;
          height: 18px;
        }
        .textstyle {
          font-size: 12px;
          font-weight: 300;
        }
        .socialtxt {
          font-size: 12px;
        }
        .shareit {
          border: 1px solid #bdc6cb;
          padding: 2px 10px 2px 0px;
          border-radius: 40px;
        }
        .boldstyle {
          font-weight: 700;
        }
        .imgstyle {
          height: 65px;
          margin-bottom: 20px;
        }
        .visionhead {
          font-size: 18px;
          font-weight: 500;
          color: #fff;
        }
        .visiontext {
          font-size: 12px;
          font-weight: 200;
          color: #fff;
        }
        .commonSizeImagesStyle {
          height: 115px;
        }
        .arrowStyle {
          height: 50px;
        }
        .arrowStyle1 {
          height: 34px;
        }
        .newfont {
          font-size: 18px;
          color: #00599d;
          font-family: "Indie Flower", cursive;
        }
        .subhead {
          font-size: 18px;
          font-weight: 700;
        }
        .circle {
          color: #2b4bb6;
          // padding: 20px;
          height: 18px;
          border-radius: 50%;
          background-color: #2b4bb6;
          width: 18px;
        }
        .vl {
          height: 150px;
          padding: 8px;
        }
  
        .adminstyle {
          height: 260px;
        }
        .adminhead {
          font-size: 12px;
          font-weight: 800;
        }
        .admintxt {
          font-size: 12px;
          font-weight: 300;
        }
        .admin-img {
          height: 200px;
          border-radius: 32px 32px 32px 0px;
        }
        .btnstyle {
          background-color: transparent;
          color: #2b4bb6;
          border-radius: 6px;
          border: 1px solid #bdc6cb;
          margin-bottom: 10px;
          font-size: 12px;
          padding: 5px;
          &:hover,
          &:focus {
            background-color: #beebf8 !important;
            color: #2b4bb6 !important;
            border-radius: 6px;
            border: transparent;
            margin-bottom: 10px;
            font-size: 12px;
            padding: 5px;
          }
        }
  
        .btn-check:checked + .btn,
        .btn.active,
        .btn.show,
        .btn:first-child:active,
        :not(.btn-check) + .btn:active {
          color: #2b4bb6;
          background-color: #beebf8;
          border-color: transparent;
          font-size: 12px;
          padding: 5px;
          margin-bottom: 10px;
          border-radius: 6px;
        }
        .btnstyle-active {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          border: transparent;
          margin-bottom: 10px;
          padding: 5px;
          font-size: 12px;
        }
        .contactimg {
          height: 320px;
          text-align: center;
        }
        .contacthead {
          color: #2b4bb6;
          font-size: 12px;
          font-weight: 700;
        }
        .contactico {
          width: 16px;
          height: 16px;
        }
        .direction {
          background-color: #2b4bb6;
          text-align: center;
        }
        .directionhead {
          font-size: 18px;
          color: #2b4bb6;
          font-weight: 800;
          padding: 10px;
        }
        .directiontext {
          font-size: 12px;
          font-weight: 300;
          text-align: right;
          padding: 10px;
        }
        .contactstyle {
          padding: 5px;
          box-shadow: 8px 8px 5px #eaf7fc;
          margin-bottom: 15px;
        }
      }
    }
  
    .css-1xc3v61-indicatorContainer {
      display: none !important;
    }
    .css-15lsz6c-indicatorContainer {
      display: none !important;
    }
  }
@media (min-width: 768px) and (max-width: 991px) {
  .about-style {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 87px;
    .logostyle {
      width: 100%;
      height: max-content;
      object-fit: contain;
      object-position: left;
      padding: 150px 0px;
    }
    .about {
      background-color: #2b4bb6;
      font-size: 35px;
      font-weight: 800;
      color: #fff;
    }
    .head {
      font-size: 36px;
      font-weight: 700;
      text-align: center;
      color: #000;
    }
    .text-style {
      font-size: 14px;
      font-weight: 300;
      color: #000;
    }
    .sendmail {
      font-size: 14px;
      font-weight: 800;
      color: #000;
    }
    .socialico {
      color: #2b4bb6;
      border: 1px solid #bdc6cb;
      padding: 10px;
      border-radius: 50%;
      height: 18px;
    }
    .textstyle {
      font-size: 14px;
      font-weight: 300;
    }
    .socialtxt {
      font-size: 14px;
    }
    .shareit {
      border: 1px solid #bdc6cb;
      padding: 2px 10px 2px 0px;
      border-radius: 40px;
    }
    .boldstyle {
      font-weight: 700;
    }
    .imgstyle {
      height: 86px;
      margin-bottom: 20px;
    }
    .visionhead {
      font-size: 24px;
      font-weight: 500;
      color: #fff;
    }
    .visiontext {
      font-size: 14px;
      font-weight: 200;
      color: #fff;
    }
    .commonSizeImagesStyle {
      height: 115px;
    }
    .arrowStyle {
      height: 50px;
    }
    .arrowStyle1 {
      height: 34px;
    }
    .newfont {
      font-size: 22px;
      color: #00599d;
      font-family: "Indie Flower", cursive;
    }
    .subhead {
      font-size: 22px;
      font-weight: 700;
    }
    .circle {
      color: #2b4bb6;
      // padding: 20px;
      height: 18px;
      border-radius: 50%;
      background-color: #2b4bb6;
      width: 18px;
    }
    .vl {
      height: 150px;
      padding: 8px;
    }

    .adminstyle {
      height: 450px;
    }
    .adminhead {
      font-size: 14px;
      font-weight: 800;
    }
    .admintxt {
      font-size: 14px;
      font-weight: 300;
    }
    .admin-img {
      height: 348px;
      border-radius: 32px 32px 32px 0px;
    }
    .btnstyle {
      background-color: transparent;
      color: #2b4bb6;
      border-radius: 6px;
      border: 1px solid #bdc6cb;
      margin-bottom: 10px;
      &:hover,
      &:focus {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
      }
    }

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      color: #2b4bb6;
      background-color: #beebf8;
      border-color: transparent;
      margin-bottom: 10px;
      border-radius: 6px;
    }
    .btnstyle-active {
      background-color: #beebf8 !important;
      color: #2b4bb6 !important;
      border-radius: 6px;
      border: transparent;
      margin-bottom: 10px;
    }
    .contactimg {
      height: 285px;
      text-align: center;
    }
    .contacthead {
      color: #2b4bb6;
      font-size: 20px;
      font-weight: 700;
    }
    .contactico {
      width: 16px;
      height: 16px;
    }
    .direction {
      background-color: #2b4bb6;
      text-align: center;
    }
    .directionhead {
      font-size: 24px;
      color: #2b4bb6;
      font-weight: 800;
      padding: 10px;
    }
    .directiontext {
      font-size: 18px;
      font-weight: 300;
      text-align: right;
      padding: 10px;
    }
    .contactstyle {
      padding: 10px;
      box-shadow: 8px 8px 5px #eaf7fc;
      margin-bottom: 15px;
    }
    .pdmob {
      padding: 0px 20px;
    }
  }
}
@media  (max-width: 389px) {
  .about-style {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 91px;
    .logostyle {
      width: 100%;
      height: 100px;
      object-fit: contain;
      object-position: left;
      padding: 0px;
    }
    .about {
      background-color: #2b4bb6;
      font-size: 33px;
      font-weight: 800;
      color: #fff;
    }
    .head {
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      color: #000;
    }
    .text-style {
      font-size: 14px;
      font-weight: 300;
      color: #000;
    }
    .sendmail {
      font-size: 14px;
      font-weight: 800;
      color: #000;
    }
    .socialico {
      color: #2b4bb6;
      border: 1px solid #bdc6cb;
      padding: 10px;
      border-radius: 50%;
      height: 18px;
    }
    .textstyle {
      font-size: 14px;
      font-weight: 300;
    }
    .socialtxt {
      font-size: 14px;
    }
    .shareit {
      border: 1px solid #bdc6cb;
      padding: 2px 10px 2px 0px;
      border-radius: 40px;
    }
    .boldstyle {
      font-weight: 700;
    }
    .imgstyle {
      height: 70px;
      margin-bottom: 20px;
    }
    .visionhead {
      font-size: 22px;
      font-weight: 500;
      color: #fff;
    }
    .visiontext {
      font-size: 14px;
      font-weight: 200;
      color: #fff;
    }
    .commonSizeImagesStyle {
      height: 115px;
      margin-bottom: 30px;
    }
    .arrowStyle {
      height: 0px;
    }
    .arrowStyle1 {
      height: 34px;
    }
    .newfont {
      font-size: 24px;
      color: #00599d;
      font-family: "Indie Flower", cursive;
    }
    .subhead {
      font-size: 20px;
      font-weight: 700;
    }
    .circle {
      display: none;
    }
    .vl {
      display: none;
    }

    .adminstyle {
      height: 320px;
    }
    .adminhead {
      font-size: 14px;
      font-weight: 800;
    }
    .admintxt {
      font-size: 14px;
      font-weight: 300;
    }
    .admin-img {
      height: 180px;
      border-radius: 32px 32px 32px 0px;
    }
    .btnstyle {
      background-color: transparent;
      color: #2b4bb6;
      border-radius: 6px;
      border: 1px solid #bdc6cb;
      margin-bottom: 10px;
      &:hover,
      &:focus {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
      }
    }

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      color: #2b4bb6;
      background-color: #beebf8;
      border-color: transparent;
      margin-bottom: 10px;
      border-radius: 6px;
    }
    .btnstyle-active {
      background-color: #beebf8 !important;
      color: #2b4bb6 !important;
      border-radius: 6px;
      border: transparent;
      margin-bottom: 10px;
    }
    .contactimg {
      height: 285px;
      text-align: center;
    }
    .contacthead {
      color: #2b4bb6;
      font-size: 20px;
      font-weight: 700;
    }
    .contactico {
      width: 16px;
      height: 16px;
    }
    .direction {
      background-color: #2b4bb6;
      text-align: center;
      height: 65px;
      padding-top: 7px;
    }
    .dirimg {
      height: 54px;
    }
    .directionhead {
      font-size: 14px;
      color: #2b4bb6;
      font-weight: 800;
      padding: 0px;
    }
    .directiontext {
      font-size: 12px;
      font-weight: 300;
      text-align: right;
      padding: 0px;
    }
    .contactstyle {
      padding: 10px;
      box-shadow: 8px 8px 5px #eaf7fc;
      margin-bottom: 15px;
    }
    .pdmob {
      padding: 0px 20px;
    }
  }
}
@media (min-width: 390px) and (max-width: 411px) {
  .about-style {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 87px;
    .logostyle {
      width: 100%;
      height: 100px;
      object-fit: contain;
      object-position: left;
      padding: 0px;
    }
    .about {
      background-color: #2b4bb6;
      font-size: 33px;
      font-weight: 800;
      color: #fff;
    }
    .head {
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      color: #000;
    }
    .text-style {
      font-size: 14px;
      font-weight: 300;
      color: #000;
    }
    .sendmail {
      font-size: 14px;
      font-weight: 800;
      color: #000;
    }
    .socialico {
      color: #2b4bb6;
      border: 1px solid #bdc6cb;
      padding: 10px;
      border-radius: 50%;
      height: 18px;
    }
    .textstyle {
      font-size: 14px;
      font-weight: 300;
    }
    .socialtxt {
      font-size: 14px;
    }
    .shareit {
      border: 1px solid #bdc6cb;
      padding: 2px 10px 2px 0px;
      border-radius: 40px;
    }
    .boldstyle {
      font-weight: 700;
    }
    .imgstyle {
      height: 70px;
      margin-bottom: 20px;
    }
    .visionhead {
      font-size: 22px;
      font-weight: 500;
      color: #fff;
    }
    .visiontext {
      font-size: 14px;
      font-weight: 200;
      color: #fff;
    }
    .commonSizeImagesStyle {
      height: 115px;
      margin-bottom: 30px;
    }
    .arrowStyle {
      height: 0px;
    }
    .arrowStyle1 {
      height: 34px;
    }
    .newfont {
      font-size: 24px;
      color: #00599d;
      font-family: "Indie Flower", cursive;
    }
    .subhead {
      font-size: 20px;
      font-weight: 700;
    }
    .circle {
      display: none;
    }
    .vl {
      display: none;
    }

    .adminstyle {
      height: 348px;
    }
    .adminhead {
      font-size: 14px;
      font-weight: 800;
    }
    .admintxt {
      font-size: 14px;
      font-weight: 300;
    }
    .admin-img {
      height: 200px;
      border-radius: 32px 32px 32px 0px;
    }
    .btnstyle {
      background-color: transparent;
      color: #2b4bb6;
      border-radius: 6px;
      border: 1px solid #bdc6cb;
      margin-bottom: 10px;
      &:hover,
      &:focus {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
      }
    }

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      color: #2b4bb6;
      background-color: #beebf8;
      border-color: transparent;
      margin-bottom: 10px;
      border-radius: 6px;
    }
    .btnstyle-active {
      background-color: #beebf8 !important;
      color: #2b4bb6 !important;
      border-radius: 6px;
      border: transparent;
      margin-bottom: 10px;
    }
    .contactimg {
      height: 285px;
      text-align: center;
    }
    .contacthead {
      color: #2b4bb6;
      font-size: 20px;
      font-weight: 700;
    }
    .contactico {
      width: 16px;
      height: 16px;
    }
    .direction {
      background-color: #2b4bb6;
      text-align: center;
      height: 65px;
      padding-top: 5px;
    }
    .dirimg {
      height: 54px;
    }
    .directionhead {
      font-size: 14px;
      color: #2b4bb6;
      font-weight: 800;
      padding: 0px;
    }
    .directiontext {
      font-size: 12px;
      font-weight: 300;
      text-align: right;
      padding: 0px;
    }
    .contactstyle {
      padding: 10px;
      box-shadow: 8px 8px 5px #eaf7fc;
      margin-bottom: 15px;
    }
    .pdmob {
      padding: 0px 20px;
    }
  }
}
@media (min-width: 412px) and (max-width: 429px) {
  .about-style {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 87px;
    .logostyle {
      width: 100%;
      height: 120px;
      object-fit: contain;
      object-position: left;
      padding: 0px;
    }
    .about {
      background-color: #2b4bb6;
      font-size: 33px;
      font-weight: 800;
      color: #fff;
    }
    .head {
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      color: #000;
    }
    .text-style {
      font-size: 14px;
      font-weight: 300;
      color: #000;
    }
    .sendmail {
      font-size: 14px;
      font-weight: 800;
      color: #000;
    }
    .socialico {
      color: #2b4bb6;
      border: 1px solid #bdc6cb;
      padding: 10px;
      border-radius: 50%;
      height: 18px;
    }
    .textstyle {
      font-size: 14px;
      font-weight: 300;
    }
    .socialtxt {
      font-size: 14px;
    }
    .shareit {
      border: 1px solid #bdc6cb;
      padding: 2px 10px 2px 0px;
      border-radius: 40px;
    }
    .boldstyle {
      font-weight: 700;
    }
    .imgstyle {
      height: 70px;
      margin-bottom: 20px;
    }
    .visionhead {
      font-size: 22px;
      font-weight: 500;
      color: #fff;
    }
    .visiontext {
      font-size: 14px;
      font-weight: 200;
      color: #fff;
    }
    .commonSizeImagesStyle {
      height: 115px;
      margin-bottom: 30px;
    }
    .arrowStyle {
      height: 0px;
    }
    .arrowStyle1 {
      height: 34px;
    }
    .newfont {
      font-size: 24px;
      color: #00599d;
      font-family: "Indie Flower", cursive;
    }
    .subhead {
      font-size: 20px;
      font-weight: 700;
    }
    .circle {
      display: none;
    }
    .vl {
      display: none;
    }

    .adminstyle {
      height: 370px;
    }
    .adminhead {
      font-size: 14px;
      font-weight: 800;
    }
    .admintxt {
      font-size: 14px;
      font-weight: 300;
    }
    .admin-img {
      height: 200px;
      border-radius: 32px 32px 32px 0px;
    }
    .btnstyle {
      background-color: transparent;
      color: #2b4bb6;
      border-radius: 6px;
      border: 1px solid #bdc6cb;
      margin-bottom: 10px;
      &:hover,
      &:focus {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
      }
    }

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      color: #2b4bb6;
      background-color: #beebf8;
      border-color: transparent;
      margin-bottom: 10px;
      border-radius: 6px;
    }
    .btnstyle-active {
      background-color: #beebf8 !important;
      color: #2b4bb6 !important;
      border-radius: 6px;
      border: transparent;
      margin-bottom: 10px;
    }
    .contactimg {
      height: 285px;
      text-align: center;
    }
    .contacthead {
      color: #2b4bb6;
      font-size: 20px;
      font-weight: 700;
    }
    .contactico {
      width: 16px;
      height: 16px;
    }
    .direction {
      background-color: #2b4bb6;
      text-align: center;
      height: 65px;
      padding-top: 7px;
    }
    .dirimg {
      height: 54px;
    }
    .directionhead {
      font-size: 18px;
      color: #2b4bb6;
      font-weight: 800;
      padding: 0px;
    }
    .directiontext {
      font-size: 14px;
      font-weight: 300;
      text-align: right;
      padding: 0px;
    }
    .contactstyle {
      padding: 10px;
      box-shadow: 8px 8px 5px #eaf7fc;
      margin-bottom: 15px;
    }
    .pdmob {
      padding: 0px 20px;
    }
  }
}
@media (min-width: 430px) and (max-width: 539px) {
  .about-style {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 87px;
    .logostyle {
      width: 100%;
      height: 120px;
      object-fit: contain;
      object-position: left;
      padding: 0px;
    }
    .about {
      background-color: #2b4bb6;
      font-size: 33px;
      font-weight: 800;
      color: #fff;
    }
    .head {
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      color: #000;
    }
    .text-style {
      font-size: 14px;
      font-weight: 300;
      color: #000;
    }
    .sendmail {
      font-size: 14px;
      font-weight: 800;
      color: #000;
    }
    .socialico {
      color: #2b4bb6;
      border: 1px solid #bdc6cb;
      padding: 10px;
      border-radius: 50%;
      height: 18px;
    }
    .textstyle {
      font-size: 14px;
      font-weight: 300;
    }
    .socialtxt {
      font-size: 14px;
    }
    .shareit {
      border: 1px solid #bdc6cb;
      padding: 2px 10px 2px 0px;
      border-radius: 40px;
    }
    .boldstyle {
      font-weight: 700;
    }
    .imgstyle {
      height: 70px;
      margin-bottom: 20px;
    }
    .visionhead {
      font-size: 22px;
      font-weight: 500;
      color: #fff;
    }
    .visiontext {
      font-size: 14px;
      font-weight: 200;
      color: #fff;
    }
    .commonSizeImagesStyle {
      height: 115px;
      margin-bottom: 30px;
    }
    .arrowStyle {
      height: 0px;
    }
    .arrowStyle1 {
      height: 34px;
    }
    .newfont {
      font-size: 24px;
      color: #00599d;
      font-family: "Indie Flower", cursive;
    }
    .subhead {
      font-size: 20px;
      font-weight: 700;
    }
    .circle {
      display: none;
    }
    .vl {
      display: none;
    }

    .adminstyle {
      height: 385px;
    }
    .adminhead {
      font-size: 14px;
      font-weight: 800;
    }
    .admintxt {
      font-size: 14px;
      font-weight: 300;
    }
    .admin-img {
      height: 200px;
      border-radius: 32px 32px 32px 0px;
    }
    .btnstyle {
      background-color: transparent;
      color: #2b4bb6;
      border-radius: 6px;
      border: 1px solid #bdc6cb;
      margin-bottom: 10px;
      &:hover,
      &:focus {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
      }
    }

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      color: #2b4bb6;
      background-color: #beebf8;
      border-color: transparent;
      margin-bottom: 10px;
      border-radius: 6px;
    }
    .btnstyle-active {
      background-color: #beebf8 !important;
      color: #2b4bb6 !important;
      border-radius: 6px;
      border: transparent;
      margin-bottom: 10px;
    }
    .contactimg {
      height: 285px;
      text-align: center;
    }
    .contacthead {
      color: #2b4bb6;
      font-size: 20px;
      font-weight: 700;
    }
    .contactico {
      width: 16px;
      height: 16px;
    }
    .direction {
      background-color: #2b4bb6;
      text-align: center;
      height: 65px;
      padding-top: 7px;
    }
    .dirimg {
      height: 54px;
    }
    .directionhead {
      font-size: 18px;
      color: #2b4bb6;
      font-weight: 800;
      padding: 0px;
    }
    .directiontext {
      font-size: 14px;
      font-weight: 300;
      text-align: right;
      padding: 0px;
    }
    .contactstyle {
      padding: 10px;
      box-shadow: 8px 8px 5px #eaf7fc;
      margin-bottom: 15px;
    }
    .pdmob {
      padding: 0px 20px;
    }
  }
}
@media (min-width: 540px) and (max-width: 767px) {
  .about-style {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 87px;
    .logostyle {
      width: 100%;
      height: 120px;
      object-fit: contain;
      object-position: left;
      padding: 0px;
    }
    .about {
      background-color: #2b4bb6;
      font-size: 33px;
      font-weight: 800;
      color: #fff;
    }
    .centerstyle {
      justify-content: center !important;
    }
    .head {
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      color: #000;
    }
    .text-style {
      font-size: 16px;
      font-weight: 300;
      color: #000;
    }
    .sendmail {
      font-size: 16px;
      font-weight: 800;
      color: #000;
    }
    .socialico {
      color: #2b4bb6;
      border: 1px solid #bdc6cb;
      padding: 10px;
      border-radius: 50%;
      height: 18px;
    }
    .textstyle {
      font-size: 16px;
      font-weight: 300;
    }
    .socialtxt {
      font-size: 16px;
    }
    .shareit {
      border: 1px solid #bdc6cb;
      padding: 2px 10px 2px 0px;
      border-radius: 40px;
    }
    .boldstyle {
      font-weight: 700;
    }
 
    .imgstyle {
      height: 70px;
      margin-bottom: 20px;
    }
    .visionhead {
      font-size: 22px;
      font-weight: 500;
      color: #fff;
    }
    .visiontext {
      font-size: 16px;
      font-weight: 200;
      color: #fff;
    }
    .commonSizeImagesStyle {
      height: 115px;
      margin-bottom: 30px;
    }
    .arrowStyle {
      height: 0px;
    }
    .arrowStyle1 {
      height: 34px;
    }
    .newfont {
      font-size: 24px;
      color: #00599d;
      font-family: "Indie Flower", cursive;
    }
    .subhead {
      font-size: 20px;
      font-weight: 700;
    }
    .circle {
      display: none;
    }
    .vl {
      display: none;
    }

    .adminstyle {
      height: 488px;
    }
    .adminhead {
      font-size: 16px;
      font-weight: 800;
    }
    .admintxt {
      font-size: 16px;
      font-weight: 300;
    }
    .admin-img {
      height: 225px;
      border-radius: 32px 32px 32px 0px;
    }
    .btnstyle {
      background-color: transparent;
      color: #2b4bb6;
      border-radius: 6px;
      border: 1px solid #bdc6cb;
      margin-bottom: 10px;
      &:hover,
      &:focus {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
      }
    }

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      color: #2b4bb6;
      background-color: #beebf8;
      border-color: transparent;
      margin-bottom: 10px;
      border-radius: 6px;
    }
    .btnstyle-active {
      background-color: #beebf8 !important;
      color: #2b4bb6 !important;
      border-radius: 6px;
      border: transparent;
      margin-bottom: 10px;
    }
    .contactimg {
      height: 285px;
      text-align: center;
    }
    .contacthead {
      color: #2b4bb6;
      font-size: 20px;
      font-weight: 700;
    }
    .contactico {
      width: 16px;
      height: 16px;
    }
    .direction {
      background-color: #2b4bb6;
      text-align: center;
      height: 65px;
      padding-top: 7px;
    }
    .dirimg {
      height: 54px;
    }
    .directionhead {
      font-size: 20px;
      color: #2b4bb6;
      font-weight: 800;
      padding: 0px;
    }
    .directiontext {
      font-size: 16px;
      font-weight: 300;
      text-align: right;
      padding: 0px;
    }
    .contactstyle {
      padding: 10px;
      box-shadow: 8px 8px 5px #eaf7fc;
      margin-bottom: 15px;
    }
    .pdmob {
      padding: 0px 20px;
    }
  }
}
.aboutimg {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px 20px;
}