@media (-webkit-min-device-pixel-ratio: 1) and (-webkit-max-device-pixel-ratio: 1.24) {
  .franchiseinauguration {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 92px;
    .wrap {
      flex-wrap: nowrap;
    }
    .lblstyle {
      font-size: 18px;
      font-weight: 300;
    }

    .btnstyle {
      background-color: transparent;
      color: #2b4bb6;
      border-radius: 6px;
      border-color: transparent !important;
      margin-bottom: 10px;
      &:hover,
      &:focus,
      &:active {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border-color: transparent !important;
        margin-bottom: 10px;
      }
    }

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      color: #2b4bb6;
      background-color: #beebf8;
      border-color: transparent !important;
    }
    .subhead {
      font-size: 24px;
      font-weight: 700;
      color: #2b4bb6;
    }
    .img-style {
      height: 207px;
      border-radius: 6px;
      width: 100%;
      border: 1px solid #d9d9d9;
      &:hover {
        border: 3px solid #006ed3;
        border-radius: 10px;
        height: 207px;
        box-shadow: 3px 0px 6px 1px #006ed34d;
        width: 100%;
      }
    }
    .awardimg {
      height: 350px;
      border-radius: 6px;
      width: 100%;
      border: 1px solid #d9d9d9;
      &:hover {
        border: 3px solid #006ed3;
        border-radius: 10px;
        height: 350px;
        box-shadow: 3px 0px 6px 1px #006ed34d;
        width: 100%;
      }
    }
    .ffamily {
      font-family: "Indie Flower", cursive;
      color: #fff;
      font-size: 30px;
    }

    .sliderdiv {
      background-color: #2b4bb6;
      border-radius: 16px;
      height: 475px;
      overflow: hidden;
    }
    .sliderdivtraining {
      background-color: #2b4bb6;
      border-radius: 16px;
      height: 600px;
      overflow: hidden;
    }
    .sliderimg {
      height: 330px;
      border-radius: 8px;
      width: auto;
    }
    .sliderimgtraining {
      height: 460px;
      border-radius: 8px;
      width: auto;
    }

    .sliderimgmarketing {
      height: 460px;
      border-radius: 8px;
      width: auto;
    }
    @-moz-document url-prefix() {
      .sliderimgmarketing {
        margin-left: auto;
        margin-right: auto;
      }
      .sliderimgtraining {
        margin-left: auto;
        margin-right: auto;
      }
      .sliderimg {
        margin-left: auto;
        margin-right: auto;
      }
    }
    .slick-list {
      position: relative;
      display: block;
      overflow: visible;
      margin: 0;
      padding: 0;
    }

    .slick-slide {
      opacity: 0.3;
      transition: opacity 0.5s ease;
    }

    .slick-active {
      opacity: 1 !important;
    }
    .slick-dots li.slick-active button:before {
      opacity: 1;
      color: #fff;
      font-size: 16px;
    }

    .slick-dots li button:before {
      font-family: "slick";
      font-size: 16px;
      line-height: 20px;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      content: "•";
      text-align: center;
      opacity: 0.25;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 50%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .slick-prev,
    .slick-next {
      opacity: 0;
    }
    .viedostyle {
      height: 288px;
      border-radius: 12px;
      border: 1px solid #bdc6cb;
      .youtube {
        border-radius: 16px;
        position: absolute;
        transform: translate(-50%, -50%);
        color: #ff0000;
      }
    }

    .franchisebtn {
      background-color: #ff9133 !important;
      padding: 8px 24px;
      border-radius: 6px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover,
    .franchisebtn:active,
    .franchisebtn:focus-visible {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
    .cancel {
      background-color: #b0afae !important;
      padding: 8px 24px;
      border-radius: 6px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .cancel::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .cancel:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .cancel:hover,
    .cancel:active,
    .cancel:focus-visible {
      background-color: #b0afae; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .franchiseinauguration {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 92px;
      .viedostyle {
        height: 143px;
        border-radius: 12px;
        border: 1px solid #bdc6cb;
      }
      .wrap {
        flex-wrap: nowrap;
      }
      .lblstyle {
        font-size: 12px;
        font-weight: 300;
      }
      .fieldstyle {
        font-size: 12px;
        height: 32px;
        padding: 2px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 2px 20px;
        border-radius: 6px;
        height: 24px;
        font-size: 12px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .cancel {
        background-color: #b0afae;
        padding: 2px 20px;
        border-radius: 6px;
        height: 24px;
        font-size: 12px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .cancel::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .cancel:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .cancel:hover {
        background-color: #b0afae; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid transparent;
        margin-bottom: 10px;
        font-size: 12px;
        text-align: left;
        padding: 5px;
        font-weight: 500;
        &:hover,
        &:focus,
        &:active {
          background-color: #beebf8 !important;
          color: #2b4bb6;
          border-radius: 6px;
          border: 1px solid transparent;
          margin-bottom: 10px;
          font-size: 12px;
          text-align: left;
          padding: 5px;
          font-weight: 500;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 12px;
        font-weight: 500;
      }
      .subhead {
        font-size: 18px;
        font-weight: 700;
        color: #2b4bb6;
      }
      .img-style {
        height: 105px;
        border-radius: 6px;
        margin-bottom: 10px;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 105px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
        }
      }
      .awardimg {
        height: 200px;
        border-radius: 6px;
        width: 100%;
        border: 1px solid #d9d9d9;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 200px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
          width: 100%;
        }
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #fff;
        font-size: 30px;
      }

      .sliderdiv {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 330px;
        overflow: hidden;
      }
      .sliderdivtraining {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 400px;
        overflow: hidden;
        // margin: 0px;
      }
      .sliderimg {
        height: 180px;
      }
      .sliderimgtraining {
        height: 255px;
        border-radius: 8px;
        width: auto;
      }

      .sliderimgmarketing {
        height: 270px;
        border-radius: 8px;
        width: auto;
      }
      .slick-list {
        position: relative;
        display: block;
        overflow: visible;
        margin: 0;
        padding: 0;
      }

      .slick-slide {
        opacity: 0.3;
        transition: opacity 0.5s ease;
      }

      .slick-active {
        opacity: 1 !important;
      }
      .slick-dots li.slick-active button:before {
        opacity: 1;
        color: #fff;
        font-size: 16px;
      }

      .slick-dots li button:before {
        font-family: "slick";
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      .slick-prev,
      .slick-next {
        opacity: 0;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .franchiseinauguration {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 92px;
      .viedostyle {
        height: 188px;
        border-radius: 12px;
        border: 1px solid #bdc6cb;
      }
      .wrap {
        flex-wrap: nowrap;
      }
      .lblstyle {
        font-size: 12px;
        font-weight: 300;
      }
      .fieldstyle {
        font-size: 12px;
        height: 32px;
        padding: 2px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 2px 24px;
        border-radius: 6px;
        height: 24px;
        font-size: 12px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .cancel {
        background-color: #b0afae;
        padding: 2px 24px;
        border-radius: 6px;
        height: 24px;
        font-size: 12px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .cancel::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .cancel:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .cancel:hover {
        background-color: #b0afae; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid transparent;
        margin-bottom: 10px;
        font-size: 12px;
        text-align: left;
        padding: 5px;
        &:hover,
        &:focus,
        &:active {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          margin-bottom: 10px;
          font-size: 12px;
          border: 1px solid transparent;
          text-align: left;
          padding: 5px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 12px;
      }
      .subhead {
        font-size: 22px;
        font-weight: 700;
        color: #2b4bb6;
      }
      .img-style {
        height: 135px;
        border-radius: 6px;
        margin-bottom: 10px;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 135px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
        }
      }
      .awardimg {
        height: 220px;
        border-radius: 6px;
        width: 100%;
        border: 1px solid #d9d9d9;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 220px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
          width: 100%;
        }
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #fff;
        font-size: 30px;
      }

      .sliderdiv {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 375px;
        overflow: hidden;
      }
      .sliderdivtraining {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 460px;
        overflow: hidden;
        // margin: 0px;
      }
      .sliderimg {
        height: 238px;
      }
      .sliderimgtraining {
        height: 325px;
        border-radius: 8px;
        width: auto;
      }

      .sliderimgmarketing {
        height: 300px;
        border-radius: 8px;
        width: auto;
      }

      .slick-list {
        position: relative;
        display: block;
        overflow: visible;
        margin: 0;
        padding: 0;
      }

      .slick-slide {
        opacity: 0.3;
        transition: opacity 0.5s ease;
      }

      .slick-active {
        opacity: 1 !important;
      }
      .slick-dots li.slick-active button:before {
        opacity: 1;
        color: #fff;
        font-size: 16px;
      }

      .slick-dots li button:before {
        font-family: "slick";
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      .slick-prev,
      .slick-next {
        opacity: 0;
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1368px) {
    .franchiseinauguration {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 92px;
      .viedostyle {
        height: 201px;
        border-radius: 12px;
        border: 1px solid #bdc6cb;
      }
      .wrap {
        flex-wrap: nowrap;
      }
      .lblstyle {
        font-size: 14px;
        font-weight: 300;
      }
      .fieldstyle {
        font-size: 14px;
        height: 32px;
        padding: 2px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 4px 24px;
        border-radius: 6px;
        // height: 30px;
        font-size: 14px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .cancel {
        background-color: #b0afae;
        padding: 4px 24px;
        border-radius: 6px;
        // height: 30px;
        font-size: 14px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .cancel::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .cancel:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .cancel:hover {
        background-color: #b0afae; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid transparent;
        margin-bottom: 10px;
        font-size: 14px;
        text-align: left;
        padding: 5px;
        &:hover,
        &:focus,
        &:active {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          margin-bottom: 10px;
          font-size: 14px;
          border: 1px solid transparent;
          text-align: left;
          padding: 5px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 14px;
      }
      .subhead {
        font-size: 22px;
        font-weight: 700;
        color: #2b4bb6;
      }
      .img-style {
        height: 145px;
        border-radius: 6px;
        margin-bottom: 10px;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 145px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
        }
      }
      .awardimg {
        height: 240px;
        border-radius: 6px;
        width: 100%;
        border: 1px solid #d9d9d9;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 240px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
          width: 100%;
        }
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #fff;
        font-size: 30px;
      }

      .sliderdiv {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 395px;
        overflow: hidden;
      }
      .sliderdivtraining {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 485px;
        overflow: hidden;
        // margin: 0px;
      }
      .sliderimg {
        height: 257px;
      }
      .sliderimgtraining {
        height: 348px;
        border-radius: 8px;
        width: auto;
      }

      .sliderimgmarketing {
        height: 340px;
        border-radius: 8px;
        width: auto;
      }
      .slick-list {
        position: relative;
        display: block;
        overflow: visible;
        margin: 0;
        padding: 0;
      }

      .slick-slide {
        opacity: 0.3;
        transition: opacity 0.5s ease;
      }

      .slick-active {
        opacity: 1 !important;
      }
      .slick-dots li.slick-active button:before {
        opacity: 1;
        color: #fff;
        font-size: 16px;
      }

      .slick-dots li button:before {
        font-family: "slick";
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      .slick-prev,
      .slick-next {
        opacity: 0;
      }
    }
  }
  @media (min-width: 1369px) and (max-width: 1440px) {
    .franchiseinauguration {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 92px;
      .viedostyle {
        height: 213px;
        border-radius: 12px;
        border: 1px solid #bdc6cb;
      }
      .wrap {
        flex-wrap: nowrap;
      }
      .lblstyle {
        font-size: 14px;
        font-weight: 300;
      }
      .fieldstyle {
        font-size: 14px;
        padding: 2px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 4px 24px;
        border-radius: 6px;
        // height: 30px;
        font-size: 14px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .cancel {
        background-color: #b0afae;
        padding: 4px 24px;
        border-radius: 6px;
        // height: 30px;
        font-size: 14px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .cancel::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .cancel:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .cancel:hover {
        background-color: #b0afae; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid transparent;
        margin-bottom: 10px;
        font-size: 14px;
        text-align: left;
        padding: 5px;
        &:hover,
        &:focus,
        &:active {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          margin-bottom: 10px;
          font-size: 14px;
          border: 1px solid transparent;
          text-align: left;
          padding: 5px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 14px;
      }
      .subhead {
        font-size: 22px;
        font-weight: 700;
        color: #2b4bb6;
      }
      .img-style {
        height: 153px;
        border-radius: 6px;
        margin-bottom: 10px;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 153px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
        }
      }
      .awardimg {
        height: 250px;
        border-radius: 6px;
        width: 100%;
        border: 1px solid #d9d9d9;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 250px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
          width: 100%;
        }
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #fff;
        font-size: 30px;
      }

      .sliderdiv {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 395px;
        overflow: hidden;
      }
      .sliderdivtraining {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 485px;
        overflow: hidden;
        // margin: 0px;
      }
      .sliderimg {
        height: 270px;
      }
      .sliderimgtraining {
        height: 360px;
        border-radius: 8px;
        width: auto;
      }

      .sliderimgmarketing {
        height: 340px;
        border-radius: 8px;
        width: auto;
      }
      .slick-list {
        position: relative;
        display: block;
        overflow: visible;
        margin: 0;
        padding: 0;
      }

      .slick-slide {
        opacity: 0.3;
        transition: opacity 0.5s ease;
      }

      .slick-active {
        opacity: 1 !important;
      }
      .slick-dots li.slick-active button:before {
        opacity: 1;
        color: #fff;
        font-size: 16px;
      }

      .slick-dots li button:before {
        font-family: "slick";
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      .slick-prev,
      .slick-next {
        opacity: 0;
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .franchiseinauguration {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 92px;
      .viedostyle {
        height: 238px;
        border-radius: 12px;
        border: 1px solid #bdc6cb;
      }
      .wrap {
        flex-wrap: nowrap;
      }
      .lblstyle {
        font-size: 16px;
        font-weight: 300;
      }
      .fieldstyle {
        font-size: 16px;
        padding: 2px;
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid transparent;
        margin-bottom: 10px;
        font-size: 16px;
        text-align: left;
        // padding: 5px;
        &:hover,
        &:focus,
        &:active {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          margin-bottom: 10px;
          font-size: 16px;
          border: 1px solid transparent;
          text-align: left;
          // padding: 5px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 16px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 4px 20px;
        border-radius: 6px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .cancel {
        background-color: #b0afae;
        padding: 4px 20px;
        border-radius: 6px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .cancel::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .cancel:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .cancel:hover {
        background-color: #b0afae; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .subhead {
        font-size: 24px;
        font-weight: 700;
        color: #2b4bb6;
      }
      .img-style {
        height: 172px;
        border-radius: 6px;
        margin-bottom: 10px;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 172px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
        }
      }
      .awardimg {
        height: 280px;
        border-radius: 6px;
        width: 100%;
        border: 1px solid #d9d9d9;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 280px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
          width: 100%;
        }
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #fff;
        font-size: 30px;
      }

      .sliderdiv {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 465px;
        overflow: hidden;
      }
      .sliderdivtraining {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 520px;
        overflow: hidden;
        // margin: 0px;
      }
      .sliderimg {
        height: 300px;
      }
      .sliderimgtraining {
        height: 390px;
        border-radius: 8px;
        width: auto;
      }

      .sliderimgmarketing {
        height: 380px;
        border-radius: 8px;
        width: auto;
      }
      .slick-list {
        position: relative;
        display: block;
        overflow: visible;
        margin: 0;
        padding: 0;
      }

      .slick-slide {
        opacity: 0.3;
        transition: opacity 0.5s ease;
      }

      .slick-active {
        opacity: 1 !important;
      }
      .slick-dots li.slick-active button:before {
        opacity: 1;
        color: #fff;
        font-size: 16px;
      }

      .slick-dots li button:before {
        font-family: "slick";
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      .slick-prev,
      .slick-next {
        opacity: 0;
      }
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .franchiseinauguration {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      .viedostyle {
        height: 251px;
        border-radius: 12px;
        border: 1px solid #bdc6cb;
      }
      .wrap {
        flex-wrap: nowrap;
      }
      .lblstyle {
        font-size: 16px;
        font-weight: 300;
      }
      .fieldstyle {
        font-size: 16px;
        padding: 4px;
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid transparent;
        margin-bottom: 10px;
        // font-size: 14px;
        text-align: left;
        // padding: 5px;
        &:hover,
        &:focus,
        &:active {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          margin-bottom: 10px;
          // font-size: 14px;
          border: 1px solid transparent;
          text-align: left;
          // padding: 5px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        // font-size: 14px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 6px 20px;
        border-radius: 6px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .cancel {
        background-color: #b0afae;
        padding: 6px 20px;
        border-radius: 6px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .cancel::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .cancel:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .cancel:hover {
        background-color: #b0afae; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .subhead {
        font-size: 24px;
        font-weight: 700;
        color: #2b4bb6;
      }
      .img-style {
        height: 176px;
        border-radius: 6px;
        margin-bottom: 10px;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 176px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
        }
      }
      .awardimg {
        height: 310px;
        border-radius: 6px;
        width: 100%;
        border: 1px solid #d9d9d9;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 310px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
          width: 100%;
        }
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #fff;
        font-size: 30px;
      }

      .sliderdiv {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 465px;
        overflow: hidden;
      }
      .sliderdivtraining {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 560px;
        overflow: hidden;
        // margin: 0px;
      }
      .sliderimg {
        height: 319px;
      }
      .sliderimgtraining {
        height: 430px;
        border-radius: 8px;
        width: auto;
      }

      .sliderimgmarketing {
        height: 420px;
        border-radius: 8px;
        width: auto;
      }
      .slick-list {
        position: relative;
        display: block;
        overflow: visible;
        margin: 0;
        padding: 0;
      }

      .slick-slide {
        opacity: 0.3;
        transition: opacity 0.5s ease;
      }

      .slick-active {
        opacity: 1 !important;
      }
      .slick-dots li.slick-active button:before {
        opacity: 1;
        color: #fff;
        font-size: 16px;
      }

      .slick-dots li button:before {
        font-family: "slick";
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      .slick-prev,
      .slick-next {
        opacity: 0;
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.49) {
  .franchiseinauguration {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 92px;
    .wrap {
      flex-wrap: nowrap;
    }
    .btnstyle {
      background-color: transparent;
      color: #2b4bb6;
      border-radius: 6px;
      border-color: transparent !important;
      margin-bottom: 10px;
      &:hover,
      &:focus,
      &:active {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border-color: transparent !important;
        margin-bottom: 10px;
      }
    }

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      color: #2b4bb6;
      background-color: #beebf8;
      border-color: transparent !important;
    }
    .subhead {
      font-size: 24px;
      font-weight: 700;
      color: #2b4bb6;
    }
    .img-style {
      height: 207px;
      border-radius: 6px;
      width: 100%;
      border: 1px solid #d9d9d9;
      &:hover {
        border: 3px solid #006ed3;
        border-radius: 10px;
        height: 207px;
        box-shadow: 3px 0px 6px 1px #006ed34d;
        width: 100%;
      }
    }
    .awardimg {
      height: 350px;
      border-radius: 6px;
      width: 100%;
      border: 1px solid #d9d9d9;
      &:hover {
        border: 3px solid #006ed3;
        border-radius: 10px;
        height: 350px;
        box-shadow: 3px 0px 6px 1px #006ed34d;
        width: 100%;
      }
    }
    .ffamily {
      font-family: "Indie Flower", cursive;
      color: #fff;
      font-size: 30px;
    }

    .sliderdiv {
      background-color: #2b4bb6;
      border-radius: 16px;
      height: 524px;
      overflow: hidden;
    }
    .sliderdivtraining {
      background-color: #2b4bb6;
      border-radius: 16px;
      height: 600px;
      overflow: hidden;
    }
    .sliderimg {
      height: 370px;
      border-radius: 8px;
      width: auto;
    }
    .sliderimgtraining {
      height: 460px;
      border-radius: 8px;
      width: auto;
    }

    .sliderimgmarketing {
      height: 460px;
      border-radius: 8px;
      width: auto;
    }
    @-moz-document url-prefix() {
      .sliderimgmarketing {
        margin-left: auto;
        margin-right: auto;
      }
      .sliderimgtraining {
        margin-left: auto;
        margin-right: auto;
      }
      .sliderimg {
        margin-left: auto;
        margin-right: auto;
      }
    }
    .slick-list {
      position: relative;
      display: block;
      overflow: visible;
      margin: 0;
      padding: 0;
    }

    .slick-slide {
      opacity: 0.3;
      transition: opacity 0.5s ease;
    }

    .slick-active {
      opacity: 1 !important;
    }
    .slick-dots li.slick-active button:before {
      opacity: 1;
      color: #fff;
      font-size: 16px;
    }

    .slick-dots li button:before {
      font-family: "slick";
      font-size: 16px;
      line-height: 20px;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      content: "•";
      text-align: center;
      opacity: 0.25;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 50%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .slick-prev,
    .slick-next {
      opacity: 0;
    }
    .viedostyle {
      height: 288px;
      border-radius: 12px;
      border: 1px solid #bdc6cb;
    }
    .franchisebtn {
      background-color: #ff9133;
      padding: 8px 24px;
      border-radius: 6px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
    .cancel {
      background-color: #b0afae;
      padding: 8px 24px;
      border-radius: 6px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .cancel::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .cancel:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .cancel:hover {
      background-color: #b0afae; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .franchiseinauguration {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 92px;
      .viedostyle {
        height: 143px;
        border-radius: 12px;
        border: 1px solid #bdc6cb;
      }
      .wrap {
        flex-wrap: nowrap;
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid transparent;
        margin-bottom: 10px;
        font-size: 12px;
        text-align: left;
        padding: 5px;
        font-weight: 500;
        &:hover,
        &:focus,
        &:active {
          background-color: #beebf8 !important;
          color: #2b4bb6;
          border-radius: 6px;
          border: 1px solid transparent;
          margin-bottom: 10px;
          font-size: 12px;
          text-align: left;
          padding: 5px;
          font-weight: 500;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 12px;
        font-weight: 500;
      }
      .subhead {
        font-size: 18px;
        font-weight: 700;
        color: #2b4bb6;
      }
      .img-style {
        height: 105px;
        border-radius: 6px;
        margin-bottom: 10px;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 105px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
        }
      }
      .awardimg {
        height: 200px;
        border-radius: 6px;
        width: 100%;
        border: 1px solid #d9d9d9;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 200px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
          width: 100%;
        }
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #fff;
        font-size: 30px;
      }

      .sliderdiv {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 330px;
        overflow: hidden;
      }
      .sliderdivtraining {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 400px;
        overflow: hidden;
        // margin: 0px;
      }
      .sliderimg {
        height: 180px;
      }
      .sliderimgtraining {
        height: 255px;
        border-radius: 8px;
        width: auto;
      }

      .sliderimgmarketing {
        height: 270px;
        border-radius: 8px;
        width: auto;
      }
      .slick-list {
        position: relative;
        display: block;
        overflow: visible;
        margin: 0;
        padding: 0;
      }

      .slick-slide {
        opacity: 0.3;
        transition: opacity 0.5s ease;
      }

      .slick-active {
        opacity: 1 !important;
      }
      .slick-dots li.slick-active button:before {
        opacity: 1;
        color: #fff;
        font-size: 16px;
      }

      .slick-dots li button:before {
        font-family: "slick";
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      .slick-prev,
      .slick-next {
        opacity: 0;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .franchiseinauguration {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 92px;
      .viedostyle {
        height: 188px;
        border-radius: 12px;
        border: 1px solid #bdc6cb;
      }
      .wrap {
        flex-wrap: nowrap;
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid transparent;
        margin-bottom: 10px;
        font-size: 12px;
        text-align: left;
        padding: 5px;
        &:hover,
        &:focus,
        &:active {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          margin-bottom: 10px;
          font-size: 12px;
          border: 1px solid transparent;
          text-align: left;
          padding: 5px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 12px;
      }
      .subhead {
        font-size: 22px;
        font-weight: 700;
        color: #2b4bb6;
      }
      .img-style {
        height: 135px;
        border-radius: 6px;
        margin-bottom: 10px;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 135px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
        }
      }
      .awardimg {
        height: 220px;
        border-radius: 6px;
        width: 100%;
        border: 1px solid #d9d9d9;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 220px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
          width: 100%;
        }
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #fff;
        font-size: 30px;
      }

      .sliderdiv {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 375px;
        overflow: hidden;
      }
      .sliderdivtraining {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 460px;
        overflow: hidden;
        // margin: 0px;
      }
      .sliderimg {
        height: 238px;
      }
      .sliderimgtraining {
        height: 325px;
        border-radius: 8px;
        width: auto;
      }

      .sliderimgmarketing {
        height: 300px;
        border-radius: 8px;
        width: auto;
      }

      .slick-list {
        position: relative;
        display: block;
        overflow: visible;
        margin: 0;
        padding: 0;
      }

      .slick-slide {
        opacity: 0.3;
        transition: opacity 0.5s ease;
      }

      .slick-active {
        opacity: 1 !important;
      }
      .slick-dots li.slick-active button:before {
        opacity: 1;
        color: #fff;
        font-size: 16px;
      }

      .slick-dots li button:before {
        font-family: "slick";
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      .slick-prev,
      .slick-next {
        opacity: 0;
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1368px) {
    .franchiseinauguration {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 92px;
      .viedostyle {
        height: 198px;
        border-radius: 12px;
        border: 1px solid #bdc6cb;
      }
      .wrap {
        flex-wrap: nowrap;
      }
      .lblstyle {
        font-size: 14px;
        font-weight: 300;
      }
      .fieldstyle {
        font-size: 14px;
        padding: 2px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 2px 20px;
        border-radius: 6px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .cancel {
        background-color: #b0afae;
        padding: 2px 20px;
        border-radius: 6px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .cancel::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .cancel:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .cancel:hover {
        background-color: #b0afae; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid transparent;
        margin-bottom: 10px;
        font-size: 14px;
        text-align: left;
        padding: 5px;
        &:hover,
        &:focus,
        &:active {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          margin-bottom: 10px;
          font-size: 14px;
          border: 1px solid transparent;
          text-align: left;
          padding: 5px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 14px;
      }
      .subhead {
        font-size: 22px;
        font-weight: 700;
        color: #2b4bb6;
      }
      .img-style {
        height: 145px;
        border-radius: 6px;
        margin-bottom: 10px;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 145px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
        }
      }
      .awardimg {
        height: 225px;
        border-radius: 6px;
        width: 100%;
        border: 1px solid #d9d9d9;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 225px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
          width: 100%;
        }
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #fff;
        font-size: 28px;
      }

      .sliderdiv {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 375px;
        overflow: hidden;
      }
      .sliderdivtraining {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 465px;
        overflow: hidden;
        // margin: 0px;
      }
      .sliderimg {
        height: 245px;
      }
      .sliderimgtraining {
        height: 325px;
        border-radius: 8px;
        width: auto;
      }

      .sliderimgmarketing {
        height: 325px;
        border-radius: 8px;
        width: auto;
      }
      .slick-list {
        position: relative;
        display: block;
        overflow: visible;
        margin: 0;
        padding: 0;
      }

      .slick-slide {
        opacity: 0.3;
        transition: opacity 0.5s ease;
      }

      .slick-active {
        opacity: 1 !important;
      }
      .slick-dots li.slick-active button:before {
        opacity: 1;
        color: #fff;
        font-size: 16px;
      }

      .slick-dots li button:before {
        font-family: "slick";
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      .slick-prev,
      .slick-next {
        opacity: 0;
      }
    }
  }
  @media (min-width: 1369px) and (max-width: 1440px) {
    .franchiseinauguration {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 92px;
      .viedostyle {
        height: 213px;
        border-radius: 12px;
        border: 1px solid #bdc6cb;
      }
      .wrap {
        flex-wrap: nowrap;
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid transparent;
        margin-bottom: 10px;
        font-size: 14px;
        text-align: left;
        padding: 5px;
        &:hover,
        &:focus,
        &:active {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          margin-bottom: 10px;
          font-size: 14px;
          border: 1px solid transparent;
          text-align: left;
          padding: 5px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 14px;
      }
      .subhead {
        font-size: 22px;
        font-weight: 700;
        color: #2b4bb6;
      }
      .img-style {
        height: 153px;
        border-radius: 6px;
        margin-bottom: 10px;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 153px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
        }
      }
      .awardimg {
        height: 250px;
        border-radius: 6px;
        width: 100%;
        border: 1px solid #d9d9d9;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 250px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
          width: 100%;
        }
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #fff;
        font-size: 30px;
      }

      .sliderdiv {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 395px;
        overflow: hidden;
      }
      .sliderdivtraining {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 485px;
        overflow: hidden;
        // margin: 0px;
      }
      .sliderimg {
        height: 270px;
      }
      .sliderimgtraining {
        height: 360px;
        border-radius: 8px;
        width: auto;
      }

      .sliderimgmarketing {
        height: 340px;
        border-radius: 8px;
        width: auto;
      }
      .slick-list {
        position: relative;
        display: block;
        overflow: visible;
        margin: 0;
        padding: 0;
      }

      .slick-slide {
        opacity: 0.3;
        transition: opacity 0.5s ease;
      }

      .slick-active {
        opacity: 1 !important;
      }
      .slick-dots li.slick-active button:before {
        opacity: 1;
        color: #fff;
        font-size: 16px;
      }

      .slick-dots li button:before {
        font-family: "slick";
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      .slick-prev,
      .slick-next {
        opacity: 0;
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .franchiseinauguration {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 92px;
      .viedostyle {
        height: 228px;
        border-radius: 12px;
        border: 1px solid #bdc6cb;
      }
      .wrap {
        flex-wrap: nowrap;
      }
      .lblstyle {
        font-size: 14px;
        font-weight: 300;
      }
      .fieldstyle {
        font-size: 14px;
        padding: 2px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 4px 20px;
        border-radius: 6px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .cancel {
        background-color: #b0afae;
        padding: 4px 20px;
        border-radius: 6px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .cancel::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .cancel:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .cancel:hover {
        background-color: #b0afae; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid transparent;
        margin-bottom: 10px;
        font-size: 16px;
        text-align: left;
        // padding: 5px;
        &:hover,
        &:focus,
        &:active {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          margin-bottom: 10px;
          font-size: 16px;
          border: 1px solid transparent;
          text-align: left;
          // padding: 5px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 16px;
      }
      .subhead {
        font-size: 24px;
        font-weight: 700;
        color: #2b4bb6;
      }
      .img-style {
        height: 172px;
        border-radius: 6px;
        margin-bottom: 10px;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 172px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
        }
      }
      .awardimg {
        height: 245px;
        border-radius: 6px;
        width: 100%;
        border: 1px solid #d9d9d9;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 245px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
          width: 100%;
        }
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #fff;
        font-size: 30px;
      }

      .sliderdiv {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 415px;
        overflow: hidden;
      }
      .sliderdivtraining {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 490px;
        overflow: hidden;
        // margin: 0px;
      }
      .sliderimg {
        height: 265px;
      }
      .sliderimgtraining {
        height: 350px;
        border-radius: 8px;
        width: auto;
      }

      .sliderimgmarketing {
        height: 350px;
        border-radius: 8px;
        width: auto;
      }
      .slick-list {
        position: relative;
        display: block;
        overflow: visible;
        margin: 0;
        padding: 0;
      }

      .slick-slide {
        opacity: 0.3;
        transition: opacity 0.5s ease;
      }

      .slick-active {
        opacity: 1 !important;
      }
      .slick-dots li.slick-active button:before {
        opacity: 1;
        color: #fff;
        font-size: 16px;
      }

      .slick-dots li button:before {
        font-family: "slick";
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      .slick-prev,
      .slick-next {
        opacity: 0;
      }
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .franchiseinauguration {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 92px;
      .viedostyle {
        height: 251px;
        border-radius: 12px;
        border: 1px solid #bdc6cb;
      }
      .wrap {
        flex-wrap: nowrap;
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid transparent;
        margin-bottom: 10px;
        // font-size: 14px;
        text-align: left;
        // padding: 5px;
        &:hover,
        &:focus,
        &:active {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          margin-bottom: 10px;
          // font-size: 14px;
          border: 1px solid transparent;
          text-align: left;
          // padding: 5px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        // font-size: 14px;
      }
      .subhead {
        font-size: 24px;
        font-weight: 700;
        color: #2b4bb6;
      }
      .img-style {
        height: 176px;
        border-radius: 6px;
        margin-bottom: 10px;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 176px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
        }
      }
      .awardimg {
        height: 310px;
        border-radius: 6px;
        width: 100%;
        border: 1px solid #d9d9d9;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 310px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
          width: 100%;
        }
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #fff;
        font-size: 30px;
      }

      .sliderdiv {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 465px;
        overflow: hidden;
      }
      .sliderdivtraining {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 560px;
        overflow: hidden;
        // margin: 0px;
      }
      .sliderimg {
        height: 319px;
      }
      .sliderimgtraining {
        height: 430px;
        border-radius: 8px;
        width: auto;
      }

      .sliderimgmarketing {
        height: 420px;
        border-radius: 8px;
        width: auto;
      }
      .slick-list {
        position: relative;
        display: block;
        overflow: visible;
        margin: 0;
        padding: 0;
      }

      .slick-slide {
        opacity: 0.3;
        transition: opacity 0.5s ease;
      }

      .slick-active {
        opacity: 1 !important;
      }
      .slick-dots li.slick-active button:before {
        opacity: 1;
        color: #fff;
        font-size: 16px;
      }

      .slick-dots li button:before {
        font-family: "slick";
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      .slick-prev,
      .slick-next {
        opacity: 0;
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 1.5) {
  .franchiseinauguration {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 92px;
    .wrap {
      flex-wrap: nowrap;
    }
    .lblstyle {
      font-size: 18px;
      font-weight: 300;
    }

    .btnstyle {
      background-color: transparent;
      color: #2b4bb6;
      border-radius: 6px;
      border-color: transparent !important;
      margin-bottom: 10px;
      &:hover,
      &:focus,
      &:active {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border-color: transparent !important;
        margin-bottom: 10px;
      }
    }

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      color: #2b4bb6;
      background-color: #beebf8;
      border-color: transparent !important;
    }
    .subhead {
      font-size: 24px;
      font-weight: 700;
      color: #2b4bb6;
    }
    .img-style {
      height: 207px;
      border-radius: 6px;
      width: 100%;
      border: 1px solid #d9d9d9;
      &:hover {
        border: 3px solid #006ed3;
        border-radius: 10px;
        height: 207px;
        box-shadow: 3px 0px 6px 1px #006ed34d;
        width: 100%;
      }
    }
    .awardimg {
      height: 320px;
      border-radius: 6px;
      width: 100%;
      border: 1px solid #d9d9d9;
      &:hover {
        border: 3px solid #006ed3;
        border-radius: 10px;
        height: 320px;
        box-shadow: 3px 0px 6px 1px #006ed34d;
        width: 100%;
      }
    }
    .ffamily {
      font-family: "Indie Flower", cursive;
      color: #fff;
      font-size: 30px;
    }

    .sliderdiv {
      background-color: #2b4bb6;
      border-radius: 16px;
      height: 460px;
      overflow: hidden;
    }
    .sliderdivtraining {
      background-color: #2b4bb6;
      border-radius: 16px;
      height: 570px;
      overflow: hidden;
    }
    .sliderimg {
      height: 310px;
      border-radius: 8px;
      width: auto;
    }
    .sliderimgtraining {
      height: 420px;
      border-radius: 8px;
      width: auto;
    }

    .sliderimgmarketing {
      height: 420px;
      border-radius: 8px;
      width: auto;
    }
    @-moz-document url-prefix() {
      .sliderimgmarketing {
        margin-left: auto;
        margin-right: auto;
      }
      .sliderimgtraining {
        margin-left: auto;
        margin-right: auto;
      }
      .sliderimg {
        margin-left: auto;
        margin-right: auto;
      }
    }
    .slick-list {
      position: relative;
      display: block;
      overflow: visible;
      margin: 0;
      padding: 0;
    }

    .slick-slide {
      opacity: 0.3;
      transition: opacity 0.5s ease;
    }

    .slick-active {
      opacity: 1 !important;
    }
    .slick-dots li.slick-active button:before {
      opacity: 1;
      color: #fff;
      font-size: 16px;
    }

    .slick-dots li button:before {
      font-family: "slick";
      font-size: 16px;
      line-height: 20px;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      content: "•";
      text-align: center;
      opacity: 0.25;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 50%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .slick-prev,
    .slick-next {
      opacity: 0;
    }
    .viedostyle {
      height: 260px;
      border-radius: 12px;
      border: 1px solid #bdc6cb;
    }
    .franchisebtn {
      background-color: #ff9133 !important;
      padding: 8px 24px;
      border-radius: 6px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover,
    .franchisebtn:active,
    .franchisebtn:focus-visible {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
    .cancel {
      background-color: #b0afae !important;
      padding: 8px 24px;
      border-radius: 6px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .cancel::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .cancel:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .cancel:hover,
    .cancel:active,
    .cancel:focus-visible {
      background-color: #b0afae; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    .franchiseinauguration {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 92px;
      .viedostyle {
        height: 143px;
        border-radius: 12px;
        border: 1px solid #bdc6cb;
      }
      .wrap {
        flex-wrap: nowrap;
      }
      .lblstyle {
        font-size: 12px;
        font-weight: 300;
      }
      .fieldstyle {
        font-size: 12px;
        height: 32px;
        padding: 2px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 2px 20px;
        border-radius: 6px;
        height: 24px;
        font-size: 12px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .cancel {
        background-color: #b0afae;
        padding: 2px 20px;
        border-radius: 6px;
        height: 24px;
        font-size: 12px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .cancel::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .cancel:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .cancel:hover {
        background-color: #b0afae; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid transparent;
        margin-bottom: 10px;
        font-size: 12px;
        text-align: left;
        padding: 5px;
        font-weight: 500;
        &:hover,
        &:focus,
        &:active {
          background-color: #beebf8 !important;
          color: #2b4bb6;
          border-radius: 6px;
          border: 1px solid transparent;
          margin-bottom: 10px;
          font-size: 12px;
          text-align: left;
          padding: 5px;
          font-weight: 500;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 12px;
        font-weight: 500;
      }
      .subhead {
        font-size: 18px;
        font-weight: 700;
        color: #2b4bb6;
      }
      .img-style {
        height: 105px;
        border-radius: 6px;
        margin-bottom: 10px;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 105px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
        }
      }
      .awardimg {
        height: 200px;
        border-radius: 6px;
        width: 100%;
        border: 1px solid #d9d9d9;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 200px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
          width: 100%;
        }
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #fff;
        font-size: 30px;
      }

      .sliderdiv {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 330px;
        overflow: hidden;
      }
      .sliderdivtraining {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 400px;
        overflow: hidden;
        // margin: 0px;
      }
      .sliderimg {
        height: 180px;
      }
      .sliderimgtraining {
        height: 255px;
        border-radius: 8px;
        width: auto;
      }

      .sliderimgmarketing {
        height: 270px;
        border-radius: 8px;
        width: auto;
      }
      .slick-list {
        position: relative;
        display: block;
        overflow: visible;
        margin: 0;
        padding: 0;
      }

      .slick-slide {
        opacity: 0.3;
        transition: opacity 0.5s ease;
      }

      .slick-active {
        opacity: 1 !important;
      }
      .slick-dots li.slick-active button:before {
        opacity: 1;
        color: #fff;
        font-size: 16px;
      }

      .slick-dots li button:before {
        font-family: "slick";
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      .slick-prev,
      .slick-next {
        opacity: 0;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    .franchiseinauguration {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 92px;
      .viedostyle {
        height: 188px;
        border-radius: 12px;
        border: 1px solid #bdc6cb;
      }
      .wrap {
        flex-wrap: nowrap;
      }
      .lblstyle {
        font-size: 12px;
        font-weight: 300;
      }
      .fieldstyle {
        font-size: 12px;
        height: 32px;
        padding: 2px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 2px 24px;
        border-radius: 6px;
        height: 24px;
        font-size: 12px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .cancel {
        background-color: #b0afae;
        padding: 2px 24px;
        border-radius: 6px;
        height: 24px;
        font-size: 12px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .cancel::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .cancel:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .cancel:hover {
        background-color: #b0afae; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid transparent;
        margin-bottom: 10px;
        font-size: 12px;
        text-align: left;
        padding: 5px;
        &:hover,
        &:focus,
        &:active {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          margin-bottom: 10px;
          font-size: 12px;
          border: 1px solid transparent;
          text-align: left;
          padding: 5px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 12px;
      }
      .subhead {
        font-size: 22px;
        font-weight: 700;
        color: #2b4bb6;
      }
      .img-style {
        height: 135px;
        border-radius: 6px;
        margin-bottom: 10px;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 135px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
        }
      }
      .awardimg {
        height: 220px;
        border-radius: 6px;
        width: 100%;
        border: 1px solid #d9d9d9;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 220px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
          width: 100%;
        }
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #fff;
        font-size: 30px;
      }

      .sliderdiv {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 375px;
        overflow: hidden;
      }
      .sliderdivtraining {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 460px;
        overflow: hidden;
        // margin: 0px;
      }
      .sliderimg {
        height: 238px;
      }
      .sliderimgtraining {
        height: 325px;
        border-radius: 8px;
        width: auto;
      }

      .sliderimgmarketing {
        height: 300px;
        border-radius: 8px;
        width: auto;
      }

      .slick-list {
        position: relative;
        display: block;
        overflow: visible;
        margin: 0;
        padding: 0;
      }

      .slick-slide {
        opacity: 0.3;
        transition: opacity 0.5s ease;
      }

      .slick-active {
        opacity: 1 !important;
      }
      .slick-dots li.slick-active button:before {
        opacity: 1;
        color: #fff;
        font-size: 16px;
      }

      .slick-dots li button:before {
        font-family: "slick";
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      .slick-prev,
      .slick-next {
        opacity: 0;
      }
    }
  }
  @media (min-width: 1281px) and (max-width: 1368px) {
    .franchiseinauguration {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 92px;
      .viedostyle {
        height: 201px;
        border-radius: 12px;
        border: 1px solid #bdc6cb;
      }
      .wrap {
        flex-wrap: nowrap;
      }
      .lblstyle {
        font-size: 14px;
        font-weight: 300;
      }
      .fieldstyle {
        font-size: 14px;
        height: 32px;
        padding: 2px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 4px 24px;
        border-radius: 6px;
        // height: 30px;
        font-size: 14px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .cancel {
        background-color: #b0afae;
        padding: 4px 24px;
        border-radius: 6px;
        // height: 30px;
        font-size: 14px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .cancel::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .cancel:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .cancel:hover {
        background-color: #b0afae; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid transparent;
        margin-bottom: 10px;
        font-size: 14px;
        text-align: left;
        padding: 5px;
        &:hover,
        &:focus,
        &:active {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          margin-bottom: 10px;
          font-size: 14px;
          border: 1px solid transparent;
          text-align: left;
          padding: 5px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 14px;
      }
      .subhead {
        font-size: 22px;
        font-weight: 700;
        color: #2b4bb6;
      }
      .img-style {
        height: 145px;
        border-radius: 6px;
        margin-bottom: 10px;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 145px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
        }
      }
      .awardimg {
        height: 240px;
        border-radius: 6px;
        width: 100%;
        border: 1px solid #d9d9d9;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 240px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
          width: 100%;
        }
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #fff;
        font-size: 30px;
      }

      .sliderdiv {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 395px;
        overflow: hidden;
      }
      .sliderdivtraining {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 485px;
        overflow: hidden;
        // margin: 0px;
      }
      .sliderimg {
        height: 257px;
      }
      .sliderimgtraining {
        height: 348px;
        border-radius: 8px;
        width: auto;
      }

      .sliderimgmarketing {
        height: 340px;
        border-radius: 8px;
        width: auto;
      }
      .slick-list {
        position: relative;
        display: block;
        overflow: visible;
        margin: 0;
        padding: 0;
      }

      .slick-slide {
        opacity: 0.3;
        transition: opacity 0.5s ease;
      }

      .slick-active {
        opacity: 1 !important;
      }
      .slick-dots li.slick-active button:before {
        opacity: 1;
        color: #fff;
        font-size: 16px;
      }

      .slick-dots li button:before {
        font-family: "slick";
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      .slick-prev,
      .slick-next {
        opacity: 0;
      }
    }
  }
  @media (min-width: 1369px) and (max-width: 1440px) {
    .franchiseinauguration {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 92px;
      .viedostyle {
        height: 213px;
        border-radius: 12px;
        border: 1px solid #bdc6cb;
      }
      .wrap {
        flex-wrap: nowrap;
      }
      .lblstyle {
        font-size: 14px;
        font-weight: 300;
      }
      .fieldstyle {
        font-size: 14px;
        padding: 2px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 4px 24px;
        border-radius: 6px;
        // height: 30px;
        font-size: 14px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .cancel {
        background-color: #b0afae;
        padding: 4px 24px;
        border-radius: 6px;
        // height: 30px;
        font-size: 14px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .cancel::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .cancel:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .cancel:hover {
        background-color: #b0afae; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid transparent;
        margin-bottom: 10px;
        font-size: 14px;
        text-align: left;
        padding: 5px;
        &:hover,
        &:focus,
        &:active {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          margin-bottom: 10px;
          font-size: 14px;
          border: 1px solid transparent;
          text-align: left;
          padding: 5px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 14px;
      }
      .subhead {
        font-size: 22px;
        font-weight: 700;
        color: #2b4bb6;
      }
      .img-style {
        height: 153px;
        border-radius: 6px;
        margin-bottom: 10px;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 153px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
        }
      }
      .awardimg {
        height: 250px;
        border-radius: 6px;
        width: 100%;
        border: 1px solid #d9d9d9;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 250px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
          width: 100%;
        }
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #fff;
        font-size: 30px;
      }

      .sliderdiv {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 395px;
        overflow: hidden;
      }
      .sliderdivtraining {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 485px;
        overflow: hidden;
        // margin: 0px;
      }
      .sliderimg {
        height: 270px;
      }
      .sliderimgtraining {
        height: 360px;
        border-radius: 8px;
        width: auto;
      }

      .sliderimgmarketing {
        height: 340px;
        border-radius: 8px;
        width: auto;
      }
      .slick-list {
        position: relative;
        display: block;
        overflow: visible;
        margin: 0;
        padding: 0;
      }

      .slick-slide {
        opacity: 0.3;
        transition: opacity 0.5s ease;
      }

      .slick-active {
        opacity: 1 !important;
      }
      .slick-dots li.slick-active button:before {
        opacity: 1;
        color: #fff;
        font-size: 16px;
      }

      .slick-dots li button:before {
        font-family: "slick";
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      .slick-prev,
      .slick-next {
        opacity: 0;
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .franchiseinauguration {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 92px;
      .viedostyle {
        height: 238px;
        border-radius: 12px;
        border: 1px solid #bdc6cb;
      }
      .wrap {
        flex-wrap: nowrap;
      }
      .lblstyle {
        font-size: 16px;
        font-weight: 300;
      }
      .fieldstyle {
        font-size: 16px;
        padding: 2px;
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid transparent;
        margin-bottom: 10px;
        font-size: 16px;
        text-align: left;
        // padding: 5px;
        &:hover,
        &:focus,
        &:active {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          margin-bottom: 10px;
          font-size: 16px;
          border: 1px solid transparent;
          text-align: left;
          // padding: 5px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        font-size: 16px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 4px 20px;
        border-radius: 6px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .cancel {
        background-color: #b0afae;
        padding: 4px 20px;
        border-radius: 6px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .cancel::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .cancel:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .cancel:hover {
        background-color: #b0afae; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .subhead {
        font-size: 24px;
        font-weight: 700;
        color: #2b4bb6;
      }
      .img-style {
        height: 172px;
        border-radius: 6px;
        margin-bottom: 10px;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 172px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
        }
      }
      .awardimg {
        height: 280px;
        border-radius: 6px;
        width: 100%;
        border: 1px solid #d9d9d9;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 280px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
          width: 100%;
        }
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #fff;
        font-size: 30px;
      }

      .sliderdiv {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 465px;
        overflow: hidden;
      }
      .sliderdivtraining {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 520px;
        overflow: hidden;
        // margin: 0px;
      }
      .sliderimg {
        height: 300px;
      }
      .sliderimgtraining {
        height: 390px;
        border-radius: 8px;
        width: auto;
      }

      .sliderimgmarketing {
        height: 380px;
        border-radius: 8px;
        width: auto;
      }
      .slick-list {
        position: relative;
        display: block;
        overflow: visible;
        margin: 0;
        padding: 0;
      }

      .slick-slide {
        opacity: 0.3;
        transition: opacity 0.5s ease;
      }

      .slick-active {
        opacity: 1 !important;
      }
      .slick-dots li.slick-active button:before {
        opacity: 1;
        color: #fff;
        font-size: 16px;
      }

      .slick-dots li button:before {
        font-family: "slick";
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      .slick-prev,
      .slick-next {
        opacity: 0;
      }
    }
  }
  @media (min-width: 1601px) and (max-width: 1680px) {
    .franchiseinauguration {
      font-family: "Be Vietnam Pro", sans-serif;
      font-style: normal;
      margin-top: 92px;
      .viedostyle {
        height: 251px;
        border-radius: 12px;
        border: 1px solid #bdc6cb;
      }
      .wrap {
        flex-wrap: nowrap;
      }
      .lblstyle {
        font-size: 16px;
        font-weight: 300;
      }
      .fieldstyle {
        font-size: 16px;
        padding: 4px;
      }
      .btnstyle {
        background-color: transparent;
        color: #2b4bb6;
        border-radius: 6px;
        border: 1px solid transparent;
        margin-bottom: 10px;
        // font-size: 14px;
        text-align: left;
        // padding: 5px;
        &:hover,
        &:focus,
        &:active {
          background-color: #beebf8 !important;
          color: #2b4bb6 !important;
          border-radius: 6px;
          margin-bottom: 10px;
          // font-size: 14px;
          border: 1px solid transparent;
          text-align: left;
          // padding: 5px;
        }
      }

      .btn-check:checked + .btn,
      .btn.active,
      .btn.show,
      .btn:first-child:active,
      :not(.btn-check) + .btn:active {
        color: #2b4bb6;
        background-color: #beebf8;
        border-color: transparent;
        // font-size: 14px;
      }
      .franchisebtn {
        background-color: #ff9133;
        padding: 6px 20px;
        border-radius: 6px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .franchisebtn::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .franchisebtn:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .franchisebtn:hover {
        background-color: #ff9133; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .cancel {
        background-color: #b0afae;
        padding: 6px 20px;
        border-radius: 6px;
        border-color: transparent !important;
        position: relative;
        overflow: hidden;
        z-index: 1;
        color: white; /* Ensure the text color is set for visibility */
        text-align: center; /* Center align text horizontally */
        display: inline-flex; /* Aligns text vertically */
        align-items: center; /* Aligns text vertically */
        justify-content: center; /* Aligns text horizontally */
        cursor: pointer;
        transition: all 0.4s ease-in-out;
      }

      .cancel::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-in-out;
        z-index: 0;
      }

      .cancel:hover::before {
        transform: translate(-50%, -50%) scale(1);
      }

      .cancel:hover {
        background-color: #b0afae; /* Ensure background color remains the same */
        color: white; /* Ensure text color remains visible on hover */
      }
      .subhead {
        font-size: 24px;
        font-weight: 700;
        color: #2b4bb6;
      }
      .img-style {
        height: 176px;
        border-radius: 6px;
        margin-bottom: 10px;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 176px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
        }
      }
      .awardimg {
        height: 310px;
        border-radius: 6px;
        width: 100%;
        border: 1px solid #d9d9d9;
        &:hover {
          border: 3px solid #006ed3;
          border-radius: 10px;
          height: 310px;
          box-shadow: 3px 0px 6px 1px #006ed34d;
          width: 100%;
        }
      }
      .ffamily {
        font-family: "Indie Flower", cursive;
        color: #fff;
        font-size: 30px;
      }

      .sliderdiv {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 465px;
        overflow: hidden;
      }
      .sliderdivtraining {
        background-color: #2b4bb6;
        border-radius: 16px;
        height: 560px;
        overflow: hidden;
        // margin: 0px;
      }
      .sliderimg {
        height: 319px;
      }
      .sliderimgtraining {
        height: 430px;
        border-radius: 8px;
        width: auto;
      }

      .sliderimgmarketing {
        height: 420px;
        border-radius: 8px;
        width: auto;
      }
      .slick-list {
        position: relative;
        display: block;
        overflow: visible;
        margin: 0;
        padding: 0;
      }

      .slick-slide {
        opacity: 0.3;
        transition: opacity 0.5s ease;
      }

      .slick-active {
        opacity: 1 !important;
      }
      .slick-dots li.slick-active button:before {
        opacity: 1;
        color: #fff;
        font-size: 16px;
      }

      .slick-dots li button:before {
        font-family: "slick";
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.25;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      .slick-prev,
      .slick-next {
        opacity: 0;
      }
    }
  }
}
@media (max-width: 389px) {
  .franchiseinauguration {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 92px;
    .viedostyle {
      height: 92px;
      border-radius: 12px;
      border: 1px solid #bdc6cb;
    }
    .wrap {
      flex-wrap: wrap;
      display: flex;
    }
    .lblstyle {
      font-size: 14px;
      font-weight: 300;
    }
    .fieldstyle {
      font-size: 14px;
      // height: 32px;
      padding: 2px;
    }
    .cancel {
      background-color: #b0afae;
      padding: 4px 24px;
      border-radius: 6px;
      height: 30px;
      font-size: 12px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .cancel::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .cancel:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .cancel:hover {
      background-color: #b0afae; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
    .franchisebtn {
      background-color: #ff9133;
      padding: 4px 24px;
      border-radius: 6px;
      height: 30px;
      font-size: 12px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
    .btnstyle {
      background-color: transparent;
      color: #2b4bb6;
      border-radius: 6px;
      border: 1px solid transparent;
      margin-bottom: 10px;
      font-size: 14px;
      padding: 5px;
      font-weight: 500;
      &:hover,
      &:focus,
      &:active {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
        font-size: 14px;
        padding: 5px;
        font-weight: 500;
      }
    }

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      color: #2b4bb6;
      background-color: #beebf8;
      border-color: transparent;
      font-size: 14px;
      padding: 5px;
      font-weight: 500;
    }
    .subhead {
      font-size: 24px;
      font-weight: 700;
      color: #2b4bb6;
    }
    .img-style {
      height: 94px;
      border-radius: 6px;
      margin-bottom: 10px;
      &:hover {
        border: 3px solid #006ed3;
        border-radius: 10px;
        height: 94px;
        box-shadow: 3px 0px 6px 1px #006ed34d;
      }
    }
    .awardimg {
      height: 140px;
      border-radius: 6px;
      width: 100%;
      border: 1px solid #d9d9d9;
      &:hover {
        border: 3px solid #006ed3;
        border-radius: 10px;
        height: 140px;
        box-shadow: 3px 0px 6px 1px #006ed34d;
        width: 100%;
      }
    }

    .ffamily {
      font-family: "Indie Flower", cursive;
      color: #fff;
      font-size: 30px;
    }

    .sliderdiv {
      background-color: #2b4bb6;
      border-radius: 16px;
      height: 300px;
      overflow: hidden;
      margin: 0px;
    }
    .sliderdivtraining {
      background-color: #2b4bb6;
      border-radius: 16px;
      height: 400px;
      overflow: hidden;
      margin: 0px;
      .slick-dots {
        position: absolute;
        bottom: -62px;
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
      }
    }

    .sliderimg {
      height: 148px;
    }
    .sliderimgtraining {
      height: 200px;
      border-radius: 8px;
      width: auto;
    }

    .sliderimgmarketing {
      height: 210px;
      border-radius: 8px;
      width: auto;
    }
    .slick-list {
      position: relative;
      display: block;
      overflow: visible;
      margin: 0;
      padding: 0;
    }

    .slick-slide {
      opacity: 0.3;
      transition: opacity 0.5s ease;
    }

    .slick-active {
      opacity: 1 !important;
    }
    .slick-dots li.slick-active button:before {
      opacity: 1;
      color: #fff;
      font-size: 16px;
    }

    .slick-dots li button:before {
      font-family: "slick";
      font-size: 16px;
      line-height: 20px;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      content: "•";
      text-align: center;
      opacity: 0.25;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 50%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .slick-prev,
    .slick-next {
      opacity: 0;
    }
  }
}
@media (min-width: 390px) and (max-width: 411px) {
  .franchiseinauguration {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 92px;
    .viedostyle {
      height: 96px;
      border-radius: 12px;
      border: 1px solid #bdc6cb;
    }
    .wrap {
      flex-wrap: wrap;
      display: flex;
    }
    .lblstyle {
      font-size: 14px;
      font-weight: 300;
    }
    .fieldstyle {
      font-size: 14px;
      // height: 32px;
      padding: 2px;
    }
    .cancel {
      background-color: #b0afae;
      padding: 4px 24px;
      border-radius: 6px;
      height: 30px;
      font-size: 12px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .cancel::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .cancel:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .cancel:hover {
      background-color: #b0afae; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
    .franchisebtn {
      background-color: #ff9133;
      padding: 4px 24px;
      border-radius: 6px;
      height: 30px;
      font-size: 12px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
    .btnstyle {
      background-color: transparent;
      color: #2b4bb6;
      border-radius: 6px;
      border: 1px solid transparent;
      margin-bottom: 10px;
      font-size: 14px;
      padding: 5px;
      font-weight: 500;
      &:hover,
      &:focus,
      &:active {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
        font-size: 14px;
        padding: 5px;
        font-weight: 500;
      }
    }

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      color: #2b4bb6;
      background-color: #beebf8;
      border-color: transparent;
      font-size: 14px;
      padding: 5px;
      font-weight: 500;
    }
    .subhead {
      font-size: 24px;
      font-weight: 700;
      color: #2b4bb6;
    }
    .img-style {
      height: 100px;
      border-radius: 6px;
      margin-bottom: 10px;
      &:hover {
        border: 3px solid #006ed3;
        border-radius: 10px;
        height: 100px;
        box-shadow: 3px 0px 6px 1px #006ed34d;
      }
    }
    .awardimg {
      height: 150px;
      border-radius: 6px;
      width: 100%;
      border: 1px solid #d9d9d9;
      &:hover {
        border: 3px solid #006ed3;
        border-radius: 10px;
        height: 150px;
        box-shadow: 3px 0px 6px 1px #006ed34d;
        width: 100%;
      }
    }

    .ffamily {
      font-family: "Indie Flower", cursive;
      color: #fff;
      font-size: 30px;
    }

    .sliderdiv {
      background-color: #2b4bb6;
      border-radius: 16px;
      height: 332px;
      overflow: hidden;
      margin: 0px;
    }
    .sliderdivtraining {
      background-color: #2b4bb6;
      border-radius: 16px;
      height: 400px;
      overflow: hidden;
      margin: 0px;
      .slick-dots {
        position: absolute;
        bottom: -62px;
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
      }
    }

    .sliderimg {
      height: 161px;
    }
    .sliderimgtraining {
      height: 215px;
      border-radius: 8px;
      width: auto;
    }

    .sliderimgmarketing {
      height: 230px;
      border-radius: 8px;
      width: auto;
    }
    .slick-list {
      position: relative;
      display: block;
      overflow: visible;
      margin: 0;
      padding: 0;
    }

    .slick-slide {
      opacity: 0.3;
      transition: opacity 0.5s ease;
    }

    .slick-active {
      opacity: 1 !important;
    }
    .slick-dots li.slick-active button:before {
      opacity: 1;
      color: #fff;
      font-size: 16px;
    }

    .slick-dots li button:before {
      font-family: "slick";
      font-size: 16px;
      line-height: 20px;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      content: "•";
      text-align: center;
      opacity: 0.25;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 50%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .slick-prev,
    .slick-next {
      opacity: 0;
    }
  }
}
@media (min-width: 411px) and (max-width: 415px) {
  .franchiseinauguration {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 92px;
    .viedostyle {
      height: 102px;
      border-radius: 12px;
      border: 1px solid #bdc6cb;
    }
    .wrap {
      flex-wrap: wrap;
      display: flex;
    }
    .lblstyle {
      font-size: 14px;
      font-weight: 300;
    }
    .fieldstyle {
      font-size: 14px;
      // height: 32px;
      padding: 3px;
    }
    .cancel {
      background-color: #b0afae;
      padding: 4px 24px;
      border-radius: 6px;
      height: 30px;
      font-size: 12px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .cancel::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .cancel:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .cancel:hover {
      background-color: #b0afae; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
    .franchisebtn {
      background-color: #ff9133;
      padding: 4px 24px;
      border-radius: 6px;
      height: 30px;
      font-size: 12px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
    .btnstyle {
      background-color: transparent;
      color: #2b4bb6;
      border-radius: 6px;
      border: 1px solid transparent;
      margin-bottom: 10px;
      font-size: 14px;
      padding: 5px;
      font-weight: 500;
      &:hover,
      &:focus,
      &:active {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
        font-size: 14px;
        padding: 5px;
        font-weight: 500;
      }
    }

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      color: #2b4bb6;
      background-color: #beebf8;
      border-color: transparent;
      font-size: 14px;
      padding: 5px;
      font-weight: 500;
    }
    .subhead {
      font-size: 24px;
      font-weight: 700;
      color: #2b4bb6;
    }
    .img-style {
      height: 110px;
      border-radius: 6px;
      margin-bottom: 10px;
      &:hover {
        border: 3px solid #006ed3;
        border-radius: 10px;
        height: 110px;
        box-shadow: 3px 0px 6px 1px #006ed34d;
      }
    }
    .awardimg {
      height: 155px;
      border-radius: 6px;
      width: 100%;
      border: 1px solid #d9d9d9;
      &:hover {
        border: 3px solid #006ed3;
        border-radius: 10px;
        height: 155px;
        box-shadow: 3px 0px 6px 1px #006ed34d;
        width: 100%;
      }
    }

    .ffamily {
      font-family: "Indie Flower", cursive;
      color: #fff;
      font-size: 30px;
    }

    .sliderdiv {
      background-color: #2b4bb6;
      border-radius: 16px;
      height: 332px;
      overflow: hidden;
      margin: 0px;
    }
    .sliderdivtraining {
      background-color: #2b4bb6;
      border-radius: 16px;
      height: 390px;
      overflow: hidden;
      margin: 0px;
      .slick-dots {
        position: absolute;
        bottom: -62px;
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
      }
    }
    .sliderimg {
      height: 171px;
    }
    .sliderimgtraining {
      height: 225px;
      border-radius: 8px;
      width: auto;
    }

    .sliderimgmarketing {
      height: 220px;
      border-radius: 8px;
      width: auto;
    }
    .slick-list {
      position: relative;
      display: block;
      overflow: visible;
      margin: 0;
      padding: 0;
    }

    .slick-slide {
      opacity: 0.3;
      transition: opacity 0.5s ease;
    }

    .slick-active {
      opacity: 1 !important;
    }
    .slick-dots li.slick-active button:before {
      opacity: 1;
      color: #fff;
      font-size: 16px;
    }

    .slick-dots li button:before {
      font-family: "slick";
      font-size: 16px;
      line-height: 20px;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      content: "•";
      text-align: center;
      opacity: 0.25;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 50%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .slick-prev,
    .slick-next {
      opacity: 0;
    }
  }
}
@media (min-width: 416px) and (max-width: 539px) {
  .franchiseinauguration {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 92px;
    .viedostyle {
      height: 107px;
      border-radius: 12px;
      border: 1px solid #bdc6cb;
    }
    .wrap {
      flex-wrap: wrap;
      display: flex;
    }
    .lblstyle {
      font-size: 14px;
      font-weight: 300;
    }
    .fieldstyle {
      font-size: 14px;
      height: 32px;
      padding: 2px;
    }
    .cancel {
      background-color: #b0afae;
      padding: 4px 24px;
      border-radius: 6px;
      height: 30px;
      font-size: 15px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .cancel::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .cancel:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .cancel:hover {
      background-color: #b0afae; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
    .franchisebtn {
      background-color: #ff9133;
      padding: 4px 24px;
      border-radius: 6px;
      height: 30px;
      font-size: 15px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
    .btnstyle {
      background-color: transparent;
      color: #2b4bb6;
      border-radius: 6px;
      border: 1px solid transparent;
      margin-bottom: 10px;
      font-size: 14px;
      padding: 5px;
      font-weight: 500;
      &:hover,
      &:focus,
      &:active {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
        font-size: 14px;
        padding: 5px;
        font-weight: 500;
      }
    }

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      color: #2b4bb6;
      background-color: #beebf8;
      border-color: transparent;
      font-size: 14px;
      padding: 5px;
      font-weight: 500;
    }
    .subhead {
      font-size: 24px;
      font-weight: 700;
      color: #2b4bb6;
    }
    .img-style {
      height: 115px;
      border-radius: 6px;
      margin-bottom: 10px;
      &:hover {
        border: 3px solid #006ed3;
        border-radius: 10px;
        height: 115px;
        box-shadow: 3px 0px 6px 1px #006ed34d;
      }
    }
    .awardimg {
      height: 165px;
      border-radius: 6px;
      width: 100%;
      border: 1px solid #d9d9d9;
      &:hover {
        border: 3px solid #006ed3;
        border-radius: 10px;
        height: 165px;
        box-shadow: 3px 0px 6px 1px #006ed34d;
        width: 100%;
      }
    }

    .ffamily {
      font-family: "Indie Flower", cursive;
      color: #fff;
      font-size: 30px;
    }

    .sliderdiv {
      background-color: #2b4bb6;
      border-radius: 16px;
      height: 332px;
      overflow: hidden;
      margin: 0px;
    }
    .sliderdivtraining {
      background-color: #2b4bb6;
      border-radius: 16px;
      height: 405px;
      overflow: hidden;
      margin: 0px;
      .slick-dots {
        position: absolute;
        bottom: -62px;
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
      }
    }
    .sliderimg {
      height: 178px;
    }
    .sliderimgtraining {
      height: 240px;
      border-radius: 8px;
      width: auto;
    }

    .sliderimgmarketing {
      height: 240px;
      border-radius: 8px;
      width: auto;
    }
    .slick-list {
      position: relative;
      display: block;
      overflow: visible;
      margin: 0;
      padding: 0;
    }

    .slick-slide {
      opacity: 0.3;
      transition: opacity 0.5s ease;
    }

    .slick-active {
      opacity: 1 !important;
    }
    .slick-dots li.slick-active button:before {
      opacity: 1;
      color: #fff;
      font-size: 16px;
    }

    .slick-dots li button:before {
      font-family: "slick";
      font-size: 16px;
      line-height: 20px;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      content: "•";
      text-align: center;
      opacity: 0.25;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 50%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .slick-prev,
    .slick-next {
      opacity: 0;
    }
  }
}
@media (min-width: 540px) and (max-width: 767px) {
  .franchiseinauguration {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 92px;
    .viedostyle {
      height: 136px;
      border-radius: 12px;
      border: 1px solid #bdc6cb;
    }
    .wrap {
      flex-wrap: wrap;
      display: flex;
    }
    .lblstyle {
      font-size: 14px;
      font-weight: 300;
    }
    .fieldstyle {
      font-size: 14px;
      height: 32px;
      padding: 5px;
    }
    .cancel {
      background-color: #b0afae;
      padding: 4px 24px;
      border-radius: 6px;
      height: 36px;
      font-size: 15px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .cancel::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .cancel:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .cancel:hover {
      background-color: #b0afae; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
    .franchisebtn {
      background-color: #ff9133;
      padding: 4px 24px;
      border-radius: 6px;
      height: 36px;
      font-size: 15px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
    .btnstyle {
      background-color: transparent;
      color: #2b4bb6;
      border-radius: 6px;
      border: 1px solid transparent;
      margin-bottom: 10px;
      &:hover,
      &:focus,
      &:active {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
      }
    }

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      color: #2b4bb6;
      background-color: #beebf8;
      border-color: transparent;
    }
    .subhead {
      font-size: 24px;
      font-weight: 700;
      color: #2b4bb6;
    }
    .img-style {
      height: 147px;
      border-radius: 6px;
      margin-bottom: 10px;
      &:hover {
        border: 3px solid #006ed3;
        border-radius: 10px;
        height: 147px;
        box-shadow: 3px 0px 6px 1px #006ed34d;
      }
    }
    .awardimg {
      height: 200px;
      border-radius: 6px;
      width: 100%;
      border: 1px solid #d9d9d9;
      &:hover {
        border: 3px solid #006ed3;
        border-radius: 10px;
        height: 200px;
        box-shadow: 3px 0px 6px 1px #006ed34d;
        width: 100%;
      }
    }
    .ffamily {
      font-family: "Indie Flower", cursive;
      color: #fff;
      font-size: 30px;
    }

    .sliderdiv {
      background-color: #2b4bb6;
      border-radius: 16px;
      height: 395px;
      overflow: hidden;
      margin: 0px;
    }
    .sliderdivtraining {
      background-color: #2b4bb6;
      border-radius: 16px;
      height: 450px;
      overflow: hidden;
      margin: 0px;
      .slick-dots {
        position: absolute;
        bottom: -30px;
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
      }
    }
    .sliderimg {
      height: 232px;
    }
    .sliderimgtraining {
      height: 315px;
      border-radius: 8px;
      width: auto;
    }

    .sliderimgmarketing {
      height: 315px;
      border-radius: 8px;
      width: auto;
    }
    .slick-list {
      position: relative;
      display: block;
      overflow: visible;
      margin: 0;
      padding: 0;
    }

    .slick-slide {
      opacity: 0.3;
      transition: opacity 0.5s ease;
    }

    .slick-active {
      opacity: 1 !important;
    }
    .slick-dots li.slick-active button:before {
      opacity: 1;
      color: #fff;
      font-size: 16px;
    }

    .slick-dots li button:before {
      font-family: "slick";
      font-size: 16px;
      line-height: 20px;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      content: "•";
      text-align: center;
      opacity: 0.25;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 50%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .slick-prev,
    .slick-next {
      opacity: 0;
    }
  }
}
@media (min-width: 768px) and (max-width: 819px) {
  .franchiseinauguration {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 92px;
    .wrap {
      flex-wrap: wrap;
    }
    .lblstyle {
      font-size: 14px;
      font-weight: 300;
    }
    .fieldstyle {
      font-size: 14px;
      //  height: 32px;
      padding: 5px;
    }
    .viedostyle {
      height: 147px;
      border-radius: 12px;
      border: 1px solid #bdc6cb;
    }
    .cancel {
      background-color: #b0afae;
      padding: 4px 24px;
      border-radius: 6px;
      height: 36px;
      font-size: 14px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .cancel::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .cancel:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .cancel:hover {
      background-color: #b0afae; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
    .franchisebtn {
      background-color: #ff9133;
      padding: 4px 24px;
      border-radius: 6px;
      height: 36px;
      font-size: 14px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
    .btnstyle {
      background-color: transparent;
      color: #2b4bb6;
      border-radius: 6px;
      border: 1px solid transparent;
      margin-bottom: 10px;
      font-size: 14px;
      text-align: left;
      padding: 5px;
      &:hover,
      &:focus,
      &:active {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
        font-size: 14px;
        text-align: left;
        padding: 5px;
      }
    }

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      color: #2b4bb6;
      background-color: #beebf8;
      border-color: transparent;
      font-size: 14px;
      text-align: left;
      padding: 5px;
    }
    .subhead {
      font-size: 24px;
      font-weight: 700;
      color: #2b4bb6;
    }
    .img-style {
      height: 103px;
      border-radius: 6px;
      margin-bottom: 10px;
      &:hover {
        border: 3px solid #006ed3;
        border-radius: 10px;
        height: 103px;
        box-shadow: 3px 0px 6px 1px #006ed34d;
      }
    }
    .awardimg {
      height: 220px;
      border-radius: 6px;
      width: 100%;
      border: 1px solid #d9d9d9;
      &:hover {
        border: 3px solid #006ed3;
        border-radius: 10px;
        height: 220px;
        box-shadow: 3px 0px 6px 1px #006ed34d;
        width: 100%;
      }
    }

    .ffamily {
      font-family: "Indie Flower", cursive;
      color: #fff;
      font-size: 30px;
    }

    .sliderdiv {
      background-color: #2b4bb6;
      border-radius: 16px;
      height: 420px;
      overflow: hidden;
      margin: 0px;
    }
    .sliderdivtraining {
      background-color: #2b4bb6;
      border-radius: 16px;
      height: 480px;
      overflow: hidden;
      margin: 0px;
    }
    .sliderimg {
      height: 250px;
    }
    .sliderimgtraining {
      height: 340px;
      border-radius: 8px;
      width: auto;
    }

    .sliderimgmarketing {
      height: 348px;
      border-radius: 8px;
      width: auto;
    }
    .slick-list {
      position: relative;
      display: block;
      overflow: visible;
      margin: 0;
      padding: 0;
    }

    .slick-slide {
      opacity: 0.3;
      transition: opacity 0.5s ease;
    }

    .slick-active {
      opacity: 1 !important;
    }
    .slick-dots li.slick-active button:before {
      opacity: 1;
      color: #fff;
      font-size: 16px;
    }

    .slick-dots li button:before {
      font-family: "slick";
      font-size: 16px;
      line-height: 20px;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      content: "•";
      text-align: center;
      opacity: 0.25;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 50%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .slick-prev,
    .slick-next {
      opacity: 0;
    }
  }
}
@media (min-width: 820px) and (max-width: 991px) {
  .franchiseinauguration {
    font-family: "Be Vietnam Pro", sans-serif;
    font-style: normal;
    margin-top: 92px;
    .wrap {
      flex-wrap: wrap;
    }
    .lblstyle {
      font-size: 14px;
      font-weight: 300;
    }
    .fieldstyle {
      font-size: 14px;
      //  height: 32px;
      padding: 5px;
    }
    .viedostyle {
      height: 154px;
      border-radius: 12px;
      border: 1px solid #bdc6cb;
    }
    .cancel {
      background-color: #b0afae;
      padding: 4px 24px;
      border-radius: 6px;
      height: 36px;
      font-size: 14px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .cancel::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .cancel:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .cancel:hover {
      background-color: #b0afae; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
    .franchisebtn {
      background-color: #ff9133;
      padding: 4px 24px;
      border-radius: 6px;
      height: 36px;
      font-size: 14px;
      border-color: transparent !important;
      position: relative;
      overflow: hidden;
      z-index: 1;
      color: white; /* Ensure the text color is set for visibility */
      text-align: center; /* Center align text horizontally */
      display: inline-flex; /* Aligns text vertically */
      align-items: center; /* Aligns text vertically */
      justify-content: center; /* Aligns text horizontally */
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }

    .franchisebtn::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.4s ease-in-out;
      z-index: 0;
    }

    .franchisebtn:hover::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .franchisebtn:hover {
      background-color: #ff9133; /* Ensure background color remains the same */
      color: white; /* Ensure text color remains visible on hover */
    }
    .btnstyle {
      background-color: transparent;
      color: #2b4bb6;
      border-radius: 6px;
      border: 1px solid transparent;
      margin-bottom: 10px;
      font-size: 14px;
      text-align: left;
      padding: 5px;
      &:hover,
      &:focus,
      &:active {
        background-color: #beebf8 !important;
        color: #2b4bb6 !important;
        border-radius: 6px;
        border: transparent;
        margin-bottom: 10px;
        font-size: 14px;
        text-align: left;
        padding: 5px;
      }
    }

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      color: #2b4bb6;
      background-color: #beebf8;
      border-color: transparent;
      font-size: 14px;
      text-align: left;
      padding: 5px;
    }
    .subhead {
      font-size: 24px;
      font-weight: 700;
      color: #2b4bb6;
    }
    .img-style {
      height: 113px;
      border-radius: 6px;
      margin-bottom: 10px;
      &:hover {
        border: 3px solid #006ed3;
        border-radius: 10px;
        height: 113px;
        box-shadow: 3px 0px 6px 1px #006ed34d;
      }
    }
    .awardimg {
      height: 220px;
      border-radius: 6px;
      width: 100%;
      border: 1px solid #d9d9d9;
      &:hover {
        border: 3px solid #006ed3;
        border-radius: 10px;
        height: 220px;
        box-shadow: 3px 0px 6px 1px #006ed34d;
        width: 100%;
      }
    }
    .ffamily {
      font-family: "Indie Flower", cursive;
      color: #fff;
      font-size: 30px;
    }

    .sliderdiv {
      background-color: #2b4bb6;
      border-radius: 16px;
      height: 420px;
      overflow: hidden;
      margin: 0px;
    }
    .sliderdivtraining {
      background-color: #2b4bb6;
      border-radius: 16px;
      height: 480px;
      overflow: hidden;
      margin: 0px;
    }
    .sliderimg {
      height: 263px;
    }
    .sliderimgtraining {
      height: 350px;
      border-radius: 8px;
      width: auto;
    }

    .sliderimgmarketing {
      height: 350px;
      border-radius: 8px;
      width: auto;
    }

    .slick-list {
      position: relative;
      display: block;
      overflow: visible;
      margin: 0;
      padding: 0;
    }

    .slick-slide {
      opacity: 0.3;
      transition: opacity 0.5s ease;
    }

    .slick-active {
      opacity: 1 !important;
    }
    .slick-dots li.slick-active button:before {
      opacity: 1;
      color: #fff;
      font-size: 16px;
    }

    .slick-dots li button:before {
      font-family: "slick";
      font-size: 16px;
      line-height: 20px;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      content: "•";
      text-align: center;
      opacity: 0.25;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 50%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .slick-prev,
    .slick-next {
      opacity: 0;
    }
  }
}

.bgImageStyle{
  background-size: cover;
  background-repeat: no-repeat;
  align-content: center;
  text-align: center;
  img{
    cursor: pointer;
  }
}