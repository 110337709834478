@media (-webkit-min-device-pixel-ratio: 1) and (-webkit-max-device-pixel-ratio: 1.24) {
.head{
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    color: #2b4bb6;
    @media (min-width:360px) and (max-width:415px){
        font-size: 30px;
    }
    @media (min-width:992px) and (max-width:1280px){
        font-size: 30px;
    }
    @media (min-width:1281px) and (max-width:1368px){
        font-size: 34px;
    }
}
.iconbg{
    background-color: #00B8FF;
    border-radius: 50%;
    border: none;
}
.chatbot-container {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .chatbot-container .react-chatbot-kit-chat-container {
    height: 500px;
    overflow: hidden;
    overflow-y: auto;
  }
  .react-chatbot-kit-chat-header {
    background-color: #2b4bb6;
    padding: 15px;
    color: #fff;
}
}
@media (-webkit-min-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.49) {
    .head{
        font-size: 36px;
        font-weight: 700;
        text-align: center;
        color: #2b4bb6;
        @media (min-width:360px) and (max-width:415px){
            font-size: 30px;
        }
        @media (min-width:992px) and (max-width:1280px){
            font-size: 30px;
        }
        @media (min-width:1281px) and (max-width:1368px){
            font-size: 30px;
        }
    }
    .iconbg{
        background-color: #00B8FF;
        border-radius: 50%;
        border: none;
    }
    }

@media (-webkit-min-device-pixel-ratio: 1.50) {
        .head{
            font-size: 36px;
            font-weight: 700;
            text-align: center;
            color: #2b4bb6;
            @media (min-width:360px) and (max-width:415px){
                font-size: 30px;
            }
            @media (min-width:992px) and (max-width:1280px){
                font-size: 30px;
            }
            @media (min-width:1281px) and (max-width:1368px){
                font-size: 34px;
            }
        }
        .iconbg{
            background-color: #00B8FF;
            border-radius: 50%;
            border: none;
        }
        .chatbot-container {
            max-width: 400px;
            width: 100%;
            margin: 0 auto;
            padding: 20px;
            background-color: #f5f5f5;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          
          .chatbot-container .react-chatbot-kit-chat-container {
            height: 500px;
            overflow: hidden;
            overflow-y: auto;
          }
          .react-chatbot-kit-chat-header {
            background-color: #2b4bb6;
            padding: 15px;
            color: #fff;
        }   
}